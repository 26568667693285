import { Button, Divider, Form, Input, Modal, notification, Select } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import API_URL from '../../Constants/api-path';
import "../../assests/css/custom.css"
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";

const CharityModal = ({ show, setShow, viewId, fetchData, allPaymentData, resetAll }) => {
    const [form] = Form.useForm();
    const userData = JSON.parse(localStorage.getItem("expertData"));
    const [values, setValues] = useState({
        name_of_charity: "",
        contact_person_name: "",
        contact_number: "",
        email: "",
        country: "",
        website_link: "",
        donation_link: "",
        expert_id: userData?.data?._id,
    });
    const once = useRef(false)
    const handleReset = () => {
        once.current = false
        setValues({
            name_of_charity: "",
            contact_person_name: "",
            contact_number: "",
            email: "",
            country: "",
            website_link: "",
            donation_link: "",
            expert_id: userData?.data?._id,
        })
        form.resetFields()
    }

    useEffect(() => {
        if (show && !once.current) {
            once.current = true
            let item = allPaymentData?.filter((type) => type?.payment_method === "charity")?.[0]
            let obj = {
                ...values,
                name_of_charity: item?.name_of_charity,
                contact_person_name: item?.contact_person_name,
                contact_number: item?.contact_number,
                email: item?.email,
                country: item?.country,
                website_link: item?.website_link,
                donation_link: item?.donation_link,
                expert_id: userData?.data?._id,
            }
            setValues(obj)
            form.setFieldsValue(obj)
        }
    }, [allPaymentData, form, show, userData?.data?._id, values])

    const [loading, setLoading] = useState(false);

    const handleInputs = (e) => {
        const key = e.target.name;
        const value = e.target.value;

        setValues({ ...values, [key]: value });
    };
    const handleCountry = (value) => {
        const selectValue = value; // Set "USD" if the value is falsy
        setValues({ ...values, country: selectValue });
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            if (viewId) {
                const res = await fetch(`${API_URL.EXPERT_PAYMENT_UPDATE}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    },
                    body: JSON.stringify({
                        payment_method: "charity",
                        payment_id: viewId,
                        ...values,
                    }),
                });

                const result = await res.json();
                if (result) {
                    resetAll()
                    fetchData();
                    if (result.status === 200 || result.status === true) {
                        notification.success({
                            message: "Payment details has been updated",
                            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                            style: {
                                backgroundColor: "#2ecc71",
                                color: "#fff !important",
                                border: "1px solid #52c41a",
                            },
                            duration: 5,
                            placement: "topRight",
                        });
                        handleReset()
                        setShow(false);
                    }
                } else {
                    notification.error({
                        message: "Error: Something went wrong server error",
                        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                        style: {
                            backgroundColor: "#e74c3c",
                            color: "#fff",
                            border: "1px solid #c0392b",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }
            } else {
                const res = await fetch(`${API_URL.EXPERT_PAYMENT_ADD}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userData?.token}`,
                    },
                    body: JSON.stringify({
                        payment_method: "charity",
                        ...values,
                    }),
                });

                const result = await res.json();
                if (result) {
                    fetchData();
                    if (result.status === 200 || result.status === true) {
                        setShow(false);
                        handleReset()
                        notification.success({
                            message: "Payment method has been updated",
                            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                            style: {
                                backgroundColor: "#2ecc71",
                                color: "#fff !important",
                                border: "1px solid #52c41a",
                            },
                            duration: 5,
                            placement: "topRight",
                        });

                    }
                } else {
                    notification.error({
                        message: "Error: Something went wrong server error",
                        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                        style: {
                            backgroundColor: "#e74c3c",
                            color: "#fff",
                            border: "1px solid #c0392b",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }
            }
        } catch (error) {
            setLoading(false);

            notification.error({
                message: "Error: Something went wrong server error",
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                    backgroundColor: "#e74c3c",
                    color: "#fff",
                    border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
            });
        }
        setLoading(false);
    };

    return (
        <Modal
            title={<div>
                Add Charity Details
                <Divider className="m-2" />
            </div>}
            open={show}
            centered
            width={1000}
            onCancel={() => {
                setShow(false)
                handleReset()
            }}
            className="modal_scroll removeScrollbar"
            footer={false}
        >
            <Form
                layout="vertical"
                autoComplete="off"
                name='charity_form'
                initialValues={values}
                form={form}
                onFinish={() => {
                    handleSubmit()
                }}
            >
                <Row>
                    <Col xs={12} md={4}>
                        <Form.Item
                            name="name_of_charity"
                            label="Name of Charity"
                            rules={[
                                {
                                    required: true,
                                    message: "Name of Charity is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder=""
                                name="name_of_charity"
                                value={values?.name_of_charity}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Item
                            name="contact_person_name"
                            label="Contact Person Name"
                            rules={[
                                {
                                    required: true,
                                    message: "Contact Person Name is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder=""
                                name="contact_person_name"
                                value={values?.contact_person_name}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>

                    <Col xs={12} md={4}>
                        <Form.Item
                            name="country"
                            label="Country"
                            rules={[
                                {
                                    required: true,
                                    message: "Country is required",
                                },
                            ]}
                        >
                            <Select
                                size="large"
                                style={{ width: "100%" }}
                                name="country"
                                value={values?.country}
                                onChange={handleCountry}
                                options={[
                                    {
                                        value: "India",
                                        label: "India",
                                    },
                                    {
                                        value: "Foreign",
                                        label: "Foreign",
                                    },
                                ]}
                            ></Select>
                        </Form.Item>
                    </Col>

                    <Col xs={12} md={6}>
                        <Form.Item
                            name="contact_number"
                            label="Contact Number With Country Code"
                            rules={[
                                {
                                    required: true,
                                    message: "Contact Number With Country Code is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder="+91 XXXXX XXXXX"
                                name="contact_number"
                                value={values?.contact_number}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Item name="email" label="Email" rules={[
                            {
                                type: "email",
                                message: "Please enter a valid email address!",
                            },
                            {
                                required: true,
                                message: "Email is required!",
                            },
                        ]}>
                            <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder=""
                                name="email"
                                value={values?.email}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Item
                            name="website_link"
                            label="Website Link"
                            rules={[
                                {
                                    required: true,
                                    message: "Website Link is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder=""
                                name="website_link"
                                value={values?.website_link}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Item
                            name="donation_link"
                            label="Donation Link"
                            rules={[
                                {
                                    required: true,
                                    message: "Donation Link is required",
                                },
                            ]}
                        >
                            <Input
                                type="text"
                                autoComplete="nope"
                                size="large"
                                placeholder=""
                                name="donation_link"
                                value={values?.donation_link}
                                onChange={handleInputs}
                            ></Input>
                        </Form.Item>
                    </Col>
                    <Col className='d-flex align-items-center justify-content-end gap-2'>
                        <Form.Item className='mb-0'>
                            <Button
                                className="expertButtonNext"
                                onClick={() => {
                                    setShow(false)
                                    handleReset()
                                }}
                                htmlType='button'
                            >
                                Close
                            </Button>
                        </Form.Item>
                        <Form.Item className='mb-0'>
                            <Button
                                className="viewButton"
                                htmlType='submit'
                            >
                                Submit
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

        </Modal>
    )
}

export default CharityModal
