import Aos from "aos";
import "aos/dist/aos.css";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import nextyn_logo from "../../../assests/images/NX19.1.png";
import "./Register.css";
import nextyn_mob_logo from "../../../assests/images/NX19.png";
import RegistrationStep1 from "./RegistrationStep1";
import Introduction from "./Introduction";
import RegistrationStep2 from "./RegistrationStep2";
import useStep from "../../../CustomHooks/UseStep";
import RegistrationStep3 from "./RegistrationStep3";
import RegistrationStep4 from "./RegistrationStep4";
import ExpertAuth from "../../components/ExpertAuth";
import { Spin } from "antd";
import { Col, Row } from "react-bootstrap";
import RegistarConsultantStep1 from "./RegistarConsultantStep1";
import RegistarConsultantStep2 from "./RegistarConsultantStep2";
import RegisterConsultantStep4 from "./RegisterConsultantStep4";
import RegisterConsultantStep3 from "./RegisterConsultantStep3";

function Register() {
  const { currentStep, setCurrentStep, currentStepConsultant, setCurrentStepConsultant, registrationType, setRegistrationType } = useStep();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams()
  Aos.init({
    offset: 100,
    easing: "ease",
    delay: 0,
    once: true,
    duration: 800,
  });
  const queryParameters = useMemo(() => new URLSearchParams(window.location.search), []);
  const [defaultFileList, setDefaultFileList] = useState([]);

  useEffect(() => {
    if (JSON.parse(queryParameters.get("step_and_reg")) === 12) {
      setRegistrationType(2)
      setCurrentStepConsultant(1);
    } else if (JSON.parse(queryParameters.get("step_and_reg")) === 11) {
      setCurrentStep(1);
      setRegistrationType(1)
    } else if (JSON.parse(queryParameters.get("regType") || queryParameters.get("regtype")) === 2) {
      setRegistrationType(2)
      setCurrentStepConsultant(JSON.parse(queryParameters.get("step")));
    } else if (JSON.parse(queryParameters.get("regType") || queryParameters.get("regtype")) === 1) {
      setRegistrationType(1)
      setCurrentStep(JSON.parse(queryParameters.get("step")));
    } else {
      setCurrentStepConsultant(null);
      setCurrentStep(null);
    }
  }, [queryParameters, setCurrentStep, setCurrentStepConsultant, registrationType, setRegistrationType]);

  document.title = "Register";
  const renderRegistrationStepAccordingToType = useCallback(() => {
    if (registrationType === 1) {
      return currentStep === 1 ? (
        <RegistrationStep1 />
      ) : currentStep === 2 ? (
        <RegistrationStep2 />
      ) : currentStep === 3 ? (
        <RegistrationStep3
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      ) : currentStep === 4 ? (
        <RegistrationStep4 />
      ) : (
        <Introduction />
      )
    } else {

      return currentStepConsultant === 1 ? (
        <RegistarConsultantStep1 />
      ) : currentStepConsultant === 2 ? (
        <RegistarConsultantStep2 defaultFileList={defaultFileList} setDefaultFileList={setDefaultFileList} />
      ) : currentStepConsultant === 3 ? (
        <RegisterConsultantStep3 />
      ) : currentStepConsultant === 4 ? (
        <RegisterConsultantStep4 defaultFileList={defaultFileList} setDefaultFileList={setDefaultFileList} />
      ) : (
        <Introduction />
      )
    }
  }, [currentStep, currentStepConsultant, defaultFileList, isLoading, registrationType])

  return (
    <>
      <ExpertAuth />
      <div
        className={
          isLoading
            ? "text-center loginScreenLinkedin active"
            : "text-center loginScreenLinkedin"
        }
      >
        <Spin size="large" />
      </div>
      <div className="login-page">
        <div className="">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col d-none d-lg-block">
              <div className="login-left-part">
                <Row className="justify-content-center">
                  <Col xs={11} className="mt-3">
                    <NavLink to="/expert-login" className="logo">
                      <img
                        src={nextyn_logo}
                        alt="logo"
                        style={{ maxWidth: "30%" }}
                      />
                    </NavLink>
                  </Col>
                  <Col lg={8} className="logo-in-div">
                    <h1
                      className="text-left text-white loginText"
                      data-aos="fade-down"
                    >
                      The easiest way for businesses to collaborate with the
                      world's leading experts and consultants.
                    </h1>
                  </Col>

                  <Col lg={8}>
                    <div className="img-box-1 mt-4">
                      <img src="images/Login.gif" alt="" />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="col">
              <div className="row justify-content-center align-items-lg-center scrollableCls">
                <div className="comon-fild col col-lg-10">
                  <div className="inside-login registerPage">
                    <NavLink
                      to="/expert-login"
                      className="logo d-block d-lg-none mb-5"
                    >
                      <img src={nextyn_mob_logo} alt="logo" />
                    </NavLink>
                    <h1>Register</h1>
                    <h3>{searchParams?.get("regType") === "1" ? "Expert " : searchParams?.get("regType") === "2" ? "Independent Consultant" : "Expert Or Independent Consultant"}</h3>
                    {renderRegistrationStepAccordingToType()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Register;
