import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BsChevronLeft } from "../../Utils/Icons";
import Sidebar from "../../components/Sidebar";
import AddNewExpertSection from "./AddNewExpertSection";
import { resetFields } from "./addNewExpertForm.slice";

function ProjectsDetails() {

  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  document.title = "Expert Cloud -  Projects Details";

  // if (projectDetails.status === false) {
  //   alert("The action you have requested is not allowed.");
  //   window.location = "/projects";
  //   return;
  // }
  // if (Loading) {
  //   return (
  //     <div className="d-flex justify-content-center mt-4">
  //       <div role="status">
  //         <Spinner animation="border" />
  //       </div>
  //     </div>
  //   );
  // } else
  const navigate = useNavigate()
  const dispatch = useDispatch()
  return (
    <>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div>
            {/* <div className="d-block d-lg-block">
                <div className="top-bar-menu float-start">
                  <TopMenu2 />
                </div>
              </div> */}

            <div className="bk-div float-start w-100">
              <div className="body-expart-div request-page projects-scope">
                <div className="d-md-flex top-ps-seection justify-content-between align-items-center">
                  <h4 className="d-flex align-items-center">
                    <span
                      // to={{
                      //   pathname: "/dashboard/expert_list",
                      //   search: `?${createSearchParams({ project_id })}`,
                      // }}
                      onClick={() => {
                        dispatch(resetFields())
                        navigate(-1)
                      }}
                      style={{ textAlign: "left", cursor: "pointer" }}
                    >
                      <BsChevronLeft className="me-3 search-btn-back" />
                    </span>
                    Add Expert Profile
                  </h4>
                  <div className="d-flex justify-content-center align-items-center">
                    <ul className="nav nav-pills mb-3 mt-4" id="pills-tab" role="tablist">
                      {/* <li className="nav-item" role="presentation">
                        <NavLink
                          to={{
                            pathname: "/dashboard/project_details",
                            search: `?${createSearchParams({ project_id })}`,
                          }}
                          className={`nav-link ${activeTab === "overview" ? "active" : ""}`}
                        >
                          Overview
                        </NavLink>
                      </li>
                      <li className="nav-item" role="presentation">
                        <NavLink
                          to={{
                            pathname: "/dashboard/chats",
                            search: `?${createSearchParams({ project_id })}`,
                          }}
                          className={`nav-link ${activeTab === "messages" ? "active" : ""}`}
                        >
                          Messages
                        </NavLink>
                      </li> */}
                      {/* <li className="nav-item" role="presentation">
                        <NavLink
                          to={{
                            pathname: "/dashboard/expert_list",
                            search: `?${createSearchParams({ project_id })}`,
                          }}
                          className={`nav-link ${activeTab === "addExpert" ? "active" : ""}`}
                        >
                          Experts
                        </NavLink>
                      </li> */}
                    </ul>
                  </div>


                  <div>
                    <div style={{ color: "#F8F8F8" }}>Create Project</div>
                  </div>
                </div>

                {/* <span className="sp-hr w-100" /> */}

                <div className="request-body-sec projects-details-page">
                  <div className="d-md-flex justify-content-center align-items-center">
                    <div className="addexpert-section-divs pt-0">
                      <AddNewExpertSection />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default ProjectsDetails;
