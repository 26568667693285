import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { AiFillCheckCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
// import ExpiredPage from "../components/ExpiredPage";
import { CheckCircleOutlined, LockOutlined } from "@ant-design/icons";
import { Alert, Form, Input, notification } from "antd";
import { Spinner } from "reactstrap";
import API_PATH from "../../Constants/api-path";
import "../ChangePassword/ChangePasswordForm.css";


function ResetPasswordForm(props) {
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);
  const [trackerBox, setTrackerBox] = useState(false);
  const { token } = useParams();
  const [form] = Form.useForm();
  const [spinner, setSpinner] = useState(false);
  const [, forceUpdate] = useState({});
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const [state, setstate] = useState({
    newPasswords: "",
    confirmPasswords: "",
  });

  function handleTrackerBox() {
    if (trackerBox === true) {
      setTrackerBox(false);
    } else {
      setTrackerBox(true);
    }
  }

  const handleInputs = (e) => {
    const { name, value } = e.target;
    const list = { ...state, [name]: value };
    setstate(list);
  };

  const handleChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setstate({ ...state, newPasswords: value });
    const minMaxLength = /^[\s\S]{8,16}$/;
    const upper = new RegExp("[A-Z]");
    const lower = new RegExp("[a-z]");
    // special = new RegExp("(?=.*[!@#$%^&*])"),
    const number = new RegExp("[0-9]");
    if (lower.test(value)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }
    if (upper.test(value)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }
    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }

    if (minMaxLength.test(value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }
  };

  // useEffect(() => {
  //   getExpiredTime();
  // }, [isExpired]);

  // const getExpiredTime = async () => {
  //   try {
  //     let d = new Date();
  //     const params = new Proxy(new URLSearchParams(window.location.search), {
  //       get: (searchParams, prop) => searchParams.get(prop),
  //     });
  //     const res = await fetch(`${API_PATH.EXPIRE_TIME}`, {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({
  //         email: atob(params.email),
  //       }),
  //     });
  //     const result = await res.json();
  //     if (result.data) {
  //       if (result.data == 0){
  //         setExpired(true);
  //       }
  //       let expiredDate = result.data;
  //       let token = atob(params.token);
  //       if (expiredDate < d.getTime()) {
  //         setExpired(true);
  //       }
  //       if (result.rData) {
  //         if (parseInt(token) !== atob(result.rData)) {
  //           setExpired(true);
  //         }
  //       }
  //     }

  //   } catch (error) {
  //     return toast.error("something went wrong server error", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 3000,
  //     });
  //   }
  // };

  const saveResetPassword = async (e) => {
    // getExpiredTime();
    e.preventDefault();
    setSpinner(true);
    try {
      const { newPasswords, confirmPasswords } = state;

      if (newPasswords !== confirmPasswords) {
        setError("New Password and confirm password not matching!");
      }
      if (
        lowerValidated &&
        upperValidated &&
        numberValidated &&
        // specialValidated &&
        lengthValidated
      ) {
        <></>;
      } else {
        setError("Invalid Password");
      }
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      const obj = {
        // email: atob(params.email),
        // token: atob(params.token),
        password: state.newPasswords,
      };
      const res = await fetch(`${API_PATH.ADMINRESETPASSWORD}/${token}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
      });
      const data = await res.json();
      if (data) {
        setSpinner(false);
        if (data.status === true || data.status === 200) {
          // if (data.status === 200) {
          //   setIsUsed(true);
          // }

          notification.success({
            message: "Password has been changed",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
          setTimeout(function () {
            navigate("/admin-login");
          }, 1000);
        }
      }
    } catch (error) {
      setSpinner(true);
      setError(error?.data?.message);
    }
  };

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  const something = (event) => {
    if (event.keyCode === 13) {
      saveResetPassword(event);
    }
  };

  return (
    <>
      {/* {isExpired ? (
        <ExpiredPage />
      ) : ( */}
      <>
        {error ? <Alert message={error} type="error" /> : null}

        <Form form={form} layout="vertical" autoComplete="off">
          <Form.Item
            name="newPasswords"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              name="newPasswords"
              id="password-field"
              size="large"
              autoComplete="nope"
              value={state?.newPasswords}
              // onChange={handleInputs}
              onClick={() => handleTrackerBox()}
              onChange={handleChange}
              onKeyDown={(e) => something(e)}
            />
          </Form.Item>

          <main className={trackerBox ? "tracker-box active" : "tracker-box"}>
            <div className={lowerValidated ? "validated" : "not-validated"}>
              {lowerValidated ? (
                <span className="list-icon green">
                  <AiFillCheckCircle style={{ fontSize: "20px" }} />
                </span>
              ) : (
                <span className="list-icon">
                  <AiOutlineCheckCircle style={{ fontSize: "20px" }} />
                </span>
              )}
              At least one lowercase letter
            </div>
            <div className={upperValidated ? "validated" : "not-validated"}>
              {upperValidated ? (
                <span className="list-icon green">
                  <AiFillCheckCircle style={{ fontSize: "20px" }} />
                </span>
              ) : (
                <span className="list-icon">
                  <AiOutlineCheckCircle style={{ fontSize: "20px" }} />
                </span>
              )}
              At least one uppercase letter
            </div>
            <div className={numberValidated ? "validated" : "not-validated"}>
              {numberValidated ? (
                <span className="list-icon green">
                  <AiFillCheckCircle style={{ fontSize: "20px" }} />
                </span>
              ) : (
                <span className="list-icon">
                  <AiOutlineCheckCircle style={{ fontSize: "20px" }} />
                </span>
              )}
              At least one number
            </div>

            <div className={lengthValidated ? "validated" : "not-validated"}>
              {lengthValidated ? (
                <span className="list-icon green">
                  <AiFillCheckCircle style={{ fontSize: "20px" }} />
                </span>
              ) : (
                <span className="list-icon">
                  <AiOutlineCheckCircle style={{ fontSize: "20px" }} />
                </span>
              )}
              Must be at least 8 characters
            </div>
          </main>

          <Form.Item
            name="confirmPasswords"
            label="Confirm Password"
            rules={[
              {
                required: true,
                message: "Please input your confirm password!",
              },
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              name="confirmPasswords"
              id="password-field"
              onChange={handleInputs}
              size="large"
              autoComplete="nope"
              value={state?.confirmPasswords}
            // onKeyDown={(e) => something(e)}
            />
          </Form.Item>

          <div className="form-group">
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  onClick={saveResetPassword}
                  className="btn login-btn"
                  type="primary"
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length
                  }
                >
                  {spinner ? (
                    <>
                      <Spinner
                        size="sm"
                        style={{
                          width: "1rem",
                          height: "1rem",
                          marginRight: "5px",
                        }}
                      />
                      Set New Password
                    </>
                  ) : (
                    " Set New Password"
                  )}
                </Button>
              )}
            </Form.Item>
          </div>

          <div className="form-group">
            <p className="text-center">
              <NavLink to="/admin-login" className="register-btn px-2">
                Login
              </NavLink>
            </p>
          </div>
        </Form>
      </>
      {/* )} */}
    </>
  );
}
export default ResetPasswordForm;
