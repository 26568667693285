import { Card, Spin, Tag, Tooltip } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { GrAttachment } from "react-icons/gr";
import { modifiedContent } from "../../Utils/util";

function ProjectScopeLeftSection({ datas }) {
  const [projectDetail, setProjectDetail] = useState({});
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    setProjectDetail({ ...datas });
    setLoading(false);
  }, [datas]);

  if (!Loading) {
    // function RemoveHtml(myString) {
    //   if (myString !== undefined) {
    //     return myString.replace(/(<([^>]+)>)/gi, "");
    //   }
    // }

    return (
      <>
        {/* <h2 style={{ color: "rgb(82, 111, 215)" }} className="mb-4">
          {" "}
          {projectDetail?.project_title}{" "}
        </h2> */}
        {/* <h5 className="mt-5"> BRIEF </h5> */}
        <Card
          className="projectOverView ant-card gap-2"
          title={
            <>
              <h2
                className="mt-0 pt-0 mb-0"
                style={{ color: "#5746EB" }}
              >
                {projectDetail?.client_name && <Tooltip title="Company" color="#5746EB">
                  <strong className="fw-bold">
                    {projectDetail?.client_name}
                  </strong>{" "}
                  -{" "}
                </Tooltip>}

                <Tooltip title={projectDetail?.project_title} color="#5746EB">
                  {_.truncate(projectDetail?.project_title || "", {
                    length: 60,
                    omission: "...",
                  })}
                </Tooltip>
              </h2>
              <p className="mt-2">
                {projectDetail?.country
                  ?.split(",")
                  ?.map((country, index) => (
                    <Tag
                      className="cust_tag"
                      color="purple"
                      key={index}
                    >
                      {" "}
                      {country}
                    </Tag>
                  ))}
              </p>
            </>
          }
        >
          {/* <div className="projectOverView"> */}
          <p
            dangerouslySetInnerHTML={{
              __html: modifiedContent(projectDetail?.question),
            }}
          ></p>
          {projectDetail?.attachments?.length > 0 && (
            <div
              style={{
                marginTop: "20px",
                paddingLeft: "10px",
              }}
            >
              {projectDetail?.attachments?.map((attach) => {
                return (
                  <div className="attachment_overview">
                    <a
                      href={attach?.s3url}
                      target="_blank"
                      rel="noreferrer"
                      className="d-flex align-items-center gap-1"
                    >
                      <GrAttachment />
                      {attach?.file_original_name}
                    </a>
                  </div>
                );
              })}
            </div>
          )}
        </Card>
        {/* </div> */}
      </>
    );
  } else {
    return (
      <>
        <div
          className="d-flex justify-content-center align-items-center text-center"
          style={{ minHeight: "100vh" }}
        >
          <Spin size="large" />
        </div>
      </>
    );
  }
}

export default ProjectScopeLeftSection;
