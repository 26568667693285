import {
  CheckCircleOutlined,
  ExclamationCircleOutlined, LockOutlined, WarningOutlined
} from "@ant-design/icons";
import { Form, Input, notification } from "antd";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import API_PATH from "../../../Constants/api-path";
import "./ChangePasswordForm.css";

const ChangePasswordForm = () => {
  let history = useNavigate();
  const UserPassword = JSON.parse(localStorage.getItem("ChangePassword"));
  const UserData = JSON.parse(localStorage.getItem("expertData"));

  const [spinner, setSpinner] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [ModalToggle,] = useState(false);
  const [ResponseMSG,] = useState("");

  const [passwordDetails, setPasswordDetails] = useState({
    expert_id: UserData?.data?._id,
    current_Password: "",
    new_Password: "",
    confirm_Password: "",
  });

  const handleInputs = (e) => {
    const { name, value } = e.target;
    const list = { ...passwordDetails, [name]: value };
    checkPassword(list);
    setPasswordDetails(list);
  };


  // const handleChange = (e) => {
  //   const key = e.target.name;
  //   const value = e.target.value;

  //   setPasswordDetails({ ...passwordDetails, new_Password: value });
  //   const minMaxLength = /^[\s\S]{8,16}$/;
  //   const upper = new RegExp("[A-Z]");
  //   const lower = new RegExp("[a-z]");
  //   // special = new RegExp("(?=.*[!@#$%^&*])"),
  //   const number = new RegExp("[0-9]");
  //   if (lower.test(value)) {
  //     setLowerValidated(true);
  //   } else {
  //     setLowerValidated(false);
  //   }
  //   if (upper.test(value)) {
  //     setUpperValidated(true);
  //   } else {
  //     setUpperValidated(false);
  //   }
  //   if (number.test(value)) {
  //     setNumberValidated(true);
  //   } else {
  //     setNumberValidated(false);
  //   }

  //   if (minMaxLength.test(value)) {
  //     setLengthValidated(true);
  //   } else {
  //     setLengthValidated(false);
  //   }
  // };

  const checkPassword = (password) => {
    const { new_Password, confirm_Password, current_Password } = password;
    if (UserPassword !== current_Password) {
      return setCurrentPassword("Please enter correct password");
    } else {
      setCurrentPassword("");
    }

    // if (new_Password.length > 3 || confirm_Password.length > 3) {
    //   var minMaxLength = /^[\s\S]{8,16}$/,
    //     upper = /[A-Z]/,
    //     lower = /[a-z]/,
    //     number = /[0-9]/;

    //   if (
    //     (minMaxLength.test(new_Password) &&
    //       upper.test(new_Password) &&
    //       lower.test(new_Password) &&
    //       number.test(new_Password)) ||
    //     (minMaxLength.test(confirm_Password) &&
    //       upper.test(confirm_Password) &&
    //       lower.test(confirm_Password) &&
    //       number.test(confirm_Password))
    //   ) {
    //     setNewPassword("");
    //   } else {
    //     return setNewPassword(
    //       `Password must contain:
    //       a minimum of 1 lower case letter [a-z] and
    //       a minimum of 1 upper case letter [A-Z] and
    //       a minimum of 1 numeric character [0-9]`
    //     );
    //   }
    // }
    if (confirm_Password?.length > 3) {
      if (new_Password !== confirm_Password) {
        return setNewPassword("New Password and confirm password must be same");
      } else {
        setNewPassword("");
      }
    }
  };

  const handelSubmit = async (e) => {
    e.preventDefault();
    if (
      passwordDetails?.current_Password !== "" &&
      passwordDetails?.new_Password !== "" &&
      passwordDetails?.confirm_Password !== ""
    ) {
      if (passwordDetails?.current_Password === passwordDetails?.new_Password) {
        return toast.error("Please enter a new password", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        setSpinner(true);
        document.getElementById("changePassword").disabled = true;
        try {
          const res = await fetch(`${API_PATH.EXPERT_CHANGE_PASSWORD}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${UserData?.token}`,
            },
            body: JSON.stringify({
              expert_id: passwordDetails?.expert_id,
              old_password: passwordDetails?.current_Password,
              password: passwordDetails?.new_Password,
            }),
          });

          const data = await res.json();
          if (data) {
            setSpinner(true);
            if (res.status === 200 || res.status === true) {
              localStorage?.setItem(
                "ChangePassword",
                JSON.stringify(passwordDetails?.new_Password)
              );
              localStorage.setItem("isExpertAuth", true);

              setTimeout(() => {
                history("/expert/dashboard");
              }, 2000);
              return notification.success({
                message: "Password has been updated",
                icon: <CheckCircleOutlined style={{ color: "#fff" }} />,
                style: {
                  backgroundColor: "#2ecc71",
                  color: "#fff !important",
                  border: "1px solid #52c41a",
                },
                duration: 5,
                placement: "topRight",
              });
            } else {
              return notification.error({
                message: data?.message,
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                  backgroundColor: "#e74c3c",
                  color: "#fff",
                  border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
              });
            }
          } else {
            setSpinner(false);
            return notification.error({
              message: "Error: Something went wrong server error",
              icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
              style: {
                backgroundColor: "#e74c3c",
                color: "#fff",
                border: "1px solid #c0392b",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        } catch (error) {
          setSpinner(false);
          return notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      }
    } else {
      setSpinner(false);
      return notification.warning({
        message: "Warning: All fields are mandatory",
        icon: <WarningOutlined style={{ color: "#fff" }} />, // Custom warning icon
        style: {
          backgroundColor: "#f39c12", // Yellow background color
          color: "#fff", // Text color
          border: "1px solid #d35400", // Border color (optional)
          padding: "10px", // Padding (optional)
        },
        duration: 5, // Duration in seconds
        placement: "topRight", // Position of the notification
      });
    }
  };

  if (ModalToggle) {
    if (ResponseMSG === "Your account has been updated") {
      return toast.success(ResponseMSG, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } else {
      return toast.error(ResponseMSG, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  }

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-6">
          <p>
            To change your current password, please enter your Old Password
            first, followed by your preference for a new one.
          </p>
          <p className="mt-3">
            Incase you opted for a LinkedIn login option, please click on the
            Forgot Password button below and enter your registered email ID to
            set a new password.
          </p>
        </div>
      </div>
      <div className="row mt-2 d-flex align-items-center py-3 justify-content-center">
        <div className="col-md-6">
          <Form layout="vertical">
            <Form.Item
              name="current_Password"
              label="Old Password"
              rules={[
                {
                  required: true,
                  message: "Please input your old password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                name="current_Password"
                id="password-field"
                size="large"
                autoComplete="nope"
                onChange={handleInputs}
                placeholder="Current Password"
              />
            </Form.Item>

            <Form.Item
              name="new_Password"
              label="New Password"
              rules={[
                {
                  required: true,
                  message: "Please input your new password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                name="new_Password"
                size="large"
                autoComplete="nope"
                onChange={handleInputs}
                // onClick={() => handleTrackerBox()}
                placeholder="Current Password"
              />
            </Form.Item>

            <Form.Item
              name="confirm_Password"
              label="Verify New Password"
              dependencies={["new_Password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                name="confirm_Password"
                size="large"
                autoComplete="nope"
                onChange={handleInputs}
                placeholder="Current Password"
              />
            </Form.Item>
            <div className="mt-5 col-md-12 text-center">
              <div className="d-flex align-items-center justify-content-between">
                {currentPassword === "" && newPassword === "" ? (
                  <button
                    className="btn btn-primary profile-button"
                    style={{ backgroundColor: "#5746ec" }}
                    type="button"
                    onClick={handelSubmit}
                    id="changePassword"
                  >
                    {spinner ? (
                      <>
                        <Spinner
                          size="sm"
                          style={{
                            width: "1rem",
                            height: "1rem",
                            marginRight: "5px",
                          }}
                        />
                        Change Password
                      </>
                    ) : (
                      "Change Password"
                    )}
                  </button>
                ) : (
                  <button
                    className="btn btn-primary profile-button"
                    style={{ backgroundColor: "#5746ec" }}
                    type="button"
                    onClick={handelSubmit}
                    disabled
                  >
                    Change Password
                  </button>
                )}

                <NavLink
                  to={"/expert/dashboard/forgot-password"}
                  style={{ fontWeight: 500, color: "rgb(87, 70, 236)" }}
                >
                  Forgot Password ?
                </NavLink>
              </div>
            </div>
          </Form>

          {/* <div className="centerLine">Or</div>
          <div className="text-center">
            <NavLink
              to={"/expert/dashboard/forgot-password"}
              style={{ fontWeight: 500, color: "rgb(87, 70, 236)" }}
            >
              Forgot Password ?
            </NavLink>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ChangePasswordForm;
