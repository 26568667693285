import { Form, Input, Select, Button } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import useStep from "../../../CustomHooks/UseStep";
import { NavLink, useNavigate } from "react-router-dom";
import API_PATH from "../../../Constants/api-path";
import { AiOutlineCheckCircle, AiFillCheckCircle } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import ExpertAuth from "../../components/ExpertAuth";
import { countryOptions } from "../../../Constants/CountryOptions";

const { Option } = Select;

function RegistrationStep3({ isLoading, setIsLoading }) {
  const history = useNavigate();
  const { setCurrentStep } = useStep();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [selectedIndustOptions, setSelectedIndustOptions] = useState([]);
  const [filteredIndustOptions, setFilteredIndustOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);
  const [trackerBox, setTrackerBox] = useState(false);
  const [code, setCode] = useState(null);
  const [phone, setPhone] = useState("");
  const [contactValid, setContactValid] = useState(false);

  const [tagtOption, setFilteredTag] = useState("");
  const [industOption, setFilteredIndust] = useState("");

  useEffect(() => {
    // Function to extract query parameters from the URL
    const getQueryParameter = (name) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(name);
    };

    // Get the 'code' query parameter from the URL
    const typeFromURL = getQueryParameter("type");
    if (typeFromURL === "linkedin") {
      // If 'code' exists in the URL, set it in the state
      setCode(typeFromURL);
      // You can perform any additional actions with the 'code' here if needed
    }
  }, []);

  const linkedinUrlPattern =
    /^(?:(?:https?:\/\/)?(?:www\.)?)?linkedin\.com\/in\/[-a-zA-Z0-9]+\/?|^\/[-a-zA-Z0-9]+\/?$/;

  const linkedinData = JSON.parse(localStorage?.getItem("linkedinData"));

  const [step3, setStep3] = useState({
    email: linkedinData?.email || "",
    contact_number: "",
    password: "",
    linkedin_url: "",
    industry: "",
    tags: "",
    contact_code: "",
    country: "",
  });

  const [step1, setStep1] = useState({
    company: "",
  });

  const handleStep1Change = (event) => {
    const { name, value } = event.target;
    setStep1((prevStep1) => ({
      ...prevStep1,
      [name]: value,
    }));
  };

  const GetIndustryList = async (industOption) => {
    setLoading(true);

    let object = {};

    if (industOption) {
      object.key = `${industOption}`;
    }

    const queryParams = new URLSearchParams(object);

    try {
      const res = await fetch(
        `${API_PATH.ADD_EXPERT_INDUSTRY
        }?${queryParams?.toString()}&page=1&limit=30`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await res.json();
      if (result) {
        setFilteredIndustOptions(result?.data);
      }
    } catch (error) {
      setLoading(false);
      console.error("Industry Error", error);
    }
    setLoading(false);
  };

  const GetTagsList = async (tagtOption) => {
    setLoading(true);

    let object = {};

    if (tagtOption) {
      object.key = `${tagtOption}`;
    }

    const queryParams = new URLSearchParams(object);

    try {
      const res = await fetch(
        `${API_PATH.ADD_EXPERT_TAG}?${queryParams?.toString()}&page=1&limit=30`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await res.json();
      if (result) {
        setFilteredOptions(result?.data);
      }
    } catch (error) {
      setLoading(false);
      console.error("tag_list_error", error);
    }
    setLoading(false);
  };

  // useEffect(() => {

  //   GetIndustryList(industOption);
  //   GetTagsList(tagtOption);
  // }, [industOption, tagtOption]);

  React.useEffect(() => {
    //!DEPENDENCY SHOULD BE ON industOption FOR A REASON
    const getData = setTimeout(() => {
      GetIndustryList(industOption);
    }, 500);

    return () => clearTimeout(getData);
  }, [industOption]);
  React.useEffect(() => {
    //!DEPENDENCY SHOULD BE ON tagtOption FOR A REASON
    const getData = setTimeout(() => {
      GetTagsList(tagtOption);
    }, 500);

    return () => clearTimeout(getData);
  }, [tagtOption]);

  const handleDropdownIndustry = (open) => {
    if (open) {
      GetIndustryList("");
    }
  };
  const handleDropdownTagList = (open) => {
    if (open) {
      GetTagsList("");
    }
  };

  const handleStep3Change = (event) => {
    const { name, value } = event.target;
    setStep3((step3) => ({
      ...step3,
      [name]: value,
    }));
  };

  function filterDuplicates(array) {
    return Array.from(new Set(array));
  }

  const handleOptionIndustChange = (selectedValues) => {
    setSelectedIndustOptions(selectedValues);
    const selectValue = selectedValues;
    setStep3({ ...step3, industry: selectValue });
  };

  const handleOptionChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
    const selectValue = selectedValues;
    setStep3({ ...step3, tags: selectValue });
  };

  // const handleSelectInputs = (value) => {
  //   const selectedCountry = countryOptions.find(
  //     (country) => country.code === value
  //   );
  //   if (selectedCountry) {
  //     const { code, name } = selectedCountry;
  //     setStep3({
  //       ...step3,
  //       contact_code: code,
  //       country: name,
  //     });
  //   }
  // };

  const handlePhoneChange = (value, country) => {
    setPhone(value);

    const selectedCountry = countryOptions?.find(
      (option) => option?.code === `+${country?.dialCode}`
    );

    // const contact_code = country?.dialCode;
    // const contact_number = value?.replace(country?.dialCode, "");

    // const isValidContact = contact_code && contact_number;

    if (selectedCountry) {
      setStep3((prevStep3) => ({
        ...prevStep3,
        contact_code: country?.dialCode,
        contact_number: value?.replace(country?.dialCode, ""),
        country: selectedCountry?.name,
      }));

      // setContactValid(isValidContact);
    } else {
      console.info(
        "No matching country found for dial code:",
        country.dialCode
      );
    }
  };

  useEffect(() => {
    const isContactValid = !!phone; // Assuming contact_number is valid if it's not empty
    setContactValid(isContactValid);
  }, [phone]);

  const saveToLocalStorage = () => {
    const data = step3;
    const expirationTime = new Date().getTime() + 60 * 60 * 1000; // 1 hour from now
    const dataToStore = {
      data,
      expiration: expirationTime,
    };
    localStorage.setItem("step3", JSON.stringify(dataToStore));
  };

  const handleSubmit = () => {
    saveToLocalStorage();
    history("/expert-register?regType=1&step=4");
    setCurrentStep(4);
  };

  const saveToLocalStorageTwo = () => {
    const data = step3;
    const expirationTime = new Date().getTime() + 60 * 60 * 1000; // 1 hour from now
    const dataToStore = {
      data,
      expiration: expirationTime,
    };
    localStorage.setItem("step3", JSON.stringify(dataToStore));

    const step1 = {
      company: form?.getFieldValue("company"),
    };

    const expirationTimeStep1 = new Date()?.getTime() + 60 * 60 * 1000; // 1 hour from now
    const dataToStoreStep1 = {
      data: step1,
      expiration: expirationTimeStep1,
    };

    localStorage?.setItem("step1", JSON?.stringify(dataToStoreStep1));
  };

  const handleSubmitTwo = () => {
    saveToLocalStorageTwo();
    history(`/expert-register?regType=1&step=4&type=linkedin`);
    setCurrentStep(4);
  };

  const handleTrackerBox = () => {
    setTrackerBox(true);
  };

  const handleChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setStep3({ ...step3, password: value });
    const minMaxLength = /^[\s\S]{8,16}$/;
    const upper = new RegExp("[A-Z]");
    const lower = new RegExp("[a-z]");
    // special = new RegExp("(?=.*[!@#$%^&*])"),
    const number = new RegExp("[0-9]");
    if (lower.test(value)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }
    if (upper.test(value)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }
    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }

    if (minMaxLength.test(value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }
  };

  const emailRules = [
    {
      type: "email",
      message: "Please enter a valid email address!",
    },
    {
      required: true,
      message: "Email is required!",
    },
  ];

  const validateEnterPassword = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter a password!"));
    }
    return Promise.resolve();
  };

  const validatePassword = (_, value) => {
    const { password } = form.getFieldValue();
    if (value && value !== password) {
      return Promise.reject(new Error("Passwords do not match!"));
    }
    return Promise.resolve();
  };

  const isLocalStorageExpired = () => {
    const storedData = localStorage.getItem("step3");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      const now = new Date().getTime();
      return parsedData.expiration && now > parsedData.expiration;
    }
    return true;
  };

  const isLocalStorageExpiredStep1 = () => {
    const storedStep1 = localStorage.getItem("step3");
    if (storedStep1) {
      const parsedData = JSON.parse(storedStep1);
      const now = new Date().getTime();
      return parsedData.expiration && now > parsedData.expiration;
    }
    return true;
  };

  // const GetLinkedin = async (code) => {
  //   // e.preventDefault();
  //   setLoading(true);

  //   const state = "foobar";

  //   try {
  //     const res = await fetch(`${API_PATH.EXPERT_REGISTRATION_LINKDIN}?code=${code}&state=${state}`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },

  //     });

  //     const result = await res.json();

  //     if (result && result?.token) {
  //       setIsLoading(true);

  //       localStorage.setItem("expertData", JSON.stringify(result));
  //       localStorage.setItem("isExpertAuth", true);

  //       setTimeout(() => {
  //         history("/expert/dashboard");
  //       }, 3000);
  //       message.success("Login Successful");
  //     } else {
  //       const { email, firstName, lastName, id: linkedinId } = result.data;
  //       setStep3((prevStep3) => ({
  //         ...prevStep3, email,
  //         firstName,
  //         lastName,
  //         linkedinId
  //       }));
  //       form.setFieldsValue({ email, firstName, lastName, linkedinId })
  //     }

  //   } catch (error) {
  //     setLoading(false);
  //     message.error("Something went wrong. Server error.");
  //   }
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   if (code) {
  //     GetLinkedin(code)
  //   }

  // }, [code]);

  useEffect(() => {
    // Function to extract query parameters from the URL
    const getQueryParameter = (name) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(name);
    };

    // Get the 'code' query parameter from the URL
    const codeFromURL = getQueryParameter("code");
    if (codeFromURL) {
      // If 'code' exists in the URL, set it in the state
      setCode(codeFromURL);
      // You can perform any additional actions with the 'code' here if needed
    } else {
      console.info("Code not found in Linkedin Url");
    }
  }, []);

  useEffect(() => {
    if (!isLocalStorageExpired()) {
      const storedData = localStorage?.getItem("step3");
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        const step3Data = parsedData.data;
        setStep3(step3Data);
        const phoneValue = `+${step3Data?.contact_code}${step3Data?.contact_number}`;
        setPhone(phoneValue);
        form.setFieldsValue({
          email: step3Data?.email,
          // contact_number: step3Data?.contact_number,
          // password: step3Data?.password,
          linkedin_url: step3Data?.linkedin_url,
          industry: step3Data?.industry,
          tags: step3Data?.tags,
          // contact_code: step3Data?.contact_code,
        });
      }
    } else {
      localStorage?.removeItem("step3"); // Remove expired item from local storage
    }

    if (!isLocalStorageExpiredStep1()) {
      const storedStep1 = localStorage?.getItem("step1");
      if (storedStep1) {
        const parsedStep1 = JSON.parse(storedStep1);
        setStep1(parsedStep1?.data);
        form.setFieldsValue({
          company: parsedStep1.data?.company,
        });
      }
    }
  }, [form]);

  return (
    <React.Fragment>
      <ExpertAuth />

      <div className="expertRegistration step1">
        <h5>Step 3 : Contact Information</h5>
        <p>
          Your contact information will help our team get in touch with you.
        </p>

        <div>
          <Form layout="vertical" autoComplete="off" form={form}>
            <Row>
              {code ? (
                <>
                  <Col xs={12}>
                    <Form.Item
                      // name={"email"}
                      label={<span className="required-asterisk">Email</span>}
                      rules={emailRules}
                    >
                      <Input
                        // type="text"
                        name="email"
                        autoComplete="nope"
                        size="large"
                        value={step3?.email}
                        onChange={handleStep3Change}
                      // readOnly={code ? true : false}
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <>
                  <Col xs={12}>
                    <Form.Item name={"email"} label="Email" rules={emailRules}>
                      <Input
                        type="text"
                        name="email"
                        autoComplete="nope"
                        size="large"
                        value={step3?.email}
                        onChange={handleStep3Change}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
            <Row>
              {/* <Col xs={12} md={6} xl={4}>
                <Form.Item
                  className="w-100"
                  name="contact_code"
                  label="Dial Code"
                  rules={[
                    {
                      required: true,
                      message: "Field is required",
                    },
                  ]}
                >
                  <Select
                    className="w-100"
                    size="large"
                    onChange={(value, option) => {
                      handleSelectInputs(value, option?.children);
                      form.setFieldsValue({
                        contact_code: value,
                        country: option?.children,
                      });
                    }}
                    placeholder="Country name"
                    name="contact_code"
                    value={selectedCountryCode}
                    virtual={false} // Disable virtual scrolling
                  >
                    {countryOptions.map((country) => (
                      <Select.Option key={country.code} value={country.code}>
                        {`${country.name}`}{" "}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col> */}
              <Col xs={12} md={12} xl={12}>
                <Form.Item
                  // name="contact_number"
                  label={
                    <span className="required-asterisk">Contact Number</span>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Field is required",
                    },
                  ]}
                >
                  <PhoneInput
                    className="react-tel-input-2"
                    country={"us"}
                    enableSearch={true}
                    value={phone}
                    onChange={(value, country) => {
                      handlePhoneChange(value, country);
                    }}
                    inputStyle={{
                      width: "100%",
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            {code ? null : (
              <>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          required: true,
                          validator: validateEnterPassword,
                        },
                      ]}
                      hasFeedback
                      validateStatus={
                        step3?.password &&
                          (!lowerValidated ||
                            !upperValidated ||
                            !numberValidated ||
                            !lengthValidated)
                          ? "error"
                          : ""
                      }
                      help={
                        step3?.password &&
                          (!lowerValidated ||
                            !upperValidated ||
                            !numberValidated ||
                            !lengthValidated)
                          ? "Password does not meet the requirements"
                          : ""
                      }
                    >
                      <Input.Password
                        name="password"
                        size="large"
                        value={setStep3?.password}
                        onChange={handleChange}
                        onClick={() => handleTrackerBox()}
                      />
                    </Form.Item>
                    <main
                      className={
                        trackerBox ? "tracker-box active" : "tracker-box"
                      }
                    >
                      <div
                        className={
                          lowerValidated ? "validated" : "not-validated"
                        }
                      >
                        {lowerValidated ? (
                          <span className="list-icon green">
                            <AiFillCheckCircle style={{ fontSize: "20px" }} />
                          </span>
                        ) : (
                          <span className="list-icon">
                            <AiOutlineCheckCircle
                              style={{ fontSize: "20px" }}
                            />
                          </span>
                        )}
                        At least one lowercase letter
                      </div>
                      <div
                        className={
                          upperValidated ? "validated" : "not-validated"
                        }
                      >
                        {upperValidated ? (
                          <span className="list-icon green">
                            <AiFillCheckCircle style={{ fontSize: "20px" }} />
                          </span>
                        ) : (
                          <span className="list-icon">
                            <AiOutlineCheckCircle
                              style={{ fontSize: "20px" }}
                            />
                          </span>
                        )}
                        At least one uppercase letter
                      </div>
                      <div
                        className={
                          numberValidated ? "validated" : "not-validated"
                        }
                      >
                        {numberValidated ? (
                          <span className="list-icon green">
                            <AiFillCheckCircle style={{ fontSize: "20px" }} />
                          </span>
                        ) : (
                          <span className="list-icon">
                            <AiOutlineCheckCircle
                              style={{ fontSize: "20px" }}
                            />
                          </span>
                        )}
                        At least one number
                      </div>
                      <div
                        className={
                          lengthValidated ? "validated" : "not-validated"
                        }
                      >
                        {lengthValidated ? (
                          <span className="list-icon green">
                            <AiFillCheckCircle style={{ fontSize: "20px" }} />
                          </span>
                        ) : (
                          <span className="list-icon">
                            <AiOutlineCheckCircle
                              style={{ fontSize: "20px" }}
                            />
                          </span>
                        )}
                        At least 8 characters
                      </div>
                    </main>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Item
                      name="verify-password"
                      label="Re-enter Password"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please verify your password!",
                        },
                        { validator: validatePassword },
                      ]}
                    >
                      <Input.Password name="verify-password" size="large" />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            {code ? (
              <Row>
                <Col xs={12}>
                  <Form.Item
                    name="company"
                    label="Company Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your company name!",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      name="company"
                      autoComplete="nope"
                      size="large"
                      placeholder="Company name"
                      value={step1?.company}
                      onChange={handleStep1Change}
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : null}

            <Row>
              <Col xs={12}>
                <Form.Item
                  name="linkedin_url"
                  label="Linkedin URL"
                  rules={[
                    {
                      required: true,
                      message: "LinkedIn URL required",
                    },
                  ]}
                >
                  <Input
                    name="linkedin_url"
                    size="large"
                    // type="url"
                    value={setStep3?.linkedin_url}
                    onChange={handleStep3Change}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Form.Item
                  name="industry"
                  label={
                    <div className="d-flex flex-column">
                      <div>Main Industry</div>
                      <div
                        className="fw-normal text-muted"
                        style={{ fontSize: "smaller" }}
                      >
                        (Please choose multiple tags from the drop down, to help
                        us match you to consulting opportunities)
                      </div>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Field is required",
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    name="industry"
                    size="large"
                    mode="tags"
                    onSearch={setFilteredIndust}
                    placeholder="Type for search"
                    value={selectedIndustOptions}
                    onDropdownVisibleChange={handleDropdownIndustry}
                    loading={loading}
                    onChange={handleOptionIndustChange}
                    filterOption={(input, option) =>
                      option?.children
                        ?.toLowerCase()
                        .indexOf(input?.toLowerCase()) >= 0
                    }
                  >
                    {filteredIndustOptions?.map((option) => (
                      <Option key={option?._id} value={option?.category_name}>
                        {option?.category_name}
                      </Option>
                    ))}
                  </Select>
                  {/* <Select
                    mode="tags"
                    size="large"
                    style={{
                      width: "100%",
                    }}
                  /> */}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Item
                  name="tags"
                  label={
                    <div className="d-flex flex-column">
                      <div>Expertise Tags</div>
                      <div
                        className="fw-normal text-muted"
                        style={{ fontSize: "smaller" }}
                      >
                        (Please choose multiple tags from the drop down, to help
                        us match you to consulting opportunities)
                      </div>
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Field is required",
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: "100%",
                    }}
                    name="tags"
                    size="large"
                    mode="tags"
                    placeholder="Type for search"
                    value={selectedOptions}
                    onSearch={setFilteredTag}
                    loading={loading}
                    onDropdownVisibleChange={handleDropdownTagList}
                    onChange={handleOptionChange}
                    filterOption={(input, option) => {
                      if (typeof option.children === "string") {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }
                      return false;
                    }}
                  >
                    {filteredOptions?.map((option) => (
                      <Option key={option?._id} value={option?.name}>
                        {typeof option?.name === "string"
                          ? option?.name.charAt(0).toUpperCase() +
                          option?.name.slice(1)
                          : option?.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <div
              style={{
                display: "flex",
                justifyContent: code ? "end" : "space-between",
                alignItems: "center",
              }}
            >
              {code ? null : (
                <>
                  <Form.Item>
                    <NavLink
                      to={"/expert-register?regType=1&step=2"}
                      onClick={() => setCurrentStep(2)}
                    >
                      <Button
                        size="large"
                        className="btn btn-responsive expertButtonNext"
                      >
                        Prev
                      </Button>
                    </NavLink>
                  </Form.Item>
                </>
              )}

              {code ? (
                <Form.Item shouldUpdate>
                  {() => (
                    <Button
                      onClick={handleSubmitTwo}
                      className="btn btn-responsive expertButtonNext"
                      size="large"
                      htmlType="submit"
                      disabled={
                        (!code && !form.isFieldsTouched(true)) ||
                        (code &&
                          !!form
                            .getFieldsError()
                            .filter(({ errors }) => errors.length).length) ||
                        !(step3?.contact_code && step3?.contact_number) ||
                        !step3?.linkedin_url ||
                        !step3?.industry ||
                        !step3?.tags ||
                        !step1?.company
                      }
                    >
                      Next
                    </Button>
                  )}
                </Form.Item>
              ) : (
                <>
                  <Form.Item shouldUpdate>
                    {() => (
                      <Button
                        onClick={handleSubmit}
                        className="btn btn-responsive expertButtonNext"
                        size="large"
                        htmlType="submit"
                        disabled={
                          !form.isFieldsTouched(true) ||
                          !!form
                            .getFieldsError()
                            .filter(({ errors }) => errors.length).length ||
                          !lowerValidated ||
                          !upperValidated ||
                          !numberValidated ||
                          !lengthValidated ||
                          !(step3?.contact_code && step3?.contact_number)
                        }
                      >
                        Next
                      </Button>
                    )}
                  </Form.Item>
                </>
              )}
            </div>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RegistrationStep3;
