import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Col, Row } from "react-bootstrap";
import "./Pages.css";
import {
  AmazonIcon,
  BankIcon,
  PaypalIcon,
  CharityIcon,
} from "../../../Utils/Icons";
import {
  Dropdown,
  Switch,
  Table,
  Menu,
  Button,
  Modal,
  notification,
  Divider,
  Spin,
} from "antd";
import API_PATH from "../../../Constants/api-path";
import axios from "axios";
import ExpertDashboard from "../../components/ExpertDashboard";
import { useNavigate } from "react-router-dom";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import BankDetailsModal from "../../components/BankDetailsModal";
import AmazonGiftModal from "../../components/AmazonGiftModal";
import CharityModal from "../../components/CharityModal";
//THIS FILE IS FROM THIS BRANCH WITH NEW CHANGES
function Payment() {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("expertData"));
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);
  const [showAmazon, setShowAmazon] = useState(false);
  const [showCharity, setShowCharity] = useState(false);
  const [allPaymentData, setAllPaymentData] = useState([]);
  const [amazonId, setAmazonId] = useState("");
  const [bankId, setBankId] = useState("");
  const [charityId, setCharityId] = useState("");
  const [modal3Open, setModal3Open] = useState(false);
  const [account, setAccount] = useState(false);
  const [accountBnk, setAccountBnk] = useState(false);
  const [accountChr, setAccountChr] = useState(false);
  const [amazonDate, setAmazonDate] = useState("");
  const [bankDate, setBankDate] = useState("");
  const [charityDate, setCharityDate] = useState("");
  const [viewId, setViewId] = useState("");
  const [deleteId, setdeleteId] = useState("");

  const resetAll = () => {
    setModal3Open(false);
    setAmazonDate("");
    setAccount(false);
    setAmazonId();
    setBankDate("");
    setAccountBnk(false);
    setBankId();
    setCharityDate("");
    setAccountChr(false);
    setCharityId();
  }

  const fetchData = React.useCallback(async () => {
    try {
      setLoad(true)
      const res = await axios?.get(
        `${API_PATH?.EXPERT_PAYMENT_LIST}/${userData?.data?._id}`
      );
      const paymentData = res?.data?.data; // Assuming the API response is stored in a variable called paymentData
      setAllPaymentData(paymentData)
      if (paymentData && paymentData?.length > 0) {
        const desiredPaymentMethods = ["amazon", "bank", "paypal", "charity"];
        desiredPaymentMethods?.forEach((desiredPaymentMethod) => {
          const desiredPayment = paymentData?.find(
            (payment) => {
              return payment?.payment_method === desiredPaymentMethod
            }
          );
          if (desiredPayment) {
            if (desiredPaymentMethod === "amazon") {
              setAmazonDate(desiredPayment?.createdAt);
              setAccount(desiredPayment?.status);
              setAmazonId(desiredPayment?._id);
            } else if (desiredPaymentMethod === "bank") {
              setBankDate(desiredPayment?.createdAt);
              setAccountBnk(desiredPayment?.status);
              setBankId(desiredPayment?._id);
            } else if (desiredPaymentMethod === "charity") {
              setCharityDate(desiredPayment?.createdAt);
              setAccountChr(desiredPayment?.status);
              setCharityId(desiredPayment?._id);
            }
          }
        });
      }
      setLoad(false)

    } catch (error) {
      setLoad(false)
      console.error(error);
    }
  }, [userData?.data?._id]);

  const handleIconClick = () => {
    navigate(-1);
  };

  const callOnce = useRef(false)

  useEffect(() => {
    if (!callOnce.current) {
      callOnce.current = true
      fetchData();
    }
  }, [fetchData]);

  const DeletePayment = React.useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.EXPERT_PAYMENT_DELETE}/${deleteId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        if (res.status === 200 || res.status === true) {
          resetAll()
          fetchData()
          notification.success({
            message: "Payment method has been deleted",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        } else {
          notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  }, [deleteId, fetchData, userData?.token]);

  const getMangerId = React.useCallback((_id) => {
    setViewId(_id);
  }, []);

  const getpayId = React.useCallback((_id) => {
    setdeleteId(_id);
  }, []);

  const openModal = React.useCallback((item) => {
    if (item === "bank") {
      setShow(true);
    } else if (item === "amazon") {
      setShowAmazon(true)
    } else {
      setShowCharity(true)
    }
    // setMethod(item);
  }, [])

  const getDropdownItems = React.useCallback((action, _id) => {
    let dropdownItems = [];

    // Customize the dropdown items based on the action parameter
    if (action === "amazon") {
      dropdownItems = [
        {
          key: "edit",
          label: "Edit",
          onClick: () => {
            openModal("amazon");
            getMangerId(_id);
          },
        },
        {
          key: "delete",
          label: "Delete",
          onClick: () => {
            setModal3Open(true);
            getpayId(_id);
          },
        },
        // Add more items specific to the "amazon" action if needed
      ];
    } else if (action === "bank") {
      dropdownItems = [
        {
          key: "edit",
          label: "Edit",
          onClick: () => {
            openModal("bank");
            getMangerId(_id);
          },
        },
        {
          key: "delete",
          label: "Delete",
          onClick: () => {
            setModal3Open(true);
            getpayId(_id);
          },
        },
        // Add more items specific to the "paypal" action if needed
      ];
    } else if (action === "paypal") {
      dropdownItems = [
        {
          key: "edit",
          label: "Edit",
          onClick: () => {
            openModal("paypal");
            getMangerId(_id);
          },
        },
        {
          key: "delete",
          label: "Delete",
          onClick: () => {
            setModal3Open(true);
            getpayId(_id);
          },
        },
        // Add more items specific to the "bank" action if needed
      ];
    } else if (action === "charity") {
      dropdownItems = [
        {
          key: "edit",
          label: "Edit",
          onClick: () => {
            openModal("charity");
            getMangerId(_id);
          },
        },
        {
          key: "delete",
          label: "Delete",
          onClick: () => {
            setModal3Open(true);
            getpayId(_id);
          },
        },
        // Add more items specific to the "charity" action if needed
      ];
    }

    return dropdownItems;
  }, [getMangerId, getpayId, openModal]);

  const convertDate = React.useCallback((date) => {
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      return p;
    } else {
      return "---------";
    }
  }, []);

  const handleSwitchChange = React.useCallback((checked, actionName, actionId) => {

    switch (actionName) {
      case "amazon":
        setAccount(checked);
        setAmazonId(actionId);
        break;
      case "bank":
        setAccountBnk(checked);
        setBankId(actionId);
        break;
      case "charity":
        setAccountChr(checked);
        setCharityId(actionId);
        break;
      default:
        break;
    }

    if (checked) {
      const requestBody = {
        payment_id: actionId,
        expert_id: userData?.data?._id,
      };
      fetch(`${API_PATH.EXPERT_PAYMENT_PRIMATY_SET}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle the response if needed
          // resetAll()
          fetchData();
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error submitting data:", error);
          notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        });
    }
  }, [fetchData, userData?.data?._id, userData?.token])

  const columns = React.useMemo(() => ([
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      render: (_, { projectName }) => (
        <>
          {projectName?.map((data, index) => {
            return (
              <div className="d-flex align-items-center" key={index}>
                <div className="me-3">{data?.icon}</div>
                <div>
                  <h5
                    className="mt-0 mb-1"
                    style={{ fontSize: "16px", fontWeight: 500 }}
                  >
                    {data?.method}
                  </h5>
                  <div className="d-flex">
                    <div className="text-muted">
                      <span>Added on {data?.date}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ),
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
      width: "280px",
      render: (_, { status, action }) => (
        <>
          {status?.map((data, index) => {
            return (
              <div
                key={index}
                className="d-flex align-items-center"
                style={{ color: data.stts && "#5746EB" }}
              >
                <span className="pe-2" style={{ fontWeight: 500 }}>
                  Primary Method
                </span>
                <Switch
                  className="themedChecked"
                  checked={data?.stts}
                  disabled={data?.stts}
                  onChange={(checked) =>
                    handleSwitchChange(checked, action?.name, action?._id)
                  }
                />
              </div>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "20px",
      render: (_, record) => {
        const { action } = record;
        const dropdownItems = getDropdownItems(action?.name, action?._id);

        return (
          <>
            {dropdownItems.length > 0 && (
              <Dropdown
                trigger={["click"]}
                overlay={
                  <Menu>
                    {dropdownItems.map((item) => (
                      <Menu.Item key={item.key} onClick={item.onClick}>
                        {item.label}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <a onClick={(e) => e.preventDefault()}>
                  <svg
                    stroke="currentColor"
                    fill="none"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="1"></circle>
                    <circle cx="12" cy="5" r="1"></circle>
                    <circle cx="12" cy="19" r="1"></circle>
                  </svg>
                </a>
              </Dropdown>
            )}
          </>
        );
      },
    },
  ]), [getDropdownItems, handleSwitchChange]);

  const data = React.useMemo(() => ([
    ...(amazonId?.length > 0
      ? [
        {
          key: "1",
          projectName: [
            {
              icon: <AmazonIcon className="tableIcon" color="5545E7" />,
              method: "Amazon",
              date: convertDate(amazonDate),
            },
          ],
          status: [
            {
              stts: account,
            },
          ],
          action: {
            _id: amazonId,
            name: "amazon",
          },
        },
      ]
      : []),
    ...(bankId?.length > 0
      ? [
        {
          key: "2",
          projectName: [
            {
              icon: <BankIcon className="tableIcon" color="5545E7" />,
              method: "Bank",
              date: convertDate(bankDate),
            },
          ],
          status: [
            {
              stts: accountBnk,
            },
          ],
          action: {
            _id: bankId,
            name: "bank",
          },
        },
      ]
      : []),
    ...(charityId?.length > 0
      ? [
        {
          key: "4",
          projectName: [
            {
              icon: <CharityIcon className="tableIcon" color="5545E7" />,
              method: "Charity",
              date: convertDate(charityDate),
            },
          ],
          status: [
            {
              stts: accountChr,
            },
          ],
          action: {
            _id: charityId,
            name: "charity",
          },
        },
      ]
      : []),
  ]), [account, accountBnk, accountChr, amazonDate, amazonId, bankDate, bankId, charityDate, charityId, convertDate]);



  const footer = React.useMemo(() => (
    <div>
      <Button key="cancel" onClick={() => setModal3Open(false)}>
        Cancel
      </Button>
      <Button
        key="submit"
        onClick={async () => {
          await DeletePayment();
          setModal3Open(false);
        }}
      >
        Yes {loading && <Spin size="small" />}
      </Button>
    </div>
  ), [DeletePayment, loading]);

  document.title = "Expert-Payments";

  return (
    <>
      <ExpertDashboard />
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            <Row className="justify-content-center marginT">
              <Col xs={11}>
                <Row>
                  <Col>
                    <h5
                      className="d-flex align-items-center mt-0"
                      style={{ fontWeight: 600, fontSize: "26px" }}
                      onClick={handleIconClick}
                    >
                      <span
                        className="pe-3 bckIcon"
                        style={{ cursor: "pointer" }}
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 320 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"></path>
                        </svg>
                      </span>

                      <span>Payment Details</span>
                    </h5>
                  </Col>
                </Row>
                <Row className="row row-cols-1 row-cols-md-3 g-3">
                  <Col>
                    {amazonId?.length > 0 ? (
                      <div className="bg-white pb-4 ps-4 pe-4 expertBox responsivePaymentBox pointer" onClick={() => {
                        openModal("amazon")
                        getMangerId(amazonId);
                      }}>
                        <AmazonIcon
                          className="paymentIconSize"
                          color="03C4DC"
                        />
                        <h4 className="paymentTitle">Amazon Gift Card</h4>
                        <p className="paymentSubTitle text-muted">
                          Receive an Amazon Gift Card
                        </p>
                      </div>
                    ) : (
                      <div
                        className="bg-white pb-4 ps-4 pe-4 expertBox responsivePaymentBox pointer"
                        onClick={() => openModal("amazon")}
                      >
                        <AmazonIcon
                          className="paymentIconSize"
                          color="03C4DC"
                        />
                        <h4 className="paymentTitle">Amazon Gift Card</h4>
                        <p className="paymentSubTitle text-muted">
                          Receive an Amazon Gift Card
                        </p>
                      </div>
                    )}
                  </Col>

                  <Col>
                    {bankId?.length > 0 ? (
                      <div className="bg-white pb-4 ps-4 pe-4 expertBox responsivePaymentBox pointer" onClick={() => {
                        openModal("bank")
                        getMangerId(bankId);
                      }}>
                        <BankIcon className="paymentIconSize" color="03C4DC" />
                        <h4 className="paymentTitle">Add Bank Account</h4>
                        <p className="paymentSubTitle text-muted">
                          Receive a Direct Bank Transfer
                        </p>
                      </div>
                    ) : (
                      <div
                        className="bg-white pb-4 ps-4 pe-4 expertBox responsivePaymentBox pointer"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          openModal("bank")
                        }}
                      >
                        <BankIcon className="paymentIconSize" color="03C4DC" />
                        <h4 className="paymentTitle">Add Bank Account</h4>
                        <p className="paymentSubTitle text-muted">
                          Receive a Direct Bank Transfer
                        </p>
                      </div>
                    )}
                  </Col>

                  <Col>
                    {charityId?.length > 0 ? (
                      <div className="bg-white pb-4 ps-4 pe-4 expertBox responsivePaymentBox pointer" onClick={() => {
                        openModal("charity")
                        getMangerId(charityId);
                      }}>
                        <CharityIcon
                          className="paymentIconSize"
                          color="03C4DC"
                        />
                        <h4 className="paymentTitle">Charitable Donation</h4>
                        <p className="paymentSubTitle text-muted">
                          Donate to a Charity
                        </p>
                      </div>
                    ) : (
                      <div
                        className="bg-white pb-4 ps-4 pe-4 expertBox responsivePaymentBox pointer"
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal("charity")}
                      >
                        <CharityIcon
                          className="paymentIconSize"
                          color="03C4DC"
                        />
                        <h4 className="paymentTitle">Charitable Donation</h4>
                        <p className="paymentSubTitle text-muted">
                          Donate to a Charity
                        </p>
                      </div>
                    )}
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col>
                    <h5
                      className="d-flex align-items-center mt-0"
                      style={{ fontWeight: 600, fontSize: "26px" }}
                    >
                      <span>Added Payment Methods</span>
                    </h5>
                  </Col>

                  <Col xs={12}>
                    <Table
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      showHeader={false}
                      loading={load}
                      scroll={{ x: 800 }}
                    ></Table>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </main>
        <BankDetailsModal show={show} setShow={setShow} fetchData={fetchData} viewId={viewId}
          allPaymentData={allPaymentData} resetAll={resetAll} />
        <AmazonGiftModal show={showAmazon} setShow={setShowAmazon} fetchData={fetchData} viewId={viewId}
          allPaymentData={allPaymentData} resetAll={resetAll} />
        <CharityModal show={showCharity} setShow={setShowCharity} fetchData={fetchData} viewId={viewId}
          allPaymentData={allPaymentData} resetAll={resetAll} />

        <Modal
          title={<div>
            Delete
            <Divider className="m-2" />
          </div>}
          open={modal3Open}
          footer={footer}
        >
          <div className="mt-4" style={{ width: "100%", textAlign: "left" }}>
            <p>
              This action cannot be undone. Are you sure you want to delete this
              payment method ?
            </p>{" "}
          </div>
        </Modal>
      </div>
    </>
  );
}

export default Payment;
