import { LoadingOutlined } from "@ant-design/icons";
import { Divider, Modal } from "antd";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { MdOutlineStar } from "react-icons/md";
import { currencyList } from "../../Constants/Currency";
const PaymentDetailsModal = ({ selectedExpert, setSelectedExpert }) => {
  const [loading,] = useState(false);

  // const headers = React.useMemo(
  //     () => ({
  //         "x-access-token": userData.access_token,
  //     }),
  //     [userData.access_token]
  // );

  // const getExpertProfileDetails = useCallback(() => {
  //     setLoading(true);
  //     axios
  //         .get(`${API_URL.EXPERT_PROFILE}/${selectedExpert?.data?.expert_id}`, {
  //             headers,
  //         })
  //         .then((res) => {
  //             setExpertDetails(res.data.data);
  //             setLoading(false);
  //         })
  //         .catch((err) => {
  //             setLoading(false);
  //         });
  // }, [headers, selectedExpert?.data?.expert_id]);
  // const callOnce = useRef(false)
  // useEffect(() => {
  //     if (!callOnce.current && selectedExpert?.data?.expert_id) {
  //         callOnce.current = true
  //         getExpertProfileDetails();
  //     }
  // }, [getExpertProfileDetails, selectedExpert?.data?.expert_id]);
  return (
    <Modal
      title={
        <div>
          {"Expert Payment Details"}
          <Divider className="m-2" />
        </div>
      }
      open={selectedExpert?.open}
      onCancel={() => {
        // callOnce.current = false
        setSelectedExpert({
          open: false,
          data: "",
        });
      }}
      footer={false}
      width={800}
    >
      <div>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center">
            {" "}
            <LoadingOutlined style={{ fontSize: 30 }} />{" "}
          </div>
        ) : (
          <div className="pvs-header__title-container pymt_mpd">
            {selectedExpert?.data?.expert_payment_details?.map(
              (curElemData, index) => (
                <div key={curElemData?._id} className="brdrt">
                  {curElemData?.payment_method.toLowerCase() === "bank" && (
                    <>
                      <div>
                        <h5
                          style={{
                            fontSize: "18px",
                            fontWeight: 600,
                            marginBottom: "5px",
                            marginTop: 0,
                          }}
                        >
                          Bank Details{" "}
                          {curElemData?.status === true && (
                            <MdOutlineStar
                              className="mb-1"
                              style={{ color: "#5746ec" }}
                            />
                          )}
                        </h5>
                        {curElemData?.country && (
                          <Row>
                            <Col className="d-flex text-center align-items-center">
                              <span className="me-2 payment_title">
                                Country:
                              </span>
                              <span className="pymtP">
                                {curElemData?.country}
                              </span>
                            </Col>
                          </Row>
                        )}
                        {curElemData?.account_holder_name && (
                          <Row>
                            <Col className="d-flex text-center align-items-center">
                              <span className="me-2 payment_title">
                                Account holder name:
                              </span>
                              <span className="pymtP">
                                {curElemData?.account_holder_name}
                              </span>
                            </Col>
                          </Row>
                        )}

                        {curElemData?.account_number && (
                          <Row>
                            <Col className="d-flex text-center align-items-center">
                              <span className="me-2 payment_title">
                                Account number:
                              </span>
                              <span className="pymtP">
                                {curElemData?.account_number}
                              </span>
                            </Col>
                          </Row>
                        )}

                        {curElemData?.bank_branch_address && (
                          <Row>
                            <Col className="d-flex text-center align-items-center">
                              <span className="me-2 payment_title">
                                Bank branch address:
                              </span>
                              <span className="pymtP">
                                {curElemData?.bank_branch_address}
                              </span>
                            </Col>
                          </Row>
                        )}

                        {curElemData?.bank_name && (
                          <Row>
                            <Col className="d-flex text-center align-items-center">
                              <span className="me-2 payment_title">
                                Bank name:
                              </span>
                              <span className="pymtP">
                                {curElemData?.bank_name}
                              </span>
                            </Col>
                          </Row>
                        )}

                        {curElemData?.branch_code && (
                          <Row>
                            <Col className="d-flex text-center align-items-center">
                              <span className="me-2 payment_title">
                                Branch Code/BSB Code:
                              </span>
                              <span className="pymtP">
                                {curElemData?.branch_code}
                              </span>
                            </Col>
                          </Row>
                        )}
                        {curElemData?.bic_or_swift && (
                          <Row>
                            <Col className="d-flex text-center align-items-center">
                              <span className="me-2 payment_title">
                                BIC/SWIFT:
                              </span>
                              <span className="pymtP">
                                {curElemData?.bic_or_swift}
                              </span>
                            </Col>
                          </Row>
                        )}

                        {curElemData?.iban_ifsc && (
                          <Row>
                            <Col className="d-flex text-center align-items-center">
                              <span className="me-2 payment_title">
                                IFSC/IBAN:
                              </span>
                              <span className="pymtP">
                                {curElemData?.iban_ifsc}
                              </span>
                            </Col>
                          </Row>
                        )}
                        {curElemData?.micr_code && (
                          <Row>
                            <Col className="d-flex text-center align-items-center">
                              <span className="me-2 payment_title">
                                MICR/Sort Code:
                              </span>
                              <span className="pymtP">
                                {curElemData?.micr_code}
                              </span>
                            </Col>
                          </Row>
                        )}
                        {curElemData?.ach_routing_code && (
                          <Row>
                            <Col className="d-flex text-center align-items-center">
                              <span className="me-2 payment_title">
                                ACH Routing Code:
                              </span>
                              <span className="pymtP">
                                {curElemData?.ach_routing_code}
                              </span>
                            </Col>
                          </Row>
                        )}
                        {curElemData?.clabe && (
                          <Row>
                            <Col className="d-flex text-center align-items-center">
                              <span className="me-2 payment_title">CLABE:</span>
                              <span className="pymtP">
                                {curElemData?.clabe}
                              </span>
                            </Col>
                          </Row>
                        )}
                        {curElemData?.currency && (
                          <Row>
                            <Col className="d-flex text-center align-items-center">
                              <span className="me-2 payment_title">
                                Payment Currency:
                              </span>
                              <span className="pymtP">
                                {curElemData?.currency} (
                                {
                                  currencyList?.filter(
                                    (curr) =>
                                      curr.code === curElemData?.currency
                                  )?.[0]?.name
                                }
                                )
                              </span>
                            </Col>
                          </Row>
                        )}
                        {curElemData?.expert_address && (
                          <Row>
                            <Col className="d-flex text-center align-items-center">
                              <span className="me-2 payment_title">
                                Expert Address:
                              </span>
                              <span className="pymtP">
                                {curElemData?.expert_address}
                              </span>
                            </Col>
                          </Row>
                        )}
                      </div>
                      {curElemData?.pan_number ? (
                        <div>
                          <hr />
                          <Row>
                            <Col>
                              <h5
                                style={{
                                  fontSize: "18px",
                                  fontWeight: 600,
                                  marginBottom: "5px",
                                  marginTop: 0,
                                }}
                              >
                                Other Details
                              </h5>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="d-flex text-center align-items-center">
                              <span className="me-2 payment_title">
                                PAN (of the account holder):
                              </span>
                              <span className="pymtP">
                                {curElemData?.pan_number.toUpperCase()}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="d-flex text-center align-items-center">
                              <span className="me-2 payment_title">
                                PAN Link with Aadhar:
                              </span>
                              <span className="pymtP">
                                {curElemData?.pan_adhar_link_status
                                  .charAt(0)
                                  .toUpperCase() +
                                  curElemData?.pan_adhar_link_status.slice(1)}
                              </span>
                            </Col>
                          </Row>
                        </div>
                      ) : null}
                    </>
                  )}
                  {curElemData?.payment_method.toLowerCase() === "amazon" && (
                    <div>
                      <h5
                        style={{
                          fontSize: "18px",
                          fontWeight: 600,
                          marginBottom: "5px",
                          marginTop: 0,
                        }}
                      >
                        Amazon Gift Card{" "}
                        {curElemData?.status === true && (
                          <MdOutlineStar
                            className="mb-1"
                            style={{ color: "#5746ec" }}
                          />
                        )}
                      </h5>
                      <Col className="d-flex text-center align-items-center">
                        <span className="me-2 payment_title">
                          Amazon Email Id:
                        </span>
                        <span className="pymtP">
                          {curElemData?.amazn_email_id}
                        </span>
                      </Col>
                    </div>
                  )}
                  {curElemData?.payment_method.toLowerCase() === "charity" && (
                    <div>
                      <h5
                        style={{
                          fontSize: "18px",
                          fontWeight: 600,
                          marginBottom: "5px",
                          marginTop: 0,
                        }}
                      >
                        Charity{" "}
                        {curElemData?.status === true && (
                          <MdOutlineStar
                            className="mb-1"
                            style={{ color: "#5746ec" }}
                          />
                        )}
                      </h5>
                      {curElemData?.name_of_charity && (
                        <Row>
                          <Col className="d-flex text-center align-items-center">
                            <span className="me-2 payment_title">
                              Name of Charity:
                            </span>
                            <span className="pymtP">
                              {curElemData?.name_of_charity}
                            </span>
                          </Col>
                        </Row>
                      )}
                      {curElemData?.contact_person_name && (
                        <Row>
                          <Col className="d-flex text-center align-items-center">
                            <span className="me-2 payment_title">
                              Contact Person Name:
                            </span>
                            <span className="pymtP">
                              {curElemData?.contact_person_name}
                            </span>
                          </Col>
                        </Row>
                      )}
                      {curElemData?.country && (
                        <Row>
                          <Col className="d-flex text-center align-items-center">
                            <span className="me-2 payment_title">Country:</span>
                            <span className="pymtP">
                              {curElemData?.country}
                            </span>
                          </Col>
                        </Row>
                      )}
                      {curElemData?.contact_number && (
                        <Row>
                          <Col className="d-flex text-center align-items-center">
                            <span className="me-2 payment_title">
                              Contact Number With Country Code:
                            </span>
                            <span className="pymtP">
                              {curElemData?.contact_number}
                            </span>
                          </Col>
                        </Row>
                      )}
                      {curElemData?.email && (
                        <Row>
                          <Col className="d-flex text-center align-items-center">
                            <span className="me-2 payment_title">Email:</span>
                            <span className="pymtP">{curElemData?.email}</span>
                          </Col>
                        </Row>
                      )}
                      {curElemData?.website_link && (
                        <Row>
                          <Col className="d-flex text-center align-items-center">
                            <span className="me-2 payment_title">
                              Website Link:
                            </span>
                            <span className="pymtP">
                              {curElemData?.website_link}
                            </span>
                          </Col>
                        </Row>
                      )}
                      {curElemData?.donation_link && (
                        <Row>
                          <Col className="d-flex text-center align-items-center">
                            <span className="me-2 payment_title">
                              Donation Link:
                            </span>
                            <span className="pymtP">
                              {curElemData?.donation_link}
                            </span>
                          </Col>
                        </Row>
                      )}
                    </div>
                  )}
                </div>
              )
            )}

            {/* {expertDetails?.[0]?.expertpaymentdetails?.map(
                        (curElemData, index) => (
                            <div key={curElemData?._id}>
                                {curElemData?.payment_method.toLowerCase() ===
                                    "bank" && (
                                        <>
                                            <h4 className='fw-bold m-0 mb-2'>Bank Details</h4>
                                            {curElemData?.country && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <span className="me-2 payment_title">
                                                            Country:
                                                        </span>
                                                        <span className="pymtP">
                                                            {curElemData?.country}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.account_holder_name && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <span className="me-2 payment_title">
                                                            Account holder name:
                                                        </span>
                                                        <span className="pymtP">
                                                            {
                                                                curElemData?.account_holder_name
                                                            }
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}

                                            {curElemData?.account_number && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <span className="me-2 payment_title">
                                                            Account number:
                                                        </span>
                                                        <span className="pymtP">
                                                            {
                                                                curElemData?.account_number
                                                            }
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}

                                            {curElemData?.bank_branch_address && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <span className="me-2 payment_title">
                                                            Bank branch address:
                                                        </span>
                                                        <span className="pymtP">
                                                            {
                                                                curElemData?.bank_branch_address
                                                            }
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}

                                            {curElemData?.bank_name && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <span className="me-2 payment_title">
                                                            Bank name:
                                                        </span>
                                                        <span className="pymtP">
                                                            {curElemData?.bank_name}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}

                                            {curElemData?.branch_code && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <span className="me-2 payment_title">
                                                            Branch Code/BSB Code:
                                                        </span>
                                                        <span className="pymtP">
                                                            {curElemData?.branch_code}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.bic_or_swift && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <span className="me-2 payment_title">
                                                            BIC/SWIFT:
                                                        </span>
                                                        <span className="pymtP">
                                                            {
                                                                curElemData?.bic_or_swift
                                                            }
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}

                                            {curElemData?.iban_ifsc && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <span className="me-2 payment_title">
                                                            IFSC/IBAN:
                                                        </span>
                                                        <span className="pymtP">
                                                            {curElemData?.iban_ifsc}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.micr_code && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <span className="me-2 payment_title">
                                                            MICR/Sort Code:
                                                        </span>
                                                        <span className="pymtP">
                                                            {curElemData?.micr_code}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.ach_routing_code && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <span className="me-2 payment_title">
                                                            ACH Routing Code:
                                                        </span>
                                                        <span className="pymtP">
                                                            {
                                                                curElemData?.ach_routing_code
                                                            }
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.clabe && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <span className="me-2 payment_title">
                                                            CLABE:
                                                        </span>
                                                        <span className="pymtP">
                                                            {curElemData?.clabe}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.currency && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <span className="me-2 payment_title">
                                                            Payment Currency:
                                                        </span>
                                                        <span className="pymtP">
                                                            {curElemData?.currency} (
                                                            {
                                                                currencyList?.filter(
                                                                    (curr) =>
                                                                        curr.code ===
                                                                        curElemData?.currency
                                                                )?.[0]?.name
                                                            }
                                                            )
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.expert_address && (
                                                <Row>
                                                    <Col className="d-flex text-center align-items-center">
                                                        <span className="me-2 payment_title">
                                                            Expert Address:
                                                        </span>
                                                        <span className="pymtP">
                                                            {
                                                                curElemData?.expert_address
                                                            }
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                            {curElemData?.pan_number ? (
                                                <>
                                                    <Row>
                                                        <Col>
                                                            <h5 className="fw-bold">
                                                                Other Details
                                                            </h5>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="d-flex text-center align-items-center">
                                                            <span className="me-2 payment_title">
                                                                PAN (of the account
                                                                holder):
                                                            </span>
                                                            <span className="pymtP">
                                                                {curElemData?.pan_number.toUpperCase()}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="d-flex text-center align-items-center">
                                                            <span className="me-2 payment_title">
                                                                PAN Link with Aadhar:
                                                            </span>
                                                            <span className="pymtP">
                                                                {curElemData?.pan_adhar_link_status
                                                                    .charAt(0)
                                                                    .toUpperCase() +
                                                                    curElemData?.pan_adhar_link_status.slice(
                                                                        1
                                                                    )}
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </>
                                            ) : null}
                                        </>
                                    )}
                            </div>
                        )
                    )}

                    {expertDetails?.[0]?.expertpaymentdetails?.map(
                        (curElemData, index) => (
                            <div key={curElemData._id}>
                                {curElemData?.payment_method.toLowerCase() ===
                                    "amazon" && (
                                        <>
                                            <h4 className='fw-bold m-0 mb-2'>Amazon Gift Card</h4>
                                            <Col className="d-flex text-center align-items-center">
                                                <span className="me-2 payment_title">
                                                    Amazon Email Id:
                                                </span>
                                                <span className="pymtP">
                                                    {
                                                        curElemData?.amazn_email_id
                                                    }
                                                </span>
                                            </Col>
                                        </>
                                    )}
                            </div>
                        )
                    )}

                    {expertDetails?.[0]?.expertpaymentdetails?.map((curElemData, index) => (
                        <div key={curElemData?._id}>
                            {curElemData?.payment_method.toLowerCase() ===
                                "charity" && (
                                    <>
                                        <h4 className='fw-bold m-0 mb-2'>Charity</h4>
                                        {curElemData?.name_of_charity && (
                                            <Row>
                                                <Col className="d-flex text-center align-items-center">
                                                    <span className="me-2 payment_title">
                                                        Name of Charity:
                                                    </span>
                                                    <span className="pymtP">
                                                        {
                                                            curElemData?.name_of_charity
                                                        }
                                                    </span>
                                                </Col>
                                            </Row>
                                        )}
                                        {curElemData?.contact_person_name && (
                                            <Row>
                                                <Col className="d-flex text-center align-items-center">
                                                    <span className="me-2 payment_title">
                                                        Contact Person Name:
                                                    </span>
                                                    <span className="pymtP">
                                                        {
                                                            curElemData?.contact_person_name
                                                        }
                                                    </span>
                                                </Col>
                                            </Row>
                                        )}
                                        {curElemData?.country && (
                                            <Row>
                                                <Col className="d-flex text-center align-items-center">
                                                    <span className="me-2 payment_title">
                                                        Country:
                                                    </span>
                                                    <span className="pymtP">
                                                        {curElemData?.country}
                                                    </span>
                                                </Col>
                                            </Row>
                                        )}
                                        {curElemData?.contact_number && (
                                            <Row>
                                                <Col className="d-flex text-center align-items-center">
                                                    <span className="me-2 payment_title">
                                                        Contact Number With
                                                        Country Code:
                                                    </span>
                                                    <span className="pymtP">
                                                        {
                                                            curElemData?.contact_number
                                                        }
                                                    </span>
                                                </Col>
                                            </Row>
                                        )}
                                        {curElemData?.email && (
                                            <Row>
                                                <Col className="d-flex text-center align-items-center">
                                                    <span className="me-2 payment_title">
                                                        Email:
                                                    </span>
                                                    <span className="pymtP">
                                                        {curElemData?.email}
                                                    </span>
                                                </Col>
                                            </Row>
                                        )}
                                        {curElemData?.website_link && (
                                            <Row>
                                                <Col className="d-flex text-center align-items-center">
                                                    <span className="me-2 payment_title">
                                                        Website Link:
                                                    </span>
                                                    <span className="pymtP">
                                                        {
                                                            curElemData?.website_link
                                                        }
                                                    </span>
                                                </Col>
                                            </Row>
                                        )}
                                        {curElemData?.donation_link && (
                                            <Row>
                                                <Col className="d-flex text-center align-items-center">
                                                    <span className="me-2 payment_title">
                                                        Donation Link:
                                                    </span>
                                                    <span className="pymtP">
                                                        {
                                                            curElemData?.donation_link
                                                        }
                                                    </span>
                                                </Col>
                                            </Row>
                                        )}
                                    </>
                                )}
                        </div>
                    )
                    )} */}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PaymentDetailsModal;
