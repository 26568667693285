import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, Table, Tooltip, notification } from "antd";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { GrContactInfo } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import API_PATH from "../../Constants/api-path";

const onChange = (pagination, filters, sorter, extra) => {
};
const CallRecords = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [, setLoading] = useState(false);
  const [callList, setCallList] = useState([]);
  const [clientDetails, setClientDetails] = useState({});
  const [clientInfoModalOpen, setClientInfoModalOpen] = useState(false);

  const convertDate = (date) => {
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      return p;
    } else {
      return "---------";
    }
  };

  const GetCallList = async (page, selectedOptions) => {
    setLoading(true);

    try {
      const res = await fetch(`${API_PATH.CALL_LIST}?sortBy=date&sortType=-1`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setCallList(result.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetCallList();
  }, []);

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sr",
      key: "sr",
      // render: (text, record, rowIndex) => rowIndex + 1,
      // width: "50px",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      // render: (date) => `${convertDate(date)}`,
      // width: "100px",
    },
    {
      title: "Project Name",
      dataIndex: "project_title",
      key: "project_title",
      // render: (text, record) => {
      //   // Truncate the project title to 6 words
      //   return (
      //     <>
      //       <p
      //         className="projectTitle"
      //         title={record?.project_title}
      //         style={{ cursor: "pointer" }}
      //       >
      //         {record.project_title || "-"}
      //       </p>
      //     </>
      //   );
      // },
    },
    {
      title: "Client Name",
      dataIndex: "client_company",
      key: "client_company",
      // width: "100px",
      // render: (record) => {
      //   return <div style={{ cursor: "pointer" }}>
      //     {record?.client_company} {<Tooltip title={"Click for Client Users"}><GrContactInfo onClick={() => {
      //       setClientDetails(record)
      //       setClientInfoModalOpen(true)
      //     }} /></Tooltip>}
      //   </div>
      // }
    },

    {
      title: "Expert Name",
      dataIndex: "expert_name",
      key: "expert_name",
      // width: "150px",
    },
    {
      title: "Team Member",
      dataIndex: "lead_by",
      key: "lead_by",
      align: "center"
    }
  ];

  const navigate = useNavigate()

  const dataSource = callList?.slice(0, 5)?.map((data, index) => ({
    key: index,
    sr: index + 1,
    date: convertDate(data?.date),
    expert_name: <div style={{ cursor: "pointer" }} >
      <div> {data?.expert_name}</div>
      <div style={{ fontSize: "10px" }}>
        {data?.expert_email}
      </div>
    </div>,
    client_company: <div style={{ cursor: "pointer" }}>
      {data?.client_company} {<Tooltip title={"Click for Client Users"}><GrContactInfo onClick={() => {
        setClientDetails(data)
        setClientInfoModalOpen(true)
      }} /></Tooltip>}
    </div>,
    project_title: <>
      <p
        className="projectTitle link-text"
        title={data?.project_title}
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate(`/dashboard/team/project-overview?project_id=${data?.project_id}&status=1`)
        }}
      >
        {data.project_title || "-"}
      </p>
    </>,
    lead_by: data?.lead_by

  }))
  return (
    <>
      <div className="dasb-div bk-div float-start w-100">
        <div className="body-expart-div">
          <div className="">
            <p className="fs-5 mb-3">
              <strong style={{ color: "#5746ec" }}>Call Records </strong>
            </p>
            <Table
              columns={columns}
              dataSource={dataSource}
              onChange={onChange}
              className="paddTable"
              pagination={false}
              scroll={{ x: 800 }}
            />
          </div>
        </div>
      </div>
      <Modal
        title={<span className="modalHeader" style={{
          fontSize: 18
        }}>Client Users</span>}
        open={clientInfoModalOpen}
        onOk={() => setClientInfoModalOpen(false)}
        onCancel={() => setClientInfoModalOpen(false)}
        width={450}
        centered
        footer={false}
      >
        <div className="mt-4" style={{ width: "100%", textAlign: "left" }}>
          <div style={{ cursor: "pointer" }} className="">
            {clientDetails?.user_id?.length > 0 && Array.isArray(clientDetails?.user_id) ?
              < >
                {clientDetails?.user_id?.map((user, index) =>
                  <Row lg={12} className="d-flex align-items-center">
                    <Col lg={6} className="mb-2 gap-1 d-flex align-items-center">
                      <span>{index + 1}.</span>
                      <div style={{ fontSize: 15 }}> {user?.client_name}</div>
                    </Col>
                    <Col lg={6} className="" >
                      <div style={{ fontSize: 12 }}>{user?.client_email}</div>
                    </Col>
                  </Row>
                )}
              </> :
              <Row lg={12} className=" d-flex align-items-center">
                <Col lg={6} className="mb-2 gap-1 d-flex align-items-center">
                  <span>{1}.</span>
                  <div style={{ fontSize: 15 }}> {clientDetails?.client_name}</div>
                </Col>
                <Col lg={6} className="" >
                  <div style={{ fontSize: 12 }}>{clientDetails?.client_email}</div>
                </Col>
              </Row>
            }
          </div>
        </div>
      </Modal>
    </>
  );
};
export default CallRecords;
