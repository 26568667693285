import { LoadingOutlined } from "@ant-design/icons";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Select,
  Spin,
  Tooltip,
} from "antd";
import axios from "axios";
import { Country } from "country-state-city";
import dayjs from "dayjs";
import _ from "lodash";
import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BsChevronLeft } from "../../Utils/Icons";
import { convertToTitleCase, getUpdatedCountriesTimeZone } from "../../Utils/util";
import "./AddMeetingForm.css";
import useZoomMeeting from "./useZoomMeeting";
import {
  resetZoomMeeting,
  setIsAutoRecordingCheckboxChecked,
  setIsMediatorCheckboxChecked,
  setIsMeetingLinkCheckboxChecked,
  setMediator,
  setStep1,
  setStep3
} from "./zoomMeeting.slice";

import debounce from "lodash/debounce";
import moment from "moment";
import ConfirmationModal from "../../components/ConfirmationModal";
import API_URL from "../../Constants/api-path";
import CustomSelect from "./CustomSelect";
import CustomSelectExpert from "./CustomSelectExpert";

const { Option } = Select;

function AddMeetingForm() {
  const dispatch = useDispatch();
  const {
    zoomAccountList,
    userProjectList,
    form,
    saveDraftMeeting,
    expertCurrency,
    handleDeleteInputExpert,
    handleDeleteInput,
    handleInputChangeExpert, handleExpertCountryChange, handleMediatorCountryChange, handleInputChangeExpertTimezone, handleInputChangeMediatorTimezone,
    handleInputChange,
    handleAddInputExpert,
    handleAddInput,
    isMediatorCheckboxChecked, autoRecordingCheckboxChecked, meetingLinkCheckboxChecked,
    step1,
    step3,
    inputs, mediators,
    zoomCompanyList,
    debounceFetchClient,
    loading,
    loadingClient, loadingExpert,
    isLoadingClients, isLoadingExperts, fetchAllClientList, debounceFetcherProject, zoomExpertList, debounceFetchExpert, fetchAllExpertList, handleAddMediator,
    handleMediatorChange,
    handleDeleteMediator
  } = useZoomMeeting();
  const inputRef = React.useRef(null);
  const clientSelectRef = React.useRef(null);
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState("");
  let [searchParams] = useSearchParams();
  let project_id = searchParams.get("pid") || null
  let project_name = searchParams.get("pn") || null
  let expert_id = searchParams.get("eid") || null
  const [exist, setExist] = useState({
    message: "",
    exist: false,
  });
  const addItem = (e, index) => {
    e.preventDefault();
    let id = Math.floor(100000 + Math.random() * 900000);
    let obj = {
      label: email,
      value: email,
      key: id,
      title: "",
      in_list: false,
    };

    form.setFieldValue([index, "client_email"], email);
    form.setFieldValue([index, "client_name"], "");
    handleInputChange(obj, "client_email", index);
    form.validateFields([[index, "client_email"]])
    setEmail("");
    clientSelectRef.current?.blur();
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  const selectBefore = (
    <div className="d-flex flex-row">
      <Select
        style={{
          width: 80,
        }}
        defaultValue="USD"
        size="large"
        disabled={!step1?.project_id || step1?.engagement_type === "introductory_call"}
        value={step1?.hourly_currency || "USD"} // Use addData?.hourly_currency if it exists, otherwise default to "USD"
        onChange={expertCurrency}
      >
        <Select.Option value="USD">USD</Select.Option>
        <Select.Option value="INR">INR</Select.Option>
        <Select.Option value="AED">AED</Select.Option>
        <Select.Option value="EUR">EUR</Select.Option>
        <Select.Option value="JPY">JPY</Select.Option>
        <Select.Option value="GBP">GBP</Select.Option>
        <Select.Option value="AUD">AUD</Select.Option>
        <Select.Option value="CAD">CAD</Select.Option>
        <Select.Option value="NZD">NZD</Select.Option>
        <Select.Option value="SGD">SGD</Select.Option>
        <Select.Option value="HKD">HKD</Select.Option>
      </Select>
    </div>
  );

  const countries = Country?.getAllCountries();

  const updatedCountries = useMemo(
    () =>
      countries?.map((country, index) => {
        return (
          <Option key={country.isoCode} value={country.name}>
            {country.name}
          </Option>
        );
      }),
    [countries]
  );

  // const updatedCountriesTimeZone = useMemo(() => {
  //     if (!["", undefined, "undefined", null, "null"]?.includes(step1.country)) {
  //         return countries?.filter((country) => country?.name === step1?.country)?.[0]?.timezones?.map((country, index) => {
  //             return <Option key={country?.zoneName} value={country?.zoneName} >{country?.zoneName} </Option>
  //         })
  //     } else {
  //         return countries?.map((country, index) => {
  //             return <Option key={country?.timezones[0]?.zoneName} value={country?.timezones[0]?.zoneName} >{country?.timezones[0]?.zoneName} </Option>
  //         })
  //     }
  // }, [countries, step1.country]);
  // const getUpdatedCountriesTimeZone = useCallback(
  //   (countryName) => {
  //     if (!["", undefined, "undefined", null, "null"]?.includes(countryName)) {
  //       let timeZones = countries?.filter(
  //         (country) => country?.name === countryName
  //       )?.[0]?.timezones;

  //       timeZones?.sort((a, b) => {
  //         if (a.abbreviation < b.abbreviation) {
  //           return -1;
  //         }
  //         if (a.abbreviation > b.abbreviation) {
  //           return 1;
  //         }
  //         return 0;
  //       });

  //       // let unique = [];
  //       // timeZones?.forEach((gmt, index) => {
  //       //   if (
  //       //     unique.filter((elem) => elem.gmtOffsetName === gmt.gmtOffsetName)
  //       //       ?.length === 0
  //       //   ) {
  //       //     unique.push(gmt);
  //       //   }
  //       // });
  //       return timeZones?.map((country, index) => {
  //         return (
  //           <Option key={country?.zoneName} value={country?.zoneName}>
  //             {country?.abbreviation} - {country?.gmtOffsetName} - {country?.zoneName}
  //           </Option>
  //         );
  //       });
  //     }
  //   },
  //   [countries]
  // );

  const dateFormat = "DD/MM/YYYY";

  // const GetZoomAccountList = useCallback(async () => {
  //     try {
  //         const res = await fetch(`${API_PATH.ZOOM_ACCOUNT_LIST}`, {
  //             method: "GET",
  //             headers: {
  //                 "Content-Type": "application/json",
  //                 Authorization: `Bearer ${userData?.token}`,
  //             },
  //         });

  //         const result = await res.json();
  //         if (result) {
  //             setZoomAccountList(result?.data);
  //         }
  //     } catch (error) {
  //         console.error('error: ', error);

  //     }
  // }, [userData?.token]);

  // const callOnce = useRef()
  // useEffect(() => {
  //     if (!callOnce.current) {
  //         callOnce.current = true
  //         GetZoomAccountList()
  //     }
  // }, [GetZoomAccountList])
  const validateName = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Client full name is required."));
    } else if (value) {
      let splitName = value?.split(" ");
      if (splitName?.length > 1) {
        return Promise.resolve();
      } else {
        return Promise.reject(new Error("Please enter full name."));
      }
    }
  };
  const validateEmail = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Moderator email is required."));
    } else if (value) {
      let isEmailValidated = value.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      if (isEmailValidated) {
        return Promise.resolve();
      } else {
        return Promise.reject(new Error("Please enter a valid email address."));
      }
    }
  };
  const validateExpertName = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Expert full name is required."));
    } else if (value) {
      let splitName = value?.split(" ");
      if (splitName?.length > 1) {
        return Promise.resolve();
      } else {
        return Promise.reject(new Error("Please enter full name."));
      }
    }
  };


  // const clientOptions = useMemo(() => {
  //   return zoomCompanyList?.map((itemData) => {
  //     return {
  //       label: itemData?.name,
  //       value: itemData?._id,
  //       key: itemData?._id,
  //     };
  //   });
  // }, [zoomCompanyList]);

  zoomCompanyList?.map((item) => ({
    label: item?.name,
    value: item?._id,
    key: item?._id,
  }));
  const navigate = useNavigate();

  const checkClientExist = React.useCallback(
    async (email, index) => {
      let resp = await axios.get(
        API_URL.CHECK_CLIENT_EXIST + `?email=${email}`
      );
      if (resp?.data?.data) {
        setExist({
          message: resp?.data?.message,
          exist: true,
        });
        form.setFields([
          {
            name: [index, "client_email"],
            errors: [],
          },
        ]);
      } else {
        setExist({
          message: resp?.data?.message,
          exist: false,
        });
        form.setFields([
          {
            name: [index, "client_email"],
            errors: [resp?.data?.message],
          },
        ]);
      }
    },
    [form]
  );
  const onEmailChange = useMemo(() => {
    let loadOptions = (event, index) => {
      checkClientExist(event.target.value, index);
    };
    return debounce(loadOptions, 800);
  }, [checkClientExist]);

  const optionsDatasEmailZoom = useMemo(() => {
    return zoomExpertList?.map((itemData) => {
      const dataEmail = itemData?.email;
      return {
        key: itemData?._id,
        value: dataEmail,
        label: dataEmail,
        title: itemData,
      };
    });
  }, [zoomExpertList]);
  return (
    <div>
      <div className="bk-div float-start w-100">
        <div className="body-expart-div">
          <div className="d-flex justify-content-start align-items-center">
            <span
              onClick={() => {
                navigate(-1);
              }}
              style={{ cursor: "pointer" }}
            >
              <BsChevronLeft className="me-2 search-btn-back" />
            </span>
            <h2>Add New Meeting</h2>
          </div>
          <div className="zoom-form-body">
            <Form
              layout="vertical"
              autoComplete="off"
              form={form}
              scrollToFirstError
              onFinish={() => {
                let time = step1?.call_time
                let date = step1?.call_date
                let fullDateTime = `${date} ${time}`
                let selectedDateTime = moment(fullDateTime, "DD/MM/YYYY HH:mm");
                let timeIsBeforeCurrent = moment().isBefore(selectedDateTime);
                if (!timeIsBeforeCurrent && !["file_or_sheet_filling", "focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type)) {
                  form.setFields([
                    {
                      name: "call_time",
                      errors: ["Meeting time cannot be in past."],
                    },
                  ]);
                } else {
                  saveDraftMeeting();
                }

              }}
            >
              <Form.Item
                label={<h5 className="mb-2">Project Details</h5>}
                name="project_information"
                required
              >
                <Row>
                  <Col>
                    <Form.Item
                      name="project_name"
                      rules={[
                        {
                          required: true,
                          message: "Project name is required.",
                        },
                      ]}
                    >
                      {/* <Input
                        type="text"
                        name="project_name"
                        autoComplete="nope"
                        size="large"
                        placeholder="Project Name"
                        value={step1?.project_name}
                        onChange={handleStep1Change}
                      /> */}
                      <Select
                        showSearch
                        allowClear
                        filterOption={false}
                        // onDropdownVisibleChange={handleDropdownVisibleChange}
                        onSearch={debounceFetcherProject}
                        onClear={() => debounceFetcherProject("")}
                        size="large"
                        value={step1?.project_name || null}
                        disabled={!step1?.engagement_type}
                        placeholder="Select Project"
                        onChange={(newValue, option) => {
                          if (project_id !== null && project_name !== null && expert_id !== null) {
                            navigate("/dashboard/draft-zoom-meetings/add-meeting")
                          }
                          if (step1?.project_name) {
                            form.resetFields()
                            dispatch(resetZoomMeeting());
                          }
                          dispatch(
                            setStep1({
                              ...step1,
                              call_time: "",
                              engagement_type: "micro_consulting_call",
                              project_name: newValue,
                              project_id: option?.key,
                            })
                          );
                          form.setFieldValue("project_name", newValue)
                          form.setFieldValue("engagement_type", "micro_consulting_call")

                        }}
                        style={{ width: "100%" }}
                        options={userProjectList?.map((item) => ({
                          label: item?.project_title,
                          value: item?.project_title,
                          key: item?._id,
                        }))}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Item
                      name={"engagement_type"}
                      rules={[
                        {
                          required: true,
                          message: "Engagement Type is required.",
                        },
                      ]}
                    >
                      <Select
                        name="engagement_type"
                        size="large"
                        labelInValue
                        allowClear
                        placeholder="Select Engagement Type"
                        autoComplete="off"
                        showSearch
                        value={step1.engagement_type}
                        onChange={(value, option) => {
                          if (value?.value === "introductory_call") {
                            dispatch(
                              setStep1({
                                ...step1,
                                engagement_type: value?.value,
                                hourly_rate_call_cunsltn: 0,
                              })
                            );
                            let newStep3 = step3?.map((st3, ind) => {
                              form.setFieldValue([ind, "expert_hourly_rate_call_cunsltn"], 0)
                              return {
                                ...st3,
                                expert_hourly_rate_call_cunsltn: 0
                              }
                            })

                            dispatch(setStep3(newStep3));
                            form.setFieldValue("hourly_rate_call_cunsltn", 0)
                            form.setFieldValue("engagement_type", value?.value)

                          } else {
                            dispatch(
                              setStep1({
                                ...step1,
                                engagement_type: value?.value,
                              })
                            );
                            form.setFieldValue("engagement_type", value?.value)

                          }

                        }}
                      >
                        <Option value={"micro_consulting_call"}>Micro-Consulting Call</Option>
                        <Option value={"introductory_call"}>Introductory Call</Option>
                        <Option value={"file_or_sheet_filling"}>File/Sheet Filling</Option>
                        <Option value={"focus_group_discuession"}>Focus Group Discussion</Option>
                        <Option value={"in_person_meeting"}>In Person Meeting</Option>
                        <Option value={"b2b_survey"}>B2B Survey</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                {/* <Row>
                  <Col>
                    <Form.Item
                      name="call_name"
                      rules={[
                        {
                          required: true,
                          message: "Call name is required.",
                        },
                      ]}
                    >
                      <Input
                        type="text"
                        name="call_name"
                        autoComplete="nope"
                        size="large"
                        placeholder="Call Name"
                        value={step1?.call_name}
                        onChange={(e) => {
                          dispatch(
                            setStep1({
                              ...step1,
                              call_name: e.target.value,
                            })
                          )
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row> */}
                <Row>
                  <Col xs={6} md={4}>
                    <Form.Item
                      name="call_date"
                      rules={[
                        {
                          required: true,
                          message: "Call Date is required.",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        value={dayjs(step1.call_date, dateFormat)}
                        size="large"
                        format={dateFormat}
                        disabled={!step1?.project_id}
                        placeholder="Call Date"
                        disabledDate={(current) =>
                          current && current?.isBefore(dayjs(), "day")
                        }
                        onChange={(value) => {
                          dispatch(
                            setStep1({
                              ...step1,
                              call_date: value
                                ? dayjs(value)?.format(dateFormat)
                                : null,
                            })
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {!["file_or_sheet_filling", "focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) && <Col xs={6} md={3} lg={2}>
                    <Form.Item
                      name="call_time"
                      rules={[
                        {
                          required: true,
                          message: "Call Time is required.",
                        },
                      ]}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileTimePicker
                          label={"Call Time (IST)"}
                          //value={dayjs(step1?.call_time, "HH:mm")}
                          views={["hours", "minutes"]}
                          className="fsfe"
                          name="call_time"
                          // defaultValue={dayjs("00:00", "HH:mm")}
                          disabled={!step1?.project_id}
                          format="HH:mm"
                          ampm={false}
                          onChange={(value) => {
                            dispatch(
                              setStep1({
                                ...step1,
                                call_time: value?.format("HH:mm"),
                                call_time_string: value?.format("HH:mm"),
                              })
                            );
                            form.setFieldValue("call_time", value);
                          }}
                          onAccept={(value) => {
                            form.setFieldValue("call_time", value);
                          }}
                        />
                      </LocalizationProvider>
                      {/* <TimePicker
                                                showSecond={false}
                                                size="large"
                                                style={{ width: '100%' }}
                                                minuteStep={15}
                                                placeholder="Call Time"
                                                format={'HH:mm'}
                                                onChange={(time, timeString) => {
                                                    dispatch(setStep1({
                                                        ...step1,
                                                        call_time: timeString, call_time_string: timeString
                                                    }))
                                                }}
                                                value={dayjs(step1?.call_time || "00:00", 'HH:mm')}
                                            /> */}
                    </Form.Item>
                  </Col>}

                  {!["focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) && <Col md={4}>
                    <Form.Item
                      name={"duration"}
                      rules={[
                        {
                          required: true,
                          message: "Duration is required.",
                        },
                      ]}
                    >
                      <Select
                        name="duration"
                        size="large"
                        labelInValue
                        allowClear
                        disabled={!step1?.project_id}
                        placeholder="Select Duration"
                        autoComplete="off"
                        showSearch
                        value={step1.duration}
                        onChange={(value) => {
                          dispatch(
                            setStep1({
                              ...step1,
                              duration: value,
                            })
                          );
                        }}
                      >
                        <Option value={15}>15 Min</Option>
                        <Option value={30}>30 Min</Option>
                        <Option value={45}>45 Min</Option>
                        <Option value={60}>60 Min</Option>
                        <Option value={75}>75 Min</Option>
                        <Option value={90}>90 Min</Option>
                        <Option value={105}>105 Min</Option>
                        <Option value={120}>120 Min</Option>
                        <Option value={135}>135 Min</Option>
                        <Option value={150}>150 Min</Option>
                        <Option value={165}>166 Min</Option>
                        <Option value={180}>180 Min</Option>
                      </Select>
                    </Form.Item>
                  </Col>}
                </Row>
              </Form.Item>
              <Form.Item
                label={<h5 className="mb-2">Client Details</h5>}
                name="client_information"
                required
              >
                <Row>
                  {!["file_or_sheet_filling", "focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) && <Col md={4}>
                    <Form.Item
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: "Location is required.",
                        },
                      ]}
                    >
                      <Select
                        name="country"
                        size="large"
                        labelInValue
                        disabled={!step1?.project_id}
                        allowClear
                        placeholder="Select Location"
                        autoComplete="off"
                        showSearch
                        value={step1.country || null}
                        onChange={(value) => {
                          dispatch(
                            setStep1({
                              ...step1,
                              country: value?.label,
                              timezone: "",
                              abbreviation: ""
                            })
                          );
                          form.setFieldValue("timezone", null)
                        }}
                      >
                        {updatedCountries}
                      </Select>
                    </Form.Item>
                  </Col>}
                  {!["file_or_sheet_filling", "focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) && <Col md={4} >
                    <Form.Item
                      name="timezone"
                      rules={[
                        {
                          required: true,
                          message: "Timezone is required.",
                        },
                      ]}
                    >
                      <Select
                        name="timezone"
                        size="large"
                        labelInValue
                        disabled={["", undefined, "undefined"]?.includes(
                          step1?.country
                        ) || _.isEmpty(step1?.country) || !step1?.project_id}
                        placeholder="Select Timezone"
                        autoComplete="off"
                        allowClear
                        showSearch
                        value={step1?.timezone}
                        filterOption={(input, option) => {
                          return (
                            option?.children
                              ?.join("")
                              ?.toLowerCase()
                              ?.indexOf(input?.toLowerCase()) >= 0
                          );
                        }}
                        onChange={(value) => {
                          dispatch(
                            setStep1({
                              ...step1,
                              timezone: value?.value,
                              abbreviation: value?.title
                            })
                          );
                        }}
                      >
                        {getUpdatedCountriesTimeZone(step1?.country)}
                      </Select>
                    </Form.Item>
                  </Col>}
                  <Col md={4} >
                    <Form.Item
                      name="hourly_rate_call_cunsltn"
                      // label="Call Rate"
                      rules={[
                        {
                          required: true,
                          message: "Call rate is required.",
                        },
                      ]}
                    >
                      <Input
                        name="hourly_rate_call_cunsltn"
                        type="number"
                        placeholder="Client Call Rate (Hourly)"
                        addonBefore={selectBefore}
                        disabled={!step1?.project_id || step1?.engagement_type === "introductory_call"}
                        size="large"
                        value={step1?.hourly_rate_call_cunsltn}
                        onChange={(e) => {
                          dispatch(
                            setStep1({
                              ...step1,
                              hourly_rate_call_cunsltn: e.target.value,
                            })
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div>
                  {inputs?.map((input, index) => {
                    let filterZoomAccountList = []
                    zoomAccountList?.forEach((user) => {
                      let exist = inputs?.filter((inp) => inp?.client_email === user?.email)
                      if (exist?.length === 0) {
                        filterZoomAccountList.push(user)
                      }
                    })
                    return (
                      <div key={index} className="mb-3">
                        <Row className="align-items-center">
                          <Col md={5}>
                            <Tooltip title={"For Registered Clients Only"}>
                              <Form.Item
                                name={[index, "client_email"]}
                                className="mb-md-0"
                                rules={[
                                  {
                                    required: true,
                                    message: "Client email is required.",
                                  },
                                ]}
                              >
                                <CustomSelect
                                  debounceFetchClient={debounceFetchClient}
                                  input={input}
                                  handleInputChange={(value, field, index) => {
                                    handleInputChange(value, field, index)
                                    if (value?.title?.split(" ")?.length < 2) {
                                      form.setFields([{
                                        name: [index, "client_name"],
                                        errors: ["Please enter full name."]
                                      }])
                                    }
                                  }}
                                  index={index}
                                  dispatch={dispatch}
                                  setStep1={setStep1}
                                  step1={step1}
                                  form={form}
                                  zoomAccountList={filterZoomAccountList}
                                  loadingClient={loadingClient}
                                  isLoadingClients={isLoadingClients}
                                  setEmail={setEmail}
                                  email={email}
                                  onEmailChange={onEmailChange}
                                  exist={exist}
                                  disabled={!step1?.project_id}
                                  addItem={addItem}
                                  creatableSelect={false}
                                  fetchAllClientList={fetchAllClientList}
                                />
                                {/* <Select
                                  showSearch
                                  type="client"
                                  optionType="email"
                                  ref={clientSelectRef}
                                  filterOption={false}
                                  onSearch={debounceFetchClient}
                                  size="large"
                                  value={input.client_email}
                                  placeholder="Client Email"
                                  // onClick={() => setResClient(false)}
                                  onChange={(newValue, option) => {
                                    handleInputChange(
                                      option,
                                      "client_email",
                                      index
                                    );
                                    dispatch(
                                      setStep1({
                                        ...step1,
                                        client: option?.company_id,
                                      })
                                    );
                                    form.setFieldValue(
                                      [index, "client_name"],
                                      convertToTitleCase(option?.title)
                                    );
                                  }}
                                  style={{
                                    width: "100%",
                                  }}
                                  options={zoomAccountList?.map((item) => ({
                                    label: item?.email,
                                    value: item?.email,
                                    key: item?._id,
                                    title: item?.name,
                                    company_id: item?.company_id,
                                    in_list: true,
                                  }))}
                                  dropdownRender={(menu) => (
                                    <>
                                      {loadingClient || isLoadingClients ? (
                                        <span
                                          className="d-flex align-items-center justify-content-center"
                                          style={{
                                            height: 40,
                                          }}
                                        >
                                          <Spin size="small" />{" "}
                                        </span>
                                      ) : (
                                        <>
                                          {menu}
                                          <Divider
                                            style={{
                                              margin: "8px 0",
                                            }}
                                          />
                                          <Space.Compact
                                            block
                                            style={{
                                              padding: "0 8px 4px",
                                              width: "100%",
                                            }}
                                            className="zum_meeting_space"
                                          >
                                            <Tooltip
                                              title={
                                                "For Unregistered Clients Only"
                                              }
                                              overlayStyle={{ zIndex: 2000 }}
                                            >
                                              <Input
                                                placeholder="Please enter client email"
                                                ref={inputRef}
                                                width={100}
                                                value={email}
                                                onChange={(e) => {
                                                  setEmail(e.target.value);
                                                  if (e.target.value !== "") {
                                                    onEmailChange(e, index);
                                                  }
                                                }}
                                                onKeyDown={(e) =>
                                                  e.stopPropagation()
                                                }
                                              />
                                            </Tooltip>
                                            {resClient ? (
                                              <Button
                                                type="text"
                                                disabled={!exist?.exist}
                                                style={{ color: "green" }}
                                                className="d-flex align-items-center"
                                              >
                                                <FaCheck />
                                              </Button>
                                            ) : (
                                              <Button
                                                type="text"
                                                disabled={!exist?.exist}
                                                icon={<PlusOutlined />}
                                                className="d-flex align-items-center"
                                                onClick={(e) =>
                                                  addItem(e, index)
                                                }
                                              >
                                                Add
                                              </Button>
                                            )}
                                          </Space.Compact>
                                        </>
                                      )}
                                    </>
                                  )}
                                /> */}
                              </Form.Item>
                            </Tooltip>
                          </Col>
                          <Col md={5}>
                            <Form.Item
                              name={[index, "client_name"]}
                              className="mb-md-0"
                              rules={[
                                {
                                  required: true,
                                  validator: validateName,
                                },
                              ]}
                            >
                              <Input
                                name="client_name"
                                type="text"
                                placeholder="Client Name"
                                size="large"
                                disabled={!step1?.project_id}
                                value={input.client_name}
                                onChange={(e) => {
                                  handleInputChange(
                                    convertToTitleCase(e.target.value),
                                    "client_name",
                                    index
                                  );
                                }}
                              />
                            </Form.Item>
                          </Col>

                          <Col md={2}>
                            <div className="text-end text-md-start">
                              {(index === inputs?.length - 1 && index !== 0) && <Button
                                type="primary"
                                onClick={() => {
                                  if (inputs.length > 1) {
                                    let allData = form.getFieldValue([index]) || {}
                                    form.setFieldsValue({
                                      [index]: {
                                        ...allData,
                                        client_email: undefined,
                                        client_name: undefined,
                                      }
                                    });
                                  }

                                  handleDeleteInput(index);
                                }}
                              >
                                Remove
                              </Button>}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}

                  <div className="d-flex justify-content-center align-items-center">
                    <Button
                      size="middle"
                      className="text-center btn-responsive expertButtonNext"
                      onClick={handleAddInput}
                    >
                      Add Client User
                    </Button>
                  </div>
                </div>
              </Form.Item>
              <Form.Item
                label={<h5 className="mb-2">{["focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) ? "Respondent/External Partner Details" : "Expert Details"}</h5>}
                name="expert_information"
                required
              >
                <div>
                  {step3?.map((input, index) => {
                    return (
                      <div key={index} className="mb-2">
                        <h6 className="py-2">{["focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) ? "Partner" : "Expert"} #{index + 1}</h6>
                        <Row>
                          <Col md={6}>
                            <Tooltip title={["focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) ? "For Registered Partners Only" : "For Registered Experts Only"}>
                              <Form.Item
                                name={[index, "expert_email"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Expert email is required.",
                                  },
                                ]}
                              >
                                {/* <DebounceSelect
                                  type="zoomExpert"
                                  size="large"
                                  value={input.expert_email}
                                  placeholder="Select Expert (if the expert is not in the list then add their email using the Add button)"
                                  optionType="email"
                                  extraFunction={(email) => {
                                    form.setFieldValue(
                                      [index, "expert_email"],
                                      email
                                    );
                                    form.setFieldValue(
                                      [index, "expert_name"],
                                      ""
                                    );
                                    handleInputChangeExpert(
                                      email,
                                      "expert_email",
                                      index
                                    );
                                    form.validateFields([[index, "expert_email"]])
                                  }}
                                  onChange={(newValue) => {
                                    handleInputChangeExpert(
                                      newValue,
                                      "expert_email",
                                      index
                                    );
                                    form.setFieldValue(
                                      [index, "expert_name"],
                                      convertToTitleCase(newValue?.title)
                                    );
                                  }}
                                  style={{
                                    width: "100%",
                                  }}
                                  creatableSelect={true}
                                /> */}
                                <CustomSelectExpert
                                  showSearch
                                  labelInValue
                                  disabled={!step1?.project_id}
                                  optionType="email"
                                  filterOption={false}
                                  placeholder={["focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) ? "Select Partner" : "Select Expert"}
                                  fetchExperts={fetchAllExpertList}
                                  debounceFetcher={debounceFetchExpert}
                                  optionsDatasEmail={optionsDatasEmailZoom}
                                  fetching={loadingExpert || isLoadingExperts}
                                  creatableSelect={false}
                                  email={email}
                                  onEmailChange={onEmailChange}
                                  addItem={addItem}
                                  onChange={(newValue) => {
                                    handleInputChangeExpert(
                                      newValue,
                                      "expert_email",
                                      index
                                    );
                                    if (newValue?.title?.name?.trim()?.split(" ")?.length < 2) {
                                      form.setFields([{
                                        name: [index, "expert_name"],
                                        errors: ["Please enter full name."]
                                      }])
                                    }

                                    form.setFieldValue(
                                      [index, "expert_name"],
                                      convertToTitleCase(newValue?.title?.name?.trim())
                                    );
                                    form.setFieldValue(
                                      [index, "expert_hourly_rate_call_cunsltn"],
                                      step1?.engagement_type === "introductory_call" ? 0 : newValue?.title?.hourly_consulting_rate
                                    );
                                    form.setFieldValue(
                                      [index, "expert_designation"],
                                      convertToTitleCase(newValue?.title?.admin_expert_experience?.current_designation || "")
                                    );
                                    form.setFieldValue(
                                      [index, "expert_company"],
                                      convertToTitleCase(newValue?.title?.admin_expert_experience?.current_compny || "")
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Tooltip>
                          </Col>
                          <Col md={6}>
                            <Form.Item
                              name={[index, "expert_name"]}
                              rules={[
                                {
                                  required: true,
                                  validator: validateExpertName,
                                },
                              ]}
                            >
                              <Input
                                name="expert_name"
                                type="text"
                                placeholder={["focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) ? "Partner Name" : "Expert Name"}
                                disabled={!step1?.project_id}
                                size="large"
                                value={input.expert_name}
                                onChange={(e) => {
                                  handleInputChangeExpert(
                                    convertToTitleCase(e.target.value),
                                    "expert_name",
                                    index
                                  );
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          {!["file_or_sheet_filling", "focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) && <Col md={6}>
                            <Form.Item
                              name={[index, "country"]}
                              // label={
                              //     <span className="">Country</span>
                              // }
                              rules={[
                                {
                                  required: true,
                                  message: "Location is required.",
                                },
                              ]}
                            >
                              <Select
                                name="country"
                                size="large"
                                labelInValue
                                allowClear
                                placeholder="Select Location"
                                disabled={!step1?.project_id}
                                autoComplete="off"
                                showSearch
                                value={input.country}
                                onChange={(value) => {
                                  handleExpertCountryChange(
                                    value?.label,
                                    index
                                  );
                                  form.setFieldValue([index, "timezone"], null)
                                }}
                              >
                                {updatedCountries}
                              </Select>
                            </Form.Item>
                          </Col>}
                          {!["file_or_sheet_filling", "focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) && <Col md={6}>
                            <Form.Item
                              name={[index, "timezone"]}
                              // label={
                              //     <span className="">City</span>
                              // }
                              rules={[
                                {
                                  required: true,
                                  message: "Timezone is required.",
                                },
                              ]}
                            >
                              <Select
                                name="timezone"
                                size="large"
                                labelInValue
                                placeholder="Select Timezone"
                                disabled={["", undefined, "undefined"]?.includes(
                                  input?.country
                                ) || _.isEmpty(input?.country) || !step1?.project_id}
                                autoComplete="off"
                                allowClear
                                showSearch
                                filterOption={(input, option) => {
                                  return (
                                    option?.children
                                      ?.join("")
                                      ?.toLowerCase()
                                      ?.indexOf(input?.toLowerCase()) >= 0
                                  );
                                }}
                                value={input?.timezone}
                                onChange={(value) => {
                                  handleInputChangeExpertTimezone(
                                    value,
                                    index
                                  );
                                }}
                              >
                                {/* {updatedCountriesTimeZone} */}
                                {getUpdatedCountriesTimeZone(input?.country)}
                              </Select>
                            </Form.Item>
                          </Col>}
                        </Row>
                        <Row className="align-items-center">
                          <Col md={4}>
                            <Form.Item
                              name={[index, "expert_hourly_rate_call_cunsltn"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Call rate is required.",
                                },
                              ]}
                            >
                              <Input
                                name="expert_hourly_rate_call_cunsltn"
                                type="number"
                                addonBefore={
                                  <div className="d-flex flex-row">
                                    <Select
                                      style={{
                                        width: 80,
                                      }}
                                      defaultValue="USD"
                                      size="large"
                                      disabled={!step1?.project_id || step1?.engagement_type === "introductory_call"}
                                      value={
                                        input?.expert_hourly_currency || "USD"
                                      } // Use addData?.hourly_currency if it exists, otherwise default to "USD"
                                      onChange={(value) => {
                                        handleInputChangeExpert(
                                          value,
                                          "expert_hourly_currency",
                                          index
                                        );
                                      }}
                                    >
                                      <Select.Option value="USD">
                                        USD
                                      </Select.Option>
                                      <Select.Option value="EUR">
                                        EUR
                                      </Select.Option>
                                      <Select.Option value="INR">INR</Select.Option>
                                      <Select.Option value="AED">AED</Select.Option>
                                      <Select.Option value="JPY">
                                        JPY
                                      </Select.Option>
                                      <Select.Option value="GBP">
                                        GBP
                                      </Select.Option>
                                      <Select.Option value="AUD">
                                        AUD
                                      </Select.Option>
                                      <Select.Option value="CAD">
                                        CAD
                                      </Select.Option>
                                      <Select.Option value="NZD">
                                        NZD
                                      </Select.Option>
                                      <Select.Option value="SGD">
                                        SGD
                                      </Select.Option>
                                      <Select.Option value="HKD">
                                        HKD
                                      </Select.Option>
                                    </Select>
                                  </div>
                                }
                                size="large"
                                disabled={!step1?.project_id || step1?.engagement_type === "introductory_call"}
                                placeholder={["focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) ? "Partner Call Rate (Hourly)" : "Expert Call Rate (Hourly)"}
                                value={input?.expert_hourly_rate_call_cunsltn}
                                onChange={(e) => {
                                  handleInputChangeExpert(
                                    e.target.value,
                                    "expert_hourly_rate_call_cunsltn",
                                    index
                                  );
                                }}
                              />
                            </Form.Item>
                          </Col>
                          {!["file_or_sheet_filling", "focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) && <Col md={4}>
                            <Form.Item
                              name={[index, "expert_designation"]}
                              // label={
                              //     <span className="">Expert Designation</span>
                              // }
                              rules={[
                                {
                                  required: true,
                                  message: "Designation is required.",
                                },
                              ]}
                            >
                              <Input
                                name="expert_designation"
                                type="text"
                                size="large"
                                disabled={!step1?.project_id}
                                placeholder="Expert Designation"
                                value={input?.expert_designation}
                                onChange={(e) => {
                                  handleInputChangeExpert(
                                    e.target.value,
                                    "expert_designation",
                                    index
                                  );
                                }}
                              />
                            </Form.Item>
                          </Col>}
                          {!["file_or_sheet_filling", "focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) && <Col md={4}>
                            <Form.Item
                              name={[index, "expert_company"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Company is required.",
                                },
                              ]}
                            >
                              <Input
                                name="expert_company"
                                type="text"
                                size="large"
                                disabled={!step1?.project_id}
                                placeholder="Expert Company"
                                value={input?.expert_company}
                                onChange={(e) => {
                                  handleInputChangeExpert(
                                    e.target.value,
                                    "expert_company",
                                    index
                                  );
                                }}
                              />
                            </Form.Item>
                          </Col>}
                        </Row>
                        <Row>
                          <Col xs={12} md={12}>
                            <Form.Item name={[index, "additional_details"]}>
                              <Input.TextArea
                                name="additional_details"
                                type="text"
                                placeholder="Additional Details"
                                disabled={!step1?.project_id}
                                size="large"
                                value={input.additional_details}
                                onChange={(e) => {
                                  handleInputChangeExpert(
                                    e.target.value,
                                    "additional_details",
                                    index
                                  );
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row className="mb-md-3 justify-content-between">
                          <Col xs={8} md={6}>
                            {input?.in_list && (
                              <Form.Item
                                name={[index, "opt_out_check"]}
                                className="mb-0"
                              >
                                <Checkbox
                                  checked={input.opt_out_check}
                                  name="opt_out_check"
                                  disabled={!step1?.project_id}
                                  onChange={(e) => {
                                    handleInputChangeExpert(
                                      e.target.checked,
                                      "opt_out_check",
                                      index
                                    );
                                  }}
                                >
                                  <span className="font-weight-bold"><strong>DO NOT</strong> {["focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) ? "send pre call email to partner" : "send pre call email to expert"}</span>
                                </Checkbox>
                              </Form.Item>
                            )}
                          </Col>

                          <Col xs={4} md={2}>
                            <div className="text-end">
                              {(index === step3?.length - 1 && index !== 0) && <Button
                                type="primary"
                                onClick={() => {
                                  if (step3.length > 1) {
                                    let allData = form.getFieldValue([index]) || {}
                                    form.setFieldsValue({
                                      [index]: {
                                        ...allData,
                                        expert_company: undefined,
                                        expert_designation: undefined,
                                        expert_email: undefined,
                                        expert_hourly_rate_call_cunsltn: step1?.engagement_type === "introductory_call" ? 0 : undefined,
                                        country: undefined,
                                        timezone: undefined,
                                        abbreviation: undefined,
                                        opt_out_check: undefined,
                                        additional_details: undefined,
                                        expert_name: undefined,
                                      }
                                    });
                                    handleDeleteInputExpert(index)
                                  }

                                }}
                              >
                                Remove
                              </Button>}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}

                  <div className="d-flex justify-content-center align-items-center">
                    <Button
                      size="middle"
                      className="text-center btn-responsive expertButtonNext"
                      onClick={handleAddInputExpert}
                    >
                      {["focus_group_discuession", "b2b_survey"]?.includes(step1?.engagement_type) ? "Add Partner" : "Add Expert"}
                    </Button>
                  </div>
                </div>
              </Form.Item>
              {/* <Col 
                <Form.Item>
                  <Checkbox
                    checked={isExpertCheckboxChecked}
                    onChange={(e) =>
                      dispatch(setIsExpertCheckboxChecked(e.target.checked))
                    }
                  >
                    <span>Opt Out the expert for pre-call and post-call emails</span>
                  </Checkbox>
                </Form.Item>
              </Col> */}
              {/* <Col>
                <Form.Item>
                  <Checkbox
                    checked={isCheckboxChecked}
                    onChange={(e) =>
                      dispatch(setIsCheckboxChecked(e.target.checked))
                    }
                  >
                    <span>Get call transcript for internal purpose</span>
                  </Checkbox>
                </Form.Item>
              </Col> */}


              {isMediatorCheckboxChecked && <Form.Item
                label={<h5 className="mb-2">Moderator Details</h5>}
                name="mediator_information"
                required
              >

                <div>
                  {mediators?.map((medi, index) => {
                    return (
                      <div key={index} className="mb-3">
                        <Row>
                          <Col md={6}>

                            <Form.Item
                              name={[index, "mediator_email"]}
                              className="mb-md-0"
                              rules={[
                                {
                                  required: true,
                                  validator: validateEmail,

                                },
                              ]}
                            >
                              <Input
                                name="mediator_email"
                                type="text"
                                placeholder="Moderator Email"
                                size="large"
                                disabled={!step1?.project_id}
                                value={medi.mediator_email}
                                onChange={(e) => {
                                  handleMediatorChange(
                                    e.target.value,
                                    "mediator_email",
                                    index
                                  );
                                }}
                              />


                            </Form.Item>
                          </Col>
                          <Col md={6}>
                            <Form.Item
                              name={[index, "mediator_name"]}
                              className="mb-md-0"
                              rules={[
                                {
                                  required: true,
                                  validator: validateName,
                                },
                              ]}
                            >
                              <Input
                                name="mediator_name"
                                type="text"
                                placeholder="Moderator Name"
                                size="large"
                                disabled={!step1?.project_id}
                                value={medi.mediator_name}
                                onChange={(e) => {
                                  handleMediatorChange(
                                    convertToTitleCase(e.target.value),
                                    "mediator_name",
                                    index
                                  );
                                }}
                              />
                            </Form.Item>
                          </Col>

                        </Row>
                        <Row className="mt-3">
                          <Col md={5}>
                            <Form.Item
                              name={[index, "mediator_country"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Location is required.",
                                },
                              ]}
                            >
                              <Select
                                name="mediator_country"
                                size="large"
                                labelInValue
                                disabled={!step1?.project_id}
                                allowClear
                                placeholder="Select Location"
                                autoComplete="off"
                                showSearch
                                value={medi.mediator_country}
                                onChange={(value) => {
                                  handleMediatorCountryChange(
                                    value?.label,
                                    index
                                  );
                                  form.setFieldValue([index, "mediator_timezone"], null)
                                }}
                              >
                                {updatedCountries}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col md={5} >
                            <Form.Item
                              name={[index, "mediator_timezone"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Timezone is required.",
                                },
                              ]}
                            >
                              <Select
                                name="mediator_timezone"
                                size="large"
                                labelInValue
                                disabled={["", undefined, "undefined"]?.includes(
                                  medi?.mediator_country
                                ) || !step1?.project_id}
                                placeholder="Select Timezone"
                                autoComplete="off"
                                allowClear
                                showSearch
                                value={medi?.mediator_timezone}
                                filterOption={(input, option) => {
                                  return (
                                    option?.children
                                      ?.join("")
                                      ?.toLowerCase()
                                      ?.indexOf(input?.toLowerCase()) >= 0
                                  );
                                }}
                                onChange={(value) => {
                                  handleInputChangeMediatorTimezone(
                                    value,
                                    index
                                  );

                                }}
                              >
                                {getUpdatedCountriesTimeZone(medi?.mediator_country)}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col md={2} className="mt-2">
                            <div className="text-end text-md-start">
                              {(index === mediators?.length - 1 && index !== 0) && <Button
                                type="primary"
                                onClick={() => {
                                  // form.setFieldsValue({ [index]: undefined });
                                  handleDeleteMediator(index);
                                  let allData = form.getFieldValue([index]) || {}
                                  if (mediators.length > 1) {
                                    form.setFieldsValue({
                                      [index]: {
                                        ...allData,
                                        mediator_email: undefined,
                                        mediator_name: undefined,
                                        mediator_country: undefined,
                                        mediator_timezone: undefined
                                      }
                                    });
                                  }

                                }}
                              >
                                Remove
                              </Button>}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}

                  <div className="d-flex justify-content-center align-items-center">
                    <Button
                      size="middle"
                      className="text-center btn-responsive expertButtonNext"
                      onClick={handleAddMediator}
                    >
                      Add Moderator
                    </Button>
                  </div>
                </div>
              </Form.Item>}
              <Col>
                <Form.Item>
                  <div className="d-flex justify-content-center align-items-center">
                    <Button
                      size="middle"
                      className="text-center btn-responsive expertButtonNext"
                      onClick={() => {
                        dispatch(setIsMediatorCheckboxChecked(!isMediatorCheckboxChecked))
                        let allData = form.getFieldsValue() || {}
                        mediators?.forEach((medi, index) => {
                          form.setFieldsValue({
                            [index]: {
                              ...allData[index],
                              mediator_email: undefined,
                              mediator_name: undefined,
                              mediator_country: undefined,
                              mediator_timezone: undefined
                            }
                          });
                        })
                        dispatch(setMediator([
                          {
                            mediator_email: "",
                            mediator_name: "",
                            mediator_country: "",
                            mediator_timezone: "",

                          },
                        ]))

                      }}
                    >
                      {isMediatorCheckboxChecked ? "Remove All Moderator Details" : "Add Moderator Details"}
                    </Button>
                  </div>

                </Form.Item>
              </Col>
              <Row className="mb-4">
                {!["file_or_sheet_filling", "focus_group_discuession", "b2b_survey", "in_person_meeting"]?.includes(step1?.engagement_type) && <Col md={6}>
                  <Checkbox
                    checked={meetingLinkCheckboxChecked}
                    onChange={(e) => {
                      if (e.target.checked) {
                        dispatch(setIsMeetingLinkCheckboxChecked(e.target.checked))
                        dispatch(setIsAutoRecordingCheckboxChecked(false))
                      } else {
                        let allData = form.getFieldsValue() || {}
                        dispatch(setIsAutoRecordingCheckboxChecked(true))
                        dispatch(setIsMeetingLinkCheckboxChecked(false))
                        dispatch(
                          setStep1({
                            ...step1,
                            meeting_link: "",
                            meeting_id: "",
                            meeting_passcode: "",
                          })
                        );

                        form.setFieldsValue({
                          ...allData,
                          meeting_link: "",
                          meeting_id: "",
                          meeting_passcode: "",
                        });
                      }
                    }
                    }
                  >
                    <span>Meeting Link Provided By Client</span>
                  </Checkbox>
                </Col>}
                {!["file_or_sheet_filling", "focus_group_discuession", "in_person_meeting", "b2b_survey"]?.includes(step1?.engagement_type) && <Col md={6} className="text-end">
                  {/* <Col md={6} className=""> */}
                  {!meetingLinkCheckboxChecked && <Checkbox
                    checked={autoRecordingCheckboxChecked}
                    onChange={(e) => {
                      if (!e.target.checked) {
                        setOpen(true)
                      } else {
                        dispatch(setIsAutoRecordingCheckboxChecked(e.target.checked))
                      }
                    }
                    }
                  >
                    <span>Record this call</span>
                  </Checkbox>}
                </Col>}

              </Row>
              {!["file_or_sheet_filling", "focus_group_discuession", "b2b_survey", "micro_consulting_call", "introductory_call"]?.includes(step1?.engagement_type) && <Row>
                <Col md={12}>
                  <Form.Item
                    name={"location_address"}
                    label="Location Address"
                    className="mb-md-0"
                    rules={[
                      {
                        required: true,
                        message: "Location Address is required"
                      },
                    ]}
                  >
                    <Input
                      name="location_address"
                      type="text"
                      placeholder="Location Address"
                      size="large"
                      disabled={!step1?.project_id}
                      value={step1.location_address}
                      onChange={(e) => {
                        dispatch(
                          setStep1({
                            ...step1,
                            location_address: e.target.value
                          })
                        );
                      }}
                    />
                  </Form.Item></Col>
              </Row>}
              {meetingLinkCheckboxChecked && <Row className="align-items-center mt-3">
                <Col md={4}>
                  <Form.Item
                    name={"meeting_link"}
                    label="Meeting Link"
                    className="mb-md-0"
                    rules={[
                      {
                        required: true,
                        message: "Meeting Link is required"
                      },
                    ]}
                  >
                    <Input
                      name="meeting_link"
                      type="text"
                      placeholder="Meeting Link"
                      size="large"
                      disabled={!step1?.project_id}
                      value={step1.meeting_link}
                      onChange={(e) => {
                        dispatch(
                          setStep1({
                            ...step1,
                            meeting_link: e.target.value
                          })
                        );
                      }}
                    />
                  </Form.Item></Col>
                <Col md={4}>
                  <Form.Item
                    name={"meeting_id"}
                    className="mb-md-0"
                    label="Meeting ID (Please enter if applicable)"
                  >
                    <Input
                      name="meeting_id"
                      type="text"
                      placeholder="Meeting Id"
                      size="large"
                      disabled={!step1?.project_id}
                      value={step1.meeting_id}
                      onChange={(e) => {
                        dispatch(
                          setStep1({
                            ...step1,
                            meeting_id: e.target.value
                          })
                        );
                      }}
                    />
                  </Form.Item></Col>
                <Col md={4}>
                  <Form.Item
                    name={"meeting_passcode"}
                    className="mb-md-0"
                    label="Passcode (Please enter if applicable)"
                  >
                    <Input
                      name="meeting_passcode"
                      type="text"
                      placeholder="Meeting Passcode"
                      size="large"
                      disabled={!step1?.project_id}
                      value={step1.meeting_passcode}
                      onChange={(e) => {
                        dispatch(
                          setStep1({
                            ...step1,
                            meeting_passcode: e.target.value
                          })
                        );
                      }}
                    />
                  </Form.Item></Col>
              </Row>
              }

              <Row>
                <div className="d-flex justify-content-center justify-content-lg-end gap-2 mt-3">
                  <Form.Item shouldUpdate className="text-end">
                    <Button
                      onClick={() => {
                        saveDraftMeeting("draft");
                      }}
                      className="btn btn-responsive expertButtonNext"
                      disabled={loading?.load && loading.type === "draft"}
                      size="large"
                      htmlType="button"
                    >
                      {loading?.load && loading.type === "draft" && (
                        <Spin
                          size="small"
                          indicator={
                            <LoadingOutlined
                              style={{
                                color: "white",
                                paddingRight: 5,
                              }}
                            />
                          }
                        />
                      )}{" "}
                      Save as Draft
                    </Button>
                  </Form.Item>
                  <Form.Item shouldUpdate className="text-end">
                    <Button
                      // onClick={handleSubmitTwo}
                      className="btn btn-responsive expertButtonNext"
                      disabled={loading?.load && loading.type === "submit"}
                      size="large"
                      htmlType="submit"
                    >
                      {loading?.load && loading.type === "submit" && (
                        <Spin
                          size="small"
                          indicator={
                            <LoadingOutlined
                              style={{
                                color: "white",
                                paddingRight: 5,
                              }}
                            />
                          }
                        />
                      )}{" "}
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </div>
      <ConfirmationModal
        open={open}
        setOpen={setOpen}
        message={"Are you sure you want recording turned off for this call?"}
        title={"Turn Off Recording"}
        okFunction={async () => {
          dispatch(setIsAutoRecordingCheckboxChecked(false))
          setOpen(false)
        }}
        cancelFunction={() => {
          setOpen(false)
        }}
        okText={"Yes"}
      />
    </div>

  );
}

export default AddMeetingForm;
