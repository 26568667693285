import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Spin,
} from "antd";
import React, { useCallback, useState } from "react";
import API_URL from "../../Constants/api-path";
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import DebounceSelect from "../CloudFeatures/InternalMeetings/Components/DebounceSelect";
const UpdateClientModal = ({
  showUpdateModal,
  setUpdateModal,
  GetClientList,
  selectedClient,
}) => {
  const [form] = Form.useForm();
  const [clienData, setClienData] = useState({
    companyName: "",
    client_type: null,
    bd_owner: null,
    cs_owner: null,
  });
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const resetForm = useCallback(() => {
    setClienData({
      companyName: "",
      client_type: null,
      bd_owner: null,
      cs_owner: null,
    });
    setUpdateModal(false);
    form.resetFields();
    fillFormValueOnce.current = false;
  }, [form, setUpdateModal]);
  const fillFormValueOnce = React.useRef(false);
  React.useEffect(() => {
    if (showUpdateModal && selectedClient?._id && !fillFormValueOnce.current) {
      fillFormValueOnce.current = true;
      let obj = {
        companyName: selectedClient?.companyName,
        client_type: selectedClient?.client_type,
        bd_owner: {
          key: selectedClient?.bd_owner?.[0]?._id,
          label: selectedClient?.bd_owner?.[0]?.name,
          value: selectedClient?.bd_owner?.[0]?.email,
        },
        cs_owner: {
          key: selectedClient?.cs_owner?.[0]?._id,
          label: selectedClient?.cs_owner?.[0]?.name,
          value: selectedClient?.cs_owner?.[0]?.email,
        },
      };
      setClienData(obj);
      form.setFieldsValue(obj);
    }
  }, [form, selectedClient, showUpdateModal]);

  const UpdateClientData = useCallback(async () => {
    setLoadingSubmit(true);

    try {
      const res = await fetch(
        `${API_URL.UPDATE_CLIENT_COMPANY}/${selectedClient?._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
          body: JSON.stringify({
            client_type: clienData?.client_type,
            bd_owner: clienData?.bd_owner?.key,
            cs_owner: clienData?.cs_owner?.key,
          }),
        }
      );

      const result = await res.json();
      if (result && (result.status === 200 || result.status === true)) {
        GetClientList();
        resetForm();
        notification.success({
          message: "Client created",
          icon: <CheckCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#2ecc71",
            color: "#fff",
            border: "1px solid #52c41a",
          },
          duration: 5,
          placement: "topRight",
        });
        setLoadingSubmit(false);
      } else if (result && result.message === "Email is already exists.") {
        setLoadingSubmit(false);
        notification.error({
          message: "Client domain already exists.",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      } else {
        setLoadingSubmit(false);
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoadingSubmit(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  }, [GetClientList, clienData?.bd_owner?.key, clienData?.client_type, clienData?.cs_owner?.key, resetForm, selectedClient?._id, userData?.token]);
  const handleInputsClient = useCallback(
    (e) => {
      const name = e.target.name;
      const value = e.target.value;
      setClienData({ ...clienData, [name]: value });
    },
    [clienData]
  );

  return (
    <Modal
      open={showUpdateModal}
      title={
        <div>
          Update Client
          <Divider className="m-2" />
        </div>
      }
      onCancel={() => {
        resetForm();
        fillFormValueOnce.current = false;
      }}
      width={800}
      centered
      scrollable={true}
      footer={false}
    >
      <Form
        form={form}
        layout="vertical"
        name="create_client_form"
        onFinish={() => {
          UpdateClientData();
        }}
      >
        <div>
          <div className="row">
            <div className="col-md-6">
              <Form.Item
                label="Company Name"
                name={"companyName"}
                rules={[
                  {
                    required: true,
                    message: "Company Name is required.",
                  },
                ]}
              >
                <Input
                  size="large"
                  disabled
                  name="companyName"
                  value={clienData?.companyName}
                  onChange={handleInputsClient}
                />
              </Form.Item>
            </div>

            <div className="col-md-6">
              <Form.Item
                label="Client Type"
                name={"client_type"}
                rules={[
                  {
                    required: true,
                    message: "Client type is required.",
                  },
                ]}
              >
                <Select
                  size="large"
                  style={{
                    width: "100%",
                  }}
                  value={clienData?.client_type}
                  onChange={(value) => {
                    setClienData({ ...clienData, client_type: value });
                  }}
                >
                  <Select.Option value={"PSFs"}>PSFs</Select.Option>
                  <Select.Option value={"Corporates"}>Corporates</Select.Option>
                  <Select.Option value={"Investment Firms"}>
                    Investment Firms
                  </Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                label="BD Owner"
                name={"bd_owner"}
                rules={[
                  {
                    required: true,
                    message: "BD Owner is required.",
                  },
                ]}
              >
                <DebounceSelect
                  type="manager"
                  size="large"
                  optionType="email"
                  name={`bd_owner`}
                  // mode="multiple"
                  value={clienData.bd_owner}
                  placeholder="Select Team Member"
                  style={{ flex: 1, marginRight: "8px" }}
                  virtual={false}
                  onChange={(selectedValue) => {
                    setClienData({ ...clienData, bd_owner: selectedValue });
                  }}
                  allowClear
                />
              </Form.Item>
            </div>
            <div className="col-md-6">
              <Form.Item
                label="CS Owner"
                name={"cs_owner"}
                rules={[
                  {
                    required: true,
                    message: "CS Owner is required.",
                  },
                ]}
              >
                <DebounceSelect
                  type="manager"
                  size="large"
                  optionType="email"
                  name={`cs_owner`}
                  value={clienData.cs_owner}
                  placeholder="Select Team Member"
                  style={{ flex: 1, marginRight: "8px" }}
                  virtual={false}
                  onChange={(selectedValue) => {
                    setClienData({ ...clienData, cs_owner: selectedValue });
                  }}
                  allowClear
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <Form.Item>
            <Button
              htmlType="button"
              className="secondaryBttn"
              onClick={() => {
                resetForm();
              }}
            >
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button className="viewButton ms-2" htmlType="submit">
              Submit{" "}
              {loadingSubmit && (
                <Spin
                  size="small"
                  indicator={
                    <LoadingOutlined
                      style={{
                        color: "white",
                      }}
                    />
                  }
                />
              )}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default UpdateClientModal;
