import { Table, notification } from "antd";
import { useEffect, useState } from "react";
import API_PATH from "../../Constants/api-path";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const onChange = (filters, sorter, extra) => {
};
const EnquiriesTable = () => {
  const [loading, setLoading] = useState(false);
  const [enquiryList, setEnquiryList] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userData"));

  const GetEnquiryrList = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.ENQUIRY_LIST}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setEnquiryList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const convertDate = (date) => {
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      return p;
    } else {
      return "---------";
    }
  };

  useEffect(() => {
    GetEnquiryrList();
  }, []);

  const columns = [
    {
      title: "S.No.",
      render: (text, record, rowIndex) => rowIndex + 1,
    },
    {
      title: "Date",
      dataIndex: "updated_at",
      render: (date, created_at) => `${convertDate(date ? date : created_at)}`,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Country",
      dataIndex: "country",
    },
  ];

  return (
    <>
      <div className="dasb-div bk-div float-start w-100">
        <div className="body-expart-div">
          <p className="fs-5 mb-3">
            <strong style={{ color: "#5746ec" }}>Enquiries</strong>
          </p>
          <Table
            columns={columns}
            dataSource={enquiryList.slice(0, 5)}
            onChange={onChange}
            pagination={false}
            scroll={{ x: 800 }}
            loading={loading}
            className="paddTable"
          />
        </div>
      </div>
    </>
  );
};
export default EnquiriesTable;
