import {
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { Button, message, Modal, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";
import holiday_img from "../../assests/images/holiday_calendra.png";
import rasesh_sign from "../../assests/images/Rasesh_Signature.png";
import Sidebar from "../../components/Sidebar";
import API_PATH from "../../Constants/api-path";

function Policy() {
  const signatureRef = useRef(null);
  const [, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [, setSignature] = useState("");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [isSignatureCaptured, setIsSignatureCaptured] = useState(false);
  const [profileData, setProfileData] = useState([]);

  let UserData = JSON.parse(localStorage.getItem("userData"));

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSaveSignature = async () => {
    const canvas = signatureRef?.current?.getCanvas();
    const capturedSignature = canvas?.toDataURL();
    setSignature(capturedSignature);
    setIsModalVisible(false);

    const currentDate = new Date()?.toISOString();

    try {
      const res = await fetch(
        `${API_PATH.PROFILE_UPDATE}/${userData?.data?._id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
          body: JSON.stringify({
            sign: capturedSignature,
            date: currentDate,
          }),
        }
      );

      if (res.ok) {
      } else {
        message.error("Something went wrong. Server error.");
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  };

  useEffect(() => {
    GetProfile();
  }, []);

  const GetProfile = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.PROFILE_GET}/${UserData.data._id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${UserData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setProfileData(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  // Function to convert Data URL to Blob
  // function dataURItoBlob(dataURI) {
  //   // Split the data URI into the header and the data parts
  //   const parts = dataURI.split(",");
  //   const mime = parts[0].match(/:(.*?);/)[1];
  //   const data = parts[1];

  //   // Convert the data to binary format
  //   const binary = atob(data);

  //   // Create an array buffer and a typed array from the binary data
  //   const buffer = new ArrayBuffer(binary.length);
  //   const view = new Uint8Array(buffer);
  //   for (let i = 0; i < binary.length; i++) {
  //     view[i] = binary.charCodeAt(i);
  //   }

  //   // Create a Blob object from the typed array and return it
  //   return new Blob([view], { type: mime });
  // }

  const handleClearSignature = () => {
    signatureRef.current.clear();
    setIsSignatureCaptured(false);
  };

  const convertDate = (date) => {
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      return p;
    } else {
      return "---------";
    }
  };

  // useEffect(() => {
  // const date = new Date();
  // const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  // const formattedDate = date?.toLocaleDateString("en-US", options)?.replace(/\//g, "/");
  // setCurrentDate(userData?.data?.createdAt);
  // }, []);

  // const formatDate = (dateString) => {
  //   const options = { year: "numeric", month: "short", day: "numeric" };
  //   const date = new Date(dateString);
  //   return date.toLocaleDateString("en-US", options);
  // };

  // const handleConvertToPDF = () => {
  //   var doc = new jsPDF();
  //   var elementHTML = document.querySelector("#termsAndConditions");
  //   // var header = (
  //   //   <>
  //   //     <div className="text-center giveMarginBottom">
  //   //       <img src={nextyn_logo} width="300px" alt="nextynLogo" />
  //   //     </div>
  //   //   </>
  //   // );
  //   doc.html(elementHTML, {
  //     callback: function (doc) {
  //       addFooters();
  //       doc.save("expert_complaince.pdf");
  //     },
  //     margin: [38, 10, 40, 10],
  //     autoPaging: "text",
  //     pageSplit: true,
  //     x: 0,
  //     y: 0,
  //     width: 180, //target width in the PDF document
  //     windowWidth: 675, //window width in CSS pixels
  //     // header: header,
  //   });

  //   function addFooters() {
  //     const pageCount = doc.internal.getNumberOfPages();
  //     for (var i = 1; i <= pageCount; i++) {
  //       doc?.setPage(i);
  //       doc?.addImage(assignImage(nextyn_logo), "png", 60, 4, 80, 0);
  //       doc?.rect(10, 33, 190, 0.5, "F");

  //       doc?.rect(10, 265, 190, 0.5, "F");
  //       doc.setFontSize(11);
  //       doc.setTextColor(196, 10, 54);
  //       doc.text(
  //         "Nextyn is a brand of Nextyn Advisory Private Limited (CIN: U74999MH2018PTC304669)",
  //         103,
  //         275,
  //         "center"
  //       );

  //       doc.setTextColor(119, 136, 153);
  //       doc.setFontSize(10);
  //       doc.text("Strictly Private and Confidential", 103, 282, "center");

  //       // if (pageCount == i) {
  //       //   doc?.addImage(assignImage(rasesh_sign), "jpg", 150, 60, 15, 0);
  //       // }
  //     }
  //   }
  // };

  // const handleIconClick = () => {
  //   navigate(-1);
  // };

  // function assignImage(image) {
  //   var img = new Image();
  //   img.src = image;
  //   return img;
  // }

  // const formatJoinDate = (dateTimeString) => {
  //   const date = new Date(dateTimeString);
  //   return date.toLocaleDateString(undefined, {
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //   });
  // };

  document.title = "Nextyn | Hr-policy";

  return (
    <>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div>
            <div className="d-block d-lg-block">
              <div className=" bk-div float-start w-100">
                {" "}
                <div className="body-expart-div">
                  <Row className="justify-content-center">
                    <Col xl={12}>
                      <div className="container rounded bg-white">
                        <Row>
                          <Col>
                            <section className="signedPDF">
                              <h1 className="mainheading  text-center">
                                Human Resource Policy Manual
                              </h1>
                              <h3 className="mainheading text-center">
                                Nextyn Advisory Private Limited (“NAPL”)
                              </h3>

                              <p>
                                We would like to align our policy guidelines
                                with employee needs as well as those of the
                                organisation. Therefore, Nextyn may change these
                                policies at any time. This Manual is therefore,
                                designed to allow updates (deletions and
                                additions) as and when the management of Nextyn
                                deems it necessary for the general benefit of
                                the Employees and the Company.
                              </p>

                              <p>
                                As we move ahead, there will be changes that may
                                need to be reflected in this manual. When such
                                revisions occur, the Custodians will receive
                                these updates along with directions to whether
                                to replace a page in a particular policy or
                                simply replace the entire policy within a
                                section.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>1. Welcome</h4>
                              <p>
                                Hello and welcome! Thank you for joining Nextyn,
                                where we strive to be every clients trusted
                                expert network & primary research partner,
                                empowering global businesses with data-driven
                                decisions for sustainable growth. As a part of
                                our team, you will play an integral role in
                                providing exemplary client service, while
                                maintaining a positive work environment with the
                                team, internally.
                              </p>

                              <p>
                                This employee handbook defines who we are and
                                how we work together. We will do everything
                                possible to create a fair and productive
                                workplace, but we need your help. We’ve created
                                this handbook to guide you through your journey
                                with us.
                              </p>

                              <p>
                                This handbook isn’t a contract or a guarantee of
                                employment. It’s a collection of our
                                expectations, commitments, and responsibilities.
                                Please read this employee handbook carefully and
                                consult it whenever you need to.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                2. Getting to know Nextyn.
                              </h4>
                              <p>
                                At Nextyn, we're more than just a consulting
                                firm – we're your strategic partner in making
                                data-driven decisions that lead to your success.
                                With a global presence in Mumbai, Bangalore, and
                                Singapore, our team of dedicated professionals
                                are committed to delivering excellence in the
                                expert network, survey, market intelligence, and
                                data analytics segments.
                              </p>

                              <p>
                                Our mission is to provide you with tech-enabled,
                                data-driven solutions that drive sustainable
                                business growth. We hold ourselves to the
                                highest standards of leadership, integrity, and
                                quality, ensuring that every insight we offer is
                                precise and actionable.
                              </p>

                              <p>
                                But we're not just about work – we believe in
                                fostering a positive work environment. That's
                                why we offer employee benefits like medical
                                insurance, EPF, and company laptops. We also
                                encourage team bonding through monthly
                                activities and shared meals.
                              </p>
                              <p>
                                Count on Nextyn as a trusted organization to
                                launch your career and grow, both personally and
                                professionally.{" "}
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                3. Employment basics.
                              </h4>

                              <p>
                                In this section, we explain our employee
                                contract types and define our basic employment
                                policies.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                i. Employment contract types.
                              </h4>

                              <p>
                                Full-time employees work at least 40 hours per
                                week or 160 per month on average.
                              </p>

                              <p>
                                Part-time employees are those who work fewer
                                than 40 hours per week.
                              </p>

                              <p>
                                Full-time and part-time employees can have
                                either temporary or indefinite duration
                                contracts. Only full-time employees under an
                                indefinite duration contract are entitled to
                                Nextyn’ s full benefits package.
                              </p>

                              <p>
                                Under normal circumstances an employee may
                                terminate your association by providing a notice
                                of 60 days after discussion with your immediate
                                Manager, HR Head and the Managing Director/CEO
                                of the Company, and an employee’s resignation
                                being duly accepted over an official
                                communication mode, preferably a formal email.
                              </p>

                              <p>
                                However, the company may terminate this
                                agreement forthwith under situations of
                                in-disciplinary behaviour, non-performance,
                                employee bankruptcy, or being convicted by a
                                court on a criminal charge, by giving you prior
                                notice of 5 working days.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                ii. Equal opportunity employment.
                              </h4>

                              <p>
                                Nextyn is an equal opportunity employer. We
                                don’t tolerate discrimination against protected
                                characteristics (gender, age, sexual
                                orientation, race, nationality, ethnicity,
                                religion, disability, veteran status.) We want
                                all employees (including executives and HR) to
                                treat others with respect and professionalism.
                                In practice, this means that we:
                              </p>
                              <ul className="listType">
                                <li>
                                  Nextyn will ensure adherence to the laws of
                                  the land about employment norms, including
                                  freedom of association, as applicable and will
                                  not indulge in practices such as employing
                                  child labor, bonded labor etc.
                                </li>
                                <li>
                                  Nextyn is an equal opportunity employer and
                                  makes the best endeavours to treat the
                                  candidates and employees without regards to
                                  their race, religion, belief, sex, colour,
                                  creed, age, National & ethnic origin, marital
                                  status, pregnancy, sexual orientation and
                                  physical disability, political affiliation or
                                  any other status protected by laws or
                                  regulations of the land.
                                </li>
                                <li>
                                  This includes equal opportunity in employment,
                                  upgrading, promotion or transfer, recruitment
                                  or recruitment advertising, layoff or
                                  termination, wages or other compensation,
                                  selection for training, including
                                  apprenticeship, pre-apprenticeship, and/ or on
                                  the job training.
                                </li>
                                <li>
                                  Nextyn ensures and maintains a work
                                  environment free of harassment, threats,
                                  intimidation, discrimination, and coercion at
                                  all sites and in all facilities at which
                                  employees are assigned to work. Any employee
                                  who violates this policy will be subjected to
                                  disciplinary procedure as per Disciplinary
                                  Policy.
                                </li>
                                <li>
                                  Nextyn will not retaliate nor discriminate
                                  against any employee or applicant because he
                                  or she has opposed any unlawful employment
                                  practice or filed a charge of employment
                                  discrimination, testified, assisted, or
                                  participated in any manner in an
                                  investigation, proceeding, or hearing related
                                  to employment practices.
                                </li>
                              </ul>

                              <p>
                                Apart from those actions, we commit to
                                penalizing every discriminatory, offensive, or
                                inappropriate behaviour. To do this properly, we
                                ask you to report any discriminatory action
                                against yourself or your colleagues to HR.
                                Nextyn will not retaliate against you if you
                                file a complaint or discrimination lawsuit. Any
                                employee who retaliates or discriminates against
                                will face disciplinary action.
                              </p>

                              <p>
                                <span>Complaint Procedure </span>
                              </p>

                              <p>
                                Any individual, who believes that he or she has
                                been discriminated against as per the policy,
                                should bring any complaint to Human Resource
                                Manager. Complaints may be lodged in writing/ in
                                person. Any employee who files a complaint will
                                be advised appropriately regarding any
                                investigation, action, or resolution of the
                                problem.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                4. Recruitment and Selection Process
                              </h4>

                              <p>
                                Our hiring steps might vary across roles, but we
                                always aim for a process that is fair and
                                effective in hiring great people. If you are
                                hiring for an open role, you will likely go
                                through these steps:
                              </p>

                              <ol type="I" className="listTypeNum">
                                <li>
                                  i. Identify the need for a new job opening.
                                </li>
                                <li>
                                  ii. Decide whether to hire externally or
                                  internally.
                                </li>
                                <li>
                                  iii. Review job descriptions and write a job
                                  ad.
                                </li>
                                <li>iv. Get approval for your job ad.</li>
                                <li>
                                  v. Select appropriate sources to post your job
                                  opening.
                                </li>
                                <li>
                                  vi. Decide on hiring stages and possible
                                  timeframes.
                                </li>
                                <li>vii. Review resumes.</li>
                                <li>viii. Source passive candidates.</li>
                                <li>ix. Shortlist applicants.</li>
                                <li>x. Screen and interview candidates.</li>
                                <li>
                                  xi. Run background checks and check
                                  references.
                                </li>
                                <li>
                                  xii. Select the most suitable candidate.
                                </li>
                                <li>xiii. Make an official offer.</li>
                              </ol>

                              <p>
                                Steps may overlap, so skip steps when
                                appropriate. Each member of a hiring team might
                                have different responsibilities (e.g.,
                                recruiters’ source and hiring managers interview
                                candidates.)
                              </p>
                              <p>
                                Throughout this process, we aim to keep
                                candidates informed, communicate well with each
                                other, and give everyone an equal opportunity to
                                work with us. Ask our recruiters for help
                                whenever you need to enhance candidate
                                experience or write an inclusive job
                                description.
                              </p>
                              <h4 style={{ color: "#5746EB" }}>
                                i. Background checks
                              </h4>
                              <p>
                                We run background checks on every candidate we
                                recruit. This is formally done through the HR
                                team. This process is sensitive, and we must
                                always abide by laws and ensure candidates
                                understand our intentions. As a rule, we
                                commission a background check for finalists
                                only.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                ii. Referrals
                              </h4>

                              <p>
                                If you know someone who you think would be a
                                good fit for a position at Nextyn, feel free to
                                refer them. If we end up hiring your referred
                                candidate, you are eligible for INR 10,000
                                referral bonus or Amazon coupons of the same
                                amount Our employee referral rewards may be
                                higher if we hire your referred candidate in a
                                hard-to-fill role. The definitions for
                                hard-to-fill roles are subjective and will be
                                communicated by the HR department, as an when
                                there is a need for such candidates. The
                                referral for hard-to-fill roles will stand at
                                INR 20,000.
                              </p>

                              <p>
                                <span>Additional rules for rewards: </span>
                              </p>

                              <ul className="listType">
                                <li>
                                  We guarantee that every reward will be paid
                                  out after three months of the date the
                                  candidate joins the company.
                                </li>
                                <li>
                                  There is no cap on the number of referrals an
                                  employee can make. All rewards will be paid
                                  accordingly.
                                </li>
                                <li>
                                  If two or more employees refer to the same
                                  candidate, only the first referrer will
                                  receive the referral incentives.
                                </li>
                                <li>
                                  Referrers are still eligible for rewards even
                                  if a candidate is hired later or gets hired
                                  for another position.
                                </li>
                                <p>
                                  Who can be referred? We have two conditions
                                  for candidates who can qualify you for our
                                  rewards. They should:
                                </p>
                                <li>
                                  Have not applied to Nextyn for at least a
                                  year.
                                </li>
                                <li>
                                  Be hired as permanent full-time employee (not
                                  as temporary employees or contractors.)
                                </li>
                              </ul>
                              <p>
                                You can reach out directly to our HR with
                                referrals.
                              </p>
                              <p>
                                Generally, we encourage you to check our open
                                positions and consider your social networks and
                                external networks as potential resources for
                                referred candidates.
                              </p>
                              <p>
                                Keep in mind that rewards may be subject to
                                taxation. Please contact HR for more
                                information.
                              </p>
                              <h4 style={{ color: "#5746EB" }}>
                                5. Workplace policies
                              </h4>
                              <p>
                                We want to ensure that private information about
                                clients, employees, partners, and Nextyn is well
                                protected. Examples of confidential information
                                are:
                              </p>
                              <ul className="listType">
                                <li>Employee records</li>
                                <li>Unpublished financial information</li>
                                <li>Data of customers/partners/vendors</li>
                                <li>
                                  Customer lists (existing and prospective)
                                </li>
                                <li>
                                  Unpublished goals, forecasts and initiatives
                                  marked as confidential.{" "}
                                </li>
                              </ul>
                              <p>
                                As part of our hiring process, we may ask you to
                                sign non-compete and non-disclosure agreements
                                (NDAs.) We are also committed to:
                              </p>
                              <ul className="listType">
                                <li>
                                  Restrict and monitor access to sensitive data.
                                </li>
                                <li>
                                  Develop transparent data collection
                                  procedures.
                                </li>
                                <li>
                                  Train employees in online privacy and security
                                  measures.
                                </li>
                                <li>
                                  Build secure networks to protect online data
                                  from cyberattacks.
                                </li>
                                <li>
                                  Establish data protection practices (e.g.,
                                  secure locks, data encryption, frequent
                                  backups, access authorization.)
                                </li>
                              </ul>
                              <p>
                                We also expect you to act responsibly when
                                handling confidential information.
                              </p>
                              <p>
                                <span>You must:</span>
                              </p>
                              <ul className="listType">
                                <li>
                                  Lock or secure confidential information
                                  always.
                                </li>
                                <li>
                                  Shred confidential documents when they’re no
                                  longer needed.
                                </li>
                                <li>
                                  Make sure you view confidential information on
                                  secure devices only.
                                </li>
                                <li>
                                  Only disclose information to other employees
                                  when it’s necessary and authorized.
                                </li>
                                <li>
                                  Keep confidential documents inside Nextyn’ s
                                  premises unless it’s necessary to move them.
                                </li>
                              </ul>
                              <p>
                                <span>You must not:</span>
                              </p>
                              <ul className="listType">
                                <li>
                                  Use confidential information for your personal
                                  benefit or profit.
                                </li>
                                <li>
                                  Disclose confidential information to anyone
                                  outside of Nextyn.
                                </li>
                                <li>
                                  Replicate confidential documents and files and
                                  store them on insecure devices.
                                </li>
                              </ul>
                              <p>
                                This policy is important for Nextyn’ s legality
                                and reputation. We will terminate any employee
                                who breaches our confidentiality guidelines for
                                personal profit.
                              </p>

                              <p>
                                We may also discipline any unintentional breach
                                of this policy depending on its frequency and
                                seriousness. We will terminate employees who
                                repeatedly disregard this policy, even when they
                                do so unintentionally.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                ii. Harassment and violence
                              </h4>
                              <p>
                                To build a happy and productive workplace, we
                                need everyone to treat others well and help them
                                feel safe. Each of us should do our part to
                                prevent harassment and workplace violence.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                iii. Workplace harassment
                              </h4>
                              <p>
                                Harassment is a broad term and may include
                                seemingly harmless actions, like gossip. We
                                can’t create an exhaustive list, but here are
                                some instances that we consider harassment:{" "}
                              </p>
                              <ul className="listType">
                                <li>Sabotaging someone’s work on purpose.</li>
                                <li>
                                  Engaging in frequent or unwanted advances of
                                  any nature.
                                </li>
                                <li>
                                  Commenting derogatorily on a person’s ethnic
                                  heritage or religious beliefs.
                                </li>
                                <li>
                                  Starting or spreading rumours about a person’s
                                  personal life.
                                </li>
                                <li>
                                  Ridiculing someone in front of others or
                                  singling them out to perform tasks unrelated
                                  to their job (e.g., bringing coffee) against
                                  their will.
                                </li>
                              </ul>

                              <h4 style={{ color: "#5746EB" }}>
                                iv. POSH (Prevention of Sexual Harassment) Act.
                              </h4>

                              <p>
                                Sexual harassment is illegal, and we will
                                seriously investigate relevant reports under the
                                Sexual Harassment of Women at Workplace
                                (Prevention, Prohibition and Redressal) Act,
                                2013 also called as “POSH” was incorporated with
                                an object of providing protection against sexual
                                harassment of women at workplace and for the
                                prevention and redressal of complaints of sexual
                                harassment. Sexual harassment results in
                                violation of the fundamental rights of a woman
                                to equality under articles 14 and 15 of the
                                Constitution of India and her rights to life and
                                to live with dignity under article 21 and right
                                to practice any profession or to carry on any
                                occupation, trade or business which includes a
                                right to a safe environment free from sexual
                                harassment.
                              </p>

                              <p>
                                Thus, to provide a safe working environment for
                                women and to protect their Constitutional
                                rights, the POSH Act was passed by the Ministry
                                of Women and Child Welfare.
                              </p>

                              <p>
                                WHAT CAN BE TERMED AS SEXUAL HARASSMENT AT
                                WORKPLACE: “Sexual Harassment” includes any one
                                or more of the following unwelcome acts or
                                behaviours (whether directly or implication)
                                namely-
                              </p>
                              <ul className="listType">
                                <li>Physical contact and advance; or</li>
                                <li>
                                  A demand or request for sexual Favors; or
                                </li>
                                <li>Making sexually coloured remarks; or</li>
                                <li> Showing pornography; or </li>
                                <li>
                                  Any other unwelcome physical, verbal, or
                                  non-verbal conduct of sexual nature.
                                </li>
                              </ul>

                              <p>
                                Further the following circumstances, among other
                                circumstance, if occurs or is present in
                                relation to or connected with any act or
                                behaviour of sexual harassment may amount to
                                sexual harassment.{" "}
                              </p>
                              <ul className="listType">
                                <li>
                                  Implied or explicit promise of preferential
                                  treatment in her employment; or
                                </li>
                                <li>
                                  Implied or explicit threat of detrimental
                                  treatment in her employment; or
                                </li>
                                <li>
                                  Implied or explicit threat about her present
                                  or future employment status; or
                                </li>
                                <li>
                                  {" "}
                                  Interference with her work or creating an
                                  intimidating or{" "}
                                </li>
                                <li>
                                  offensive or hostile work environment for her;
                                  or
                                </li>
                                <li>
                                  Humiliating treatment is likely to affect her
                                  health or safety.
                                </li>
                              </ul>

                              <p>
                                <span>Applicability Of the Posh </span>
                              </p>
                              <p>
                                Provisions are applicable to all establishments,
                                companies, organization’s (whether public or
                                private) employing 10 or more employees (whether
                                permanent, temporary, ad-hoc, consultants,
                                interns or contract workers irrespective of
                                gender) irrespective of business or industry.
                              </p>

                              <p>
                                <span>Compliances under posh </span>
                              </p>
                              <ul className="listTypeNum">
                                <li>
                                  1) Constitution of Internal Complaints
                                  Committee
                                </li>
                                <li>2) Formulation of Internal POSH Policy </li>
                                <li>
                                  3) Organizing Workshops, Awareness Programs
                                  and Orientation Programs.{" "}
                                </li>
                                <li> 4) Submission of Reports </li>
                              </ul>

                              <p>
                                Constitution of internal complaints committee
                                (icc):
                              </p>

                              <ul className="listTypeNum">
                                <li>
                                  1) Every employer of the workplace shall
                                  constitute a committee called as “Internal
                                  Complaints Committee” under section 4 of the
                                  Act.
                                </li>
                                <li>
                                  2) The Internal Complaints Committee shall
                                  consist of the following members to be
                                  nominated by the employer, namely:
                                </li>
                              </ul>

                              <p>
                                <span>Presiding officer</span> Shall be a women
                                employed at a senior level at workplace amongst
                                the employees.{" "}
                              </p>

                              <p>
                                <span>Two internal members– </span> At least 2
                                members amongst employees preferably committed
                                to cause of women or who have experience in
                                social work or have legal knowledge.{" "}
                              </p>

                              <p>
                                <span>One external member– </span>
                              </p>
                              <ul className="listTypeNum">
                                <li>
                                  1) 1 Member amongst non-governmental
                                  Organizations or associations committed to the
                                  cause of women.{" "}
                                </li>
                                <li>
                                  2) 3. At least one-half of the total members
                                  so nominated shall be women.
                                </li>
                                <li>
                                  3) 4. The presiding officer and every member
                                  of the Internal Committee shall hold office
                                  for such a period not exceeding 3 years.{" "}
                                </li>
                                <li>
                                  4) 5. The members appointed amongst the
                                  non-governmental organizations or associations
                                  shall be paid shall be paid such fees or
                                  allowances by the employer for holding the
                                  proceedings of the Internal Committees.{" "}
                                </li>
                                <li>
                                  5) 6. Where the presiding officer or any
                                  member of the Committee, contravenes any
                                  provisions of section 16, or has been
                                  convicted for an offence or inquiry into
                                  offence, or has been found guilty in any
                                  disciplinary proceeding, then such presiding
                                  officer or member (as the case may be) shall
                                  be removed from the committee.{" "}
                                </li>
                                <li>
                                  6) 7. Any vacancy on the Committee shall be
                                  filled by fresh nomination.{" "}
                                </li>
                              </ul>

                              <p>
                                <span>
                                  Formulation of internal posh policy{" "}
                                </span>
                              </p>

                              <p>
                                Every employer of the workplace shall be
                                responsible to have a Policy for protection of
                                women from sexual harassment at workplace. Such
                                policy shall contain the following details:{" "}
                              </p>

                              <ul className="listType">
                                <li>
                                  The objective and scope of such a policy.
                                </li>
                                <li>
                                  Function and duties of Internal Complaints
                                  Committee
                                </li>
                                <li>
                                  Composition and contact details of Internal
                                  Committee members
                                </li>
                                <li> Redressal Mechanism </li>
                                <li>Conciliation opportunity</li>
                                <li>Inquiry procedure</li>
                                <li>Reports submissions</li>
                                <li>
                                  ORGANISING WORKSHOPS, AWARENESS AND
                                  ORIENTATION PROGRAMS
                                </li>
                                <li>
                                  Every employer of the workplace shall be
                                  responsible:
                                </li>
                                <li>
                                  To organize workshops and awareness programs
                                  at regular intervals for all employees.
                                </li>
                                <li>
                                  To organize orientation programs for the
                                  members of the Internal Complaints Committee.
                                </li>
                                <li>
                                  To display at any conspicuous place in the
                                  workplace, the consequences of sexual
                                  harassment and the order constituting the
                                  Internal Committee.
                                </li>
                              </ul>

                              <p>
                                <span>Submitting reports under the act </span>
                              </p>
                              <ul className="listType">
                                <li>
                                  1. The Internal Complaints Committee or the
                                  Local Committee every calendar year, shall
                                  prepare and submit an annual report to the
                                  employer and district officer.
                                </li>
                                <li>
                                  2. The district officer shall forward a brief
                                  report on the annual reports received from the
                                  Internal Complaints Committee.
                                </li>
                                <li>
                                  3. The employer in his report shall include
                                  the number of cases filed, if any, and their
                                  disposal.{" "}
                                </li>
                              </ul>

                              <p>
                                <span>Complaint of sexual harassment</span>
                              </p>
                              <ul className="listType">
                                <li>
                                  1. Any aggrieved women can make a complaint in
                                  writing about the sexual harassment at
                                  workplace to Internal Complaints Committee (If
                                  constituted) and if not, then to the Local
                                  Committee, within a period of 3 months from
                                  the date of incident and in case of a series
                                  of incidents, within a period of 3 months from
                                  the date of last incident.{" "}
                                </li>
                                <li>
                                  2. Where the aggrieved women are unable to
                                  make a complaint on account of her physical,
                                  mental incapacity or death or otherwise, her
                                  legal heir may make a compliant.{" "}
                                </li>
                                <li>
                                  3. The Internal Committee or the Local
                                  Committee may before initiate an inquiry under
                                  section 11 and at the request of the aggrieved
                                  women take step to settle the matter between
                                  her and the respondent through conciliation.{" "}
                                </li>
                                <li>
                                  4. If the matter is settled, the Internal
                                  Committee or the Local Committee will take
                                  into records and forward the same to the
                                  employer or district officer to take further
                                  action.{" "}
                                </li>
                                <li>
                                  5. When settlement is arrived, no further
                                  inquiry shall be conducted by the Internal
                                  Committee or the Local Committee.
                                </li>
                              </ul>

                              <p>
                                <span>Inquiry of complaint </span>
                              </p>
                              <ul className="listType">
                                <li>
                                  1. If the settlement is not arrived, the
                                  Internal Complaints Committee or the Local
                                  Committee shall proceed with the inquiry under
                                  the Act.{" "}
                                </li>
                                <li>
                                  2. A copy of the complaint should be sent to
                                  accused within 7 days of receipt of complaint
                                  by aggrieved women and the accused shall
                                  submit his reply within 10 days from the
                                  receipt of the complaint.{" "}
                                </li>
                                <li>
                                  3. After completion of an inquiry, the
                                  Internal Complaints Committee, or the Local
                                  Committee, shall provide a report of its
                                  findings to the employer or the district
                                  officer within a period of 10 days of
                                  completion of the inquiry.{" "}
                                </li>
                                <li>
                                  4. Where the Internal Complaints Committee or
                                  the Local Committee arrives at the conclusion
                                  that the allegation against the respondent has
                                  not been proved, then it shall recommend to
                                  the employer and district officer that no
                                  action is required to be taken in this matter.{" "}
                                </li>
                                <li>
                                  5. And if the committee arrives at the
                                  conclusion that the allegations are proved,
                                  then it shall recommend to the employer or
                                  district officer, as the case may be- (i) To
                                  act for sexual harassment as a misconduct.
                                  (ii) To deduct from salary or wages of the
                                  respondent such sum as it may consider
                                  appropriate to be paid to the aggrieved women
                                  or her legal heirs.
                                </li>
                                <li>
                                  6. The employer or the district officer shall
                                  act upon the recommendation within 60 days of
                                  its receipts by him.{" "}
                                </li>
                                <li>
                                  7. Where the Internal Complaints Committee or
                                  the Local Committee, arrives at a conclusion
                                  that the allegation against the respondent is
                                  malicious or the aggrieved women or any other
                                  person making the complaint has made a false
                                  complaint, then it may recommend the employer
                                  or district officer to act against such women
                                  or other person.{" "}
                                </li>
                              </ul>
                              <p>
                                COMPLIANCES UNDER COMPANIES ACT, 2013 According
                                to Rule 8 of the Companies (Accounts) Rules,
                                2014, it is mandatory for a company to make a
                                statement in its directors’ report stating that
                                the company has complied with provisions
                                relating to the constitution of internal
                                complaints committee under the Sexual Harassment
                                of Women at Workplace (Prevention, Protection
                                and Redressal) Act, 2013 Non-disclosure will
                                attract the penalty under section 134 of the
                                Companies Act, 2013
                              </p>

                              <p>
                                <span>Penalty under posh act </span>
                              </p>
                              <ul className="listType">
                                <li>
                                  1. The employer shall be punishable with a
                                  fine which may extend to Rs. 50,000/- if
                                  he/they: Fails to Constitute an Internal
                                  Complaints Committee. Fails to Comply with the
                                  recommendation of Internal Complaints
                                  Committee. Fails to file an annual report to
                                  the district officer. If contravenes or
                                  attempts to contravene or abets contravention
                                  of other provisions of this Act or any rules
                                  made thereunder.{" "}
                                </li>
                                <li>
                                  2. If the employer after having previously
                                  convicted of an offence punishable under this
                                  Act subsequently commits and is convicted of
                                  the same offence, he shall be liable to- Twice
                                  the punishment which might have been imposed
                                  on first conviction, subject to the punishment
                                  being maximum provided for the same offence.
                                  Cancellation of his license, withdrawal or
                                  non-renewal or cancellation of registration as
                                  required for carrying on his business or
                                  activity.{" "}
                                </li>
                              </ul>

                              <h4 style={{ color: "#5746EB" }}>
                                v. Workplace violence
                              </h4>

                              <p>
                                Violence in our workplace is a serious form of
                                harassment. It includes physical and sexual
                                assault, destruction of property, threats to
                                harm a person or property and verbal and
                                psychological abuse. We want to avoid those
                                incidents altogether, but we also want to be
                                ready to respond if needed.
                              </p>

                              <p>For this reason, we ask you to:</p>
                              <ul className="listType">
                                <li>
                                  Report to HR if you suspect or know that
                                  someone is being violent. Your report will be
                                  confidential, and we will investigate the
                                  situation with discretion.{" "}
                                </li>
                                <li>
                                  Call our building’s security if you witness
                                  incidents of severe physical violence (e.g.,
                                  ones that involve a lethal weapon.) For your
                                  safety, avoid getting involved.{" "}
                                </li>
                              </ul>
                              <p>
                                We will treat employees who verbally threaten
                                others as high risk and they will receive an
                                appropriate penalty. If HR finds that an
                                employee commits an act of violence, we will
                                terminate that employee and possibly press
                                criminal charges. Employees who damage property
                                deliberately will be solely responsible for
                                paying for it.
                              </p>
                              <p>Supporting victims</p>
                              <p>
                                To support victims of workplace violence, we
                                may:
                              </p>
                              <ul className="listType">
                                <li>
                                  Cover relevant medical bills through the
                                  insurance provided to every employee.{" "}
                                </li>
                                <li>
                                  Pay for mental health treatment if needed.{" "}
                                </li>
                              </ul>

                              <h4 style={{ color: "#5746EB" }}>
                                vi. Workplace safety and health
                              </h4>

                              <p>
                                Nextyn is committed to creating a hazard-free
                                workplace. To this end, we will ensure workplace
                                safety through preventative action and emergency
                                management.
                              </p>

                              <p>
                                <span>Emergency management</span>
                              </p>

                              <p>
                                Emergency management refers to our plan to deal
                                with sudden catastrophes like fire, floods,
                                earthquakes, or explosions. Our emergency
                                management provisions include:
                              </p>
                              <ul className="listType">
                                <li>
                                  [Functional smoke alarms and sprinklers that
                                  are regularly inspected.]{" "}
                                </li>
                                <li>
                                  [Technicians (external or internal) available
                                  to repair leakages, damages, and blackouts
                                  quickly.]{" "}
                                </li>
                                <li>
                                  [Fire extinguishers and other fire protection
                                  equipment that are easily accessible.]{" "}
                                </li>
                              </ul>

                              <h4 style={{ color: "#5746EB" }}>
                                vii. Smoking/Vaping
                              </h4>

                              <p>
                                Nextyn’ s is a smoke-free workplace. You can
                                smoke in [designated smoking areas, balconies,
                                open-air verandas, and outer premises, like
                                gardens and sidewalks.] Any other area in our
                                workplace (like restrooms, lobby, offices,
                                staircases, warehouses) is strictly smoke-free
                                to protect non-smokers.
                              </p>

                              <p>We also advise you to:</p>
                              <ul className="listType">
                                <li>
                                  Extinguish your cigarettes and discard them in
                                  [outdoor ashtrays, cigarette burns.]
                                </li>
                                <li>
                                  Avoid smoking when you have scheduled meetings
                                  with clients or vendors.{" "}
                                </li>
                                <li>
                                  Avoid smoking near flammable objects and
                                  areas.{" "}
                                </li>
                              </ul>

                              <p>
                                Setting off fire alarms and causing fires by
                                smoking are serious offenses. If you are found
                                responsible, you may face disciplinary action up
                                to and including termination.{" "}
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                viii. Drug-free workplace
                              </h4>

                              <p>
                                Nextyn is a drug-free workplace. Whether you are
                                an employee, contractor, or visitor, you must
                                not bring, use, give away or sell any drugs on
                                company premises. If you are caught with illegal
                                drugs or show that you are under the influence
                                of substances, you will face disciplinary action
                                up to and including termination.
                              </p>

                              <p>
                                A list of prohibited drugs and substances
                                includes, but isn’t limited to:
                              </p>
                              <ul className="listType">
                                <li>
                                  heroin/cocaine/methamphetamine in any form
                                </li>
                                <li>marijuana </li>
                              </ul>

                              <p>
                                <span>Alcohol</span>
                              </p>
                              <p>
                                We prohibit employees from consuming alcohol
                                during working hours, but they may consume
                                alcoholic drinks in moderation at company
                                events.
                              </p>

                              <p>
                                <span>Prescription drugs</span>
                              </p>
                              <p>
                                If you feel that a prescription drug (e.g., an
                                anxiety mediation) unexpectedly affects your
                                senses, thinking or movement, ask for the rest
                                of your day off. If your manager suspects
                                substance abuse, you may face disciplinary
                                action.
                              </p>
                              <p>
                                You must not use medical marijuana in our
                                workplace. We have the right to terminate you if
                                your off-duty use of medical marijuana makes you
                                unable to complete your job duties correctly.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                6. Employee Code of Conduct
                              </h4>

                              <p>
                                As an employee, all company policies mentioned
                                above apply to you. We have some additional
                                expectations about your behaviour at work, which
                                we outline here. We can’t cover every single
                                case of conduct, but we trust you to always use
                                your best judgement. Reach out to your manager
                                or HR if you face any issues or have any
                                questions.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                i. Dress code
                              </h4>

                              <p>
                                Nextyn’ s official dress code is [Business/
                                Business Casual/ Smart Casual/ Casual. This
                                includes slacks/ loafers/ blouses/ boots.
                                However, an employee’s position may also inform
                                how they should dress. If you frequently meet
                                with clients or prospects, conform to a more
                                formal dress code. We expect you to be clean
                                when coming to work and avoid wearing clothes
                                that are unprofessional (e.g., workout clothes.)
                              </p>

                              <p>
                                As long as you conform with our guidelines
                                above, we don’t have specific expectations about
                                what types of clothes or accessories you should
                                wear.
                              </p>

                              <p>
                                We also respect and permit grooming styles,
                                clothing and accessories that are dictated by
                                religious beliefs, ethnicity, or disability.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                ii. Cyber security and digital devices
                              </h4>

                              <p>
                                This section deals with all things digital at
                                work. We want to set some guidelines for using
                                computers, phones, our internet connection, and
                                social media to ensure security and protect our
                                assets.
                              </p>

                              <p>
                                <span>Internet usage</span>
                              </p>
                              <p>
                                Our corporate internet connection is primarily
                                for business. But you can occasionally use our
                                connection for personal purposes as long as they
                                don’t interfere with your job responsibilities.
                                Also, we expect you to temporarily halt personal
                                activities that slow down our internet
                                connection (e.g., uploading photos, downloading
                                videos, etc.) if you’re asked to.
                              </p>

                              <p>
                                You must not use our internet connection to:
                              </p>

                              <ul className="listType">
                                <li>
                                  Download or upload obscene, offensive, or
                                  illegal material.
                                </li>
                                <li>
                                  Send confidential information to unauthorized
                                  recipients.{" "}
                                </li>
                                <li>
                                  Invade another person’s privacy and gain
                                  access to sensitive information.
                                </li>
                                <li>
                                  Download or upload pirated movies, music,
                                  material, or software.
                                </li>
                                <li>
                                  Visit potentially dangerous websites that can
                                  compromise our network and computers’ safety.
                                </li>
                                <li>
                                  Perform unauthorized or illegal actions, like
                                  hacking, fraud or buying/selling illegal
                                  goods.
                                </li>
                              </ul>

                              <p>
                                <span>Cell phone</span>
                              </p>
                              <p>
                                We allow use of cell phones at work. But we also
                                want to ensure that your devices won’t distract
                                you from your work or disrupt our workplace. We
                                ask you to follow a few simple rules:
                              </p>

                              <ul className="listType">
                                <li>
                                  Use your cell phone in a manner that benefits
                                  your work (business calls, productivity apps,
                                  calendars.){" "}
                                </li>
                                <li>
                                  Keep personal calls brief and use an empty
                                  meeting room or common area so as not to
                                  disturb your colleagues.{" "}
                                </li>
                                <li>
                                  Avoid playing games on your phone or texting
                                  excessively.{" "}
                                </li>
                                <li>
                                  Avoid using your phone for any reason while
                                  driving a company vehicle.{" "}
                                </li>
                                <li>
                                  Don’t use your phone to record confidential
                                  information.{" "}
                                </li>
                                <li>
                                  Don’t download or upload inappropriate,
                                  illegal, or obscene material using our
                                  corporate internet connection.{" "}
                                </li>
                              </ul>

                              <p>
                                <span>Corporate email</span>
                              </p>
                              <p>
                                Email is essential to our work. You should use
                                Nextyn email primarily for work, but we allow
                                some uses of Nextyn email for personal reasons.
                              </p>

                              <ul className="listType">
                                <li>
                                  Work-related use. You can use your corporate
                                  email for work-related purposes without
                                  limitations. For example, you can sign up for
                                  newsletters and online.
                                </li>
                                <li>
                                  Services that will help you in your job or
                                  professional growth.{" "}
                                </li>
                                <li>
                                  Personal use. You can use your email for
                                  personal reasons as long as you keep it safe
                                  and avoid spamming and disclosing confidential
                                  information. For example,{" "}
                                </li>
                              </ul>
                              <p>
                                You can send emails to friends and family and
                                download eBooks, guides and other safe content
                                for your personal use.
                              </p>

                              <p>
                                <span>Our general expectations</span>
                              </p>

                              <ul className="listType">
                                <li>
                                  Signing up for illegal, unreliable,
                                  disreputable, or suspect websites and
                                  services.
                                </li>
                                <li>
                                  Sending unauthorized marketing content or
                                  emails.{" "}
                                </li>
                                <li>
                                  Registering for a competitor’s services,
                                  unless authorized.
                                </li>
                                <li>
                                  Sending insulting or discriminatory messages
                                  and content.
                                </li>
                                <li>
                                  Intentionally spamming other people’s emails,
                                  including your coworkers.
                                </li>
                              </ul>

                              <p>
                                In general, use strong passwords and be vigilant
                                in catching emails that carry malware or
                                phishing attempts. If you are not sure that an
                                email you received is safe, ask our Security
                                Specialists.{" "}
                              </p>

                              <p>
                                <span>Social media</span>
                              </p>
                              <p>
                                We want to provide practical advice to prevent
                                careless use of social media in our workplace.
                              </p>
                              <p>
                                We address two types of social media uses: using
                                personal social media at work and representing
                                Nextyn through social media.
                              </p>
                              <p>Using personal social media at work</p>
                              <p>
                                You are permitted to access your personal
                                accounts at work. But we expect you to act
                                responsibly, according to our policies, and
                                ensure that you stay productive. Specifically,
                                we ask you to:
                              </p>

                              <ul className="listType">
                                <li>
                                  Discipline yourself. Avoid getting
                                  side-tracked by your social platforms.
                                </li>
                                <li>
                                  Ensure others know that your personal account
                                  or statements don’t represent Nextyn. For
                                  example, use a disclaimer such as “opinions
                                  are.my own.”{" "}
                                </li>
                                <li>
                                  Avoid sharing intellectual property (e.g.,
                                  trademarks) or confidential information. Ask
                                  your manager first before you share company
                                  news that’s not officially announced.
                                </li>
                                <li>
                                  Avoid any defamatory, offensive, or derogatory
                                  content. You may violate Nextyn’ s
                                  anti-harassment policy if you direct such
                                  content towards colleagues, clients, or
                                  partners.
                                </li>
                              </ul>

                              <p>
                                <span>
                                  Representing Nextyn through social media
                                </span>
                              </p>
                              <p>
                                If you handle our social media accounts or speak
                                on Nextyn’ s behalf, we expect you to protect
                                Nextyn’ s image and reputation. Specifically,
                                you should:
                              </p>
                              <ul className="listType">
                                <li>Be respectful, polite, and patient.</li>
                                <li>
                                  Avoid speaking on matters outside your field
                                  of expertise when possible.
                                </li>
                                <li>
                                  Follow our confidentiality and data protection
                                  policies and observe laws governing
                                  copyrights, trademarks, plagiarism, and fair
                                  use.
                                </li>
                                <li>
                                  Correct or remove any misleading or false
                                  content as quickly as possible,
                                </li>
                              </ul>

                              <p>
                                <span>Conflict of interest</span>
                              </p>
                              <p>
                                When you are experiencing a conflict of
                                interest, your personal goals are no longer
                                aligned with your responsibilities towards us.
                                For example, accepting a bribe may benefit you
                                financially, but it is illegal and against our
                                business code of ethics. If we become aware of
                                such behaviour, you will lose your job and may
                                face legal trouble.
                              </p>
                              <p>
                                For this reason, conflicts of interest are a
                                serious issue for all of us. We expect you to be
                                vigilant to spot circumstances that create
                                conflicts of interest, either to yourself or for
                                your direct reports. Follow our policies and
                                always act in Nextyn’ s best interests. Whenever
                                possible, do not let personal or financial
                                interests get in the way of your job. If you are
                                experiencing an ethical dilemma, talk to your
                                manager or HR and we will try to help you
                                resolve it.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                7. Employee relationships
                              </h4>
                              <p>
                                We want to ensure that relationships between
                                employees are appropriate and harmonious. We
                                outline our guidelines, and we ask you to always
                                behave professionally.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                i. Fraternization
                              </h4>
                              <p>
                                Fraternization refers to dating or being friends
                                with your colleagues. In this policy, “dating”
                                equals consensual romantic relationships and
                                sexual relations. Non-consensual relationships
                                constitute sexual violence, and we prohibit them
                                explicitly.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                ii. Dating colleagues
                              </h4>
                              <p>
                                If you start dating a colleague, we expect you
                                to maintain professionalism and keep personal
                                discussions outside of our workplace.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                iii. Dating managers
                              </h4>
                              <p>
                                To avoid accusations of favouritism, abuse of
                                authority and sexual harassment, supervisors
                                must not date their direct reports. This
                                restriction extends to every manager above an
                                employee.
                              </p>
                              <p>
                                Also, if you act as a hiring manager, you aren’t
                                allowed to hire your partner to your team. You
                                can refer them for employment to other teams or
                                departments where you don’t have any managerial
                                or hiring authority.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                iv. Friendships at work
                              </h4>
                              <p>
                                Employees who work together may naturally form
                                friendships either in or outside of the
                                workplace. We encourage this relationship
                                between peers, as it can help you communicate
                                and collaborate. But we expect you to focus on
                                your work and keep personal disputes outside of
                                our workplace.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                v. Employment of relatives
                              </h4>
                              <p>
                                Everyone in Nextyn should be hired, recognized,
                                or promoted because of their skills, character,
                                and work ethic. We would not like to see
                                phenomena of nepotism, favouritism, or conflicts
                                of interest, so we will place some restrictions
                                on hiring employees’ relatives.
                              </p>

                              <p>
                                To Nextyn, a “relative” is related by blood or
                                marriage within the third degree to an employee.
                                This includes parents, grandparents, in-laws,
                                spouses or domestic partners, children,
                                grandchildren, siblings, uncles, aunts, nieces,
                                nephews, stepparents, stepchildren and adopted
                                children.
                              </p>

                              <p>
                                As an employee, you can refer your relatives to
                                work with Nextyn. Here are our only
                                restrictions:
                              </p>
                              <ul className="listType">
                                <li>
                                  You must not be involved in a
                                  supervisory/reporting relationship with a
                                  relative.
                                </li>
                                <li>
                                  You cannot be transferred, promoted, or hired
                                  inside a reporting relationship with a
                                  relative.
                                </li>
                                <li>
                                  you cannot be part of a hiring committee when
                                  your relative is interviewed for that
                                  position.
                                </li>
                                <li>
                                  If you become related to a manager or direct
                                  report after you both become employed by
                                  Nextyn, we may have to transfer one of you.
                                </li>
                              </ul>

                              <p>
                                <span>Solicitation and distribution</span>
                              </p>
                              <p>
                                Solicitation is any form of requesting money,
                                support or participation in products, groups,
                                organizations or causes which are unrelated to
                                Nextyn (e.g., religious proselytise, asking for
                                petition signatures.) Distribution means
                                disseminating literature or material for
                                commercial or political purposes.
                              </p>

                              <p>
                                We don’t allow solicitation and distribution by
                                non-employees in our workplace. As an employee,
                                you may solicit from your colleagues only when
                                you want to:
                              </p>
                              <ul className="listType">
                                <li>
                                  Ask colleagues to help organize events for
                                  another employee (e.g., adoption/birth of a
                                  child, promotion, retiring.)
                                </li>
                                <li>
                                  Seek support for a cause, charity or
                                  fundraising event sponsored, funded,
                                  organized, or authorized by Nextyn.
                                </li>
                                <li>
                                  Invite colleagues to employee activities for
                                  an authorized non-business purpose (e.g.,
                                  recreation, volunteering.)
                                </li>
                                <li>
                                  Ask colleagues to participate in
                                  employment-related activities or groups
                                  protected by law (e.g., trade unions.)
                                </li>
                              </ul>
                              <p>
                                In all cases, we ask that you do not disturb or
                                distract colleagues from their work.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>vi. Payroll</h4>
                              <p>
                                We pay your salary or wage on or before the 7th
                                of the following months by either checks or bank
                                transfers. If you are an hourly employee, you
                                should be diligent [in clocking in and out/using
                                our timesheet software] so we can accurately
                                calculate your pay.
                              </p>

                              <p>
                                <span>Bonus Policy: </span>
                              </p>
                              <p>
                                Bonuses are offered at Nextyn based on the
                                company's guidelines and performance criteria.
                                To be eligible for a bonus, employees must be
                                actively employed by Nextyn at the time of
                                scheduled bonus payment. Bonuses may be
                                pro-rated for employees joining or leaving
                                during the accrual period. In the event of
                                voluntary resignation or termination for cause
                                before the bonus payment date, no outstanding
                                bonus will be paid, and any unearned bonus
                                amount already paid may be subject to recovery.
                                Bonuses are discretionary, subject to
                                modification or cancellation by the company, and
                                the details are to be kept confidential by
                                employees.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                vii. Office Timing Policy
                              </h4>
                              <p>
                                At Nextyn, we believe in maintaining punctuality
                                and adhering to a strict office timing policy.
                                We expect all employees to arrive at work on
                                time and be ready to start their workday
                                promptly. To ensure compliance with this policy,
                                the following rules apply:
                              </p>

                              <p>
                                <span>1. Late Arrival Deductions: </span>If an
                                employee logs in after 10:00 AM on three
                                occasions within a calendar month, a deduction
                                equivalent to one day's salary will be made from
                                their monthly compensation.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                viii. Attendance and Leave Reporting Policy:
                              </h4>
                              <p>
                                At Nextyn, we place a significant emphasis on
                                accurate attendance and leave reporting. To
                                ensure accurate record-keeping and maintain
                                transparency, the following rules apply:
                              </p>

                              <ul className="listTypeNum">
                                <li>
                                  <p>
                                    <span>1. Biometric Authentication: </span>It
                                    is mandatory for all employees to use the
                                    face biometric system for logging in and
                                    updating their leave records in our
                                    designated HR software. Failure to use this
                                    system may result in discrepancies in
                                    attendance records.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    <span>2. Leave Reporting: </span>When taking
                                    a day off, employees are required to request
                                    leave through the designated HR software.
                                    Any absence from work without a proper leave
                                    request will be considered unauthorized.
                                  </p>
                                </li>
                                <li>
                                  <p>
                                    <span>3. Anomaly Resolution: </span>If there
                                    is a discrepancy between an employee's
                                    attendance (failure to log in) and leave
                                    records (no approved leave request) for a
                                    specific day, it will be considered as a day
                                    without pay, and appropriate salary
                                    deductions may apply.
                                  </p>
                                </li>
                              </ul>
                              <p>
                                We understand that unforeseen circumstances may
                                arise, and exceptions can be made on a
                                case-by-case basis. If you have any questions or
                                concerns regarding these policies or need to
                                request an exception, please reach out to the HR
                                department for clarification and assistance.
                              </p>

                              <p>
                                By implementing and adhering to these policies,
                                we aim to maintain a fair and transparent work
                                environment while ensuring the efficient
                                operation of our organization. Your cooperation
                                in adhering to these guidelines is greatly
                                appreciated.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                ix. Performance management
                              </h4>

                              <p>
                                We have built our performance management
                                practices to:
                              </p>

                              <ul className="listType">
                                <li>
                                  Ensure you understand your job
                                  responsibilities and have specific goals to
                                  meet.
                                </li>
                                <li>
                                  Provide you with actionable and timely
                                  feedback on your work.
                                </li>
                                <li>
                                  Invest in development opportunities that help
                                  you grow professionally.
                                </li>
                                <li>
                                  Recognize and reward your work in financial or
                                  non-financial ways (e.g., employee awards.
                                </li>
                              </ul>

                              <p>To meet these objectives, we have:</p>

                              <ul className="listType">
                                <li>
                                  Established bi-annual performance reviews.
                                  During these reviews, your manager will fill
                                  out your performance evaluation report and
                                  arrange a meeting with you to discuss it.
                                  Through these discussions, managers aim to
                                  recognize employees who are good at their
                                  jobs, identify areas of improvement and talk
                                  about career moves. Pay increases or bonuses
                                  are not guaranteed. But we encourage managers
                                  to recommend rewards for their team members
                                  when they deserve them. There won’t be any
                                  comparison between employees, as our goal is
                                  to help all employees improve and develop
                                  their careers.
                                </li>
                                <li>
                                  Instructed all managers to meet with their
                                  team members once per quarter to provide
                                  feedback and talk about their work and
                                  motivations. This way, you can receive
                                  feedback in a timely manner and avoid
                                  surprises during your bi-annual performance
                                  review.
                                </li>
                              </ul>

                              <h4 style={{ color: "#5746EB" }}>
                                x. How we expect managers to lead employees
                              </h4>

                              <p>
                                If you manage a team, you are responsible for
                                your team members’ performance. To conduct
                                effective regular meetings and performance
                                evaluations, we expect you to:
                              </p>

                              <ul className="listType">
                                <li>
                                  Set clear objectives. Your team members should
                                  know what you expect of them. When you first
                                  hire someone to your team, ensure they
                                  understand their job duties. Set specific
                                  goals for each team member (and team-wide if
                                  applicable.) Revisit those goals during
                                  bi-annual performance reviews.
                                </li>
                                <li>
                                  Provide useful feedback. During scheduled
                                  meetings with your team members, give them
                                  both guidance and praise, as appropriate. Be
                                  fair and specific to help them understand and
                                  implement your feedback.
                                </li>
                                <li>
                                  Keep your team members involved. There should
                                  be two-way communication between you and your
                                  team. Make your expectations clear, but always
                                  take your team members’ motivations and
                                  aspirations into account. Discuss training and
                                  development opportunities that may interest
                                  your team members.
                                </li>
                                <li>
                                  Keep logs with important incidents about each
                                  one of your team members. These logs help you
                                  evaluate your team, but may also prove useful
                                  when rewarding, promoting, or terminating your
                                  team members.
                                </li>
                              </ul>

                              <h4 style={{ color: "#5746EB" }}>
                                8. Benefits and perks
                              </h4>

                              <p>
                                In this section, we describe what we offer to
                                our employees. We provide information on our
                                health insurance plans and benefits like work
                                from home options and company- issued equipment.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                i. Employee health
                              </h4>

                              <p>
                                Employee health is important to us. We don’t
                                discriminate against people with disabilities or
                                health conditions, but we want to do everything
                                possible to help employees stay healthy. At a
                                minimum, we provide group health insurance to
                                all eligible employees. For more information
                                about our insurance package, contact HR.
                              </p>

                              <p>
                                We have also established non-smoking and
                                substance abuse policies to protect employee
                                health. We will create a workplace with minimal
                                noise and good lighting.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                ii. Employee expenses
                              </h4>

                              <p>
                                There are some expenses that we will pay
                                directly on your behalf (e.g., hotel rooms for
                                work-related travel.) But we ask you to keep
                                track and report on those reimbursable expenses
                                that you pay yourself. We reimburse employee
                                expenses that are related to:
                              </p>

                              <ul className="listType">
                                <li>Business travel</li>
                                <li>Relocation</li>
                                <li>Education and training</li>
                              </ul>

                              <p>
                                Not all travel expenses are reimbursable. For
                                example, we will pay for your transportation to
                                an airport for work-related travel, but not to a
                                museum for a personal visit. Before traveling
                                for business, contact HR to clarify which
                                expenses are reimbursable for your trip.
                              </p>

                              <p>
                                Please keep receipts for all reimbursable
                                expenses. You can submit them to your manager
                                within three months after the date of each
                                expense. If your manager approves your expenses,
                                you will receive your reimbursement within two
                                pay periods.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                iii. Company-issued equipment
                              </h4>

                              <p>
                                As an employee, you may receive a company cell
                                phone, laptop or other device, furniture. Unless
                                otherwise mentioned in your contract, any
                                equipment we offer belongs to Nextyn and you may
                                not sell it or give it away. You are also
                                responsible for keeping our equipment safe and
                                in as good a condition as possible. If your
                                equipment breaks or malfunctions, let us know so
                                we can arrange to get it repaired. Upon leaving
                                the Nextyn, it is mandatory to return any
                                company-issued laptops to protect the security
                                and integrity of company data. Please note that
                                <span>
                                  {" "}
                                  there is no option to purchase company laptops
                                  upon departure.
                                </span>
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                iv. Theft and damage of company equipment:
                              </h4>

                              <p>
                                Our equipment is insured for theft and damage.
                                We ask you to inform us within 24 hours if your
                                equipment is stolen or damaged. We might be able
                                to trace stolen laptops and cell phones. Please
                                also file a theft statement (affidavit) with the
                                police and submit a copy to us.
                              </p>

                              <p>
                                <span>Security of company issued devices:</span>
                              </p>

                              <p>
                                We advise you to keep Nextyn-issued computer,
                                tablet, and cell phone secure. You can do this
                                if you:
                              </p>

                              <ul className="listType">
                                <li>Keep all devices password protected.</li>
                                <li>
                                  Ensure you do not leave your devices
                                  unattended.
                                </li>
                                <li>
                                  Install security updates for browsers and
                                  other systems as soon as updates are
                                  available.
                                </li>
                                <li>
                                  Log into company accounts and systems through
                                  secure and private networks only.
                                </li>
                                <li>
                                  Follow all instructions for disk encryption,
                                  anti-malware protection and password
                                  management that you received along with your
                                  equipment.
                                </li>
                              </ul>

                              <h4 style={{ color: "#5746EB" }}>
                                9. Leaves and Time
                              </h4>

                              <p>
                                In this section, we explain our provisions for
                                your working hours and time off. We include 3
                                types of leave and holidays.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                v. Working hours
                              </h4>

                              <p>
                                Nextyn operates between 9.30 a.m. to 6 p.m. on
                                weekdays. You may come to work at any time
                                between 9.30 a.m. and 10.00 a.m., depending on
                                your team’s needs.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                vi. Paid time off (PTO)
                              </h4>

                              <p>
                                Employees receive 18 days of Paid Time Off (PTO)
                                per year. You PTO accrual begins the day you
                                join Nextyn, and you receive 1.5 days per month.
                                You can take your PTO at any time after your
                                first week with us and you can use time off you
                                haven’t accrued yet.
                              </p>

                              <p>
                                If you want to use PTO, send a request through
                                Day Off – System. If your manager or HR
                                approves, you are permitted to take your leave.
                                You do not have to specify a reason for
                                requesting PTO. Updating your PTO information on
                                Day Off must be done either before you take
                                leave or within 24 hours of you returning to
                                office. Not recording your leaves in the system
                                on time may impact your salary calculation at
                                the end of the month.
                              </p>

                              <p>
                                Leave Transfer Policy: 25% of any remaining PTO
                                gets transferred to the next financial year. We
                                encourage you to use your time off throughout
                                the year.
                              </p>

                              <p>
                                If you leave the Company, your remaining PTOs
                                are not encashed and lapse on the last day of
                                your tenure with the Company.{" "}
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                vii. Mandatory Holidays
                              </h4>

                              <p>
                                Nextyn observes the following holidays in 2023.{" "}
                              </p>

                              <img
                                src={holiday_img}
                                alt="holiday_img"
                                style={{ height: "65%", width: "65%" }}
                              ></img>

                              <h4 style={{ color: "#5746EB" }}>
                                viii. Parental leave
                              </h4>

                              <p>
                                Caring for a newborn is an exciting time for
                                parents. We want to support new mothers and
                                fathers in their first months of parenthood with
                                paternity and maternity leave.
                              </p>
                              <p>
                                Afterwards, we will continue to support parents
                                with flexible work options.
                              </p>
                              <p>Paternity and maternity leave</p>
                              <p>
                                Nextyn offers three months of paid maternity
                                leave and a paternity leave of 15 days within
                                six months of the birth or adoption of a child.
                                If local or national law stipulates longer
                                leave, we will follow the law.
                              </p>
                              <p>
                                If you are about to be a new mother or father
                                either through childbirth or adoption, talk to
                                HR to arrange your leave. Please give us at
                                least three months’ notice before your leave
                                begins.
                              </p>
                              <p>
                                Depending on local or national law, pregnant
                                women can take part of their leave before labor.
                                If you suffer complications during childbirth or
                                have other issues, you can ask for an unpaid
                                leave extension of up to three months. Contact
                                HR as soon as possible to arrange this.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                10. Leaving Nextyn
                              </h4>

                              <p>
                                In this section, we describe our procedures
                                regarding resignation and termination of our
                                employees. We also refer to our progressive
                                discipline process that may sometimes result in
                                termination.
                              </p>

                              <p>Progressive discipline</p>

                              <p>
                                Here we outline steps we will take to address
                                employee misconduct. We want to give employees a
                                chance to correct their behaviour when possible
                                and assist them in doing so. We also want to
                                ensure that we thoroughly investigate and handle
                                serious offenses.
                              </p>

                              <p>
                                Our progressive discipline process has six steps
                                of increasing severity. These steps are:
                              </p>

                              <ul className="listTypeNum">
                                <li>i. Verbal warning</li>
                                <li>ii. Informal meeting with supervisor</li>
                                <li>iii. Formal reprimand</li>
                                <li>iv. Formal disciplinary meeting</li>
                                <li>v. Penalties</li>
                                <li>vi. Termination</li>
                              </ul>

                              <p>
                                Different offenses correspond to different steps
                                in our disciplinary process. For example, minor,
                                one-time offenses (e.g., breach of our dress
                                code policy) will trigger Step 1. More severe
                                violations (e.g., sexual harassment) will
                                trigger step 6.
                              </p>

                              <p>
                                If you manage employees, inform them when you
                                launch a progressive discipline process.
                                Pointing out a performance issue is not
                                necessarily a verbal warning and may be part of
                                your regular feedback. If you judge that
                                progressive discipline is appropriate, let your
                                team members know and ask HR to help you explain
                                our full procedure.
                              </p>

                              <p>
                                Managers may skip or repeat steps at their
                                discretion. Nextyn may treat circumstances
                                differently from those described in this policy.
                                But we are always obliged to act fairly and
                                lawfully and document every stage of our
                                progressive discipline process.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                i. Resignation
                              </h4>

                              <p>
                                You resign when you voluntarily inform HR or
                                your manager that you will stop working for
                                Nextyn. We also consider you resigning if you
                                don’t come to work for three consecutive days
                                without notice.
                              </p>

                              <p>
                                We may terminate an employee either for cause or
                                without cause.
                              </p>
                              <ul className="listType">
                                <li>
                                  For cause termination is justified when an
                                  employee breaches their contract, engages in
                                  illegal activities (e.g., embezzlement),
                                  disrupts our workplace (e.g., harasses
                                  colleagues), performs below acceptable
                                  standards or causes damage or financial loss
                                  to Nextyn.
                                </li>
                                <li>
                                  Without cause termination refers to
                                  redundancies or layoffs that may be necessary
                                  if we cease some of our operations or
                                  re-assign job duties within teams. We will
                                  follow applicable laws regarding notice and
                                  payouts.
                                </li>
                              </ul>

                              <p>
                                We will offer no severance pay to such
                                employees. We, however, may also help employees
                                who were terminated without cause to find work
                                elsewhere, if possible.
                              </p>
                              <p>
                                If you manage team members, avoid wrongful
                                dismissal. When you terminate an employee for
                                cause, we expect you to be certain you made the
                                right choice and keep accurate performance
                                and/or disciplinary records to support your
                                decision.
                              </p>

                              <p>
                                <span>References</span>
                              </p>

                              <p>
                                When we terminate employees, we may provide
                                references for those who leave in good standing.
                                This means that employees shouldn’t have been
                                terminated for cause. If you are laid off, you
                                may receive references. Please ask your manager.
                              </p>

                              <p>
                                If you resign, you may ask for references and
                                your manager has a right to oblige or refuse.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                11. Policy revision
                              </h4>

                              <p>
                                We will always strive for fairness and equal
                                opportunity and penalize offensive and illegal
                                behaviours. But, as laws and our environment
                                change, we may revise and modify some of our
                                policies.
                              </p>

                              <p>
                                We have established an annual revision of our
                                handbook to bring it up to date with legislation
                                and employment trends. We also ask you to
                                contact HR if you spot any inconsistencies or
                                mistakes. And, if you have any ideas about how
                                to improve our workplace, we are happy to hear
                                them.
                              </p>

                              <h4 style={{ color: "#5746EB" }}>
                                12. Employee acknowledgement
                              </h4>

                              <p>
                                Please sign to acknowledge that you’ve read this
                                handbook and that you are committed to following
                                our policies. If you need any clarifications,
                                feel free to ask HR.
                              </p>
                            </section>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col>
                            <div>
                              {profileData[0]?.sign ? null : (
                                <>
                                  <Button onClick={showModal}>Sign</Button>
                                </>
                              )}

                              <div>
                                <p style={{ paddingTop: "20px" }}>
                                  <span>Signature:</span>
                                  {profileData[0]?.sign ? (
                                    <>
                                      <img
                                        src={profileData[0]?.sign}
                                        alt="Signature"
                                        style={{
                                          width: "80px",
                                          position: "relative",
                                        }}
                                      />
                                    </>
                                  ) : null}
                                </p>
                              </div>

                              <p>
                                Name:
                                {profileData[0]?.name ? (
                                  <>{profileData[0]?.name}</>
                                ) : null}
                              </p>

                              <p>
                                Date:
                                {profileData[0]?.date ? (
                                  <>{convertDate(profileData[0]?.date)}</>
                                ) : null}
                              </p>
                            </div>

                            <Modal
                              title="Signature"
                              visible={isModalVisible}
                              onCancel={handleCancel}
                              footer={[
                                isSignatureCaptured && (
                                  <Button
                                    key="save"
                                    type="primary"
                                    onClick={handleSaveSignature}
                                  >
                                    Save
                                  </Button>
                                ),
                                isSignatureCaptured && (
                                  <Button
                                    key="clear"
                                    onClick={handleClearSignature}
                                  >
                                    Clear
                                  </Button>
                                ),
                              ]}
                            >
                              <SignatureCanvas
                                ref={signatureRef}
                                canvasProps={{ width: 500, height: 200 }}
                                onBegin={() => setIsSignatureCaptured(true)}
                                onEnd={() => setIsSignatureCaptured(true)}
                              />
                            </Modal>
                          </Col>
                          <Col>
                            <p style={{ fontWeight: 500 }}>
                              Nextyn Advisory Pvt Ltd
                            </p>
                            <p style={{ paddingTop: "20px" }}>
                              <span>Signature - </span>{" "}
                              <img
                                src={rasesh_sign}
                                alt="sign"
                                style={{
                                  width: "50px",
                                  verticalAlign: "middle",
                                }}
                              />
                            </p>
                            <p>Name - Rasesh Seth</p>
                            <p>Title - Director</p>
                            <p>Date - 20/06/2023</p>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default Policy;
