import { Select, Spin } from "antd";
import debounce from "lodash/debounce";
import React, { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSelectExpert from "../../../ZoomMeeting/CustomSelectExpert";
import useCreateMeeting from "../hooks/useCreateMeeting";
import { setIsFetching } from "../Slice/internalMeeting.slice";

function DebounceSelect({
  type,
  optionType = "name",
  debounceTimeout = 800,
  creatableSelect = false,
  extraFunction = () => { },
  ...props
}) {
  const { fetching } = useSelector((state) => state.internalmeeting);
  const {
    experts,
    managers,
    clients,
    fetchExperts,
    fetchZoomExperts,
    fetchClients,
    fetchManagers,
    fetchCompanies,
    companyList, zoomExperts
  } = useCreateMeeting();
  const optionsDatas = useMemo(() => {
    return experts?.map((itemData) => {
      const dataName = itemData?.name
        ? itemData?.name
        : `${itemData?.first_name} ${itemData?.last_name}`;
      return { value: itemData?._id, label: dataName };
    });
  }, [experts]);

  const optionsDatasZoom = useMemo(() => {
    return zoomExperts?.map((itemData) => {
      const dataName = itemData?.name
        ? itemData?.name
        : `${itemData?.first_name} ${itemData?.last_name}`;
      return { value: itemData?._id, label: dataName };
    });
  }, [zoomExperts]);

  const optionsDatasEmail = useMemo(() => {
    return experts?.map((itemData) => {
      const dataEmail = itemData?.email;
      return {
        key: itemData?._id,
        value: dataEmail,
        label: dataEmail,
        title: itemData?.name,
      };
    });
  }, [experts]);

  const optionsDatasEmailZoom = useMemo(() => {
    return zoomExperts?.map((itemData) => {
      const dataEmail = itemData?.email;
      return {
        key: itemData?._id,
        value: dataEmail,
        label: dataEmail,
        title: itemData?.name,
      };
    });
  }, [zoomExperts]);

  const optionsDatasClient = useMemo(() => {
    return clients?.map((itemData) => {
      const dataName = itemData?.name;
      return { value: itemData?._id, label: dataName };
    });
  }, [clients]);

  const optionsDatasClientEmail = useMemo(() => {
    return clients?.map((itemData) => {
      const dataEmail = itemData?.email;
      return {
        key: itemData?._id,
        value: dataEmail,
        label: dataEmail,
        title: itemData?.name,
      };
    });
  }, [clients]);

  const optionsDatasManager = useMemo(() => {
    return managers?.map((itemData) => {
      const dataName = itemData?.name;
      return { value: itemData?._id, label: dataName };
    });
  }, [managers]);
  const optionsDatasManagerEmail = useMemo(() => {
    return managers?.map((itemData) => {
      const dataEmail = itemData?.email;
      const dataName = itemData?.name;
      return { key: itemData?._id, value: dataEmail, label: dataName };
    });
  }, [managers]);

  const optionsDatasCompanies = useMemo(() => {
    return companyList?.map((itemData) => {
      const dataName = itemData?.company_name;
      return { value: itemData?._id, label: dataName };
    });
  }, [companyList]);
  const dispatch = useDispatch();
  const debounceFetcher = useMemo(() => {
    let loadOptions = [];
    if (type === "expert") {
      loadOptions = async (value) => {
        dispatch(setIsFetching(true));
        let res = await fetchExperts({ is_filtering: true, value: value });
        if (res.status) {
          dispatch(setIsFetching(false));
        }
      };
    }
    if (type === "zoomExpert") {
      loadOptions = async (value) => {
        dispatch(setIsFetching(true));
        let res = await fetchZoomExperts({ is_filtering: true, value: value });
        if (res.status) {
          dispatch(setIsFetching(false));
        }
      };
    }
    if (type === "client") {
      loadOptions = (value) => {
        fetchClients({ is_filtering: true, value: value });
      };
    } else if (type === "manager") {
      loadOptions = (value) => {
        fetchManagers({ is_filtering: true, value: value });
      };
    } else if (type === "company") {
      loadOptions = (value) => {
        fetchCompanies({ is_filtering: true, value: value });
      };
    }

    return debounce(loadOptions, debounceTimeout);
  }, [type, debounceTimeout, dispatch, fetchExperts, fetchZoomExperts, fetchClients, fetchManagers, fetchCompanies]);

  const inputRef = useRef(null);
  const [email, setEmail] = useState("");

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const addItem = (e) => {
    e.preventDefault();
    let id = Math.floor(100000 + Math.random() * 900000);
    let obj = {
      label: email,
      value: email,
      key: id,
      title: "",
      in_list: false,
    };
    extraFunction(obj);
    setEmail("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  if (type === "expert") {
    return (
      <CustomSelectExpert
        showSearch
        labelInValue
        allowClear
        filterOption={false}
        fetchExperts={fetchExperts}
        debounceFetcher={debounceFetcher}
        optionsDatasEmail={optionsDatasEmail}
        optionsDatas={optionsDatas}
        optionType={optionType}
        fetching={fetching}
        creatableSelect={creatableSelect}
        email={email}
        onEmailChange={onEmailChange}
        addItem={addItem}
        {...props}
      />
      // <Select
      //   showSearch
      //   labelInValue
      //   allowClear
      //   filterOption={false}
      //   onDropdownVisibleChange={(open) =>
      //     open && fetchExperts({ is_filtering: false, value: "" })
      //   }
      //   onSearch={debounceFetcher}
      //   {...props}
      //   options={optionType === "email" ? optionsDatasEmail : optionsDatas}
      //   dropdownRender={
      //     creatableSelect
      //       ? (menu) => (
      //           <>
      //             {fetching ? (
      //               <span
      //                 className="d-flex align-items-center justify-content-center"
      //                 style={{
      //                   height: 40,
      //                 }}
      //               >
      //                 <Spin size="small" />
      //               </span>
      //             ) : (
      //               <>
      //                 {menu}
      //                 <Divider
      //                   style={{
      //                     margin: "8px 0",
      //                   }}
      //                 />
      //                 <Space.Compact
      //                   block
      //                   style={{
      //                     padding: "0 8px 4px",
      //                     width: "100%",
      //                   }}
      //                   className="zum_meeting_space"
      //                 >
      //                   <Tooltip
      //                     title={"For Unregistered Experts Only"}
      //                     overlayStyle={{ zIndex: 2000 }}
      //                   >
      //                     <Input
      //                       placeholder="Please enter expert email"
      //                       ref={inputRef}
      //                       width={100}
      //                       value={email}
      //                       onChange={onEmailChange}
      //                       onKeyDown={(e) => e.stopPropagation()}
      //                     />
      //                   </Tooltip>
      //                   {/* <Button
      //                     type="text"
      //                     disabled={!exist?.exist}
      //                     style={{ color: "green" }}
      //                     className="d-flex align-items-center"
      //                   >
      //                     <FaCheck />
      //                   </Button> */}
      //                   <Button
      //                     type="text"
      //                     icon={<PlusOutlined />}
      //                     onClick={addItem}
      //                   >
      //                     Add
      //                   </Button>
      //                 </Space.Compact>
      //               </>
      //             )}
      //           </>
      //         )
      //       : false
      //   }
      // />
    );
  } else if (type === "zoomExpert") {
    return (
      <CustomSelectExpert
        showSearch
        labelInValue
        filterOption={false}
        fetchExperts={fetchExperts}
        debounceFetcher={debounceFetcher}
        optionsDatasEmail={optionsDatasEmailZoom}
        optionsDatas={optionsDatasZoom}
        optionType={optionType}
        fetching={fetching}
        creatableSelect={creatableSelect}
        email={email}
        onEmailChange={onEmailChange}
        addItem={addItem}
        {...props}
      />
    )
  }
  else if (type === "client") {
    return (
      <Select
        showSearch
        labelInValue
        allowClear
        filterOption={false}
        onDropdownVisibleChange={(open) =>
          open && fetchClients({ is_filtering: false, value: "" })
        }
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={
          optionType === "email" ? optionsDatasClientEmail : optionsDatasClient
        }
      />
    );
  } else if (type === "manager") {
    return (
      <Select
        showSearch
        labelInValue
        filterOption={false}
        onDropdownVisibleChange={(open) =>
          open && fetchManagers({ is_filtering: false, value: "" })
        }
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={
          optionType === "email"
            ? optionsDatasManagerEmail
            : optionsDatasManager
        }
      />
    );
  } else if (type === "company") {
    return (
      <Select
        showSearch
        labelInValue
        filterOption={false}
        allowClear
        onDropdownVisibleChange={(open) =>
          open && fetchCompanies({ is_filtering: false, value: "" })
        }
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={optionsDatasCompanies}
      />
    );
  }
}

export default DebounceSelect;
