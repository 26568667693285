import React from "react";
import { Helmet } from "react-helmet";
import Sidebar from "../../components/Sidebar";
import { Spin } from "antd";
import Invoice from "./Invoice";

function InvoiceGenerator() {
  return (
    <>
      <Helmet>
        <title>{"Invoice-Generator | Nextyn"}</title>
      </Helmet>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <div className="text-center loginScreenLinkedin">
          <Spin size="large" />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div
            className="dasb-div bk-div float-start w-100"
            style={{
              position: "relative",
              zIndex: 10,
              background: "#f8f8f8",
              height: "100vh",
            }}
          >
            <div className="">
              <div className="dasb-div bk-div float-start w-100">
                <div className="body-expart-div">
                  <Invoice />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default InvoiceGenerator;
