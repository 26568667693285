import React from "react";
import Helmet from "react-helmet";
import Sidebar from "../../components/Sidebar";
import EnquiryPageSection from "./EnquiryPageSection";

const EnquiryPage = () => {

  return (
    <>
      <Helmet>
        <title>{"Nextyn | Enquiries"}</title>
      </Helmet>
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div>
            <div className="d-block d-lg-block">
              <div className="bk-div float-start w-100">
                <div className="body-expart-div request-page projects-scope">
                  <h2>Enquiries</h2>
                  <div className="request-body-sec projects-details-page">
                    <EnquiryPageSection />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default EnquiryPage;
