import React, { useState, useEffect } from "react";
import { notification } from "antd";
import API_PATH from "../../Constants/api-path";
import { useNavigate } from "react-router-dom";
import {
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import ENV from "../../Constants/env";

const LinkedInLogin = ({ txt, setIsLoading }) => {
  const [userData, setUserData] = useState(null);
  const [code, setCode] = useState(null);

  useEffect(() => {
    // Function to extract query parameters from the URL
    const getQueryParameter = (name) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(name);
    };

    // Get the 'code' query parameter from the URL
    const codeFromURL = getQueryParameter("code");
    if (codeFromURL) {
      setIsLoading(true);
      // If 'code' exists in the URL, set it in the state
      setCode(codeFromURL);

      // You can perform any additional actions with the 'code' here if needed
    } else {
      console.info("Code not found in URL");
    }
  }, []);

  // const handleSuccess = async (response) => {
  //   try {
  //     // Handle the successful LinkedIn authentication response

  //     // Save the response data to the state
  //     setUserData(response);

  //     // Retrieve the LinkedIn ID from the response and log it
  //     const linkedinId = response.id;

  //     // Additional actions with the response data can be done here
  //   } catch (error) {
  //     console.error("Error handling LinkedIn login:", error);
  //   }
  // };

  // const handleFailure = (error) => {
  //   console.error("LinkedIn Login Error:", error);
  // };

  const redirectToLinkedInLogin = () => {
    const clientId = "77zzr4bbd3gaj0";
    // const redirectUri = "https://platform.nextyn.com/expert-register?regType=1&step=1";
    const redirectUri = `${ENV.FRONTEND_BASE_URL}/expert-register?step_and_reg=11`;

    const state = "foobar";
    const scope = "r_liteprofile r_emailaddress";
    const authURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;

    const urlParams = new URLSearchParams(window.location.search);
    const error = urlParams.get("error");

    if (error === "user_cancelled_authorize") {
      notification.error({
        message: "Authorization cancelled by user. Please try again.",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
      window.location.href = "/expert-register?regType=1&step=1";
    } else {
      window.location.href = authURL;
    }
  };

  const GetLinkedin = async (code) => {
    // e.preventDefault();
    const state = "foobar";
    try {
      const res = await fetch(
        `${API_PATH.EXPERT_REGISTRATION_LINKDIN}?code=${code}&state=${state}&step_and_reg=11`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = await res.json();
      if (
        result?.status === true &&
        result?.message === "Login Successfully."
      ) {
        setIsLoading(false);
        notification.error({
          message: "You have already registered please login",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });

        setTimeout(() => {
          window.location.href = "/expert-login";
        }, 1000);
      } else {
        if (result?.status) {
          localStorage.setItem("linkedinData", JSON.stringify(result?.data));

          setTimeout(() => {
            window.location.href = "/expert-register?regType=1&step=3&type=linkedin";
            setIsLoading(false);
          }, 1000);
        } else {
          notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
          setTimeout(() => {
            window.location.href = "/expert-register?regType=1&step=1";
          }, 1000);
        }
      }
    } catch (error) {
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });

      setTimeout(() => {
        window.location.href = "/expert-register?regType=1&step=1";
        setIsLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (code) {
      GetLinkedin(code);
    }
  }, [code]);

  return (
    <div>
      <div className="social-Login">
        <button
          className="btn btn-block btn-social btn-linkedin"
          onClick={redirectToLinkedInLogin}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path
              d="M6.94 5a2 2 0 1 1-4-.002 2 2 0 0 1 4 .002zM7 8.48H3V21h4V8.48zm6.32 0H9.34V21h3.94v-6.57c0-3.66 4.77-4 4.77 0V21H22v-7.93c0-6.17-7.06-5.94-8.72-2.91l.04-1.68z"
              fill="rgba(255,255,255,1)"
            ></path>
          </svg>{" "}
          {txt}
        </button>
      </div>
      {/* Display response data in the console */}
      {userData && <pre>{JSON.stringify(userData, null, 2)}</pre>}
    </div>
  );
};

export default LinkedInLogin;
