import {
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { notification, Spin } from "antd";
import React, { useState } from "react";
import AvatarGroup from "react-avatar-group";
import { NavLink } from "react-router-dom";
import "../../App.css";
import notificationsvg from "../../assests/images/Push-notifications-rafiki.svg";
import Sidebar from "../../components/Sidebar";
import API_PATH from "../../Constants/api-path";

function Notification() {
  document.title = "Nextyn | notification";
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [Loading1, setLoading1] = useState(false);
  // const [datas, setDatas] = useState([]);
  const [filter, setFilter] = useState(true);
  const [, setRead] = useState(false);
  // const history = useNavigate();
  // function randomColor() {
  //   let hex = Math.floor(Math.random() * 0xffffff);
  //   let color = "#" + hex.toString(16);

  //   return color;
  // }

  const userData = JSON.parse(localStorage.getItem("userData"));
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userData?.token}`,
  };
  // const [response, setResponse] = useState("");

  // useEffect(() => {
  //   // getData();
  //   getNotifications();
  //   const interval = setInterval(() => {
  //     getData();
  //   }, 3000);

  //   return () => clearInterval(interval);
  // }, []);

  const getData = async () => {
    try {
      const res = await fetch(
        `${API_PATH.NOTIFICATION_LIST}?limit=50&page=1&notification_for=admin&admin_id=${userData?.data?._id}`,
        {
          method: "GET",
          headers: headers,
        }
      );

      const result = await res.json();
      if (result) {
        setNotifications(result?.data);
        setLoading(true);
        setRead(true);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      // return toast.error("something went wrong server error", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 3000,
      // });
      setLoading(false);
    }
  };

  // const refreshPage = () => {
  //   window.location.reload();
  // };

  const readNotifications = async (notificationIds) => {
    try {
      if (!Array?.isArray(notificationIds)) {
        notificationIds = [notificationIds];
      }

      const res = await fetch(`${API_PATH?.UPDATE_NOTIFICATION_READ_STATUS}`, {
        method: "PUT",
        headers: headers,
        body: JSON.stringify({ id: notificationIds }),
      });

      const result = await res.json();
      if (result) {
        // getData();
        getNotifications();
        setLoading(true);
        setRead(false);
        // window.location.reload(true);
        setTimeout(() => { }, 1000);

        // notification.success({
        //   message: "All messages are seen",
        //   icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
        //   style: {
        //     backgroundColor: "#2ecc71",
        //     color: "#fff !important",
        //     border: "1px solid #52c41a",
        //   },
        //   duration: 5,
        //   placement: "topRight",
        // });

        // setNotifications([...result]);
        // setLoading(true);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      console.error(error);
      // return toast.error("something went wrong server error", {
      //   position: toast.POSITION.TOP_RIGHT,
      //   autoClose: 3000,
      // });
    }
  };

  if (loading) {
    // var count = notifications.filter((item) => item.isRead === false).length;
    var notificationdata = [];

    for (let i = notifications.length - 1; i >= 0; i--) {
      notificationdata.push(notifications[i]);
    }

    //setAllChat([...chatdata]);
  }

  String.prototype.toTitleCase = function () {
    return this.split(" ")
      .map((i) => i[0]?.toUpperCase() + i?.substring(1)?.toLowerCase())
      .join(" ");
  };

  const getNotifications = () => {
    if (notifications instanceof Array) {
      if (filter) {
        if (notifications?.filter((item) => item?.seen === false)?.length > 0) {
          let notificationData = notifications?.filter(
            (item) => item?.seen === false
          );
          return (
            <>
              {notificationData?.map((e, index) => {
                return (
                  <div
                    className="notificationDiv d-flex justify-content-between align-items-center"
                    key={index}
                  >
                    <div className="d-flex align-items-center">
                      <div className="userIcon me-3">
                        {e?.expertData && (
                          <>
                            <div className="avatarImg">
                              <figure className="m-0">
                                <AvatarGroup
                                  avatars={[e?.expertData?.name]}
                                  uppercase={true}
                                  className="team-info"
                                  initialCharacters={2}
                                  fontColor="#f33636"
                                  max={2}
                                  size={35}
                                  fontSize=".38"
                                  backgroundColor="#EFEFEF"
                                />
                              </figure>
                            </div>
                          </>
                        )}

                        {e?.clientData && (
                          <>
                            <div className="avatarImg">
                              <figure className="m-0">
                                <AvatarGroup
                                  avatars={[e?.clientData?.name]}
                                  uppercase={true}
                                  className="team-info"
                                  initialCharacters={2}
                                  fontColor="#f33636"
                                  max={2}
                                  size={35}
                                  fontSize=".38"
                                  backgroundColor="#EFEFEF"
                                />
                              </figure>
                            </div>
                          </>
                        )}

                        {e?.userData && (
                          <div className="avatarImg">
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                color: "#f33636",
                                backgroundColor: "#EFEFEF",
                                width: "35px",
                                height: "35px",
                                borderRadius: "50%",
                                fontSize: "18px",
                              }}
                            >
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 24 24"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M2 3.75C2 2.784 2.784 2 3.75 2h16.5c.966 0 1.75.784 1.75 1.75v16.5A1.75 1.75 0 0 1 20.25 22H9.75a.75.75 0 0 1 0-1.5h10.5a.25.25 0 0 0 .25-.25V9h-17v3A.75.75 0 0 1 2 12ZM9 7.5h11.5V3.75a.25.25 0 0 0-.25-.25H9Zm-5.5 0h4v-4H3.75a.25.25 0 0 0-.25.25Z"></path>
                                <path d="m9.308 14.5-2.104-2.236a.75.75 0 1 1 1.092-1.028l3.294 3.5a.75.75 0 0 1 0 1.028l-3.294 3.5a.75.75 0 1 1-1.092-1.028L9.308 16H6.09a2.59 2.59 0 0 0-2.59 2.59v2.66a.75.75 0 0 1-1.5 0v-2.66a4.09 4.09 0 0 1 4.09-4.09h3.218Z"></path>
                              </svg>
                            </div>
                          </div>
                        )}

                        {e?.projectData && (
                          <div className="avatarImg">
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                color: "#f33636",
                                backgroundColor: "#EFEFEF",
                                width: "35px",
                                height: "35px",
                                borderRadius: "50%",
                                fontSize: "18px",
                              }}
                            >
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M3.505 2.365A41.369 41.369 0 019 2c1.863 0 3.697.124 5.495.365 1.247.167 2.18 1.108 2.435 2.268a4.45 4.45 0 00-.577-.069 43.141 43.141 0 00-4.706 0C9.229 4.696 7.5 6.727 7.5 8.998v2.24c0 1.413.67 2.735 1.76 3.562l-2.98 2.98A.75.75 0 015 17.25v-3.443c-.501-.048-1-.106-1.495-.172C2.033 13.438 1 12.162 1 10.72V5.28c0-1.441 1.033-2.717 2.505-2.914z"></path>
                                <path d="M14 6c-.762 0-1.52.02-2.271.062C10.157 6.148 9 7.472 9 8.998v2.24c0 1.519 1.147 2.839 2.71 2.935.214.013.428.024.642.034.2.009.385.09.518.224l2.35 2.35a.75.75 0 001.28-.531v-2.07c1.453-.195 2.5-1.463 2.5-2.915V8.998c0-1.526-1.157-2.85-2.729-2.936A41.645 41.645 0 0014 6z"></path>
                              </svg>
                            </div>
                          </div>
                        )}
                      </div>

                      <div
                        className="notificationFrom"
                        style={{ cursor: "pointer" }}
                      >
                        <NavLink
                          to={e?.redirection_link}
                          onClick={() => readNotifications(e?._id)}
                        >
                          {e?.userData ? (
                            <>
                              <p
                                style={{
                                  fontSize: "14px",
                                  color: "#848594",
                                  lineHeight: "1.7",
                                }}
                              >
                                New project submitted by {e?.userData?.name}
                              </p>
                            </>
                          ) : null}

                          {e?.projectData && (
                            <>
                              <p
                                style={{
                                  fontSize: "14px",
                                  color: "#848594",
                                  lineHeight: "1.7",
                                }}
                              >
                                New message received in{" "}
                                <strong style={{ fontWeight: 500 }}>
                                  {e?.projectData?.project_title}
                                </strong>
                              </p>
                            </>
                          )}

                          {e?.expertData && (
                            <>
                              <p
                                style={{
                                  fontSize: "14px",
                                  color: "#848594",
                                  lineHeight: "1.7",
                                }}
                              >
                                {e?.expertData?.name.toTitleCase()} has
                                registered as Expert
                              </p>
                            </>
                          )}

                          {e?.clientData && (
                            <>
                              <p
                                style={{
                                  fontSize: "14px",
                                  color: "#848594",
                                  lineHeight: "1.7",
                                }}
                              >
                                {e?.clientData?.name?.toTitleCase()} has
                                registered as User
                              </p>
                            </>
                          )}
                        </NavLink>
                      </div>
                    </div>

                    <div className="notificationtime">
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#848594",
                          lineHeight: "1.7",
                        }}
                      >
                        {Calculate_days_hours(
                          new Date(e?.createdAt),
                          new Date(Date.now())
                        )}
                      </p>
                    </div>
                  </div>
                );
              })}
            </>
          );
        } else {
          return (
            <>
              <div className="text-center noResultFound">
                <div className="container">
                  <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-md-6">
                      <img src={notificationsvg} alt="notify"></img>
                      <h5 className="ms-4 mt-4">No Notification Available</h5>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }
      } else {
        if (notifications?.length > 0) {
          return (
            <>
              {notifications?.map((e, index) => {

                return (
                  <div
                    className="notificationDiv d-flex justify-content-between align-items-center cursor-pointer"
                    key={index}
                  >
                    <div className="d-flex align-items-center">
                      <div className="userIcon me-3">
                        {e?.expertData && (
                          <>
                            <div className="avatarImg">
                              <figure className="m-0">
                                <AvatarGroup
                                  avatars={[e?.expertData?.name]}
                                  uppercase={true}
                                  className="team-info"
                                  initialCharacters={2}
                                  fontColor="#f33636"
                                  max={2}
                                  size={35}
                                  fontSize=".38"
                                  backgroundColor="#EFEFEF"
                                />
                              </figure>
                            </div>
                          </>
                        )}

                        {e?.clientData && (
                          <>
                            <div className="avatarImg">
                              <figure className="m-0">
                                <AvatarGroup
                                  avatars={[e?.clientData?.name]}
                                  uppercase={true}
                                  className="team-info"
                                  initialCharacters={2}
                                  fontColor="#f33636"
                                  max={2}
                                  size={35}
                                  fontSize=".38"
                                  backgroundColor="#EFEFEF"
                                />
                              </figure>
                            </div>
                          </>
                        )}

                        {e?.userData && (
                          <div className="avatarImg">
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                color: "#f33636",
                                backgroundColor: "#EFEFEF",
                                width: "35px",
                                height: "35px",
                                borderRadius: "50%",
                                fontSize: "18px",
                              }}
                            >
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 24 24"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M2 3.75C2 2.784 2.784 2 3.75 2h16.5c.966 0 1.75.784 1.75 1.75v16.5A1.75 1.75 0 0 1 20.25 22H9.75a.75.75 0 0 1 0-1.5h10.5a.25.25 0 0 0 .25-.25V9h-17v3A.75.75 0 0 1 2 12ZM9 7.5h11.5V3.75a.25.25 0 0 0-.25-.25H9Zm-5.5 0h4v-4H3.75a.25.25 0 0 0-.25.25Z"></path>
                                <path d="m9.308 14.5-2.104-2.236a.75.75 0 1 1 1.092-1.028l3.294 3.5a.75.75 0 0 1 0 1.028l-3.294 3.5a.75.75 0 1 1-1.092-1.028L9.308 16H6.09a2.59 2.59 0 0 0-2.59 2.59v2.66a.75.75 0 0 1-1.5 0v-2.66a4.09 4.09 0 0 1 4.09-4.09h3.218Z"></path>
                              </svg>
                            </div>
                          </div>
                        )}

                        {e?.projectData && (
                          <div className="avatarImg">
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{
                                color: "#f33636",
                                backgroundColor: "#EFEFEF",
                                width: "35px",
                                height: "35px",
                                borderRadius: "50%",
                                fontSize: "18px",
                              }}
                            >
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M3.505 2.365A41.369 41.369 0 019 2c1.863 0 3.697.124 5.495.365 1.247.167 2.18 1.108 2.435 2.268a4.45 4.45 0 00-.577-.069 43.141 43.141 0 00-4.706 0C9.229 4.696 7.5 6.727 7.5 8.998v2.24c0 1.413.67 2.735 1.76 3.562l-2.98 2.98A.75.75 0 015 17.25v-3.443c-.501-.048-1-.106-1.495-.172C2.033 13.438 1 12.162 1 10.72V5.28c0-1.441 1.033-2.717 2.505-2.914z"></path>
                                <path d="M14 6c-.762 0-1.52.02-2.271.062C10.157 6.148 9 7.472 9 8.998v2.24c0 1.519 1.147 2.839 2.71 2.935.214.013.428.024.642.034.2.009.385.09.518.224l2.35 2.35a.75.75 0 001.28-.531v-2.07c1.453-.195 2.5-1.463 2.5-2.915V8.998c0-1.526-1.157-2.85-2.729-2.936A41.645 41.645 0 0014 6z"></path>
                              </svg>
                            </div>
                          </div>
                        )}
                      </div>

                      <div
                        className="notificationFrom"
                        style={{ cursor: "pointer" }}
                      >
                        <NavLink
                          to={e?.redirection_link}
                          onClick={() => readNotifications(e?._id)}
                        >
                          {/* <h5 className="m-0">
                            <span
                              style={{
                                color: "#848594",
                                fontWeight: "600",
                                fontSize: "16px",
                                textTransform: "none",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {e?.top_text}
                            </span>{" "}
                          </h5> */}
                          {e?.userData ? (
                            <>
                              <p
                                style={{
                                  fontSize: "14px",
                                  color: "#848594",
                                  lineHeight: "1.7",
                                }}
                              >
                                New project submitted by {e?.userData?.name}
                              </p>
                            </>
                          ) : null}

                          {e?.projectData && (
                            <>
                              <p
                                style={{
                                  fontSize: "14px",
                                  color: "#848594",
                                  lineHeight: "1.7",
                                }}
                              >
                                New message received in{" "}
                                <strong style={{ fontWeight: 500 }}>
                                  {e?.projectData?.project_title}
                                </strong>
                              </p>
                            </>
                          )}

                          {e?.expertData ? (
                            <>
                              <p
                                style={{
                                  fontSize: "14px",
                                  color: "#848594",
                                  lineHeight: "1.7",
                                }}
                              >
                                {e?.expertData?.name?.toTitleCase()} has
                                registered as Expert
                              </p>
                            </>
                          ) : null}

                          {e?.clientData && (
                            <>
                              <p
                                style={{
                                  fontSize: "14px",
                                  color: "#848594",
                                  lineHeight: "1.7",
                                }}
                              >
                                {e?.clientData?.name?.toTitleCase()} has
                                registered as User
                              </p>
                            </>
                          )}
                        </NavLink>
                      </div>
                    </div>

                    <div className="notificationtime">
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#848594",
                          lineHeight: "1.7",
                        }}
                      >
                        {Calculate_days_hours(
                          new Date(e?.createdAt),
                          new Date(Date.now())
                        )}
                      </p>
                    </div>
                  </div>
                );
              })}
            </>
          );
        } else {
          return (
            <>
              <div className="text-center noResultFound">
                <div className="container">
                  <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-md-6">
                      <img src={notificationsvg} alt="notify"></img>
                      <h5 className="ms-4 mt-4">No Notification Available</h5>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }
      }
    }
  };

  function Calculate_days_hours(date1, date2) {
    var diff = date2.getTime() - date1.getTime();
    var days = Math.floor(diff / (1000 * 60 * 60 * 24));
    var hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((diff % (1000 * 60)) / 1000);
    ///return { days, hours, minutes, seconds };
    if (days !== 0) {
      return days + " " + "days ago ";
    } else if (hours !== 0) {
      return hours + " " + "hours ago";
    } else if (minutes !== 0) {
      return minutes + " " + "min. ago";
    } else if (seconds < 60) {
      return seconds + " " + "sec. ago";
    }
  }

  return (
    <>
      {/* <AdminDashboard /> */}
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <div className="sidebar-menu" id="navAccordion">
            <Sidebar />
          </div>
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            {loading ? (
              <>
                <div className="d-block d-lg-block ">
                  {/* <div className="top-bar-menu float-start">
                <TopMenu />
              </div> */}

                  <div className="dasb-div bk-div float-start w-100">
                    <div className="body-expart-div request-page projects-scope">
                      <div className="request-body-sec collaborate-page">
                        { }
                        <div className="notificationContainer">
                          <div className="notificationHeader d-flex justify-content-between">
                            <h4>Notifications</h4>
                            <div
                              className="d-flex justify-content-between "
                              style={{
                                fontSize: "16px",
                                fontWeight: "400",
                              }}
                            >
                              <NavLink
                                to={""}
                                type="button"
                                className="me-3 cursor-pointer"
                                onClick={() => setFilter(!filter)}
                              >
                                {filter === true ? (
                                  <button className="btn mt-3 viewButton">
                                    View all
                                  </button>
                                ) : (
                                  <button className="btn mt-3 viewButton">
                                    Filter by unread
                                  </button>
                                )}
                              </NavLink>
                              <NavLink
                                to={""}
                                onClick={() => {
                                  const notificationIds = notifications?.map(
                                    (notification) => notification?._id
                                  );
                                  readNotifications(notificationIds);
                                }}
                              >
                                <button className="btn mt-3 viewButton">
                                  Mark all as read
                                </button>
                              </NavLink>

                              {/* ):(
                            <NavLink></NavLink>
                          )} */}
                            </div>
                          </div>

                          <div className="notifications">
                            {getNotifications()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className="d-flex justify-content-center align-items-center text-center"
                  style={{ minHeight: "100vh" }}
                >
                  <Spin size="large" />
                </div>
              </>
            )}
          </div>
        </main>
      </div>
    </>
  );
}

export default Notification;
