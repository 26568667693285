import React, { useEffect } from 'react';

const ExpertDashboard = () => {

    const userData = JSON.parse(localStorage.getItem("expertData"));
    useEffect(() => {
        // Assuming FS is available after FullStory is initialized
        if (window.FS) {
            window.FS.identify(userData?.data?._id, {
                displayName: userData?.data?.full_Name,
                email: userData?.data?.email,
                reviewsWritten_int: 14
            });
        }
    }, []);


};

export default ExpertDashboard;
