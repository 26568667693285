import * as React from "react";
import { useMotionValue, Reorder, useDragControls } from "framer-motion";
import { ReorderIcon } from "./Icon";
import { Button, DatePicker, Input, Switch } from "antd";
import { Col, Row } from "react-bootstrap";
import dayjs from "dayjs";
import { useRaisedShadow } from "./useRaisedShadow";
import { setInputsTwo } from "./addNewExpertForm.slice";
import { useDispatch } from "react-redux";

export const PreviousCompanyItem = ({
    input, index, Form, inputsTwo }) => {
    const y = useMotionValue(0);
    const boxShadow = useRaisedShadow(y);
    const dragControls = useDragControls();
    const dispatch = useDispatch()
    const handleDeleteInputTwo = React.useCallback(
        (index) => {
            const newInputs = [...inputsTwo];
            newInputs.splice(index, 1);
            dispatch(setInputsTwo(newInputs));
        },
        [dispatch, inputsTwo]
    );

    const handlePrevChange = React.useCallback(
        (checked, index) => {
            let updatedInputs = [...inputsTwo];
            let updatedArr = updatedInputs?.map((inp, ind) => {
                if (ind === index) {
                    return {
                        ...inp,
                        previous_show_check: checked ? "yes" : "no"
                    }
                } else {
                    return {
                        ...inp
                    }
                }
            })

            dispatch(setInputsTwo(updatedArr));
        },
        [dispatch, inputsTwo]
    );

    const handleInputChangeTwo = React.useCallback((value, field, index) => {
        let newArr = [...inputsTwo]
        let newInputsTwo = newArr?.map((input, i) => {
            if (i === index) {
                return {
                    ...input,
                    [field]:
                        (field === "startDatePrev" || field === "endDatePrev") && value
                            ? dayjs(value)?.format("MM, YYYY") // Format the date in "01, 2020" format
                            : value, // If value is null (cleared), then set field to null
                };
            }
            return input

        })
        dispatch(setInputsTwo(newInputsTwo));
    }, [dispatch, inputsTwo]);
    return (
        <Reorder.Item
            value={input}
            id={input?.id || index}
            style={{ boxShadow, y }}
            dragListener={false}
            dragControls={dragControls}
        >
            <ReorderIcon dragControls={dragControls} />
            <div>
                <div>
                    <Row
                        gutter={[24, 24]}
                        className="d-flex justify-content-between align-items-center"
                    >
                        <Col span={12}>
                            <Input
                                size="large"
                                autoComplete="off"
                                placeholder="Job Title"
                                name="titlePrev"
                                value={input?.titlePrev}
                                onChange={(event) =>
                                    handleInputChangeTwo(
                                        event.target.value,
                                        "titlePrev",
                                        index
                                    )
                                }
                            />
                        </Col>
                        <Col span={12}>
                            <Input
                                size="large"
                                autoComplete="off"
                                placeholder="Company"
                                name="companyPrev"
                                value={input?.companyPrev}
                                onChange={(event) =>
                                    handleInputChangeTwo(
                                        event.target.value,
                                        "companyPrev",
                                        index
                                    )
                                }
                            />
                        </Col>
                    </Row>

                    <Row
                        gutter={[24, 24]}
                        className="d-flex justify-content-between align-items-center mt-4"
                    >
                        <Col span={12}>
                            <Form.Item
                                name={[index, "prev_start_date"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "Start date is required.",
                            //   },
                            // ]} 
                            >
                                <DatePicker
                                    size="large"
                                    style={{ width: "100%" }}
                                    placeholder="Start Date"
                                    name="startDatePrev"
                                    value={
                                        input?.startDatePrev
                                            ? dayjs(input?.startDatePrev, "MM, YYYY")
                                            : null
                                    }
                                    onChange={(value) =>
                                        handleInputChangeTwo(value, "startDatePrev", index)
                                    }
                                    picker="month"
                                    format="MM, YYYY" // Include the day of the month in the format
                                    disabledDate={(current) =>
                                        current && current.isAfter(dayjs(), "month")
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={[index, "prev_end_date"]}
                            // rules={[
                            //   {
                            //     required: true,
                            //     message: "End date is required.",
                            //   },
                            // ]}
                            >
                                <DatePicker
                                    size="large"
                                    style={{ width: "100%" }}
                                    placeholder="End Date"
                                    name="endDatePrev"
                                    value={
                                        input?.endDatePrev
                                            ? dayjs(input?.endDatePrev, "MM, YYYY")
                                            : null
                                    }
                                    onChange={(value) =>
                                        handleInputChangeTwo(value, "endDatePrev", index)
                                    }
                                    picker="month"
                                    format="MM, YYYY" // Include the day of the month in the format
                                    disabledDate={(current) =>
                                        current && current.isAfter(dayjs(), "month")
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row
                        gutter={[24, 24]}
                        className="d-flex justify-content-between align-items-center mt-4"
                    >
                        <Col span={12}>
                            <Switch
                                checked={input?.previous_show_check === "yes"}
                                value={input?.previous_show_check === "yes"}
                                onChange={(checked) => handlePrevChange(checked, index)}
                            />
                            <span className="ms-3">Highlight Designation</span>
                        </Col>

                        {/* <Col span={12}>
              <Checkbox size="large">Present</Checkbox>
            </Col> */}
                    </Row>
                </div>

                <div>
                    {inputsTwo.length > 1 ? (
                        <div className="d-flex justify-content-end align-items-center my-2">
                            <Button
                                type="primary"
                                onClick={() => handleDeleteInputTwo(index)}
                            >
                                Remove
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
        </Reorder.Item>
    );
};
