import {
  CheckCircleOutlined,
  ExclamationCircleOutlined, UserOutlined
} from "@ant-design/icons";
import { Alert, Button, Form, Input, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import API_PATH from "../../../Constants/api-path";
import Sidebar from "../../Dashboard/Sidebar/Sidebar";
import ExpertAuth from "../../components/ExpertAuth";

const ForgotPassword = () => {
  let history = useNavigate();
  const [form] = Form.useForm();
  const [getEmail, setEmail] = useState({
    email: "",
  });
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState("");
  const [, forceUpdate] = useState({});

  const emailRules = [
    {
      type: "email",
      message: "Please enter a valid email address!",
    },
    {
      required: true,
      message: "Email is required!",
    },
  ];

  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  const handleInputs = (e) => {
    const { name, value } = e.target;
    const list = { ...getEmail, [name]: value };
    // checkEmail(list.email);
    setEmail(list);
    setError("");
  };

  const sendOtpMail = async (e) => {
    e.preventDefault();
    setSpinner(false);

    try {
      let { email } = getEmail;
      email = email?.toLowerCase();
      setSpinner(true);
      const newProtocol = window?.location?.protocol;
      const newHost = window?.location?.host;
      const host = `${newProtocol}//${newHost}`;

      const res = await fetch(`${API_PATH.EXPERT_FORGOT_PASSWORD}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: email }),
      });

      const data = await res.json();
      if (data) {
        setSpinner(true);
        if (data?.status === 200 || data?.status === true) {
          setEmail("");
          localStorage.removeItem("expertData");
          localStorage.removeItem("isExpertAuth");
          setTimeout(() => {
            history("/expert-login");
          }, 2000);
          notification.success({
            message: "An email has been sent to registered email ID.",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        } else {
          setEmail("");
          setSpinner(false);
          setError(data?.message);
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setSpinner(false);
      setError(error?.response?.data?.message);
    }
  };

  const something = (event) => {
    if (event.keyCode === 13) {
      sendOtpMail(event);
    }
  };

  useEffect(() => {
    document.body.style.overflow = "";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <>
      <ExpertAuth />
      <div className="page-wrapper chiller-theme toggled">
        <div className="main-sec d-lg-flex">
          <Sidebar />
        </div>
        <main className="body-total content-wrapper float-start w-100">
          <div className="container-fluid">
            {/* <div className="d-block d-lg-block">
              <div className="top-bar-menu float-start" id="header">
                <TopMenu2 />
              </div>
            </div> */}

            <div className="dasb-div bk-div float-start w-100">
              <div className="body-expart-div projects-pages support-page">
                <div className="inside-support">
                  <Row className="justify-content-center">
                    <Col md={6}>
                      <h2> Forgot Password</h2>
                    </Col>
                  </Row>
                </div>
                <div className="faq-section mt-4">
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-6">
                      <p>
                        Please enter your registered Email ID and we will have a
                        Password Reset link sent to the same, immediately
                      </p>
                    </div>
                  </div>
                  <div className="row mt-2 d-flex align-items-center justify-content-center py-3">
                    <div className="col-md-6">
                      {error ? <Alert message={error} type="error" /> : null}

                      <Form form={form} layout="vertical">
                        <Form.Item
                          name={["user", "email"]}
                          label="Please enter your registered email"
                          rules={emailRules}
                        >
                          <Input
                            prefix={
                              <UserOutlined className="site-form-item-icon" />
                            }
                            type="text"
                            name="email"
                            autoComplete="none"
                            placeholder="Enter Your E-mail Address"
                            onChange={handleInputs}
                            onKeyDown={(e) => something(e)}
                            size="large"
                            value={getEmail}
                          />
                        </Form.Item>

                        <div className="form-group mt-5 col-md-12 text-center">
                          <div className="d-flex align-items-center justify-content-between">
                            <Form.Item shouldUpdate>
                              {() => (
                                <Button
                                  onClick={sendOtpMail}
                                  className="btn login-btn"
                                  type="primary"
                                  htmlType="submit"
                                  disabled={
                                    !form.isFieldsTouched(true) ||
                                    !!form
                                      .getFieldsError()
                                      .filter(({ errors }) => errors.length)
                                      .length
                                  }
                                >
                                  {spinner ? (
                                    <>
                                      <Spinner
                                        size="sm"
                                        style={{
                                          width: "1rem",
                                          height: "1rem",
                                          marginRight: "5px",
                                        }}
                                      />
                                      Submit
                                    </>
                                  ) : (
                                    "Submit"
                                  )}
                                </Button>
                              )}
                            </Form.Item>
                            <NavLink
                              to={"/expert/change-password"}
                              style={{
                                fontWeight: 500,
                                color: "rgb(87, 70, 236)",
                              }}
                            >
                              Change Password
                            </NavLink>
                          </div>
                        </div>
                      </Form>

                      {/* <div className="centerLine">Or</div>
                      <div className="text-center">
                        <NavLink
                          to={"/expert/change-password"}
                          style={{
                            fontWeight: 500,
                            color: "rgb(87, 70, 236)",
                          }}
                        >
                          Change Password
                        </NavLink>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ForgotPassword;
