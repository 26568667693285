import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { notification } from "antd";
import axios from "axios";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSWR from "swr";
import API_PATH from "../../../../Constants/api-path";
import {
  clearAll,
  setAllCompanyList,
  setClients,
  setExperts,
  setFetchingClients,
  setFetchingExperts,
  setFetchingManagers,
  setFetchingMeetings,
  setManagers,
  setMeetingInfo,
  setMeetingList,
  setMeetingPage,
  setOpenAddMeethingModel,
  setSearch,
  setSelectedMeeting,
  setSelectedMeetingDetails,
  setSortBy,
  setSortType,
  setTranscriptText,
  setZoomExperts,
} from "../Slice/internalMeeting.slice";
import { useOnceCall } from "./useOnceCall";

function useCreateMeeting() {
  const dispatch = useDispatch();
  const {
    clients,
    experts,
    zoomExperts,
    managers,
    formvalues,
    meetingList,
    selectedMeeting,
    selectedMeetingDetails,
    companyList, meeting_page,
    sortBy, sortType, search
  } = useSelector((state) => state.internalmeeting);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [loadingTranscript, setLoadingTranscript] = useState(false);
  const [loadingTranscriptDraft, setLoadingTranscriptDraft] = useState(false);
  const expertList = useSWR("expert_list", () => getExperts({ search: "" }), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    onSuccess: (data) => {
      dispatch(setExperts(filterDuplicates([...data?.data])));
    },
    onError: (error) => {
      console.error("EXPERT ERROR", error);
    },
  });
  const zoomExpertList = useSWR("zoom_expert_list", () => getZoomExperts({ search: "" }), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    onSuccess: (data) => {
      dispatch(setZoomExperts(filterDuplicates([...data?.data])));
    },
    onError: (error) => {
      console.error("EXPERT ERROR", error);
    },
  });
  const clientList = useSWR("client_user_list", () => getAllClients({ search: "" }), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    onSuccess: (data) => {
      dispatch(setClients(filterDuplicates([...data?.data])));
    },
    onError: (error) => {
      console.error("EXPERT ERROR", error);
    },
  });

  useOnceCall(() => {
    dispatch(setSortBy(""))
    dispatch(setSortType(""))
    dispatch(setSearch(""))
    dispatch(setMeetingPage(1))
  })
  const { mutate, isLoading, isValidating } = useSWR(
    selectedMeeting?.meetingId ? "meeting_by_id" : null,
    () => getMeetingById(),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onSuccess: (data) => {
        let obj = {
          ...data?.data,
          expert_company: data?.data?.meetingsData?.[0]?.expert_company,
          expert_designation: data?.data?.meetingsData?.[0]?.expert_designation,
          topic: replaceTxt(data?.data?.topic, [
            "nextyn",
            "micro",
            "consulting",
            "-consulting",
            "call - ",
            "call",
            "-",
          ])?.trim(),
        };
        dispatch(setSelectedMeetingDetails(obj));
        let text = data?.data?.modified_transcript
          ? data?.data?.modified_transcript
          : data?.data?.assembly_response.utterances;
        dispatch(setTranscriptText(text));
      },
      onError: (error) => {
        console.error("EXPERT ERROR", error);
      },
    }
  );
  const managerList = useSWR(
    "manager_list",
    () => getManagers({ search: "" }),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onSuccess: (data) => {
        dispatch(setManagers(data?.data));
      },
      onError: (error) => {
        console.error("MANAGER ERROR", error);
      },
    }
  );
  const internalMeetingList = useSWR(
    "internal_meeting_list",
    () => getMeetings({ search_key: "", page_no: meeting_page }),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onSuccess: (data) => {
        let obj = { data: data?.data, count: data?.countData };
        dispatch(setMeetingList(obj));
      },
      onError: (error) => {
        console.error("MEETING ERROR", error);
      },
    }
  );
  const companyListForMeeting = useSWR(
    "get_company_list_for_meeting",
    () => getCompanyList({ search: "" }),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      onSuccess: (data) => {
        dispatch(setAllCompanyList(filterDuplicates([...data?.data])));
      },
      onError: (error) => {
        console.error("COMPANY LIST ERROR", error);
      },
    }
  );

  function filterDuplicates(array) {
    return Array.from(new Set(array));
  }
  const getAllClients = useCallback(
    async ({ search = "", page_no = 1, page_limit = 10 }) => {
      const response = await axios.get(
        `${API_PATH.ALL_CLIENT_LIST}?page=${page_no}&limit=${page_limit}${search !== "" ? `&key=${search}` : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );
      return response.data;
    },
    [userData?.token]
  );
  const getExperts = useCallback(
    async ({ search = "", page_no = 1, page_limit = 10 }) => {
      try {
        const response = await axios.get(
          `${API_PATH.EXPERT_LIST}?page=${page_no}&limit=${page_limit}${search !== "" ? `&key=${search}` : ""
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error('error: ', error);
      }

    },
    [userData?.token]
  );
  const getZoomExperts = useCallback(
    async ({ search = "", page_no = 1, page_limit = 10 }) => {
      try {
        const response = await axios.get(
          `${API_PATH.ZOOM_EXPERT_LIST}?page=${page_no}&limit=${page_limit}${search !== "" ? `&key=${search}` : ""
          }`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error('error: ', error);
      }

    },
    [userData?.token]
  );

  const getManagers = useCallback(
    async ({ search = "", page_no = 1, page_limit = 10 }) => {
      const response = await axios.get(
        `${API_PATH.MANAGER_LIST}?page=${page_no}&limit=${page_limit}${search !== "" ? `&key=${search}` : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );
      return response.data;
    },
    [userData?.token]
  );

  const getMeetings = useCallback(
    async ({ search_key = search, page_no = 1, page_limit = 10, sort_By = sortBy, sort_Type = sortType }) => {
      const response = await axios.get(
        `${API_PATH.INTERNAL_MEETING}?page=${page_no}&limit=${page_limit}${search_key !== "" ? `&key=${search_key}` : ""
        }${sort_By !== "" ? `&sortBy=${sort_By}` : ""}${sort_Type !== "" ? `&sortType=${sort_Type}` : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );
      return response.data;
    },
    [search, sortBy, sortType, userData?.token]
  );
  const getCompanyList = useCallback(
    async ({ search = "", page_no = 1, page_limit = 10 }) => {
      const response = await axios.get(
        `${API_PATH.GET_COMPANIES_LIST_FOR_MEETING
        }?page=${page_no}&limit=${page_limit}${search !== "" ? `&key=${search}` : ""
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );
      return response.data;
    },
    [userData?.token]
  );

  const getMeetingById = useCallback(async () => {
    const response = await axios.get(
      `${API_PATH.GET_MEETING_BY_ID}?meetings_id=${selectedMeeting?.meetingId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      }
    );
    return response.data;
  }, [userData?.token, selectedMeeting?.meetingId]);

  //REFETCH
  const fetchMeetings = useCallback(
    async ({
      is_refetching = false,
      is_filtering = false,
      value = search,
      page_no = meeting_page,
      page_limit = 10,
      sort_By = sortBy,
      sort_Type = sortType
    }) => {
      if (is_filtering) {
        dispatch(setFetchingMeetings(true));
        dispatch(setSearch(value))
        dispatch(setMeetingPage(1));
        let response = await getMeetings({ search_key: value, page_no: 1 });
        let obj = { data: response?.data, count: response?.countData };
        dispatch(setMeetingList(obj));
        dispatch(setFetchingMeetings(false));
      } else if (is_refetching) {
        dispatch(setFetchingMeetings(true));
        dispatch(setSearch(""))
        let response = await getMeetings({
          search_key: value,
          page_no: page_no,
          page_limit: page_limit,
        });
        let obj = { data: response?.data, count: response?.countData };
        dispatch(setMeetingList(obj));
        dispatch(setFetchingMeetings(false));
      } else {
        dispatch(setMeetingPage(page_no));
        dispatch(setSortBy(sort_By))
        dispatch(setSortType(sort_Type))
        let response = await getMeetings({ search_key: value, page_no: page_no, sort_By: sort_By, sort_Type: sort_Type });
        let obj = { data: response?.data, count: response?.countData };
        dispatch(setMeetingList(obj));

      }
    },
    [search, meeting_page, sortBy, sortType, dispatch, getMeetings]
  );

  const handleDeleteMeeting = useCallback(async () => {
    const response = await axios
      .delete(
        `${API_PATH.INTERNAL_MEETING}?meetings_id=${selectedMeeting?.meetingId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      )
      .then((res) => {
        setTimeout(() => {
          fetchMeetings({ is_refetching: true });
          notification.success({
            message: "Meeting Deleted Successfully",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }, 100);
      })
      .catch((res) => {
        setTimeout(() => {
          notification.error({
            message: "Error While Deleting Meeting",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }, 100);
      });
  }, [userData?.token, selectedMeeting?.meetingId, fetchMeetings]);

  const addMeeting = useCallback(async () => {
    let body = {
      zoomEmail: formvalues.zoomEmail?.[0].label,
      meetingId: formvalues.meetingId,
      call_taken_by: formvalues.call_taken_by.map((val) => val.key),
      expert_id: formvalues.expert_id?.[0].value,
      project_title: formvalues.project_title,
      // company_id: formvalues.company_select.map((val) => val.key),
    };

    await axios
      .post(API_PATH.INTERNAL_MEETING, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      })
      .then((res) => {
        fetchMeetings({ is_refetching: true });
        dispatch(clearAll());
        dispatch(setOpenAddMeethingModel(false));
        dispatch(setSelectedMeeting({}));
      })
      .catch((err) => {
        console.error(err?.response?.data?.msg);
        dispatch(clearAll());
        dispatch(setSelectedMeeting({}));
        setTimeout(() => {
          notification.error({
            message: err?.response?.data?.msg,
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }, 100);
      });
  }, [formvalues, userData?.token, dispatch, fetchMeetings]);

  const updateMeeting = useCallback(async () => {
    let body = {
      zoomEmail: formvalues.zoomEmail?.[0].label,
      meetingId: formvalues.meetingId,
      call_taken_by: formvalues.call_taken_by.map((val) => val.key),
      expert_id: formvalues.expert_id?.[0].value,
      project_title: formvalues.project_title,
      cloud_zoom_meetings_id: formvalues.cloud_zoom_meetings_id,
      // company_id: formvalues.company_select.map((val) => val.key), //REMOVE AS SIR SAID
    };

    await axios
      .post(API_PATH.INTERNAL_MEETING, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      })
      .then((res) => {
        fetchMeetings({ is_refetching: true });
        dispatch(clearAll());
        dispatch(setSelectedMeeting({}));
        dispatch(setOpenAddMeethingModel(false));
      })
      .catch((err) => {
        dispatch(setSelectedMeeting({}));
        console.error(err);
        setTimeout(() => {
          notification.error({
            message: "Meeting Already Exists",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }, 100);
      });
  }, [formvalues, userData?.token, dispatch, fetchMeetings]);

  const updateTranscript = useCallback(
    async (id = 0) => {
      if (id === 0) {
        setLoadingTranscriptDraft(true);
      } else {
        setLoadingTranscript(true);
      }
      let body = {
        transcript: selectedMeetingDetails.modified_transcript
          ? selectedMeetingDetails.modified_transcript
          : selectedMeetingDetails.transcript,
        topic: selectedMeetingDetails?.topic,
        meetingId: selectedMeetingDetails?.meetingId,
        status: id,
        keyword: selectedMeetingDetails?.keyword,
        brand: selectedMeetingDetails?.brand,
        expert_designation: selectedMeetingDetails?.expert_designation,
        expert_company: selectedMeetingDetails?.expert_company,
        country: selectedMeetingDetails?.country,
        company_id: formvalues.company_select.map((val) => val.key), //ADDED IN TRANSCRIPT
        industry: selectedMeetingDetails?.industry
      };

      await axios
        .post(API_PATH.UPDATE_TRANSCRIPT, body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        })
        .then((res) => {
          dispatch(clearAll());
          dispatch(setOpenAddMeethingModel(false));
          dispatch(setTranscriptText(""));
          dispatch(setSelectedMeeting({}));
          dispatch(setMeetingInfo(false));
          setLoadingTranscript(false);
          setLoadingTranscriptDraft(false);
          dispatch(setSelectedMeetingDetails({}));
          fetchMeetings({ is_refetching: true });

          setTimeout(() => {
            notification.success({
              message: "Transcript Updated Successfully",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          }, 100);
        })
        .catch((err) => {
          dispatch(setMeetingInfo(false));
          setLoadingTranscript(false);
          setLoadingTranscriptDraft(false);
          setTimeout(() => {
            notification.error({
              message: "Error While Updating Transcript!",
              icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#e74c3c",
                color: "#fff",
                border: "1px solid #c0392b",
              },
              duration: 5,
              placement: "topRight",
            });
          }, 100);
        });
    },
    [selectedMeetingDetails.modified_transcript, selectedMeetingDetails.transcript, selectedMeetingDetails?.topic, selectedMeetingDetails?.meetingId, selectedMeetingDetails?.keyword, selectedMeetingDetails?.brand, selectedMeetingDetails?.expert_designation, selectedMeetingDetails?.expert_company, selectedMeetingDetails?.country, selectedMeetingDetails?.industry, formvalues.company_select, userData?.token, dispatch, fetchMeetings]
  );

  //REFETCH
  const fetchClients = useCallback(
    async ({ is_filtering = false, value = "" }) => {
      if (is_filtering) {
        dispatch(setFetchingClients(true));
        let response = await getAllClients({ search: value });
        dispatch(setClients(filterDuplicates([...response?.data])));
        dispatch(setFetchingClients(false));
      } else {
        let response = await getAllClients({ search: value });
        dispatch(setClients(filterDuplicates([...response?.data])));
      }
    },
    [dispatch, getAllClients]
  );
  const fetchExperts = useCallback(
    async ({ is_filtering = false, value = "" }) => {
      if (is_filtering) {
        dispatch(setFetchingExperts(true));
        let response = await getExperts({ search: value });
        dispatch(setExperts(filterDuplicates([...response?.data])));
        dispatch(setFetchingExperts(false));
        return response
      } else {
        let response = await getExperts({ search: value });
        dispatch(setExperts(filterDuplicates([...response?.data])));
        return response
      }
    },
    [getExperts, dispatch]
  );
  const fetchZoomExperts = useCallback(
    async ({ is_filtering = false, value = "" }) => {
      if (is_filtering) {
        dispatch(setFetchingExperts(true));
        let response = await getZoomExperts({ search: value });
        dispatch(setZoomExperts(filterDuplicates([...response?.data])));
        dispatch(setFetchingExperts(false));
        return response
      } else {
        let response = await getZoomExperts({ search: value });
        dispatch(setZoomExperts(filterDuplicates([...response?.data])));
        return response
      }
    },
    [dispatch, getZoomExperts]
  );
  const fetchCompanies = useCallback(
    async ({ is_filtering = false, value = "" }) => {
      if (is_filtering) {
        dispatch(setFetchingExperts(true));
        let response = await getCompanyList({ search: value });
        dispatch(setAllCompanyList(filterDuplicates([...response?.data])));
        dispatch(setFetchingExperts(false));
      } else {
        let response = await getCompanyList({ search: value });
        dispatch(setAllCompanyList(filterDuplicates([...response?.data])));
      }
    },
    [getCompanyList, dispatch]
  );

  //REFETCH
  const fetchManagers = useCallback(
    async ({ is_filtering = false, value = "" }) => {
      if (is_filtering) {
        dispatch(setFetchingManagers(true));
        let response = await getManagers({ search: value });
        dispatch(setManagers(response?.data));
        dispatch(setFetchingManagers(false));
      } else {
        let response = await getManagers({ search: value });
        dispatch(setManagers(response?.data));
      }
    },
    [getManagers, dispatch]
  );

  const replaceTxt = useCallback((txt, para) => {
    txt = txt?.toLowerCase();
    for (var i = 0; i < para.length; i++) {
      txt = txt?.replace(para[i], "");
    }
    return txt;
  }, []);

  const capitalizeFirstLetter = useCallback((string = "") => {
    return string?.trim()?.charAt(0).toUpperCase() + string?.trim()?.slice(1);
  }, []);

  const values = useMemo(
    () => ({
      getExperts: getExperts,
      fetchManagers: fetchManagers,
      experts: experts,
      zoomExperts: zoomExperts,
      clients: clients,
      managers: managers,
      fetchExperts: fetchExperts,
      fetchZoomExperts: fetchZoomExperts,
      companyList: companyList,
      fetchCompanies: fetchCompanies,
      addMeeting: addMeeting,
      updateMeeting: updateMeeting,
      meetingList: meetingList,
      fetchMeetings: fetchMeetings,
      replaceTxt: replaceTxt,
      getMeetingById: getMeetingById,
      handleDeleteMeeting: handleDeleteMeeting,
      updateTranscript: updateTranscript,
      loadingTranscript: loadingTranscript,
      loadingTranscriptDraft: loadingTranscriptDraft,
      capitalizeFirstLetter: capitalizeFirstLetter,
      getCompanyList: getCompanyList,
      mutate: mutate,
      isLoading: isLoading,
      isValidating: isValidating,
      fetchClients: fetchClients
    }),
    [getExperts, fetchManagers, experts, zoomExperts, clients, managers, fetchExperts, fetchZoomExperts, companyList, fetchCompanies, addMeeting, updateMeeting, meetingList, fetchMeetings, replaceTxt, getMeetingById, handleDeleteMeeting, updateTranscript, loadingTranscript, loadingTranscriptDraft, capitalizeFirstLetter, getCompanyList, mutate, isLoading, isValidating, fetchClients]
  );
  return values;
}

export default useCreateMeeting;
