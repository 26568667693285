import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Divider, Modal, notification } from "antd";
import React from "react";
import API_PATH from "../../Constants/api-path";


function DeleteModal({ show, setShow, type, teamId, GetLiveProjectList = () => { }, GetCompleteProjectList = () => { } }) {
  const userData = JSON.parse(localStorage.getItem("userData"));



  const DeleteManager = async () => {
    // e.preventDefault();
    try {
      const res = await fetch(`${API_PATH.TEAM_PROJECT_ADD}/${teamId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },

      });

      const result = await res.json();
      if (result) {
        if (res.status === 200 || res.status === true) {
          GetLiveProjectList();
          GetCompleteProjectList();
          setShow(false)
          notification.success({
            message: "Project has been deleted",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      }
    } catch (error) {
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
  };

  return (
    <Modal
      title={
        <div>
          Delete {type} <Divider className="m-2" />
        </div>
      }
      open={show}
      onCancel={() => setShow(false)}
      centered
      size={"lg"}
      scrollable={true}
      footer={false}
    >


      <div>
        Are you sure you want to delete this{" "}
        {type.toLowerCase()} ?
      </div>
      <div className="d-flex justify-content-end mt-3">
        <button className="secondaryBttn btn" onClick={() => setShow(false)}>
          No
        </button>
        <button className="viewButton btn ms-2" onClick={() => DeleteManager()}>Yes</button>
      </div>

    </Modal>
  );
}

export default DeleteModal;
