import React, { createContext, useState } from 'react';
import API_PATH from "../Constants/api-path";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { notification } from "antd"
import useDecodedToken from '../Utils/useDecodedToken';

const ApiContext = createContext();

const ApiProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(false);
    const [read, setRead] = useState(false);
    const decodedToken = useDecodedToken()
    const userData = JSON.parse(localStorage.getItem("userData"));
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userData?.token}`,
    };

    // useEffect(() => {
    //     getData();
    //     const interval = setInterval(() => {
    //         getData();
    //     }, 3000);
    //     return () => clearInterval(interval);
    // }, []);

    const getData = async (prop) => {
        try {
            if (prop) {
                const res = await fetch(
                    // `${API_PATH.NOTIFICATION_LIST}?limit=50&page=1&notification_for=admin&admin_id=${prop?.data?._id}`,
                    `${API_PATH.NOTIFICATION_LIST}?limit=50&page=1&notification_for=admin&admin_id=${decodedToken?.admin_id || prop?.data?._id}`,
                    {
                        method: "GET",
                        headers: headers,
                    }
                );

                const result = await res.json();
                if (result) {
                    setNotifications(result);
                    setLoading(true);
                    setRead(true);
                } else {
                    notification.error({
                        message: "Error: Something went wrong server error",
                        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                        style: {
                            backgroundColor: "#e74c3c",
                            color: "#fff",
                            border: "1px solid #c0392b",
                        },
                        duration: 5,
                        placement: "topRight",
                    });
                }

            } else {
                if (userData) {
                    const res = await fetch(
                        `${API_PATH.NOTIFICATION_LIST}?limit=50&page=1&notification_for=admin&admin_id=${userData?.data?._id}`,
                        {
                            method: "GET",
                            headers: headers,
                        }
                    );

                    const result = await res.json();
                    if (result) {
                        setNotifications(result);
                        setLoading(true);
                        setRead(true);
                    } else {
                        notification.error({
                            message: "Error: Something went wrong server error",
                            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                            style: {
                                backgroundColor: "#e74c3c",
                                color: "#fff",
                                border: "1px solid #c0392b",
                            },
                            duration: 5,
                            placement: "topRight",
                        });
                    }
                } else {
                    console.info("userData not found");
                }
            }
        } catch (error) {
            console.error(error);
            // return toast.error("something went wrong server error", {
            //   position: toast.POSITION.TOP_RIGHT,
            //   autoClose: 3000,
            // });
        }
    };

    return (
        <ApiContext.Provider value={{ notifications, getData, setNotifications, loading, setLoading, read, setRead }}>
            {children}
        </ApiContext.Provider>
    );
};

export { ApiContext, ApiProvider };
