import {
  CheckCircleOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Pagination,
  Select,
  Switch,
  Table,
  Tag,
  Tooltip,
} from "antd";
import axios from "axios";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BiTransfer } from "react-icons/bi";
import { FaEye } from "react-icons/fa6";
import { IoMdPersonAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import "react-phone-input-2/lib/style.css";
import { useLocation } from "react-router-dom";
import ConfirmationModal from "../../components/ConfirmationModal";
import API_PATH from "../../Constants/api-path";
import AddUserModal from "./AddUserModal";
import CreateClientModal from "./CreateClientModal";
import UpdateClientModal from "./UpdateClientModal";
import UpdateUserModal from "./UpdateUserModal";

const MyFormItemContext = React.createContext([]);
function toArr(str) {
  return Array.isArray(str) ? str : [str];
}
const MyFormItemGroup = ({ prefix, children }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(
    () => [...prefixPath, ...toArr(prefix)],
    [prefixPath, prefix]
  );
  return (
    <MyFormItemContext.Provider value={concatPath}>
      {children}
    </MyFormItemContext.Provider>
  );
};
const MyFormItem = ({ name, ...props }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName =
    name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
  return <Form.Item name={concatName} {...props} />;
};

const ClientTable = () => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modal3Open, setModal3Open] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [clientView, setClientView] = useState([]);
  const [viewId, setViewId] = useState("");
  const [showCreateModal, setCreateModal] = useState(false);
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [userUpdateModal, setUserUpdateModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [callPage, setCallPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewCompany, setViewCompany] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    GetClientList(pageNumber, selectedOptions);
  };

  const [companyId, setCompanyId] = useState("");
  const [price, setPrice] = useState({
    expertRate: "",
    currency: [],
    show_price: false,
  });
  const [expertId, setExpertId] = useState("");
  const [filteredClientList, setFilteredClientList] = useState([]);

  const paginationSettings = {
    current: currentPage,
    onChange: handlePageChange,
    pageSize: 10,
    total: callPage,
  };

  const GetClientList = useCallback(
    async (page = 1, selectedOptions) => {
      setLoading(true);
      try {
        let object = {};

        if (selectedOptions?.key) {
          object.key = selectedOptions?.key;
        }
        const res = await fetch(
          `${API_PATH.CLIENT_LIST}?page=${page}&limit=10&key=${selectedOptions?.key || ""
          }`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userData?.token}`,
            },
          }
        );

        const result = await res.json();
        if (result) {
          setClientList(result?.data);
          const filteredData = result?.data?.filter((record) =>
            Object.values(record)
              .join("")
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );
          setFilteredClientList(filteredData);
          setCallPage(result?.countData);
        } else {
          notification.error({
            message: "Error: Something went wrong server error",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } catch (error) {
        setLoading(false);
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
      setLoading(false);
    },
    [searchText, userData?.token]
  );

  const UpdatePrice = async () => {
    // e.preventDefault();
    setLoading(true);

    try {
      const res = await fetch(`${API_PATH.CLIENT_UPDATE_PRICE}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          ...price,
          company_id: companyId,
        }),
      });

      const result = await res.json();
      if (result) {
        GetClientList();
        if (res.status === 200 || res.status === true) {
          notification.success({
            message: "Price has been updated",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });

          // window.location.reload();
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };
  const moveToMynt = async () => {
    setLoading(true);
    const body = {
      registration_type:
        selectedClient?.registrationType === "client" ? "cloud" : "client",
    };
    try {
      const res = await axios.put(
        `${API_PATH.MOVE_CLIENT_TO_MYNT}/${selectedClient?._id}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      if (res?.data?.status) {
        GetClientList();
        setSelectedClient({});
        if (res.status === 200 || res.status === true) {
          notification.success({
            message: `Client updated successfully!`,
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } else {
        notification.error({
          message: "Error while moving Client",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const DeleteExpertID = (_id) => {
    setExpertId(_id);
  };

  const DeleteExpert = async () => {
    setLoading(true);

    try {
      const res = await fetch(`${API_PATH.CLIENT_DELETE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          client_id: [expertId],
        }),
      });

      const result = await res.json();
      if (result) {
        if (res.status === 200 || res.status === true) {
          viewClientModal();
          GetClientList();

          notification.success({
            message: "User has been deleted",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const viewClientModal = React.useCallback(async (id = null) => {
    setLoading(true);
    let vid = id === null ? viewId : id;
    let reslt = clientList.find((item) => item._id === vid);
    setViewCompany(reslt?.companyName);
    try {
      const res = await fetch(`${API_PATH.CLIENT_VIEW}/${vid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setClientView(result?.data);
        setCompanyId(result?.data[0]?.company_id);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
      console.error("something went wrong server error");
    }
    setLoading(false);
  }, [clientList, userData?.token, viewId]);

  const getClientById = React.useCallback(async (id = null) => {
    try {
      const res = await fetch(`${API_PATH.CLIENT_BY_ID}/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setSelectedClient(result?.data?.[0])
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
      console.error("something went wrong server error");
    }
    setLoading(false);
  }, [userData?.token]);


  const getMangerId = ({ data, id }) => {
    setViewId(id);
    const res = data?.find((val) => val._id === id);
    setPrice({
      expertRate: res?.expertRate,
      currency: res?.currency ? res?.currency : [],
      show_price: res?.show_price,
    });
  };

  const callOnce = useRef(false);
  useEffect(() => {
    if (!callOnce.current) {
      callOnce.current = true;
      // GetRoleList();
      GetClientList(currentPage, selectedOptions);
    }
  }, [GetClientList, currentPage, selectedOptions]);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery()
  let client_id = query.get("client_id")
  let autoView = query.get("view")
  const onlyOnce = useRef(false)
  useEffect(() => {
    const getData = async () => {
      await getClientById(client_id)
      await viewClientModal(client_id);
      setModal1Open(true);
    }
    if (autoView && client_id && !onlyOnce.current) {
      onlyOnce.current = true
      getData()
    }
  }, [autoView, client_id, filteredClientList, getClientById, selectedClient, viewClientModal])


  const columns = [
    {
      title: "S.NO.",
      key: "#",
      align: "center",
      render: (text, record, rowIndex) =>
        (currentPage - 1) * 10 + (rowIndex + 1),
      onCell: (record) => {
        return {
          onClick: async (event) => {
            getMangerId({ data: filteredClientList, id: record?._id });
            await viewClientModal(record?._id);
            setSelectedClient(record);
            setModal1Open(true);
          },
        };
      },
    },
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
      render: (_id, record) => (
        <div className="">
          <div>{record?.name || "-"}</div>
          <Tag color={"purple"} className="cust_tag">
            {record?.domain || "-"}
          </Tag>
        </div>
      ),
      onCell: (record) => {
        return {
          onClick: async (event) => {
            getMangerId({ data: filteredClientList, id: record?._id });
            await viewClientModal(record?._id);
            setSelectedClient(record);
            setModal1Open(true);
          },
        };
      },
    },
    {
      title: "Team",
      dataIndex: "team",
      key: "team",
      render: (_id, record) => (
        <div>
          <div style={{ fontSize: "14px" }}>
            <Tooltip title={"BD Owner"} color="#5746EB">
              <span style={{ color: "#2F539B" }}>
                {record?.bd_owner?.[0]?.name || "-"}
              </span>
              {/* <Tag className="cs_blue"></Tag> */}
            </Tooltip>
          </div>
          <div style={{ fontSize: "14px" }}>
            <Tooltip title={"CS Owner"} color="#5746EB">
              <span style={{ color: "#2F539B" }}>
                {record?.cs_owner?.[0]?.name || "-"}
              </span>
              {/* <Tag className="cs_purple"></Tag> */}
            </Tooltip>
          </div>
        </div>
      ),
    },
    {
      title: "Client Type",
      dataIndex: "client_type",
      key: "client_type",
      align: "left",
      render: (text, record) => {
        return record?.client_type ? (
          <Tag
            className={
              record?.client_type?.slice(0, -1) === "Corporate"
                ? "cs_blue"
                : record?.client_type?.slice(0, -1) === "Investment Firm"
                  ? "cs_green"
                  : "cs_red"
            }
          >
            {" "}
            {record?.client_type?.slice(0, -1)}
          </Tag>
        ) : (
          "-"
        );
      },
      onCell: (record) => {
        return {
          onClick: async (event) => {
            getMangerId({ data: filteredClientList, id: record?._id });
            await viewClientModal(record?._id);
            setSelectedClient(record);
            setModal1Open(true);
          },
        };
      },
    },
    {
      title: "Client Users",
      dataIndex: "team_count",
      align: "center",
      key: "team_count",
      onCell: (record) => {
        return {
          onClick: async (event) => {
            getMangerId({ data: filteredClientList, id: record?._id });
            await viewClientModal(record?._id);
            setSelectedClient(record);
            setModal1Open(true);
          },
        };
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      align: "center",
      render: (_id, record) => (
        <div className="d-flex justify-content-center expertActions align-items-center gap-1">
          {userData?.data?.roleData?.[0]?.role_permission?.[1]?.client
            ?.edit && (
              <Tooltip
                placement="top"
                title={
                  !userData?.data?.roleData?.[0]?.role_permission?.[1]?.client
                    ?.edit
                    ? "Permission Denied"
                    : "Edit Client"
                }
                color="#5746EB"
              >
                <span>
                  <button
                    size="large"
                    className="viewBttnExpert"
                    disabled={
                      !userData?.data?.roleData?.[0]?.role_permission?.[1]?.client
                        ?.edit
                    }
                    onClick={() => {
                      setSelectedClient(record);
                      setUpdateModal(true);
                    }}
                  >
                    <EditOutlined />
                  </button>
                </span>
              </Tooltip>
            )}
          {userData?.data?.roleData?.[0]?.role_permission?.[1]?.client
            ?.view && (
              <Tooltip
                placement="top"
                title={
                  !userData?.data?.roleData?.[0]?.role_permission?.[1]?.client
                    ?.view
                    ? "Permission Denied"
                    : "View Profile"
                }
                color="#5746EB"
              >
                <span>
                  <button
                    size="large"
                    className="viewBttnExpert"
                    disabled={
                      !userData?.data?.roleData?.[0]?.role_permission?.[1]?.client
                        ?.view
                    }
                    onClick={async () => {
                      getMangerId({ data: filteredClientList, id: _id });
                      setSelectedClient(record);
                      await viewClientModal(_id);
                      setModal1Open(true);
                    }}
                  >
                    <FaEye />
                  </button>
                </span>
              </Tooltip>
            )}
          {userData?.data?.roleData?.[0]?.role_permission?.[1]?.client
            ?.move && (
              <Tooltip
                placement="top"
                title={
                  !userData?.data?.roleData?.[0]?.role_permission?.[1]?.client
                    ?.move
                    ? "Permission Denied"
                    : record?.registrationType === "client"
                      ? "Move to Nextyn IQ"
                      : "Move to Client"
                }
                color="#5746EB"
              >
                <span>
                  <button
                    size="large"
                    className="viewBttnExpert"
                    disabled={
                      !userData?.data?.roleData?.[0]?.role_permission?.[1]?.client
                        ?.move
                    }
                    onClick={() => {
                      getMangerId({ data: filteredClientList, id: _id });
                      setOpen(true);
                      setSelectedClient(record);
                    }}
                  >
                    <BiTransfer />
                  </button>
                </span>
              </Tooltip>
            )}
          {userData?.data?.roleData?.[0]?.role_permission?.[1]?.client?.add && (
            <Tooltip placement="top" title={"Add New User"} color="#5746EB">
              <span>
                <button
                  size="large"
                  className="viewBttnExpert"
                  disabled={
                    !userData?.data?.roleData?.[0]?.role_permission?.[1]?.client
                      ?.add
                  }
                  onClick={async () => {
                    await viewClientModal(record?._id);
                    setUserModal(true);
                  }}
                >
                  <IoMdPersonAdd />
                </button>
              </span>
            </Tooltip>
          )}

          {record?.team_count > 0 ? (
            <Tooltip
              placement="top"
              title={
                !userData?.data?.roleData?.[0]?.role_permission?.[1]?.client
                  ?.edit
                  ? "Permission Denied"
                  : price?.expertRate !== "" && price?.currency?.length > 0
                    ? "Edit Price"
                    : "Add Price"
              }
              color="#5746EB"
            >
              <span>
                <button
                  size="large"
                  className="viewBttnExpert"
                  disabled={
                    !userData?.data?.roleData?.[0]?.role_permission?.[1]?.client
                      ?.edit
                  }
                  onClick={() => {
                    getMangerId({ data: filteredClientList, id: _id });
                    setModal2Open(true);
                  }}
                >
                  {price?.expertRate !== "" && price?.currency?.length > 0 ? (
                    <>
                      <EditOutlined />
                    </>
                  ) : (
                    <>
                      <PlusOutlined />
                    </>
                  )}
                </button>
              </span>
            </Tooltip>
          ) : null}
        </div>
      ),
    },
  ];

  const columns10 = [
    {
      title: "S.No.",
      dataIndex: "1.",
      key: "1.",
      render: (text, record, rowIndex) => rowIndex + 1,
      width: "50px",
    },
    {
      title: "Image",
      dataIndex: "name",
      key: "name",
      width: "40px",
      render: (name) => (
        <>
          <Avatar alt={name}>{name?.charAt(0)?.toUpperCase()}</Avatar>
        </>
      ),
    },
    {
      title: "Name",
      dataIndex: ["firstNmae", "lastName"],
      key: "name",
      render: (text, record) => (
        <>
          <Tooltip title={record?.name} color="#5746ec">
            {_.truncate(record?.name, { length: 20, omission: "..." })}
          </Tooltip>
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => (
        <Tooltip title={record?.email} color="#5746ec">
          {_.truncate(record?.email, { length: 35, omission: "..." })}
        </Tooltip>
      ),
    },
    {
      title: "Phone",
      dataIndex: "name",
      key: "name",
      render: (text, record) =>
        record?.contact_code && record?.contact_number
          ? !record?.contact_code?.includes("+")
            ? `+${record?.contact_code} ${record?.contact_number}`
            : `${record?.contact_code} ${record?.contact_number}`
          : "-",
    },

    {
      title: "Action",
      dataIndex: "_id",
      key: "action",
      render: (_id, record) => (
        <div className="d-flex expertActions align-items-center gap-1">
          <Tooltip placement="top" title={"Edit User"} color="#5746EB">
            <span>
              <button
                size="large"
                className="viewBttnExpert"
                disabled={
                  !userData?.data?.roleData?.[0]?.role_permission?.[1]?.client
                    ?.edit
                }
                onClick={async () => {
                  setUserUpdateModal(true);
                  setSelectedUser(record);
                }}
              >
                <EditOutlined />
              </button>
            </span>
          </Tooltip>

          <Tooltip
            placement="top"
            title={
              !userData?.data?.roleData?.[0]?.role_permission?.[1]?.client
                ?.delete === true
                ? "Permission Denied"
                : "Delete"
            }
            color="#5746EB"
          >
            <span>
              <button
                size="large"
                className="viewBttnExpert"
                disabled={
                  !userData?.data?.roleData?.[0]?.role_permission?.[1]?.client
                    ?.delete === true
                }
                onClick={() => {
                  setModal3Open(true);
                  DeleteExpertID(_id);
                }}
              >
                <MdDelete />
              </button>
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleInputs = useCallback(
    (value) => {
      const selectValue = value;
      setPrice({ ...price, expertRate: selectValue });
    },
    [price]
  );

  const handleChange = useCallback((values) => {
    setPrice((prevPrice) => ({
      ...prevPrice,
      currency: values, // Store the selected currencies as an array
    }));
  }, []);

  const handleSwitchChange = useCallback(
    (checked) => {
      const selectValue = checked;
      setPrice({ ...price, show_price: selectValue });
    },
    [price]
  );

  const footer = (
    <div>
      <button
        className="secondaryBttn btn"
        key="cancel"
        onClick={() => setModal3Open(false)}
      >
        No
      </button>
      <button
        key="submit"
        className="viewButton btn ms-2"
        onClick={() => {
          DeleteExpert();
          setModal3Open(false);
        }}
      >
        Yes
      </button>
    </div>
  );

  const handleInputChange = useCallback((e) => {
    const inputValue = e.target.value;
    setSelectedOptions({
      key: inputValue,
    });
    callSearch.current = true;
    setSearchText(inputValue);
    setCurrentPage(1);
  }, []);

  let callSearch = useRef(false);
  useEffect(() => {
    if (callOnce.current && callSearch.current) {
      let handler = setTimeout(async () => {
        callSearch.current = false;
        GetClientList(1, { key: searchText });
      }, 1000);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [GetClientList, searchText]);

  return (
    <>
      <div>
        <div className="bk-div float-start w-100">
          {" "}
          <div className="body-expart-div">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Clients</h2>
              {userData?.data?.roleData?.[0]?.role_permission?.[1]?.client
                ?.add === true && (
                  <button
                    className="btn viewButton"
                    onClick={() => setCreateModal(true)}
                    size="large"
                  >
                    Create Client
                  </button>
                )}
            </div>

            <Input
              placeholder="Search"
              value={selectedOptions?.key}
              onChange={handleInputChange}
              prefix={<SearchOutlined />}
              style={{ marginBottom: 16 }}
              size="large"
            />
            <Table
              dataSource={filteredClientList}
              columns={columns}
              pagination={false}
              loading={loading}
              scroll={{ x: 800 }}
              size="large"
              onRow={(record) => {
                return {
                  style: {
                    cursor: "pointer",
                  },
                };
              }}
            />
            <Pagination
              {...paginationSettings}
              showSizeChanger={false}
              style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
            />
          </div>
        </div>
      </div>

      <Modal
        title={
          <div>
            Client Details
            <Divider className="m-2" />
          </div>
        }
        open={modal1Open}
        onOk={() => setModal1Open(false)}
        onCancel={() => {
          setModal1Open(false);
          setClientView([]);
        }}
        width={1000}
        footer={null}
        centered
      >
        <div className="mt-3">
          <div className="row m-b-10 ">
            <div className="col-12 col-md-7">
              <p className="d-flex" style={{ fontSize: "12px", color: "#000" }}>
                <strong className=" fw-bold me-1 modalHeader">
                  Company Name:{" "}
                </strong>{" "}
                <strong className=" fw-bold me-2 modalHeader">
                  {" "}
                  {viewCompany}
                </strong>
                {selectedClient?.client_type && (
                  <Tooltip title="Client Type" color="#5746EB">
                    <Tag
                      className={
                        selectedClient?.client_type?.slice(0, -1) ===
                          "Corporate"
                          ? "cs_blue d-flex align-items-center"
                          : selectedClient?.client_type?.slice(0, -1) ===
                            "Investment Firm"
                            ? "cs_green d-flex align-items-center"
                            : "cs_red d-flex align-items-center"
                      }
                    >
                      {" "}
                      {selectedClient?.client_type?.slice(0, -1)}
                    </Tag>
                  </Tooltip>
                )}
              </p>
            </div>

            <div className="col-12 col-md-5 text-end">
              <p style={{ fontSize: "14px", color: "#000" }} className="">
                <span className="px-1">
                  <strong className="col-indigo fw-bold modalHeader">
                    Client Users:
                  </strong>{" "}
                  <span className="fw-bold">{clientView?.length}</span>
                </span>
                <span className="ps-2">
                  <Button
                    style={{
                      color: "#5746EB",
                      borderColor: "#5746EB",
                    }}
                    onClick={async () => {
                      await viewClientModal(selectedClient?._id);
                      setUserModal(true);
                      setModal1Open(false);
                    }}
                  >
                    Add New User
                  </Button>
                </span>
              </p>
            </div>
          </div>
          <div>
            {selectedClient?.bd_owner?.[0]?.name && (
              <Tooltip title="BD Owner" color="#5746EB">
                <Tag className="cs_blue">
                  {" "}
                  {selectedClient?.bd_owner?.[0]?.name}
                </Tag>
              </Tooltip>
            )}
            {selectedClient?.cs_owner?.[0]?.name && (
              <Tooltip title="CS Owner" color="#5746EB">
                <Tag className="cs_red">
                  {" "}
                  {selectedClient?.cs_owner?.[0]?.name}
                </Tag>
              </Tooltip>
            )}
          </div>
          <Divider className="m-2" />
          <Table
            columns={columns10}
            dataSource={clientView}
            pagination={false}
          />
        </div>
      </Modal>

      <Modal
        title={
          <div>
            Price & Currency
            <Divider className="m-2" />
          </div>
        }
        style={{
          top: 20,
        }}
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
        width={800}
        footer={null}
        centered
      >
        <div>
          <Form name="form_item_path" layout="vertical" onFinish={UpdatePrice}>
            <MyFormItemGroup prefix={["user"]}>
              <MyFormItemGroup prefix={["name"]}>
                <MyFormItem label="Customise Price">
                  <InputNumber
                    size="large"
                    type="number"
                    value={price?.expertRate}
                    onChange={handleInputs}
                    style={{
                      width: "100%",
                    }}
                  />
                </MyFormItem>
                <MyFormItem label="Currency ">
                  <Select
                    size="large"
                    name="currency"
                    placeholder="Select Currency"
                    onChange={handleChange}
                    value={price?.currency}
                    options={[
                      {
                        value: "GBP - British Pound Sterling - £",
                        label: "GBP - British Pound Sterling - £",
                      },
                      {
                        value: "EURO - Euro -  £",
                        label: "EURO - Euro -  £",
                      },
                      {
                        value: "INR - Indian Rupee - ₹",
                        label: "INR - Indian Rupee - ₹",
                      },
                      {
                        value: "USD - US Dollar - $",
                        label: "USD - US Dollar - $",
                      },
                    ]}
                  />
                </MyFormItem>
              </MyFormItemGroup>

              <div className="d-flex align-items-center justify-content-between">
                <MyFormItem
                  name="show_price"
                  label="Show Currency"
                  className="me-3 mb-0"
                >
                  <Switch
                    checked={price?.show_price}
                    value={price?.show_price}
                    onChange={handleSwitchChange}
                  />{" "}
                </MyFormItem>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => setModal2Open(false)}
                >
                  Update
                </Button>
              </div>
            </MyFormItemGroup>
          </Form>
        </div>
      </Modal>

      <Modal
        title={<div className="modalHeader">Delete</div>}
        open={modal3Open}
        onOk={() => setModal3Open(false)}
        onCancel={() => setModal3Open(false)}
        footer={footer}
        centered
        width={800}
      >
        <div className="mt-4" style={{ width: "100%", textAlign: "left" }}>
          <p>
            Are you sure, do you want to delete this client? If you proceed it
            will not undone.
          </p>{" "}
        </div>
      </Modal>

      <CreateClientModal
        GetClientList={GetClientList}
        setCreateModal={setCreateModal}
        showCreateModal={showCreateModal}
        selectedClient={selectedClient}
      />
      <UpdateClientModal
        GetClientList={GetClientList}
        setUpdateModal={setUpdateModal}
        showUpdateModal={showUpdateModal}
        selectedClient={selectedClient}
      />

      <AddUserModal
        viewClientModal={viewClientModal}
        clientView={clientView}
        userModal={userModal}
        setUserModal={setUserModal}
        GetClientList={GetClientList}
      />
      <UpdateUserModal
        selectedUser={selectedUser}
        userModal={userUpdateModal}
        setUserModal={setUserUpdateModal}
        GetClientList={GetClientList}
        viewClientModal={viewClientModal}
      />
      <ConfirmationModal
        open={open}
        setOpen={setOpen}
        message={"Are you sure you want to move this client?"}
        title={`Move to ${selectedClient?.registrationType === "client" ? "Nextyn IQ" : "Client"
          }`}
        okFunction={async () => {
          await moveToMynt();
          setOpen(false);
        }}
        cancelFunction={() => {
          setOpen(false);
          setSelectedClient({});
        }}
      />
    </>
  );
};

export default ClientTable;
