import {
  ExclamationCircleOutlined,
  SearchOutlined
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal, Select,
  Space,
  Table,
  notification
} from "antd";
import React, { useEffect, useState } from "react";
import "react-h5-audio-player/lib/styles.css";
import "../../App.css";
import "../../assests/css/custom.css";
import API_PATH from "../../Constants/api-path";


const { RangePicker } = DatePicker;
const MyFormItemContext = React.createContext([]);
function toArr(str) {
  return Array.isArray(str) ? str : [str];
}
// const MyFormItemGroup = ({ prefix, children }) => {
//   const prefixPath = React.useContext(MyFormItemContext);
//   const concatPath = React.useMemo(
//     () => [...prefixPath, ...toArr(prefix)],
//     [prefixPath, prefix]
//   );
//   return (
//     <MyFormItemContext.Provider value={concatPath}>
//       {children}
//     </MyFormItemContext.Provider>
//   );
// };
// const MyFormItem = ({ name, ...props }) => {
//   const prefixPath = React.useContext(MyFormItemContext);
//   const concatName =
//     name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
//   return <Form.Item name={concatName} {...props} />;
// };



const LogsTable = () => {

  const dataSource = [
    {
      key: "1",
      name: "Akshay Jain",
      email: "Add Expert Temp data (0) Added by Akshay Jain",
      role: "-",

      contact: (
        <>
          <div>
            <p>14 Apr, 2023</p>
            <p style={{ color: "maroon" }}>11:41:52 am</p>
          </div>
        </>
      ),
    },
    {
      key: "2",
      name: "Akshay Jain",
      email: "Add Expert Temp data (0) Added by Akshay Jain",
      role: "-",

      contact: (
        <>
          <div>
            <p>14 Apr, 2023</p>
            <p style={{ color: "maroon" }}>11:41:52 am</p>
          </div>
        </>
      ),
    },
    {
      key: "3",
      name: "Akshay Jain",
      email: "Add Expert Temp data (0) Added by Akshay Jain",
      role: "-",

      contact: (
        <>
          <div>
            <p>14 Apr, 2023</p>
            <p style={{ color: "maroon" }}>11:41:52 am</p>
          </div>
        </>
      ),
    },
    {
      key: "4",
      name: "Akshay Jain",
      email: "Add Expert Temp data (0) Added by Akshay Jain",
      role: "-",

      contact: (
        <>
          <div>
            <p>14 Apr, 2023</p>
            <p style={{ color: "maroon" }}>11:41:52 am</p>
          </div>
        </>
      ),
    },
    {
      key: "5",
      name: "Akshay Jain",
      email: "Add Expert Temp data (0) Added by Akshay Jain",
      role: "-",

      contact: (
        <>
          <div>
            <p>14 Apr, 2023</p>
            <p style={{ color: "maroon" }}>11:41:52 am</p>
          </div>
        </>
      ),
    },
    {
      key: "6",
      name: "Akshay Jain",
      email: "Add Expert Temp data (0) Added by Akshay Jain",
      role: "-",

      contact: (
        <>
          <div>
            <p>14 Apr, 2023</p>
            <p style={{ color: "maroon" }}>11:41:52 am</p>
          </div>
        </>
      ),
    },
    {
      key: "7",
      name: "Akshay Jain",
      email: "Add Expert Temp data (0) Added by Akshay Jain",
      role: "-",

      contact: (
        <>
          <div>
            <p>14 Apr, 2023</p>
            <p style={{ color: "maroon" }}>11:41:52 am</p>
          </div>
        </>
      ),
    },
    {
      key: "8",
      name: "Akshay Jain",
      email: "Add Expert Temp data (0) Added by Akshay Jain",
      role: "-",

      contact: (
        <>
          <div>
            <p>14 Apr, 2023</p>
            <p style={{ color: "maroon" }}>11:41:52 am</p>
          </div>
        </>
      ),
    },
    {
      key: "9",
      name: "Akshay Jain",
      email: "Add Expert Temp data (0) Added by Akshay Jain",
      role: "-",

      contact: (
        <>
          <div>
            <p>14 Apr, 2023</p>
            <p style={{ color: "maroon" }}>11:41:52 am</p>
          </div>
        </>
      ),
    },
    {
      key: "10",
      name: "Akshay Jain",
      email: "Add Expert Temp data (0) Added by Akshay Jain",
      role: "-",

      contact: (
        <>
          <div>
            <p>14 Apr, 2023</p>
            <p style={{ color: "maroon" }}>11:41:52 am</p>
          </div>
        </>
      ),
    },
  ];
  const [searchText, setSearchText] = useState("");
  const [, setFilteredData] = useState(dataSource);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: dataSource.length,
  });
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modal3Open, setModal3Open] = useState(false);
  // const [isPlaying, setIsPlaying] = useState(false);
  const [, setLoading] = useState(false);
  const [logList, setLogList] = useState("")
  const [componentSize, setComponentSize] = useState('default');
  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };

  // const handlePlayPause = () => {
  //   setIsPlaying(!isPlaying);
  // };

  const GetLogsList = async () => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.LOGS_LIST}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setLogList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    GetLogsList();
  }, [])


  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <span
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#submitted"
          aria-controls="offcanvasRight"
        >
          {text}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "email",
      key: "email",
      render: (text) => <span onClick={() => setModal2Open(true)}>{text}</span>,
    },
    {
      title: "Action Url",
      dataIndex: "role",
      key: "role",
      render: (text) => (
        <span
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#submitted"
          aria-controls="offcanvasRight"
        >
          {text}
        </span>
      ),
    },
    {
      title: "Date-time",
      dataIndex: "contact",
      key: "contact",
      render: (text) => (
        <span
        >
          {text}
        </span>
      ),
    },
  ];

  const handleSearch = (value) => {
    setSearchText(value);
    const filteredDataSource = dataSource.filter((record) =>
      Object.values(record).join("").toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredDataSource);
    setPagination({
      ...pagination,
      current: 1,
      total: filteredDataSource.length,
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const addFooter = (
    <div className="d-flex justify-content-around align-items-center">
      <Button type="primary" key="cancel">Cancel</Button>
      <Button key="submit">Add as Manager</Button>
    </div>
  );

  const updateFooter = (
    <div className="d-flex justify-content-around align-items-center">
      <Button type="primary" key="cancel">Close</Button>
      <Button key="submit">Update</Button>
    </div>
  );

  const footer = (
    <div>
      <Button key="cancel">No stay</Button>
      <Button key="submit">Yes Delete</Button>
    </div>
  );


  return (
    <>
      <div className="container-fluid">
        <div className="bk-div float-start w-100">
          {" "}
          {/* <hr className="sp-hr" /> */}
          <div className="body-expart-div">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Logs</h2>
              {/* <Button
                type="primary"
                onClick={() => setModal1Open(true)}
                size="large"
              >
                Add New Manager
              </Button> */}
            </div>

            <div className="row justify-content-center">
              <div className="col-md-4 p-b-20">
                <label className="_fs14_  _drkclr_ _fntwss_">Search By Date</label>
                <span id="two-inputs" style={{ width: "100%", display: "inline-block" }}>
                  <Space direction="vertical" size={12}>
                    <RangePicker />
                  </Space>
                </span>
              </div>
            </div>

            <Input
              placeholder="Search"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              prefix={<SearchOutlined />}
              style={{ marginBottom: 16, marginTop: 16 }}
              size="large"
            />
            <Table
              dataSource={logList}
              columns={columns}
              pagination={pagination}
              onChange={handleTableChange}
              scroll={{ x: 800 }}
              size="large"
            />
          </div>
        </div>
      </div>

      <div
        className="offcanvas ttt offcanvas-end"
        tabIndex="-1"
        id="submitted"
        aria-labelledby="offcanvasRightLabel"
      >
        {/* {loading1 ? ( */}
        <>
          <div
            className="offcanvas-header align-items-center justify-content-between"
            style={{
              borderBottom: "1px solid rgb(197, 195, 195)",
            }}
          >
            <button
              type="button"
              className="btn-close "
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
            <h5 id="offcanvasRightLabel">Add New Manager</h5>
          </div>
          <div className="offcanvas-body">
            <div className="experien-list mt-4">
              <div>
                <ul className="nextyn_list_items">
                  <li className="nextyn_list_item border-bottom-0">
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">From:</span>
                      <div className="companyName_1dSwA">Capital One</div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">To:</span>
                      <div className="companyName_1dSwA">Capital One</div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Through:</span>
                      <div className="companyName_1dSwA">+17182858858</div>
                    </div>
                  </li>
                  <li className="nextyn_list_item border-bottom-0">
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Call Reference Id:</span>
                      <div className="companyName_1dSwA">Capital One</div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Total Talk Time:</span>
                      <div className="companyName_1dSwA">s</div>
                    </div>
                  </li>
                  <li className="nextyn_list_item border-bottom-0">
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Start Time:</span>
                      <div className="companyName_1dSwA">
                        Mon, 10 Apr 2023 11:14:01
                      </div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">End Time:</span>
                      <div className="companyName_1dSwA">
                        Mon, 10 Apr 2023 11:14:01
                      </div>
                    </div>
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Call Time:</span>
                      <div className="companyName_1dSwA">
                        Mon, 10 Apr 2023 11:14:01
                      </div>
                    </div>
                  </li>
                  <li className="nextyn_list_item border-bottom-0">
                    <div className="expertCompany_2UyI2">
                      <span className="title_1p_LH">Transcription:</span>
                      <div className="companyName_1dSwA">Capital One</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
        {/* ) : (
            ""
          )} */}
      </div>



      <Modal
        title="Add New Manager"
        open={modal1Open}
        onOk={() => setModal1Open(false)}
        onCancel={() => setModal1Open(false)}
        visible={true}
        footer={addFooter}
        width={600}
      >
        <div className="mt-4 text-center">

          <Form
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 14,
            }}
            layout="horizontal"
            initialValues={{
              size: componentSize,
            }}
            onValuesChange={onFormLayoutChange}
            size={componentSize}
            style={{
              maxWidth: 600,
            }}
          >
            <Form.Item label="Name:">
              <Input />
            </Form.Item>
            <Form.Item label="Mobile:">
              <Input />
            </Form.Item>
            <Form.Item label="Email:">
              <Input />
            </Form.Item>
            <Form.Item label="Password:">
              <Input />
            </Form.Item>
            <Form.Item label="Role:">
              <Select>
                <Select.Option value="demo">Demo</Select.Option>
                <Select.Option value="demo">Demo</Select.Option>
                <Select.Option value="demo">Demo</Select.Option>
                <Select.Option value="demo">Demo</Select.Option>
              </Select>
            </Form.Item>


          </Form>

        </div>
      </Modal>

      <Modal
        title="Update Manager"
        open={modal3Open}
        onOk={() => setModal3Open(false)}
        onCancel={() => setModal3Open(false)}
        visible={true}
        footer={updateFooter}
        width={600}
      >
        <div className="mt-4 text-center">

          <Form
            labelCol={{
              span: 4,
            }}
            wrapperCol={{
              span: 14,
            }}
            layout="horizontal"
            initialValues={{
              size: componentSize,
            }}
            onValuesChange={onFormLayoutChange}
            size={componentSize}
            style={{
              maxWidth: 600,
            }}
          >
            <Form.Item label="Name:">
              <Input />
            </Form.Item>
            <Form.Item label="Mobile:">
              <Input />
            </Form.Item>
            <Form.Item label="Email:">
              <Input />
            </Form.Item>
            <Form.Item label="Password:">
              <Input />
            </Form.Item>
            <Form.Item label="Role:">
              <Select>
                <Select.Option value="demo">Demo</Select.Option>
                <Select.Option value="demo">Demo</Select.Option>
                <Select.Option value="demo">Demo</Select.Option>
                <Select.Option value="demo">Demo</Select.Option>
              </Select>
            </Form.Item>


          </Form>

        </div>
      </Modal>


      <Modal
        title="Delete this Account."
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
        visible={true}
        footer={footer}
        width={800}
      >
        <div className="mt-4" style={{ width: "100%", textAlign: "left" }}>
          <p>
            This action cannot be undone. If you delete this account then their account will be deactivated and will not be able to log in again with their credentials. Are you sure you want to delete this account?
          </p>{" "}
        </div>
      </Modal>
    </>
  );
};

export default LogsTable;
