import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import RingVolumeOutlinedIcon from "@mui/icons-material/RingVolumeOutlined";
import { Pagination, Spin, Table, Tooltip } from "antd";
import _ from "lodash";
import React, { useState } from "react";
import AvatarGroup from "react-avatar-group";
import { CgTranscript } from "react-icons/cg";
import { MdCalendarToday } from "react-icons/md";
import { createSearchParams, NavLink, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../../App.css";
import messageColoredSvg from "../../assests/images/message-colored.svg";
import messageSvg from "../../assests/images/message.svg"; //modal imports
import no_found from "../../assests/images/Post-traumatic.svg";
import ProjectCallDetailsModal from "./ProjectCallDetailsModal";

function ComonProjectTable({
  projectList,
  load,
  currentPage,
  countData,
  handlePageChange,
}) {
  const history = useNavigate();
  const [open, setOpen] = useState({
    id: "",
    isOpen: false
  });
  const userData = JSON.parse(localStorage.getItem("userData"));
  const convertDate = (date) => {
    if (date !== null && date !== "") {
      var d = new Date(date);
      var day = d.getDate();
      var x = d.toDateString().substr(4, 3);
      var year = d.getFullYear();
      let p = `${x} ${day}, ${year}`;
      return p;
    } else {
      return "---------";
    }
  };

  function Calculate_days(date1) {
    var diff = new Date().getTime() - new Date(date1).getTime();
    var days = Math.floor(diff / (1000 * 60 * 60 * 24));

    ///return { days, hours, minutes, seconds };
    if (days !== 0) {
      if (days < 0) {
        return Math.abs(days) + " " + "days left ";
      } else {
        return days + " " + "days ago ";
      }
    } else {
      return "Today";
    }
  }

  const goToDetailsPage = (project_id) => {
    history({
      pathname: "/dashboard/project_details",
      search: `?to=chat&${createSearchParams({ project_id })}`,
    });
  };
  const goToChatPage = (project_id) => {
    history({
      pathname: "/dashboard/chats",
      search: `?to=chat&${createSearchParams({ project_id })}`,
    });
  };

  const customizeChatCount = (chatCount) => {
    chatCount = parseInt(chatCount);
    if (chatCount > 9) {
      return "9+";
    } else {
      return chatCount;
    }
  };

  function CalculatePercentage(total, percentage) {
    const completedCalls = parseFloat(total);

    return parseInt((percentage / completedCalls) * 100);
  }

  const columns = [
    {
      title: "CLIENT",
      dataIndex: "companyName",
      key: "companyName",
      width: "100px",
      render: (text, record) => {
        return (
          <>
            <Tooltip
              title={record?.client?.companyData[0]?.name}
              color="#5746EB"
            >
              <p
                className="company_Title"
                title={record?.client?.companyData[0]?.name}
                onClick={() => goToDetailsPage(record?._id)}
                style={{ cursor: "pointer" }}
              >
                {_.truncate(record?.client?.companyData[0]?.name, {
                  length: "25",
                  omission: "...",
                }) || "-"}
              </p>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "PROJECT NAME",
      dataIndex: "project_title",
      key: "name",
      render: (text, record) => {
        // Truncate the project title to 6 words
        return (
          <>
            <div className="d-flex justify-content-start align-items-center gap-2">
              <Tooltip
                color="#5746EB"
                title={record?.project_title}
                placement="topLeft"
              >
                <p
                  className="projectTitle"
                  title={record?.project_title}
                  onClick={() => goToDetailsPage(record?._id)}
                  style={{ cursor: "pointer", color: "#5746EB" }}
                >
                  {_.truncate(record?.project_title, {
                    length: 40,
                    omission: "...",
                  }) || "-"}
                </p>
              </Tooltip>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex">
                {/* <img src="/images/expert-icon1.svg" alt="sn" width="17px" /> */}
                <span
                  className="text-muted createdDate"
                  style={{ fontSize: "12px" }}
                >
                  <MdCalendarToday className="me-2" />
                  {convertDate(record?.created_at)}
                  {/* <CopyToClipboard
                    text={record?._id}
                    onCopy={() => {
                      setCopiedId(record?._id);
                    }}
                  >
                    <Tooltip
                      title={copiedId === record?._id ? "Copied!" : "Copy ID"}
                    >
                      <MdContentCopy
                        className="ms-3"
                        style={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                  </CopyToClipboard> */}
                </span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: "PROGRESS",
      key: "name",
      width: "100px",
      render: (render, record) => (
        <>
          <div
            className="progress"
            onClick={() => goToDetailsPage(record?._id)}
            style={{ cursor: "pointer" }}
          >
            <div
              className="progress-bar"
              style={{
                width: `${CalculatePercentage(
                  render?.expected_calls,
                  render?.completed_Calls
                )}%`,
              }}
              role="progressbar"
              aria-valuenow="0"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <ul className="d-flex justify-content-center align-items-center list-unstyled mt-2">

            <li className="me-3">
              <span>
                <CgTranscript style={{ fontSize: "18px" }} /> :
              </span>
              {render?.transcript_required ? render?.transcript_required : 0}
            </li>
            <li className="me-3">
              <span>
                <LocalPhoneOutlinedIcon style={{ fontSize: "18px" }} /> :
              </span>
              {render?.expected_calls ? render?.expected_calls : 0}
            </li>

            <li className="me-3">
              <span>
                <PersonAddAlt1OutlinedIcon style={{ fontSize: "18px" }} /> :
              </span>
              {render?.expected_Submitted ? render?.expected_Submitted : 0}
            </li>

            <li className="link-text d-flex align-items-center" onClick={() => {
              if (render?.completed_Calls > 0 || render?.completed_add_call > 0) {
                setOpen({
                  id: record?._id,
                  isOpen: true
                })
              }
            }}>
              <span>
                <RingVolumeOutlinedIcon style={{ fontSize: "18px" }} /> :
              </span>
              {render?.completed_Calls
                ? render?.completed_Calls
                : render?.completed_add_call
                  ? render?.completed_add_call
                  : 0}
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "DEADLINE",
      key: "name",
      render: (record) => (
        <>
          <div
            onClick={() => goToDetailsPage(record?._id)}
            style={{ cursor: "pointer" }}
          >
            <p>{convertDate(record?.project_deadline)}</p>
            <p style={{ fontSize: "12px" }} className="text-muted">
              {" "}
              {Calculate_days(record?.project_deadline)}{" "}
            </p>
          </div>
        </>
      ),
    },
    {
      title: "TEAM",
      render: (record) => (
        <>
          {record.invite_team_dashboard_name ? <AvatarGroup
            avatars={record?.invite_team_dashboard_name
              ?.split(",")
              ?.filter(
                (item) =>
                  item.toLowerCase() !== userData?.data?.name?.toLowerCase()
              )}
            uppercase={true}
            initialCharacters={2}
            fontColor="#f33636"
            max={2}
            size={30}
            fontSize=".38"
            displayAllOnHover={true}
            tooltipStyle={{
              backgroundColor: "#5746EB"
            }}
            backgroundColor="#EFEFEF"
          /> : "-"}
        </>
      ),
    },
    {
      title: "CHAT",
      key: "name",
      render: (record) => (
        <>
          <div
            onClick={() => goToChatPage(record?._id)}
            style={{ cursor: "pointer" }}
          >
            {record?.chat_count ? (
              <NavLink
                to={{
                  pathname: "/projects-details-message",
                  // search: `?${createSearchParams({ record.project_id })}`,
                }}
              >
                <img
                  alt="msgSVG"
                  src={messageColoredSvg}
                  className="me-1"
                  loading="lazy"
                />
                <span style={{ fontSize: "14px" }}>
                  {customizeChatCount(record?.chat_count)}
                </span>
              </NavLink>
            ) : (
              <>
                <img alt="msgSVG" src={messageSvg} />
              </>
            )}
          </div>
        </>
      ),
    },
    // {
    //   title: "STATUS",
    //   key: "name",
    //   render: (record) => (
    //     <>
    //       <div
    //         className="dropdown"
    //         onClick={() => goToDetailsPage(record?._id)}
    //         style={{ cursor: "pointer" }}
    //       >
    //         {getDropDownItem(record?.status, record?.project_id)}
    //       </div>
    //     </>
    //   ),
    // },
  ];

  // const getDropDownItem = (status, project_id) => {
  //   if (status === 1) {
  //     return (
  //       <ul>
  //         <li>
  //           <span className="submited-text">Active</span>
  //           {/* <span className="badge bg-success">Active</span> */}
  //         </li>
  //       </ul>
  //     );
  //   } else if (status === 0) {
  //     return (
  //       <ul>
  //         <li>
  //           <span className="requested-text">Invitation</span>
  //         </li>
  //       </ul>
  //     );
  //   } else if (status === 3) {
  //     return (
  //       <ul>
  //         <li>
  //           <span className="scheduled-text">Completed</span>
  //         </li>
  //       </ul>
  //     );
  //   } else if (status === 2) {
  //     return (
  //       <ul>
  //         <li>
  //           <span className="declined-text">Archived</span>
  //         </li>
  //       </ul>
  //     );
  //   } else if (status === 4) {
  //     return (
  //       <ul>
  //         <li>
  //           <span className="declined-text">On Hold</span>
  //         </li>
  //       </ul>
  //     );
  //   } else {
  //     return (
  //       <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
  //         <li>
  //           <span className="dropdown-item" href="#">
  //             <i className="fa fa-retweet" aria-hidden="true"></i>
  //             <span style={{ marginLeft: "10px" }}>Resume</span>{" "}
  //           </span>
  //         </li>
  //         <li>
  //           <span className="dropdown-item" href="#">
  //             <i className="fa fa-check" aria-hidden="true"></i>
  //             <span style={{ marginLeft: "10px" }}>Completed</span>
  //           </span>
  //         </li>
  //         <li>
  //           <span className="dropdown-item" href="#">
  //             <i className="fa fa-eye" aria-hidden="true"></i>
  //             <span style={{ marginLeft: "10px" }}>View</span>{" "}
  //           </span>
  //         </li>
  //         <li>
  //           <span className="dropdown-item" href="#">
  //             <i className="fa fa-trash-o" aria-hidden="true"></i>
  //             <span style={{ marginLeft: "10px" }}>Delete</span>
  //           </span>
  //         </li>
  //       </ul>
  //     );
  //   }
  // };

  const paginationSettings = {
    current: currentPage,
    onChange: handlePageChange,
    pageSize: 10,
    total: countData,
  };

  return (
    <>
      <div>
        {/* {Array.isArray(projectList) && projectList?.length > 0 && ( */}
        <>
          <Table
            dataSource={projectList}
            columns={columns}
            className="paddTable"
            pagination={false}
            loading={{
              spinning: load,
              indicator: <Spin size="large" />,
            }}
            scroll={{ x: 700 }}
            size="large"
          />
          {/* <div className="pagination-background mb-4">
            <Pagination1
              activePage={currentPage}
              itemsCountPerPage={10}
              totalItemsCount={countData}
              // pageRangeDisplayed={10}
              onChange={handlePageChange}
              // itemclassName="page-item"
              // linkclassName="page-link"
              // showLast
            />
          </div> */}
          <Pagination
            {...paginationSettings}
            showSizeChanger={false}
            style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
          />
        </>
        {/* )} */}

        {!load && !Array.isArray(projectList) && projectList?.length === 0 && (
          <div className="text-center noResultFound">
            <div className="container">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-md-6">
                  <img
                    src={no_found}
                    alt="sv"
                    style={{ height: "400px", marginTop: "50px" }}
                  ></img>
                </div>
                <div className="col-md-6">
                  <h3
                    style={{
                      fontSize: "18px",
                      textAlign: "left",
                      fontWeight: "300",
                      lineHeight: "26px",
                    }}
                  >
                    <span style={{ fontWeight: "500" }}>Oops,</span> Project not
                    found .
                  </h3>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <ProjectCallDetailsModal isOpen={open?.isOpen} project_id={open?.id} setIsOpen={(val) => {
        setOpen({
          id: "",
          isOpen: val
        })
      }} />
    </>
  );
}
export default ComonProjectTable;
