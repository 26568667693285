import Aos from "aos";
import "aos/dist/aos.css";
import React from "react";
import { NavLink } from "react-router-dom";
import ForgotPasswordForm from "./ForgotPasswordForm";
import nextyn_logo from "../../../assests/images/NX19.1.png";
import nextyn_mob_logo from "../../../assests/images/NX19.png";
import leftimage from "../../../assests/images/Login.gif";
import ExpertAuth from "../../components/ExpertAuth";
import { Col, Row } from "react-bootstrap";

function ForgotPasssword() {
  Aos.init({
    offset: 100,
    easing: "ease",
    delay: 0,
    once: true,
    duration: 800,
  });

  document.title = "Expert - Forgotpassword";
  return (
    <>
      <ExpertAuth />
      <div className="login-page">
        <div>
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col d-none d-md-block">
              <div className="login-left-part">
                <Row className="justify-content-center">
                  <Col xs={11} className="mt-3">
                    <NavLink to="/expert-login" className="logo">
                      <img
                        src={nextyn_logo}
                        alt="logo"
                        style={{ maxWidth: "30%" }}
                      />
                    </NavLink>
                  </Col>
                  <Col lg={8} className="logo-in-div">
                    <h1 className="text-left text-white  " data-aos="fade-down">
                      Consulting experts is <br /> as easy as a click of a
                      button.
                    </h1>
                  </Col>

                  <Col lg={8}>
                    <div className="img-box-1">
                      <img src={leftimage} alt={leftimage}></img>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="col position-relative">
              <div className="row justify-content-center align-items-center scrollableCls">
                <div className="comon-fild col-lg-8">
                  <div className="inside-login registerPage">
                    <NavLink
                      to="/expert-login"
                      className="logo d-block d-lg-none mb-5"
                    >
                      <img src={nextyn_mob_logo} alt="logo" />
                    </NavLink>
                    <h4> Forgot Password? 🔒 </h4>
                    <p style={{ color: "#5746ec" }}>
                      It happens to all of us! Don't worry, we're here to help
                    </p>
                    <ForgotPasswordForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ForgotPasssword;
