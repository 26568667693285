import React, { useState, useEffect, useRef } from "react";
import useStep from "../../../CustomHooks/UseStep";
import { NavLink, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import {
  Checkbox,
  Form,
  Input,
  Select,
  Button,
  notification,
  message,
} from "antd";
import rasesh_sign from "../../../assests/images/Rasesh_Signature.png";
import API_PATH from "../../../Constants/api-path";
import { Spinner } from "reactstrap";
import nextyn_logo from "../../../assests/images/NX19.png";
import jsPDF from "jspdf";
import ExpertAuth from "../../components/ExpertAuth";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import ReCAPTCHA from "react-google-recaptcha";
import ENV from "../../../Constants/env";
import axios from "axios";

function RegisterConsultantStep4({ defaultFileList }) {
  const { registrationType, setCurrentStepConsultant } =
    useStep();
  const history = useNavigate();
  const [loading, setLoading] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [form] = Form.useForm();
  const [addData, setAddData] = useState({
    hourly_rate_call_cunsltn: "",
    charge_duration: "hourly",
    hourly_currency: "USD",
  });
  const [, setStep1] = useState({
    first_name: "",
    last_name: "",
  });
  const captchaRef = useRef(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [code, setCode] = useState(null);
  const [, setIsAuth] = useState(false);

  useEffect(() => {
    // Function to extract query parameters from the URL
    const getQueryParameter = (name) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(name);
    };

    // Get the 'code' query parameter from the URL
    const typeFromURL = getQueryParameter("type");
    if (typeFromURL === "linkedin") {
      // If 'code' exists in the URL, set it in the state
      setCode(typeFromURL);
      // You can perform any additional actions with the 'code' here if needed
    }
  }, []);

  const expertCurrency = (value) => {
    const selectValue = value || "USD"; // Set "USD" if the value is falsy
    setAddData({ ...addData, hourly_currency: selectValue });
  };

  const hourly_rate = (e) => {
    const key = e.target.name;
    const value = e.target.value;

    setAddData({ ...addData, [key]: value });
  };

  const handleStep1Change = (event) => {
    const { name, value } = event.target;
    setStep1((prevStep1) => ({
      ...prevStep1,
      [name]: value,
    }));
  };

  const selectBefore = (
    <div className="d-flex flex-row">
      <Select
        style={{
          width: 90,
          fontSize: "smaller",
        }}
        defaultValue="hourly"
        size="large"
        value={addData?.charge_duration || "hourly"} // Use addData?.hourly_currency if it exists, otherwise default to "USD"
        onChange={(value) => {
          setAddData({ ...addData, charge_duration: value });
        }}
      >
        <Select.Option value="hourly">Hourly</Select.Option>
        <Select.Option value="daily">Daily</Select.Option>
      </Select>
      <div className="vertical-rule"></div>
      <Select
        style={{
          width: 80,
        }}
        defaultValue="USD"
        size="large"
        value={addData?.hourly_currency || "USD"} // Use addData?.hourly_currency if it exists, otherwise default to "USD"
        onChange={expertCurrency}
      >
        <Select.Option value="USD">USD</Select.Option>
        <Select.Option value="EUR">EUR</Select.Option>
        <Select.Option value="JPY">JPY</Select.Option>
        <Select.Option value="GBP">GBP</Select.Option>
        <Select.Option value="AUD">AUD</Select.Option>
        <Select.Option value="CAD">CAD</Select.Option>
        <Select.Option value="NZD">NZD</Select.Option>
        <Select.Option value="SGD">SGD</Select.Option>
        <Select.Option value="HKD">HKD</Select.Option>
      </Select>
    </div>
  );
  // const selectAfter = (
  //   <Select
  //     style={{
  //       width: 90,
  //       fontSize: "smaller",
  //     }}
  //     defaultValue="hourly"
  //     size="large"
  //     value={addData?.charge_duration || "hourly"} // Use addData?.hourly_currency if it exists, otherwise default to "USD"
  //     onChange={(value) => {
  //       setAddData({ ...addData, charge_duration: value });
  //     }}
  //   >
  //     <Select.Option value="hourly">Hourly</Select.Option>
  //     <Select.Option value="daily">Daily</Select.Option>
  //   </Select>
  // );

  const storedStep1 = JSON?.parse(localStorage?.getItem("step1"));
  // const storedStep2 = JSON?.parse(localStorage?.getItem("step2"));
  // const storedStep3 = JSON?.parse(localStorage?.getItem("step3"));

  function textToCanvas(text) {
    let myFont = new FontFace(
      "cursive",
      "url(https://fonts.gstatic.com/s/cedarvillecursive/v17/yYL00g_a2veiudhUmxjo5VKkoqA-B_nuIrpw4cNOTw.woff2)"
    );
    const canvas = document.createElement("canvas");
    document.fonts.add(myFont);
    canvas.width = 200;
    canvas.height = 50;
    const ctx = canvas.getContext("2d");
    ctx.font = "32px cursive";
    ctx.fillText(text, 10, 30);
    ctx.fillStyle = "rgba(0,0,0)";
    return canvas.toDataURL();
  }

  const handleSubmit = async () => {
    // e.preventDefault();
    setLoading(true);

    const savedStep1 = JSON.parse(localStorage.getItem("step1"));
    const step2Data = JSON.parse(localStorage.getItem("step2"));
    const step3Data = JSON.parse(localStorage.getItem("step3"));

    const signNameCanvas = textToCanvas(savedStep1?.data?.first_name);
    const project_array = step3Data?.step3?.map((step) => {
      return {
        project_name: step?.project_name,
        project_scope: step?.project_scope,
        project_description: step?.project_description,
        project_type: step?.project_type,
        project_sub_type: step?.project_sub_type,
        project_specific: step?.project_specific,
        project_industry: step?.industry,
        project_duration: step?.project_duration,
        size_of_organization: step?.size_of_organization,
        // function: step?.function,
        project_city: step?.city?.value,
        project_country: step?.country?.value,
      };
    });
    const data = {
      //step1
      first_name: savedStep1?.data?.first_name,
      last_name: savedStep1?.data?.last_name,
      city: savedStep1?.data?.city?.value,
      country: savedStep1?.data?.country?.value,
      password: savedStep1?.data?.password,
      email: savedStep1?.data?.email?.toLowerCase(),
      contact_code: savedStep1?.data?.contact_code,
      contact_number: savedStep1?.data?.contact_number,
      // step2
      // resume: step2Data?.defaultFileList?.[0]?.base64?.split(",")[1],
      // resume_extension: step2Data?.defaultFileList?.[0]?.type,
      highest_education: step2Data?.step2?.highest_education,
      highest_professional_level: step2Data?.step2?.highest_professional_level,
      years_of_experience: step2Data?.step2?.years_of_experience,
      linkedin_url: step2Data?.step2?.linkedin_url,
      industry: step2Data?.step2?.industry,
      tags: step2Data?.step2?.tags,
      // career_title: step2Data?.inputs?.career_title,
      // company_name: step2Data?.inputs?.company_name,
      // location: step2Data?.inputs?.location,
      // exp_city: step2Data?.inputs?.city?.map((cit) => cit?.value),
      // exp_country: step2Data?.inputs?.country?.map((count) => count?.value),
      // date_from: step2Data?.inputs?.date_from,
      // date_till: step2Data?.inputs?.date_till,
      //step3 !SET INSIDE ARRAY
      project_details: project_array,
      //step4
      hourly_currency: addData?.hourly_currency,
      hourly_rate_call_cunsltn: addData?.hourly_rate_call_cunsltn,
      charge_duration: addData?.charge_duration,
      sign_image: signNameCanvas,
      expert_type: "consultant",
    };
    try {
      const res = await fetch(`${API_PATH.EXPERT_REGISTRATION}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await res.json();

      if (result) {
        if (result?.status === 200 || result?.status === true) {
          if (defaultFileList?.[0]) {
            try {
              const form = new FormData();
              form.append("resume", defaultFileList?.[0]?.originFileObj);
              form.append("expert_id", result.expert_id);
              form.append("tempExpert_id", result.tempExpert_id);
              const res = await axios.post(`${API_PATH.UPLOAD_RESUME}`, form, {
                headers: { "Content-Type": "multipart/form-data" },
              });
              if (res.status) {
                localStorage.removeItem("expertData")
                localStorage.removeItem("isExpertAuth")
                localStorage.removeItem("lastExpertActiveTime")
                localStorage.removeItem("lastExpertLoginTime")
                localStorage.setItem("expertData", JSON.stringify(result));

                if (result?.token) {
                  setIsAuth(true);
                  localStorage.setItem("isExpertAuth", true);
                  //!PASSWORD STEP UNKNOWN
                  localStorage.setItem(
                    "ChangePassword",
                    JSON.stringify(savedStep1?.data?.password)
                  );
                  notification.success({
                    message: "Registration Successful!",
                    icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                    style: {
                      backgroundColor: "#2ecc71",
                      color: "#fff !important",
                      border: "1px solid #52c41a",
                    },
                    duration: 5,
                    placement: "topRight",
                  });
                  history("/expert/dashboard");
                }
              }
            } catch (error) {
              setLoading(false);
              return notification.error({
                message: "Error: error while uploading resume",
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                  backgroundColor: "#e74c3c",
                  color: "#fff",
                  border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
              });
            }
          }
          localStorage.removeItem("expertData")
          localStorage.removeItem("isExpertAuth")
          localStorage.removeItem("lastExpertActiveTime")
          localStorage.removeItem("lastExpertLoginTime")
          localStorage.setItem("expertData", JSON.stringify(result));

          if (result?.token) {
            setIsAuth(true);
            localStorage.setItem("isExpertAuth", true);
            //!PASSWORD STEP UNKNOWN
            localStorage.setItem(
              "ChangePassword",
              JSON.stringify(savedStep1?.data?.password)
            );
            notification.success({
              message: "Registration Successful!",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
            history("/expert/dashboard");
          }
          // window.location.reload();
        } else {
          if (
            result?.message === "Email already exists.." ||
            result?.message === true
          ) {
            notification.error({
              message: "Email already exists. Please use a different email.",
              icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
              style: {
                backgroundColor: "#e74c3c",
                color: "#fff",
                border: "1px solid #c0392b",
              },
              duration: 5,
              placement: "topRight",
            });
          } else {
            notification.error({
              message: "Error: Something went wrong server error",
              icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
              style: {
                backgroundColor: "#e74c3c",
                color: "#fff",
                border: "1px solid #c0392b",
              },
              duration: 5,
              placement: "topRight",
            });
          }
        }
      }
    } catch (error) {
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const linkedinData = JSON.parse(localStorage?.getItem("linkedinData"));

  const handleSubmitTwo = async () => {
    // e.preventDefault();
    setLoading(true);

    const savedStep1 = JSON.parse(localStorage.getItem("step1"));
    const step2Data = JSON.parse(localStorage.getItem("step2"));
    const step3Data = JSON.parse(localStorage.getItem("step3"));

    const linkedinData = JSON.parse(localStorage?.getItem("linkedinData"));

    const signNameCanvas = textToCanvas(linkedinData?.firstName);
    const project_array = step3Data?.step3?.map((step) => {
      return {
        project_name: step?.project_name,
        project_scope: step?.project_scope,
        project_description: step?.project_description,
        project_type: step?.project_type,
        project_sub_type: step?.project_sub_type,
        project_specific: step?.project_specific,
        project_industry: step?.industry,
        project_duration: step?.project_duration,
        size_of_organization: step?.size_of_organization,
        // function: step?.function,
        project_city: step?.city?.value,
        project_country: step?.country?.value,
      };
    });

    const data = {
      first_name: linkedinData?.firstName,
      last_name: linkedinData?.lastName,
      linkedinId: linkedinData?.id,
      //step1
      email: savedStep1?.data?.email?.toLowerCase(),
      contact_code: savedStep1?.data?.contact_code,
      contact_number: savedStep1?.data?.contact_number,
      // step2
      // resume: step2Data?.defaultFileList?.[0]?.base64?.split(",")[1],
      // resume_extension: step2Data?.defaultFileList?.[0]?.type,
      linkedin_url: step2Data?.step2?.linkedin_url,
      tags: step2Data?.step2?.tags,
      //step3 !SET INSIDE ARRAY
      project_details: project_array,
      //step4
      hourly_currency: addData?.hourly_currency,
      hourly_rate_call_cunsltn: addData?.hourly_rate_call_cunsltn,
      charge_duration: addData?.charge_duration,
      sign_image: signNameCanvas,
      expert_type: "consultant",
    };
    try {
      const res = await fetch(`${API_PATH.EXPERT_REGISTRATION}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const result = await res.json();
      if (result) {
        if (result?.status === 200 || result?.status === true) {
          if (defaultFileList?.[0]) {
            try {
              let form = new FormData();
              form.append("resume", defaultFileList?.[0]?.originFileObj);
              form.append("expert_id", result.expert_id);
              form.append("tempExpert_id", result.tempExpert_id);

              const res = await axios.post(`${API_PATH.UPLOAD_RESUME}`, form, {
                headers: { "Content-Type": "multipart/form-data" },
              });
              if (res.status) {
                localStorage.removeItem("expertData")
                localStorage.removeItem("isExpertAuth");
                localStorage.removeItem("lastExpertLoginTime");
                localStorage.removeItem("lastExpertActiveTime");
                localStorage.setItem("expertData", JSON.stringify(result));

                if (result?.token) {
                  setIsAuth(true);
                  localStorage.setItem("isExpertAuth", true);
                  notification.success({
                    message: "Registration Successful!",
                    icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
                    style: {
                      backgroundColor: "#2ecc71",
                      color: "#fff !important",
                      border: "1px solid #52c41a",
                    },
                    duration: 5,
                    placement: "topRight",
                  });
                  history("/expert/dashboard");
                }
              }
            } catch (error) {
              setLoading(false);
              return notification.error({
                message: "Error: error while uploading resume",
                icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
                style: {
                  backgroundColor: "#e74c3c",
                  color: "#fff",
                  border: "1px solid #c0392b",
                },
                duration: 5,
                placement: "topRight",
              });
            }
          }
          localStorage.removeItem("expertData")
          localStorage.removeItem("isExpertAuth");
          localStorage.removeItem("lastExpertLoginTime");
          localStorage.removeItem("lastExpertActiveTime");
          localStorage.setItem("expertData", JSON.stringify(result));
          if (result?.token) {
            setIsAuth(true);
            localStorage.setItem("isExpertAuth", true);

            history("/expert/dashboard");
            notification.success({
              message: "Registration successful",
              icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
              style: {
                backgroundColor: "#2ecc71",
                color: "#fff !important",
                border: "1px solid #52c41a",
              },
              duration: 5,
              placement: "topRight",
            });
          }
          // window.location.reload();
        } else if (
          result?.status === 409 ||
          result?.message === "Email already exists.." ||
          result?.message === true
        ) {
          notification.error({
            message: "Email already exists. Please use a different email.",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        } else {
          // Handle other error cases if needed
          notification.error({
            message: "Error: Some error occurred. Please try again later.",
            icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
            style: {
              backgroundColor: "#e74c3c",
              color: "#fff",
              border: "1px solid #c0392b",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const date = new Date();
    const options = { month: "short", day: "numeric", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    setCurrentDate(formattedDate);
  }, [code]);

  const handleConvertToPDF = () => {
    var doc = new jsPDF();
    var elementHTML = document.querySelector("#termsAndConditions");
    // var header = (
    //   <>
    //     <div class="text-center giveMarginBottom">
    //       <img src={nextyn_logo} width="300px" alt="nextynLogo" />
    //     </div>
    //   </>
    // );
    doc.html(elementHTML, {
      callback: function (doc) {
        addFooters();
        doc.save("document-html.pdf");
      },
      margin: [38, 10, 40, 10],
      autoPaging: "text",
      pageSplit: true,
      x: 0,
      y: 0,
      width: 180, //target width in the PDF document
      windowWidth: 675, //window width in CSS pixels
      // header: header,
    });

    function addFooters() {
      const pageCount = doc.internal.getNumberOfPages();
      for (var i = 1; i <= pageCount; i++) {
        doc?.setPage(i);
        doc?.addImage(assignImage(nextyn_logo), "png", 60, 4, 80, 0);
        doc?.rect(10, 33, 190, 0.5, "F");

        doc?.rect(10, 265, 190, 0.5, "F");
        doc.setFontSize(11);
        doc.setTextColor(196, 10, 54);
        doc.text(
          "Nextyn is a brand of Nextyn Advisory Private Limited (CIN: U74999MH2018PTC304669)",
          103,
          275,
          "center"
        );

        doc.setTextColor(119, 136, 153);
        doc.setFontSize(10);
        doc.text("Strictly Private and Confidential", 103, 282, "center");
      }
    }
  };

  function assignImage(image) {
    var img = new Image();
    img.src = image;
    return img;
  }

  const handleChange = async (value) => {
    try {
      // Sending secret key and response token to Google Recaptcha API for authentication.
      await axios
        .post(API_PATH.VERIFY_CAPTCHA + `?value=${value}`)
        .then((res) => {
          // Check response status and send back to the client-side
          if (res.data === "Human") {
            // message.success("Human 👨 👩");
            if (code) {
              handleSubmitTwo();
            } else {
              handleSubmit();
            }
          } else {
            message.error("It seems like it's a robot. Please try again.");
          }
        });
    } catch (error) {
      // Handle any errors that occur during the reCAPTCHA verification process
      console.error(error);
    }
  };

  // const asyncScriptOnLoad = () => {
  //     // this.setState({ callback: "called!" });
  //     setRecaptchaLoaded(true)
  // };

  return (
    <React.Fragment>
      <ExpertAuth />

      <div className="expertRegistration step1">
        <h5>ACCEPTANCE OF TERMS</h5>
        <p>
          Please note, this tutorial only highlights important Clauses and is
          NOT a complete summary of the Confidentiality Terms & Conditions.
          Please read the{" "}
          <a
            target="_blank"
            onClick={handleConvertToPDF}
            style={{ color: "#5746ec", fontWeight: 500, cursor: "pointer" }}
          >
            Confidentiality Terms and Conditions
          </a>{" "}
          for a more elaborate understanding of the topics discussed. Kindly
          select the checkbox below, and confirm your full name, to acknowledge
          that you have read and accepted all the Terms & Conditions mentioned
          in the document.
        </p>

        <div>
          <Form layout="vertical" autoComplete="off" form={form}>
            <Row>
              <Col xs={12}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "You must agree to the Terms & Conditions",
                      validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject(),
                    },
                  ]}
                >
                  <Checkbox
                    checked={isCheckboxChecked}
                    onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                  >
                    <span>
                      I agree and shall adhere to the above mentioned{" "}
                      <a
                        style={{
                          color: "#5746ec",
                          fontWeight: 500,
                          cursor: "pointer",
                        }}
                        onClick={handleConvertToPDF}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms & Conditions
                      </a>
                      .
                    </span>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>

            <Row className="justify-content-between">
              <Col xs={12} md={5}>
                {/* <Row>
                                    <Col xs={12}>
                                        <Form.Item
                                            // name="project_duration"
                                            name={"consulting_rate_duration"}
                                            label="Charge Duration"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Charge duration is required!",
                                                },
                                            ]}
                                        >
                                            <Radio.Group name="project_duration" value={addData.charge_duration}
                                                // onChange={onExperianceChange}
                                                onChange={(event) => setAddData({ ...addData, charge_duration: event?.target?.value })}>
                                                <Radio.Button className="" value="hourly">Hourly</Radio.Button>
                                                <Radio.Button className="" value="daily">Daily</Radio.Button>
                                            </Radio.Group>

                                        </Form.Item>
                                    </Col>
                                </Row> */}
                <Row>
                  <Col xs={12}>
                    <Form.Item
                      name="hourly_rate_call_cunsltn"
                      label="Consulting Rate"
                      rules={[
                        {
                          required: true,
                          message: "Please fill out this field.",
                        },
                      ]}
                    >
                      <Input
                        name="hourly_rate_call_cunsltn"
                        type="number"
                        addonBefore={selectBefore}
                        // addonAfter={selectAfter}
                        size="large"
                        value={addData?.hourly_rate_call_cunsltn}
                        onChange={hourly_rate}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {code ? (
                  <>
                    <Row>
                      <Col xs={12}>
                        <Form.Item
                          // name="firstName"
                          label="First Name"
                          rules={[
                            {
                              required: true,
                              message: "Please input your first name!",
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            name="firstName"
                            autoComplete="nope"
                            size="large"
                            placeholder="First Name"
                            value={linkedinData?.firstName}
                            onChange={handleStep1Change}
                            readOnly={code ? true : false}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <Form.Item
                          // name="lastName"
                          label="Last Name"
                          rules={[
                            {
                              required: true,
                              message: "Please input your last name!",
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            name="lastName"
                            autoComplete="nope"
                            size="large"
                            placeholder="Last Name"
                            value={linkedinData?.lastName}
                            onChange={handleStep1Change}
                            readOnly={code ? true : false}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col xs={12}>
                        <Form.Item label="First Name">
                          <Input
                            size="large"
                            type="text"
                            value={storedStep1?.data?.first_name}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <Form.Item label="Last Name">
                          <Input
                            size="large"
                            type="text"
                            value={storedStep1?.data?.last_name}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col xs={12}>
                    <div className="d-flex align-items-center">
                      <h6 className="mb-0 mt-0">
                        <strong>Date</strong>
                      </h6>
                      <h6 className="mb-0 mt-0 ms-3">
                        <strong>{currentDate}</strong>
                      </h6>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={6}>
                <hr className="d-block d-lg-none" />{" "}
                <p style={{ fontWeight: 500 }}>Nextyn Advisory Pvt. Ltd.</p>
                <p>
                  Signature:{" "}
                  <img
                    style={{
                      width: "50px",
                    }}
                    src={rasesh_sign}
                    alt="Rasesh Signature"
                  />
                </p>
                <p>Name: Rasesh Seth</p>
                <p>Name: Director</p>
                <p>Date: {currentDate}</p>
              </Col>
            </Row>
            <div
              className="mt-4"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {code ? (
                <>
                  <Form.Item>
                    <NavLink
                      to={"/expert-register?regType=1&step=3&type=linkedin"}
                      onClick={() => setCurrentStepConsultant(3)}
                    >
                      <Button
                        size="large"
                        className="btn btn-responsive expertButtonNext"
                      >
                        Prev
                      </Button>
                    </NavLink>
                  </Form.Item>
                </>
              ) : (
                <>
                  <>
                    <Form.Item>
                      <NavLink
                        to={`/expert-register?step=3&regType=${registrationType}`}
                        onClick={() => setCurrentStepConsultant(3)}
                      >
                        <Button
                          size="large"
                          className="btn btn-responsive expertButtonNext"
                        >
                          Prev
                        </Button>
                      </NavLink>
                    </Form.Item>
                  </>
                </>
              )}

              {code ? (
                <Form.Item shouldUpdate>
                  <Button
                    onClick={() => {
                      captchaRef?.current?.execute();
                    }} //handleSubmit2
                    className="btn btn-responsive expertButtonNext text-end"
                    size="large"
                    htmlType="submit"
                    disabled={
                      !isCheckboxChecked ||
                      !form.isFieldsTouched(true) ||
                      !!form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                  >
                    {loading ? (
                      <>
                        <Spinner
                          size="sm"
                          style={{
                            width: "1rem",
                            height: "1rem",
                            marginRight: "5px",
                          }}
                        />
                        Complete Registration
                      </>
                    ) : (
                      "Complete Registration"
                    )}
                  </Button>

                  {code && (
                    <ReCAPTCHA
                      style={{ display: "inline-block" }}
                      ref={captchaRef}
                      size="invisible"
                      sitekey={ENV.REACT_APP_SITE_KEY}
                      onChange={handleChange}
                    // asyncScriptOnLoad={asyncScriptOnLoad}
                    />
                  )}
                </Form.Item>
              ) : (
                <>
                  <Form.Item shouldUpdate>
                    {() => (
                      <Button
                        onClick={() => {
                          captchaRef?.current?.execute();
                        }} //handleSubmit
                        className="btn btn-responsive expertButtonNext"
                        size="large"
                        htmlType="submit"
                        disabled={
                          !isCheckboxChecked ||
                          !form.isFieldsTouched(true) ||
                          !!form
                            .getFieldsError()
                            .filter(({ errors }) => errors.length).length
                        }
                      >
                        {loading ? (
                          <>
                            <Spinner
                              size="sm"
                              style={{
                                width: "1rem",
                                height: "1rem",
                                marginRight: "5px",
                              }}
                            />
                            Complete Registration
                          </>
                        ) : (
                          "Complete Registration"
                        )}
                      </Button>
                    )}
                  </Form.Item>
                  {!code && (
                    <ReCAPTCHA
                      style={{ display: "inline-block" }}
                      ref={captchaRef}
                      size="invisible"
                      sitekey={ENV.REACT_APP_SITE_KEY}
                      onChange={handleChange}
                    // asyncScriptOnLoad={asyncScriptOnLoad}
                    />
                  )}
                </>
              )}
            </div>
          </Form>
        </div>
      </div>

      <div className="container-fluid d-none">
        <div id="termsAndConditions">
          <Row>
            <Col>
              <section className="signedPDF">
                <h3
                  style={{
                    fontSize: "30px",
                    fontWeight: 700,
                    textAlign: "center",
                    fontFamily: "Work Sans",
                  }}
                >
                  Independent Consultant
                </h3>
                <h4
                  style={{
                    fontSize: "25px",
                    fontWeight: 700,
                    textAlign: "center",
                    fontFamily: "Work Sans",
                  }}
                >
                  Terms & Conditions of Engagement
                </h4>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily: "Work Sans",
                  }}
                >
                  Terms of Engagement
                </h4>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  These Terms of Engagement (the “Agreement”) are entered into
                  between you (“Independent consultant”) and Nextyn and its
                  subsidiaries or affiliates (“Nextyn”, “we”, “us”, “our”) and,
                  together with our Privacy Policy, govern your relationship
                  with Nextyn and your participation in Interchanges & Projects
                  as defined below. Acceptance of these Terms of Engagement is a
                  prerequisite to your participation in any Interchange and/or
                  Project with a Client.
                </p>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily: "Work Sans",
                  }}
                >
                  Definitions
                </h4>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  “Nextyn” means a brand under the registered company of Nextyn
                  Advisory Private Limited, having its registered office in
                  Mumbai, India and Nextyn Pte. Ltd., having its registered
                  office in Singapore.
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  {" "}
                  “Client” means any organization and their designated contacts
                  and representatives you may interact with in the context of an
                  Interchange and/or Project in accordance with the terms of
                  this Agreement.
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  {" "}
                  “Interchange and/or Project” means a professional exchange
                  involving Client(s) and Independent Consultant(s) which may be
                  short or long-term in nature and take the form of a remote
                  consultation (telephone, email or other form of electronic
                  messaging), in-person consultation, information provision in
                  the form of reports and surveys, or advisory or employment
                  relationships with Client or Client’s affiliate(s).
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  {" "}
                  “Mandate Scope” means a description of the subject matter to
                  be covered in the context of an Interchange and/or Project.
                </p>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily: "Work Sans",
                  }}
                >
                  General Eligibility
                </h4>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  Our Clients need to be able to rely on the fact that, in
                  participating in an Interchange and/or Project with them, you
                  are eligible to do so and are not violating any obligations
                  you may owe to any third parties (e.g. your employer, former
                  employer etc.). By accepting these Terms of Engagement, you
                  are confirming that: (i) your participation as an individual
                  is permissible, (ii) you have obtained all necessary and
                  appropriate consents and waivers, and (iii) you will be acting
                  as an independent contractor and not as a Nextyn employee,
                  agent, representative, partner or joint venture.
                </p>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily: "Work Sans",
                  }}
                >
                  Background Information
                </h4>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  You agree that any information you provide or confirm to
                  Nextyn, including that relating to your career background,
                  industries of expertise and your personal data ("Background
                  Information") will be true, accurate and not misleading. In
                  particular, you confirm and agree that you will inform us of
                  any inaccuracies or omissions in your career history summary
                  and on projects undertaken in the past.
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  {" "}
                  You further agree that Nextyn, in its absolute discretion, may
                  share some or all of such Background Information with
                  Client(s) to enable them to schedule a potential Interchange
                  and/or Project with you
                </p>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily: "Work Sans",
                  }}
                >
                  Commitments before an Interchange and/or Project
                </h4>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  Nextyn may contact you regarding your possible participation
                  in Interchange(s) and/or Project(s). Discussing a particular
                  Mandate Scope with you merely constitutes an attempt at
                  determining your potential suitability and Nextyn cannot
                  guarantee you will be contacted again or indeed will engage in
                  an Interchange and/or Project with our Client as our Clients
                  determine with whom they wish to engage in Interchanges and/or
                  Projects at their sole discretion.
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  {" "}
                  Upon learning of a given Mandate Scope, it is your
                  responsibility to determine whether participating in a
                  prospective Interchange and/or Project given the Mandate Scope
                  is appropriate. Participation in any Interchange and/or
                  Project is always at your discretion, and you may decline to
                  participate without reason. You should categorically decline
                  participation in any Interchange and/or Project if:
                </p>
                <ul className="listType">
                  <li
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      fontFamily: "Work Sans",
                    }}
                  >
                    you know that by participating you are violating any law or
                    regulation or breaching any agreement with or obligation
                    towards a third party (e.g person, employer, former employer
                    or other);
                  </li>
                  <li
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      fontFamily: "Work Sans",
                    }}
                  >
                    your participation in the Interchange and/or Project would
                    give rise to any professional, regulatory or ethical
                    conflict, or would result in a breach of a duty of
                    confidentiality to which you are subject;
                  </li>
                  <li
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      fontFamily: "Work Sans",
                    }}
                  >
                    your participation in the Interchange and/or Project would
                    give rise to any professional, regulatory or ethical
                    conflict, or would result in a breach of a duty of
                    confidentiality to which you are subject;
                  </li>
                  <li
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      fontFamily: "Work Sans",
                    }}
                  >
                    n a personal capacity, you have ever been convicted of a
                    felony, been the subject of sanctions administered by any
                    jurisdiction, been the subject of an investigation by a
                    securities regulator or other regulatory authority or been a
                    defendant in any proceeding where fraud or violation of laws
                    or regulations is alleged. You confirm that you have never
                    been subject to any such convictions, sanctions,
                    investigations or proceedings; or
                  </li>
                  <li
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      fontFamily: "Work Sans",
                    }}
                  >
                    you are a serving member of the armed forces of any country.
                  </li>
                </ul>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  Provided that you have determined that it is appropriate to
                  participate in a prospective Interchange and/or Project given
                  the particular Mandate Scope, and our Client has requested
                  Nextyn to schedule a Consultation, we will contact you to
                  determine your availability and share with you our Client’s
                  identity, if permitted to. At this point, if you believe the
                  Client to be a competitor of your employer or the entity on
                  whose board you serve or there to be any other conflict of
                  interest, you must decline to participate.
                </p>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily: "Work Sans",
                  }}
                >
                  Commitments during an Interchange and/or Project
                </h4>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  During the course of its business, our Client may carry out
                  investment activities, including, but not limited to, buying
                  or selling public or privately held securities. Accordingly,
                  our Client does not wish to receive any inside or other
                  non-public, confidential or proprietary information from you.
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  You agree that, while participating in an Interchange and/or
                  Project:
                </p>
                <ul className="listType">
                  <li
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      fontFamily: "Work Sans",
                    }}
                  >
                    you will not discuss or disclose information that is
                    relatable to a company of which you are an officer, director
                    or employee;
                  </li>
                  <li
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      fontFamily: "Work Sans",
                    }}
                  >
                    you will not disclose, and your participation in the
                    Interchange and/or Project will not otherwise result in the
                    disclosure of, any:
                  </li>
                  <li
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      fontFamily: "Work Sans",
                    }}
                  >
                    inside information or other non-public information
                    concerning a company whose securities are traded on a
                    recognised exchange or a quoted instrument;
                  </li>
                  <li
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      fontFamily: "Work Sans",
                    }}
                  >
                    trade secret; or
                  </li>
                  <li
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      fontFamily: "Work Sans",
                    }}
                  >
                    other information that you have a duty to keep confidential,
                    or that you have obtained from any person who expects you to
                    keep such information confidential, or could reasonably
                    expect to be considered confidential;
                  </li>
                  <li
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      fontFamily: "Work Sans",
                    }}
                  >
                    you will not provide investment, financial, accountancy,
                    legal, medical, or other professional advice to any Client
                    in the context of the Interchange and/or Project. For the
                    avoidance of doubt, we do not consider your general industry
                    perspective, opinions and insights to constitute advice;
                  </li>
                  <li
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      fontFamily: "Work Sans",
                    }}
                  >
                    if you are an auditor or a former auditor, you will not
                    comment on companies or organisations you currently audit or
                    have audited in the last 5 years;
                  </li>
                  <li
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      fontFamily: "Work Sans",
                    }}
                  >
                    if you have worked in the accounting and finance department
                    of any company within the last 12 months, you will not
                    discuss accounting or financial issues relating to that
                    company or that company’s affiliates;
                  </li>
                  <li
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      fontFamily: "Work Sans",
                    }}
                  >
                    if you currently serve as a government official in any
                    jurisdiction, you will not discuss any government
                    legislation, government regulation, government policy or
                    government business that you are in a position to influence
                    or vote upon;
                  </li>
                  <li
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      fontFamily: "Work Sans",
                    }}
                  >
                    you will not disclose any material non-public or other
                    confidential information relating to a live clinical trial
                    in which you are involved; and
                  </li>
                  <li
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      fontFamily: "Work Sans",
                    }}
                  >
                    you will comply with all applicable laws, statutes,
                    regulations and codes relating to anti-bribery and
                    anti-corruption, including, without limitation, The
                    Prevention of Corruption Act, 1988 (India) along with its
                    amendments or any other law prohibiting corruption and
                    bribery in a country other than India (“Anti-Corruption
                    Laws”).
                  </li>
                  <li
                    style={{
                      fontSize: "14px",
                      fontWeight: 300,
                      fontFamily: "Work Sans",
                    }}
                  >
                    you will share appropriate information with the client
                    during an Interchange. If the client feels that you do not
                    have appropriate information/expertise on the matter of
                    discussion, the client has the right to discontinue the
                    consultation within the first 15 minutes. In such case, you
                    are not entitled to be compensated for the consultation.
                  </li>
                </ul>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  If during an Interchange and/or Project, you determine any
                  professional, regulatory or ethical conflict, or if you
                  identify any other reason why you may not or should not
                  continue to participate in the Interchange and/or Project, you
                  should discontinue your participation in the Interchange
                  and/or Project and notify Nextyn immediately.
                </p>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily: "Work Sans",
                  }}
                >
                  Commitments after an Interchange and/or Project
                </h4>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  Client relationships for Nextyn are an important asset to the
                  Company. Accordingly, you agree, following your introduction
                  to any Client by Nextyn, that:
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  a) unless you have a consulting, employment or other business
                  relationship (“Business Relationship”) with such Client that
                  predates the introduction, you will not knowingly solicit from
                  or propose to any such Client any kind of Business
                  Relationship without such Client’s express consent; and
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  b) you will in any event promptly notify us in writing of any
                  Business Relationship with a Client that takes place within
                  one year of your initial introduction.
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  You agree to provide all reasonable assistance to Nextyn and
                  our Client in responding to any regulatory enquiries relating
                  to any Interchange and/or Project in which you are involved.
                </p>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily: "Work Sans",
                  }}
                >
                  Invoicing & Payment
                </h4>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  Once the consultation has been completed, you shall raise an
                  invoice to us within 7 working days for the same. The invoice
                  shall be as per the pre-decided consulting rate for the
                  assignment and shall be inclusive of all Government or local
                  taxes, as applicable in your jurisdiction. The invoice must be
                  emailed to{" "}
                  <a href="mailto:accounts@nextyn.com">accounts@nextyn.com</a>{" "}
                  and must consist of your bank details for us to successfully
                  remit the fee.
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  The fee shall be remitted to your account, no more than 30
                  business days after successfully completing the consultation
                  and/or project.
                </p>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily: "Work Sans",
                  }}
                >
                  Confidentiality and Proprietary Information
                </h4>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  By participating in an Interchange and/or Project, certain
                  information (“Proprietary Information”), including but not
                  limited to
                  <ul className="listType">
                    <li
                      style={{
                        fontSize: "14px",
                        fontWeight: 300,
                        fontFamily: "Work Sans",
                      }}
                    >
                      the existence or subject matter of the Interchange(s)
                      and/or Project(s),{" "}
                    </li>
                    <li
                      style={{
                        fontSize: "14px",
                        fontWeight: 300,
                        fontFamily: "Work Sans",
                      }}
                    >
                      the identity and details relating to the Client, and
                    </li>
                    <li
                      style={{
                        fontSize: "14px",
                        fontWeight: 300,
                        fontFamily: "Work Sans",
                      }}
                    >
                      any other confidential information or intellectual
                      property of Nextyn or its Client (including information
                      relating to any actual or potential investment, trading
                      and/or any business decisions of Client), may be disclosed
                      to you or become known to you. You agree not to disclose
                      any Proprietary Information to any third party other than
                      the party that disclosed it to you, or to use or benefit
                      from any Proprietary Information (including by dealing in
                      the securities of any company to which Proprietary
                      Information relates or encouraging any other person to do
                      so). For the avoidance of doubt, you may not publicize or
                      market your participation in Interchange(s) and/or
                      Project(s) or your relationship with Nextyn.
                    </li>
                  </ul>
                </p>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily: "Work Sans",
                  }}
                >
                  Communication with Third Parties
                </h4>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  If you speak with any third person prior to or following an
                  Interchange and/or Project, you agree.
                  <ul>
                    <li
                      style={{
                        fontSize: "14px",
                        fontWeight: 300,
                        fontFamily: "Work Sans",
                      }}
                    >
                      to comply with your obligations relating to Proprietary
                      Information at all times, ii) not to compensate such
                      person in any way for information, and
                    </li>
                    <li
                      style={{
                        fontSize: "14px",
                        fontWeight: 300,
                        fontFamily: "Work Sans",
                      }}
                    >
                      to disclose clearly that you are acting on behalf of a
                      third party (without identifying our Client). In the
                      context of participating in Interchange(s) and/or
                      Project(s) with our Client(s), you should neither before
                      nor after an Interchange and/or Project, consult with your
                      contacts at any company asking them for information about
                      that company or about the scope of the Interchange and/or
                      Project.
                    </li>
                  </ul>
                </p>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily: "Work Sans",
                  }}
                >
                  Intellectual Property
                </h4>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  If, in connection with an Interchange and/or Project, you
                  provide a Client or Nextyn with any verbal communication,
                  written documentation or any other written (or otherwise
                  documented) material (including, without limitation, reports,
                  data, studies, charts, specifications and programmes)
                  (“Documentation”):
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  a) you irrevocably and unconditionally warrant and represent
                  that you are the sole unencumbered author, owner or holder of
                  an appropriate, valid and sufficient license of all rights,
                  title and interest (including the intellectual property
                  rights) in such Documentation.
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  b) you hereby grant (and you warrant that you have the right
                  to grant) our Client a nonexclusive, irrevocable, perpetual,
                  royalty-free, fully sub-licensable, fully paid up, worldwide
                  license to use, copy, modify, delete and adapt the
                  Documentation; and
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  c) you indemnify and agree to keep indemnified Nextyn, its
                  Clients and any agent, service provider or consultant
                  appointed by either Nextyn or its Clients to participate in,
                  and conduct, an Interchange and/or Project, against any and
                  all liabilities in connection with claims or proceedings based
                  on the claim that the Documentation infringes any intellectual
                  property rights of yours or a third party.
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  d) you agree that Nextyn has the right to publish the
                  Independent consultant’s interaction with Nextyn, including
                  but not limited to discussions, insights, and any other
                  relevant information. This publication may be in the form of
                  transcripts, reports, case studies, or other suitable formats.
                </p>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily: "Work Sans",
                  }}
                >
                  Term & Termination
                </h4>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  These Terms of Engagement come into force on the date of your
                  acceptance and will continue to be in force until terminated
                  by either party at any time upon giving written notice to the
                  other. Following termination, you will continue to be bound by
                  your obligations listed under your Responsibilities after an
                  Interchange and/or Project, Confidentiality and Proprietary
                  Information, Communication with Third Parties, Intellectual
                  Property and Third Parties.
                </p>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily: "Work Sans",
                  }}
                >
                  Liability
                </h4>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  We shall under no circumstances whatever be liable to you,
                  whether in contract, tort (including negligence), breach of
                  statutory duty or otherwise, for any direct loss or any
                  indirect or consequential loss or damage of any kind arising
                  under or in connection with these Terms of Engagement PROVIDED
                  THAT nothing in this Agreement shall limit or exclude (or
                  purport to limit or exclude) our liability for death or
                  personal injury caused by its negligence, or the negligence of
                  its employees, agents or subcontractors and fraud or
                  fraudulent misrepresentation.
                </p>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily: "Work Sans",
                  }}
                >
                  Third Parties
                </h4>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  You agree that these Terms of Engagement are intended to
                  benefit Clients and enable them to satisfy themselves that all
                  Interchanges and/or Projects occur in accordance with the
                  Terms of Engagement, applicable laws or regulations and will
                  not lead to the disclosure of restricted information,
                  including inside and/or material non-public information. You
                  further agree that Clients may rely on and enforce these Terms
                  of Engagement. Apart from you, Nextyn and our Clients, no
                  third party is entitled to rely on or enforce these Terms of
                  Engagement.
                </p>
                <h4
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily: "Work Sans",
                  }}
                >
                  Additional Terms & Conditions
                </h4>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  If any term, condition, or provision of these Terms of
                  Engagement (or part thereof) is determined to be unlawful,
                  invalid, void, or for any reason unenforceable, the validity
                  and enforceability of the remaining terms, conditions and
                  provisions (or parts thereof) shall not in any way be affected
                  or impaired.
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  We may provide a translation of these Terms of Engagement for
                  reference purposes. For the avoidance of doubt, in the event
                  of any inconsistency between the English version of the Terms
                  of Engagement and a translation provided to you, the terms of
                  the English version of the Terms of Engagement shall always
                  prevail.
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  These Terms of Engagement are governed by and shall be
                  construed in accordance with the Indian Law. The parties
                  hereby submit to the exclusive jurisdiction of the Indian
                  courts in relation to any claims or other matters arising from
                  or related hereto, whether in tort, contract or otherwise.
                </p>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Work Sans",
                  }}
                >
                  These Terms of Engagement constitute the entire agreement
                  between you and us relating to the subject matter herein. In
                  the event that there is a conflict between these Terms of
                  Engagement and any separate acknowledgements and/or
                  undertakings given by you to Nextyn in connection with any
                  Interchange(s) and/or Project(s), these Terms of Engagement
                  will prevail. Any amendment to this Agreement must be in
                  writing, accepted by both parties and expressed to constitute
                  an amendment to these Terms of Engagement.
                </p>
              </section>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RegisterConsultantStep4;
