import React, { useEffect, useState } from "react";
// import { Modal } from "react-bootstrap";
import { Spin } from "antd";

function ScopeTeamsli({ datas, id }) {

  const [Loading, setLoading] = useState(true);
  // const [, setModalToggle] = useState(false);
  // const [, setModalToggle1] = useState(false);
  // const [, setDeleteEmail] = useState("");

  // const handleClose = () => setModalToggle(false);
  // const handleClose1 = () => setModalToggle1(false);

  useEffect(() => {
    setLoading(false);
  }, [datas]);

  // const DeleteModal = (email, name) => {
  //   let emailname = email.split(" ");
  //   if (emailname[0] !== "Invite") {
  //     setDeleteEmail(email);
  //   } else {
  //     setDeleteEmail(name);
  //   }
  //   setModalToggle(true);
  // };

  // const convertDate = (date) => {
  //   if (date !== null && date !== "" && date) {
  //     var d = new Date(date);
  //     var day = d.getDate();
  //     var x = d.toDateString().substr(4, 3);
  //     var year = d.getFullYear();
  //     let p = `${x} ${day}, ${year}`;
  //     return p;
  //   } else {
  //     return "--";
  //   }
  // };

  // const handelDelete = (deleteEmail) => {
  //   let emailID = deleteEmail.split("@")[0];
  //   axios
  //     .get(`${API_PATH.REMOVE_INVITES}/${id}/${emailID}`)
  //     .then((res) => {
  //       setModalToggle(false);
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 3000);
  //       if (res.data.data === "Remove Successfully") {
  //         return toast.success("Removed Successfully", {
  //           position: toast.POSITION.TOP_RIGHT,
  //           autoClose: 3000,
  //         });
  //       }

  //       //window.location.reload();
  //     })
  //     .catch((err) => {
  //     });
  // };

  const editName = (email) => {
    if (typeof email !== "string") {
      return "";
    }

    let emailID = email.split("@")[0];
    let [firstName, lastName] = emailID.split(".");
    firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    lastName = lastName
      ? lastName.charAt(0).toUpperCase() + lastName.slice(1)
      : "";
    return `${firstName} ${lastName}`.trim();
  };

  if (!Loading) {
    return (
      <>
        <div className="client-teams-ul-li mt-4">
          <ul className="list-unstyled">
            {datas && datas?.map((curElem, id) => {
              return (
                <>
                  <li key={id} className="mb-3">
                    <div className="teams-li ">
                      <figure>
                        <div className="avatarImg">
                          <img
                            width="40px"
                            src={`https://ui-avatars.com/api/?&rounded=true&font-size=0.38&background=EFEFEF&color=f33636&name=${curElem?.email || curElem?.name || "NoName"
                              }`}
                            alt={curElem?.email || curElem?.name}
                          />
                        </div>
                      </figure>
                      <h5 className="m-0" key={id}>
                        {curElem?.name ? curElem?.name : editName(curElem?.email || curElem?.firstNmae)}
                        {curElem?.email && (
                          <span className="d-block">{`${curElem?.email}`}</span>
                        )}
                        {/* <span className="d-block" style={{ color: "#c40a36" }}>
                          {curElem?.Invite === "Invite pending"
                            ? `Invite pending (sent ${convertDate(
                              curElem?.created_at
                            )})`
                            : null}
                        </span> */}
                      </h5>
                    </div>
                  </li>
                </>
              );
            })}
          </ul>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center text-center" style={{ minHeight: '100vh' }}>
          <Spin size="large" />
        </div>
      </>
    );
  }
}
export default ScopeTeamsli;
