import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Divider, Modal, notification } from "antd";
import React, { useState } from "react";
import API_PATH from "../../Constants/api-path";

function CloseProject({ show, setShow, teamIdClosed, updatedStatus = 2,
  GetLiveProjectList = () => { },
  GetCompleteProjectList = () => { },

}) {

  const userData = JSON.parse(localStorage.getItem("userData"));
  const [, setLoading] = useState(false);

  const AddTeamProject = async () => {
    setLoading(true);
    try {

      const res = await fetch(`${API_PATH?.TEAM_PROJECT_ADD}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          project_id: teamIdClosed,
          status: updatedStatus
        }),
      });

      const result = await res?.json();
      if (result) {
        if (result && (res.status === 200 || res.status === true)) {
          setShow(false);
          GetLiveProjectList();
          GetCompleteProjectList();

          let message = updatedStatus === 2 ? "Project has been closed" : updatedStatus === 1 ? "Project has been set to active again" : "Project has been put on hold"
          notification.success({
            message: message,
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });
        }
      }

    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }

    setLoading(false);
  };

  return (
    <Modal
      title={<div>Close Project<Divider className="m-2" /></div>}
      open={show}
      onCancel={() => setShow(false)}
      centered
      size={"lg"}
      footer={false}
    >
      {updatedStatus === 2 && <div>
        Are you sure you want to close this project ?
      </div>}
      {updatedStatus === 3 && <div>
        Are you sure you want to hold this project for now ?
      </div>}
      {updatedStatus === 1 && <div>
        Are you sure to make this project Active?
      </div>}
      <div className="d-flex justify-content-end mt-3">
        <button className="secondaryBttn btn" onClick={() => setShow(false)}>
          No
        </button>
        <button className="viewButton btn ms-2" onClick={() => AddTeamProject()}>Yes</button>
      </div>
    </Modal >
  );
}

export default CloseProject;
