import {Navigate} from "react-router-dom";
import useDecodedToken from "../../Utils/useDecodedToken";

const PrivateRouteTwo = ({children}) => {
  const decodedToken = useDecodedToken();
  // let isExpertAuth = JSON.parse(localStorage.getItem("isExpertAuth"));

  // if (decodedToken && !isExpertAuth) {
  if (decodedToken) {
    return <Navigate to="/dashboard" replace />;
  } else {
    return children;
  }
};

export default PrivateRouteTwo;
