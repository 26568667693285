import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import {
  Divider,
  Modal,
  Space,
  Table,
  Tooltip,
  notification
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import "react-h5-audio-player/lib/styles.css";
import { MdDelete, MdEdit } from "react-icons/md";
import { NavLink, createSearchParams, useNavigate } from "react-router-dom";
import "../../App.css";
import API_PATH from "../../Constants/api-path";
import "../../assests/css/custom.css";

const ExotelTable = () => {
  const dataSource = [
    {
      key: "1",
      name: "1",
      email: "SUPER ADMIN",
      role: "access to all funtions of the dashboard",
      action: (
        <>
          <Space size="middle">
            <EditOutlined
              onClick={() => setModal3Open(true)}
              style={{ fontSize: "18px" }}
            />
            <DeleteOutlined
              onClick={() => setModal2Open(true)}
              style={{ fontSize: "18px" }}
            />
          </Space>
        </>
      ),
    },
    {
      key: "2",
      name: "2",
      email: "SUPER ADMIN",
      role: "access to all funtions of the dashboard",
      action: (
        <>
          <Space size="middle">
            <EditOutlined
              onClick={() => setModal3Open(true)}
              style={{ fontSize: "18px" }}
            />
            <DeleteOutlined
              onClick={() => setModal2Open(true)}
              style={{ fontSize: "18px" }}
            />
          </Space>
        </>
      ),
    },
    {
      key: "3",
      name: "3",
      email: "SUPER ADMIN",
      role: "access to all funtions of the dashboard",
      action: (
        <>
          <Space size="middle">
            <EditOutlined
              onClick={() => setModal3Open(true)}
              style={{ fontSize: "18px" }}
            />
            <DeleteOutlined
              onClick={() => setModal2Open(true)}
              style={{ fontSize: "18px" }}
            />
          </Space>
        </>
      ),
    },
  ];
  const [searchText,] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: dataSource.length,
  });
  const history = useNavigate();
  const [modal2Open, setModal2Open] = useState(false);
  const [, setModal3Open] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [loading, setLoading] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [managerId, setManagerId] = useState("");
  const [filteredClientList, setFilteredClientList] = useState([]);
  const permission = useMemo(
    () => userData?.data?.roleData?.[0]?.role_permission,
    [userData?.data?.roleData]
  );
  const goToDetailsPage = (_id) => {
    const role_id = _id;
    history({
      pathname: "/dashboard/addRoles",
      search: `?${createSearchParams({ role_id })}`,
    });
  };

  useEffect(() => {
    GetRoleList();
  }, []);

  const GetRoleList = async () => {
    try {
      const res = await fetch(`${API_PATH.ROLE_LIST}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setRoleList(result?.data);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const DeleteRole = async (_id) => {
    // e.preventDefault();
    setLoading(true);

    try {
      const res = await fetch(`${API_PATH.ROLE_DELETE}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify({
          id: [_id],
        }),
      });

      const result = await res.json();
      if (result) {
        GetRoleList();
        if (res.status === 200 || res.status === true) {
          notification.success({
            message: "Role deleted successful",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });

          // window.location.reload();
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };


  const columns = [
    {
      title: "ID",
      render: (text, record, rowIndex) => rowIndex + 1,
    },
    {
      title: "Role",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (_id) => (
        // <Space size="middle">
        //   <Tooltip color="#5746EB" title="Edit" placement="top">
        //     <MdBorderColor
        //       onClick={() => goToDetailsPage(_id)}
        //       style={{ fontSize: "18px", cursor: "pointer" }}
        //     ></MdBorderColor>
        //   </Tooltip>

        //   <Tooltip color="#5746EB" title="Delete" placement="top">
        //     <MdDelete
        //       onClick={() => {
        //         setModal2Open(true);
        //         getMangerId(_id);
        //       }}
        //       style={{ fontSize: "18px", cursor: "pointer" }}
        //     />
        //   </Tooltip>

        // </Space>
        <div className="d-flex expertActions align-items-center gap-1">
          <Tooltip placement="top" title={!permission?.[10]?.roles?.edit ? "Permission Denied"
            : "Edit"} color="#5746EB">
            <span>
              <button
                size="large"
                disabled={!permission?.[10]?.roles?.edit}
                className="viewBttnExpert"
                onClick={() => goToDetailsPage(_id)}
              >
                <MdEdit />
              </button>
            </span>
          </Tooltip>

          <Tooltip placement="top" title={!permission?.[10]?.roles?.delete ? "Permission Denied"
            : "Delete"} color="#5746EB">
            <span>
              <button
                size="large"
                disabled={!permission?.[10]?.roles?.delete}
                className="viewBttnExpert"
                onClick={() => {
                  setModal2Open(true);
                  getMangerId(_id);
                }}
              >
                <MdDelete />
              </button>
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const getMangerId = (_id) => {
    setManagerId(_id);
  };

  useEffect(() => {
    const filteredData = roleList?.filter((record) =>
      Object.values(record)
        .join("")
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
    setFilteredClientList(filteredData);
    setPagination({
      ...pagination,
      current: 1,
      total: filteredData.length,
    });
  }, [roleList, searchText]);


  // const footer = (
  //   <div>
  //     <button
  //       key="cancel"
  //       className="secondaryBttn btn"
  //       onClick={() => setModal2Open(false)}
  //     >
  //       No
  //     </button>
  //     <button
  //       key="submit"
  //       className="viewButton btn ms-2"
  //       onClick={async () => {
  //         await DeleteRole(managerId)
  //         setModal2Open(false);
  //       }}
  //     >
  //       Yes
  //     </button>
  //   </div>
  // );

  return (
    <>
      <div>
        <div className="bk-div float-start w-100">
          {" "}
          {/* <hr className="sp-hr" /> */}
          <div className="body-expart-div">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Roles</h2>
              {permission?.[10]?.roles?.add && <button className="btn viewButton" size="large">
                <NavLink to={"/dashboard/addRoles"}>Add Role</NavLink>
              </button>}
            </div>
            <Table
              dataSource={filteredClientList}
              columns={columns}
              loading={loading}
              pagination={pagination}
              onChange={handleTableChange}
              scroll={{ x: 800 }}
              size="large"
            />
          </div>
        </div>
      </div>

      <Modal
        title={<div className="">Delete Role <Divider className="m-2" /></div>}
        open={modal2Open}
        onOk={async () => {
          await DeleteRole(managerId)
          setModal2Open(false);
        }}
        cancelText={"No"}
        okText={"Yes"}
        onCancel={() => setModal2Open(false)}
        width={800}
        centered
      >
        <div className="mt-4" style={{ width: "100%", textAlign: "left" }}>
          <p>
            Are you sure you want to delete this role?
          </p>{" "}
        </div>
      </Modal>
    </>
  );
};

export default ExotelTable;
