import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  PhoneTwoTone,
  PlaySquareOutlined,
  SearchOutlined
} from "@ant-design/icons";
import {
  Form,
  Input,
  Modal,
  Pagination,
  Select,
  Table,
  notification
} from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "../../App.css";
import "../../assests/css/custom.css";
import API_PATH from "../../Constants/api-path";

const MyFormItemContext = React.createContext([]);
function toArr(str) {
  return Array.isArray(str) ? str : [str];
}
const MyFormItemGroup = ({ prefix, children }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatPath = React.useMemo(
    () => [...prefixPath, ...toArr(prefix)],
    [prefixPath, prefix]
  );
  return (
    <MyFormItemContext.Provider value={concatPath}>
      {children}
    </MyFormItemContext.Provider>
  );
};
const MyFormItem = ({ name, ...props }) => {
  const prefixPath = React.useContext(MyFormItemContext);
  const concatName =
    name !== undefined ? [...prefixPath, ...toArr(name)] : undefined;
  return <Form.Item name={concatName} {...props} />;
};

const dataSource = [
  {
    key: "1",
    to: "+33772004382",
    call: (
      <span>
        <PhoneTwoTone style={{ fontSize: "15px" }} />
      </span>
    ),
    time: "Thu, 27 May 2021 09:31:00",
    caller_id: "+17182858858",
    from: "09769300880",
    records: (
      <>
        <div className="d-flex align-items-center">
          <PlaySquareOutlined className="me-2" style={{ fontSize: "24px" }} />
          <span className="recording_duration">00:00:29</span>
        </div>
      </>
    ),
  },
  {
    key: "2",
    to: "+33772004382",
    call: (
      <span>
        <PhoneTwoTone style={{ fontSize: "15px" }} />
      </span>
    ),
    time: "Thu, 27 May 2021 09:31:00",
    caller_id: "+17182858858",
    from: "09769300880",
    records: "-",
  },
  {
    key: "3",
    to: "+33772004382",
    call: (
      <span>
        <PhoneTwoTone style={{ fontSize: "15px" }} />
      </span>
    ),
    time: "Thu, 27 May 2021 09:31:00",
    caller_id: "+17182858858",
    from: "09769300880",
    records: (
      <>
        <div className="d-flex align-items-center">
          <PlaySquareOutlined className="me-2" style={{ fontSize: "24px" }} />
          <span className="recording_duration">00:00:29</span>
        </div>
      </>
    ),
  },
  {
    key: "4",
    to: "+33772004382",
    call: (
      <span>
        <PhoneTwoTone style={{ fontSize: "15px" }} />
      </span>
    ),
    time: "Thu, 27 May 2021 09:31:00",
    caller_id: "+17182858858",
    from: "09769300880",
    records: (
      <>
        <div className="d-flex align-items-center">
          <PlaySquareOutlined className="me-2" style={{ fontSize: "24px" }} />
          <span className="recording_duration">00:00:29</span>
        </div>
      </>
    ),
  },
  {
    key: "5",
    to: "+33772004382",
    call: (
      <span>
        <PhoneTwoTone style={{ fontSize: "15px" }} />
      </span>
    ),
    time: "Thu, 27 May 2021 09:31:00",
    caller_id: "+17182858858",
    from: "09769300880",
    records: (
      <>
        <div className="d-flex align-items-center">
          <PlaySquareOutlined className="me-2" style={{ fontSize: "24px" }} />
          <span className="recording_duration">00:00:29</span>
        </div>
      </>
    ),
  },
  {
    key: "6",
    to: "+33772004382",
    call: (
      <span>
        <PhoneTwoTone style={{ fontSize: "15px" }} />
      </span>
    ),
    time: "Thu, 27 May 2021 09:31:00",
    caller_id: "+17182858858",
    from: "09769300880",
    records: "-",
  },
  {
    key: "7",
    to: "+33772004382",
    call: (
      <span>
        <PhoneTwoTone style={{ fontSize: "15px" }} />
      </span>
    ),
    time: "Thu, 27 May 2021 09:31:00",
    caller_id: "+17182858858",
    from: "09769300880",
    records: (
      <>
        <div className="d-flex align-items-center">
          <PlaySquareOutlined className="me-2" style={{ fontSize: "24px" }} />
          <span className="recording_duration">00:00:29</span>
        </div>
      </>
    ),
  },
  {
    key: "8",
    to: "+33772004382",
    call: (
      <span>
        <PhoneTwoTone style={{ fontSize: "15px" }} />
      </span>
    ),
    time: "Thu, 27 May 2021 09:31:00",
    caller_id: "+17182858858",
    from: "09769300880",
    records: "-",
  },
  {
    key: "9",
    to: "+33772004382",
    call: (
      <span>
        <PhoneTwoTone style={{ fontSize: "15px" }} />
      </span>
    ),
    time: "Thu, 27 May 2021 09:31:00",
    caller_id: "+17182858858",
    from: "09769300880",
    records: (
      <>
        <div className="d-flex align-items-center">
          <PlaySquareOutlined className="me-2" style={{ fontSize: "24px" }} />
          <span className="recording_duration">00:00:29</span>
        </div>
      </>
    ),
  },
  {
    key: "10",
    to: "+33772004382",
    call: (
      <span>
        <PhoneTwoTone style={{ fontSize: "15px" }} />
      </span>
    ),
    time: "Thu, 27 May 2021 09:31:00",
    caller_id: "+17182858858",
    from: "09769300880",
    records: (
      <>
        <div className="d-flex align-items-center">
          <PlaySquareOutlined className="me-2" style={{ fontSize: "24px" }} />
          <span className="recording_duration">00:00:29</span>
        </div>
      </>
    ),
  },
];

const countryOptions = [
  { code: "+1", name: "United States" },
  { code: "+44", name: "United Kingdom" },
  { code: "+61", name: "Australia" },
  { code: "+33", name: "France" },
  { code: "+49", name: "Germany" },
  { code: "+81", name: "Japan" },
  { code: "+91", name: "India" },
  { code: "+86", name: "China" },
  { code: "+55", name: "Brazil" },
  { code: "+52", name: "Mexico" },
  { code: "+39", name: "Italy" },
  { code: "+7", name: "Russia" },
  { code: "+82", name: "South Korea" },
  { code: "+351", name: "Portugal" },
  { code: "+34", name: "Spain" },
  { code: "+54", name: "Argentina" },
  { code: "+63", name: "Philippines" },
  { code: "+234", name: "Nigeria" },
  { code: "+62", name: "Indonesia" },
  { code: "+55", name: "Anguilla" },
  { code: "+1264", name: "Antigua and Barbuda" },
  { code: "+54", name: "Argentina" },
  { code: "+374", name: "Armenia" },
  { code: "+297", name: "Aruba" },
  { code: "+61", name: "Australia" },
  { code: "+43", name: "Austria" },
  { code: "+994", name: "Azerbaijan" },
  { code: "+1242", name: "Bahamas" },
  { code: "+973", name: "Bahrain" },
  { code: "+880", name: "Bangladesh" },
  { code: "+1246", name: "Barbados" },
  { code: "+375", name: "Belarus" },
  { code: "+32", name: "Belgium" },
  { code: "+501", name: "Belize" },
  { code: "+229", name: "Benin" },
  { code: "+1441", name: "Bermuda" },
  { code: "+975", name: "Bhutan" },
  { code: "+591", name: "Bolivia" },
  { code: "+387", name: "Bosnia and Herzegovina" },
  { code: "+267", name: "Botswana" },
  { code: "+55", name: "Brazil" },
  { code: "+246", name: "British Indian Ocean Territory" },
  { code: "+1284", name: "British Virgin Islands" },
  { code: "+673", name: "Brunei" },
  { code: "+359", name: "Bulgaria" },
  { code: "+226", name: "Burkina Faso" },
  { code: "+257", name: "Burundi" },
  { code: "+855", name: "Cambodia" },
  { code: "+237", name: "Cameroon" },
  { code: "+1", name: "Canada" },
  { code: "+238", name: "Cape Verde" },
  { code: "+599", name: "Caribbean Netherlands" },
  { code: "+1345", name: "Cayman Islands" },
  { code: "+236", name: "Central African Republic" },
  { code: "+235", name: "Chad" },
  { code: "+56", name: "Chile" },
  { code: "+86", name: "China" },
  { code: "+57", name: "Colombia" },
  { code: "+269", name: "Comoros" },
  { code: "+242", name: "Congo - Brazzaville" },
  { code: "+243", name: "Congo - Kinshasa" },
  { code: "+682", name: "Cook Islands" },
  { code: "+506", name: "Costa Rica" },
  { code: "+225", name: "Côte d’Ivoire" },
  { code: "+385", name: "Croatia" },
  { code: "+53", name: "Cuba" },
  { code: "+599", name: "Curaçao" },
  { code: "+357", name: "Cyprus" },
  { code: "+420", name: "Czech Republic" },
  { code: "+45", name: "Denmark" },
  { code: "+253", name: "Djibouti" },
  { code: "+1767", name: "Dominica" },
  { code: "+1809", name: "Dominican Republic" },
  { code: "+670", name: "East Timor" },
  { code: "+593", name: "Ecuador" },
  { code: "+20", name: "Egypt" },
  { code: "+503", name: "El Salvador" },
  { code: "+240", name: "Equatorial Guinea" },
  { code: "+291", name: "Eritrea" },
  { code: "+372", name: "Estonia" },
  { code: "+251", name: "Ethiopia" },
  { code: "+500", name: "Falkland Islands" },
  { code: "+298", name: "Faroe Islands" },
  { code: "+679", name: "Fiji" },
  { code: "+358", name: "Finland" },
  { code: "+33", name: "France" },
  { code: "+594", name: "French Guiana" },
  { code: "+689", name: "French Polynesia" },
  { code: "+241", name: "Gabon" },
  { code: "+220", name: "Gambia" },
  { code: "+995", name: "Georgia" },
  { code: "+49", name: "Germany" },
  { code: "+233", name: "Ghana" },
  { code: "+350", name: "Gibraltar" },
  { code: "+30", name: "Greece" },
  { code: "+299", name: "Greenland" },
  { code: "+1473", name: "Grenada" },
  { code: "+590", name: "Guadeloupe" },
  { code: "+1671", name: "Guam" },
  { code: "+502", name: "Guatemala" },
  { code: "+44", name: "Guernsey" },
  { code: "+224", name: "Guinea" },
  { code: "+245", name: "Guinea-Bissau" },
  { code: "+592", name: "Guyana" },
  { code: "+509", name: "Haiti" },
  { code: "+504", name: "Honduras" },
  { code: "+852", name: "Hong Kong SAR China" },
  { code: "+36", name: "Hungary" },
  { code: "+354", name: "Iceland" },
  { code: "+91", name: "India" },
  { code: "+62", name: "Indonesia" },
  { code: "+98", name: "Iran" },
  { code: "+964", name: "Iraq" },
  { code: "+353", name: "Ireland" },
  { code: "+44", name: "Isle of Man" },
  { code: "+972", name: "Israel" },
  { code: "+39", name: "Italy" },
  { code: "+1876", name: "Jamaica" },
  { code: "+81", name: "Japan" },
  { code: "+44", name: "Jersey" },
  { code: "+962", name: "Jordan" },
  { code: "+7", name: "Kazakhstan" },
  { code: "+254", name: "Kenya" },
  { code: "+686", name: "Kiribati" },
  { code: "+383", name: "Kosovo" },
  { code: "+965", name: "Kuwait" },
  { code: "+996", name: "Kyrgyzstan" },
  { code: "+856", name: "Laos" },
  { code: "+371", name: "Latvia" },
  { code: "+961", name: "Lebanon" },
  { code: "+266", name: "Lesotho" },
  { code: "+231", name: "Liberia" },
  { code: "+218", name: "Libya" },
  { code: "+423", name: "Liechtenstein" },
  { code: "+370", name: "Lithuania" },
  { code: "+352", name: "Luxembourg" },
  { code: "+853", name: "Macau SAR China" },
  { code: "+389", name: "Macedonia" },
  { code: "+261", name: "Madagascar" },
  { code: "+265", name: "Malawi" },
  { code: "+60", name: "Malaysia" },
  { code: "+960", name: "Maldives" },
  { code: "+223", name: "Mali" },
  { code: "+356", name: "Malta" },
  { code: "+692", name: "Marshall Islands" },
  { code: "+596", name: "Martinique" },
  { code: "+222", name: "Mauritania" },
  { code: "+230", name: "Mauritius" },
  { code: "+262", name: "Mayotte" },
  { code: "+52", name: "Mexico" },
  { code: "+691", name: "Micronesia" },
  { code: "+373", name: "Moldova" },
  { code: "+377", name: "Monaco" },
  { code: "+976", name: "Mongolia" },
  { code: "+382", name: "Montenegro" },
  { code: "+1664", name: "Montserrat" },
  { code: "+212", name: "Morocco" },
  { code: "+258", name: "Mozambique" },
  { code: "+95", name: "Myanmar (Burma)" },
  { code: "+264", name: "Namibia" },
  { code: "+674", name: "Nauru" },
  { code: "+977", name: "Nepal" },
  { code: "+31", name: "Netherlands" },
  { code: "+687", name: "New Caledonia" },
  { code: "+64", name: "New Zealand" },
  { code: "+505", name: "Nicaragua" },
  { code: "+227", name: "Niger" },
  { code: "+234", name: "Nigeria" },
  { code: "+683", name: "Niue" },
  { code: "+672", name: "Norfolk Island" },
  { code: "+850", name: "North Korea" },
  { code: "+1670", name: "Northern Mariana Islands" },
  { code: "+47", name: "Norway" },
  { code: "+968", name: "Oman" },
  { code: "+92", name: "Pakistan" },
  { code: "+680", name: "Palau" },
  { code: "+970", name: "Palestinian Territories" },
  { code: "+507", name: "Panama" },
  { code: "+675", name: "Papua New Guinea" },
  { code: "+595", name: "Paraguay" },
  { code: "+51", name: "Peru" },
  { code: "+63", name: "Philippines" },
  { code: "+48", name: "Poland" },
  { code: "+351", name: "Portugal" },
  { code: "+1", name: "Puerto Rico" },
  { code: "+974", name: "Qatar" },
  { code: "+262", name: "Réunion" },
  { code: "+40", name: "Romania" },
  { code: "+7", name: "Russia" },
  { code: "+250", name: "Rwanda" },
  { code: "+590", name: "Saint Barthélemy" },
  { code: "+290", name: "Saint Helena" },
  { code: "+1869", name: "Saint Kitts and Nevis" },
  { code: "+1758", name: "Saint Lucia" },
  { code: "+590", name: "Saint Martin" },
  { code: "+508", name: "Saint Pierre and Miquelon" },
  { code: "+1784", name: "Saint Vincent and the Grenadines" },
  { code: "+685", name: "Samoa" },
  { code: "+378", name: "San Marino" },
  { code: "+239", name: "São Tomé and Príncipe" },
  { code: "+966", name: "Saudi Arabia" },
  { code: "+221", name: "Senegal" },
  { code: "+381", name: "Serbia" },
  { code: "+248", name: "Seychelles" },
  { code: "+232", name: "Sierra Leone" },
  { code: "+65", name: "Singapore" },
  { code: "+1721", name: "Sint Maarten" },
  { code: "+421", name: "Slovakia" },
  { code: "+386", name: "Slovenia" },
  { code: "+677", name: "Solomon Islands" },
  { code: "+252", name: "Somalia" },
  { code: "+27", name: "South Africa" },
  { code: "+82", name: "South Korea" },
  { code: "+211", name: "South Sudan" },
  { code: "+34", name: "Spain" },
  { code: "+94", name: "Sri Lanka" },
  { code: "+249", name: "Sudan" },
  { code: "+597", name: "Suriname" },
  { code: "+47", name: "Svalbard and Jan Mayen" },
  { code: "+268", name: "Swaziland" },
  { code: "+46", name: "Sweden" },
  { code: "+41", name: "Switzerland" },
  { code: "+963", name: "Syria" },
  { code: "+886", name: "Taiwan" },
  { code: "+992", name: "Tajikistan" },
  { code: "+255", name: "Tanzania" },
  { code: "+66", name: "Thailand" },
  { code: "+670", name: "Timor-Leste" },
  { code: "+228", name: "Togo" },
  { code: "+690", name: "Tokelau" },
  { code: "+676", name: "Tonga" },
  { code: "+1868", name: "Trinidad and Tobago" },
  { code: "+216", name: "Tunisia" },
  { code: "+90", name: "Turkey" },
  { code: "+993", name: "Turkmenistan" },
  { code: "+1649", name: "Turks and Caicos Islands" },
  { code: "+688", name: "Tuvalu" },
  { code: "+1340", name: "U.S. Virgin Islands" },
  { code: "+256", name: "Uganda" },
  { code: "+380", name: "Ukraine" },
  { code: "+971", name: "United Arab Emirates" },
  { code: "+44", name: "United Kingdom" },
  { code: "+1", name: "United States" },
  { code: "+598", name: "Uruguay" },
  { code: "+998", name: "Uzbekistan" },
  { code: "+678", name: "Vanuatu" },
  { code: "+39", name: "Vatican City" },
  { code: "+58", name: "Venezuela" },
  { code: "+84", name: "Vietnam" },
  { code: "+681", name: "Wallis and Futuna" },
  { code: "+212", name: "Western Sahara" },
  { code: "+967", name: "Yemen" },
  { code: "+260", name: "Zambia" },
  { code: "+263", name: "Zimbabwe" },
];

const ExotelTable = () => {
  const [searchText, setSearchText] = useState("");
  const [, setFilteredData] = useState(dataSource);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: dataSource.length,
  });
  const [modal2Open, setModal2Open] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [loading, setLoading] = useState(false);
  const [exotelList, setExotelList] = useState("");
  const [connectExotel, setConnectExotel] = useState({
    admin_id: userData?.data?._id,
    country_code: "",
    number: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [callPage, setCallPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [popData, setPopData] = useState("")

  // const handlePlayPause = () => {
  //   setIsPlaying(!isPlaying);
  // };

  const GetExotelList = async (page) => {
    setLoading(true);
    try {
      const res = await fetch(`${API_PATH.EXOTEL_LIST}?page=${page}&limit=10`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const result = await res.json();
      if (result) {
        setExotelList(result?.data);
        setCallPage(result.countData);
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    GetExotelList(currentPage);
  }, [currentPage]);

  const setUserModal = ({ data, id }) => {
    setPopData(id)
    // const res = data?.find((val) => val._id === id);
  };

  const columns = [
    {
      title: "To",
      dataIndex: "to",
      key: "to",
      render: (text, _id) => (
        <span
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#submitted"
          aria-controls="offcanvasRight"
          onClick={() =>
            setUserModal({ data: exotelList, id: _id })
          }
        >
          {text}
        </span>
      ),
    },
    {
      title: "Call",
      dataIndex: "call",
      key: "call",
      render: (text) => (
        <span>
          <PhoneTwoTone style={{ fontSize: "15px" }} />
        </span>
      ),
    },
    {
      title: "Time",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (text, _id) => (
        <span
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#submitted"
          aria-controls="offcanvasRight"
          onClick={() =>
            setUserModal({ data: exotelList, id: _id })
          }
        >
          {moment(text).format("ddd, DD MMMM YYYY HH:mm:ss")}
        </span>
      ),
    },
    {
      title: "Caller ID",
      dataIndex: "callerId",
      key: "callerId",
      render: (text, _id) => (
        <span
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#submitted"
          aria-controls="offcanvasRight"
          onClick={() =>
            setUserModal({ data: exotelList, id: _id })
          }
        >
          {text}
        </span>
      ),
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      render: (text, _id) => (
        <span
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#submitted"
          aria-controls="offcanvasRight"
          onClick={() =>
            setUserModal({ data: exotelList, id: _id })
          }
        >
          {text}
        </span>
      ),
    },
    {
      title: "Recording",
      dataIndex: "records",
      key: "records",
      render: (records) => `${records ? records : "-"}`,
    },
  ];

  const handleSearch = (value) => {
    setSearchText(value);
    const filteredDataSource = dataSource.filter((record) =>
      Object.values(record).join("").toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filteredDataSource);
    setPagination({
      ...pagination,
      current: 1,
      total: filteredDataSource.length,
    });
  };

  const paginationSettings = {
    current: currentPage,
    onChange: handlePageChange,
    pageSize: 10,
    total: callPage,
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const handleChange = (value) => {
    const selectValue = value;

    setConnectExotel({ ...connectExotel, country_code: selectValue });
  };

  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    // const key = e.target.name;

    setConnectExotel({ ...connectExotel, number: value });

    const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters

    if (numericValue.length !== 10) {
      setErrorMessage("Mobile number should be 10 digits");
    } else {
      setErrorMessage(null);
    }
  };

  const onFinish = async () => {
    setLoading(true);
    setModal2Open(false);
    try {
      const res = await fetch(`${API_PATH.EXOTEL_CONNECT}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userData?.token}`,
        },
        body: JSON.stringify(connectExotel),
      });

      const result = await res.json();
      if (result) {
        GetExotelList();
        if (res.status === 200 || res.status === true) {

          notification.success({
            message: "Call has been connected",
            icon: <CheckCircleOutlined style={{ color: "#fff" }} />, // Custom success icon
            style: {
              backgroundColor: "#2ecc71",
              color: "#fff !important",
              border: "1px solid #52c41a",
            },
            duration: 5,
            placement: "topRight",
          });

          // window.location.reload();
        }
      } else {
        notification.error({
          message: "Error: Something went wrong server error",
          icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
          style: {
            backgroundColor: "#e74c3c",
            color: "#fff",
            border: "1px solid #c0392b",
          },
          duration: 5,
          placement: "topRight",
        });
      }
    } catch (error) {
      setLoading(false);

      notification.error({
        message: "Error: Something went wrong server error",
        icon: <ExclamationCircleOutlined style={{ color: "#fff" }} />,
        style: {
          backgroundColor: "#e74c3c",
          color: "#fff",
          border: "1px solid #c0392b",
        },
        duration: 5,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  // const onChange = (checked) => {
  // };
  const permission = useMemo(
    () => userData?.data?.roleData?.[0]?.role_permission,
    [userData?.data?.roleData]
  );
  return (
    <>
      <div>
        <div className="bk-div float-start w-100">
          {" "}
          {/* <hr className="sp-hr" /> */}
          <div className="body-expart-div">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Exotel</h2>
              {permission?.[11]?.exotel?.add && <button
                className="btn viewButton"
                onClick={() => setModal2Open(true)}
                size="large"
              >
                New Call
              </button>}
            </div>

            <Input
              placeholder="Search"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              prefix={<SearchOutlined />}
              style={{ marginBottom: 16 }}
              size="large"
            />
            <Table
              dataSource={exotelList}
              columns={columns}
              pagination={false}
              onChange={handleTableChange}
              scroll={{ x: 800 }}
              size="large"
              loading={loading}
            />
            <Pagination
              {...paginationSettings}
              showSizeChanger={false}
              style={{ marginTop: 16, marginBottom: 16, textAlign: "right" }}
            />
          </div>
        </div>
      </div>

      <div
        className="offcanvas ttt offcanvas-end"
        tabIndex="-1"
        id="submitted"
        aria-labelledby="offcanvasRightLabel"
      >
        {/* {loading1 ? ( */}
        <>
          <div
            className="offcanvas-header align-items-center justify-content-between"
            style={{
              borderBottom: "1px solid rgb(197, 195, 195)",
            }}
          >
            <button
              type="button"
              className="btn-close "
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
            <h5 id="offcanvasRightLabel">Call Records</h5>
          </div>
          <div className="offcanvas-body">
            {popData && (<>
              <div className="experien-list mt-4">
                <div>
                  <ul className="nextyn_list_items">
                    <li className="nextyn_list_item border-bottom-0">
                      <div className="expertCompany_2UyI2">
                        <span className="title_1p_LH">From:</span>
                        <div className="companyName_1dSwA">{popData?.from}</div>
                      </div>
                      <div className="expertCompany_2UyI2">
                        <span className="title_1p_LH">To:</span>
                        <div className="companyName_1dSwA">{popData?.to}</div>
                      </div>
                      <div className="expertCompany_2UyI2">
                        <span className="title_1p_LH">Through:</span>
                        <div className="companyName_1dSwA">{popData?.forwardedFrom}</div>
                      </div>
                    </li>
                    <li className="nextyn_list_item border-bottom-0">
                      <div className="expertCompany_2UyI2">
                        <span className="title_1p_LH">Call Reference Id:</span>
                        <div className="companyName_1dSwA">{popData?.callerId}</div>
                      </div>
                      <div className="expertCompany_2UyI2">
                        <span className="title_1p_LH">Total Talk Time:</span>
                        <div className="companyName_1dSwA">{popData?.duration}</div>
                      </div>
                    </li>
                    <li className="nextyn_list_item border-bottom-0">
                      <div className="expertCompany_2UyI2">
                        <span className="title_1p_LH">Start Time:</span>
                        <div className="companyName_1dSwA">
                          {popData?.startTime}
                        </div>
                      </div>
                      <div className="expertCompany_2UyI2">
                        <span className="title_1p_LH">End Time:</span>
                        <div className="companyName_1dSwA">
                          {popData?.endTime}
                        </div>
                      </div>

                    </li>
                    <li className="">
                      <div className="expertCompany_2UyI2">
                        <span className="title_1p_LH">Transcription:</span>
                        <div className="companyName_1dSwA">
                          <AudioPlayer
                            autoPlay
                            src={popData?.recordingUrl}
                            onPlay={e => console.info("onPlay")}
                          // other props here
                          />
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </>)}

          </div>
        </>
        {/* ) : (
            ""
          )} */}
      </div>

      <Modal
        title={false}
        style={{
          top: 20,
        }}
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
        width={800}
        visible={true}
        footer={null}
      >
        <div className="mt-5">
          <h4 className="modalHeader">Dial Out</h4>
          <Form name="form_item_path" layout="vertical" onFinish={onFinish}>
            <MyFormItemGroup prefix={["user"]}>
              <MyFormItemGroup prefix={["name"]}>
                <MyFormItem name="country_code" label="Country Code">
                  <Select
                    size="large"
                    onChange={handleChange}
                    placeholder="Select Country Code"
                    virtual={false}
                    allowClear
                  >
                    {countryOptions.map((country) => (
                      <Select.Option key={country.code} value={country.code}>
                        {`${country.name} (${country.code})`}
                      </Select.Option>
                    ))}
                  </Select>
                </MyFormItem>

                <Form.Item
                  label="Contact Number"
                  name="contact_number"
                  rules={[
                    { required: true, message: "Mobile number is required!" },
                  ]}
                  validateStatus={errorMessage ? "error" : ""}
                  help={errorMessage}
                >
                  <Input
                    name="number"
                    size="large"
                    value={connectExotel?.number}
                    maxLength={10}
                    onChange={handleMobileNumberChange}
                  />
                </Form.Item>
              </MyFormItemGroup>
            </MyFormItemGroup>

            <div className="d-flex justify-content-end align-items-center">
              <button className="btn viewButton" size="large" htmlType="submit">
                Call
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default ExotelTable;
