import Aos from "aos";
import "aos/dist/aos.css";
import React from "react";
import { NavLink } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
// import API_PATH from "../Constants/api-path";
import { Col, Row } from "react-bootstrap";
import loginGIf from "../../assests/images/Login.gif";
import nextyn_logo from "../../assests/images/NX19.1.png";
import logoSvg from "../../assests/images/NX19.png";
import ResetPasswordForm from "./ResetPasswordForm";

function ResetPasssword() {
  Aos.init({
    offset: 100,
    easing: "ease",
    delay: 0,
    once: true,
    duration: 800,
  });

  // useEffect(() => {
  //   getExpiredTime();
  //   if (isExpired) {
  //     history("/expire");
  //   }
  // }, [isExpired]);

  // const getExpiredTime = async () => {
  //   try {
  //     let d = new Date();
  //     const params = new Proxy(new URLSearchParams(window.location.search), {
  //       get: (searchParams, prop) => searchParams.get(prop),
  //     });
  //     const res = await fetch(`${API_PATH.EXPIRE_TIME}`, {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({
  //         email: atob(params.email),
  //       }),
  //     });

  //     const result = await res.json();
  //     if (result.data) {
  //       let expiredDate = result.data;
  //       if (expiredDate < d.getTime()) {
  //         setExpired(true);
  //       }
  //     }
  //     else if(result.data === 0) {
  //       setExpired(true);
  //     }
  //   } catch (error) {
  //     return toast.error("something went wrong server error", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 3000,
  //     });
  //   }
  // };

  document.title = "Expert Cloud - Login";
  return (
    <>
      {/* {isExpired ? (
        <ExpiredPage />
      ) : ( */}
      <div className="login-page">
        <div className="">
          <div className="row row-cols-1 row-cols-lg-2">
            <div className="col d-none d-md-block">
              <div className="login-left-part">
                <Row className="justify-content-center">
                  <Col xs={11} className="mt-3">
                    <NavLink to="/admin-login" className="logo">
                      <img
                        src={nextyn_logo}
                        alt="logo"
                        style={{ maxWidth: "30%" }}
                      />
                    </NavLink>
                  </Col>
                  <Col lg={8} className="logo-in-div">
                    <h1 className="text-left text-white" data-aos="fade-down">
                      Consulting experts is <br /> as easy as a click of a
                      button.
                    </h1>
                  </Col>

                  <Col lg={8}>
                    <div className="img-box-1">
                      <img alt="login_gif" src={loginGIf}></img>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="col position-relative">
              <div className="row justify-content-center align-items-lg-center scrollableCls">
                <div className="comon-fild col-lg-8">
                  <div className="inside-login registerPage">
                    <NavLink
                      to="/admin-login"
                      className="logo d-block d-lg-none"
                    >
                      <img src={logoSvg} alt="logo" />
                    </NavLink>
                    <h4> Reset Password? 🔒 </h4>
                    <p className="mb-2" style={{ color: "#5746ec" }}>
                      Your new password must be different from previously used
                      passwords
                    </p>
                    <ResetPasswordForm />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
}
export default ResetPasssword;
