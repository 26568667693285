import nextyn_logo from "../../assests/images/next_l.png";
import nextyn_srt_logo from "../../assests/images/nextyn_small_logo.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import "../../assests/css/ExpertSubmittedProfile.css";

export const generateExpertProfile = async (profiles, preview = false) => {
  let data = Array.isArray(profiles) ? profiles : [profiles];
  try {
    const root = document.getElementById("root");
    root.classList.add("generatePDF");

    // Initialize jsPDF object with compression
    const pdf = new jsPDF({ compress: true });

    // Get the width and height of the PDF page
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Define constants for the footer and top margin
    const footerHeight = 25;
    const topMargin = 8;

    // Available space for content on a single page
    let pageHeight = pdfHeight - footerHeight - topMargin;

    // Loop through each expert profile to generate content for each one
    for (let i = 0; i < data.length; i++) {
      // Create a container for the current profile
      let cont = document.createElement("div");
      cont.setAttribute("id", `generatedPDF_${i}`);
      cont.style.position = "fixed";
      cont.style.top = "0";
      cont.style.zIndex = 0;
      cont.style.padding = "30px";
      cont.style.width = "1100px";
      cont.style.height = "auto"; // Set auto height to fit the profile content

      // Set the content of the container with the current expert's data
      cont.innerHTML = pdfContent([data[i]]);
      document.body.appendChild(cont); // Add the container to the body

      // Use html2canvas to convert the content of the current profile to a canvas
      await html2canvas(cont, {
        scale: 2, // Increase scale for higher resolution
        x: 0,
        y: 0,
        width: 1100, // Set canvas width to fit content
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgProps = pdf.getImageProperties(imgData); // Get image properties

        // Calculate the image height relative to PDF width
        const imgWidth = pdfWidth;
        const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

        // If the image height exceeds the page height, split it into multiple pages
        let position = 0;
        const tempCanvas = document.createElement("canvas");
        tempCanvas.width = canvas.width;
        tempCanvas.height = (pageHeight * canvas.width) / pdfWidth;
        const tempContext = tempCanvas.getContext("2d");

        while (position < canvas.height) {
          tempContext.clearRect(0, 0, tempCanvas.width, tempCanvas.height);
          tempContext.drawImage(
            canvas,
            0,
            position,
            canvas.width,
            tempCanvas.height,
            0,
            0,
            tempCanvas.width,
            tempCanvas.height
          );

          const croppedImgData = tempCanvas.toDataURL("image/png");

          // For the first profile, no need to add a new page, otherwise add new pages
          if (position > 0 || i > 0) {
            pdf.addPage();
          }

          // Add the cropped image to the current page
          pdf.addImage(
            croppedImgData,
            "PNG",
            0,
            topMargin,
            pdfWidth,
            pageHeight
          );

          position += tempCanvas.height;

          // Add footer
          pdf.setFontSize(10);
          pdf.setTextColor("#C41C36");
          pdf.text(
            "Nextyn Advisory Private Limited (CIN:U74999MH2018PTC304669)",
            pdfWidth / 2,
            pdfHeight - 15,
            { align: "center" }
          );
          pdf.setFontSize(8);
          pdf.setTextColor(150, 150, 150);
          pdf.text(
            "Strictly Private and Confidential",
            pdfWidth / 2,
            pdfHeight - 10,
            { align: "center" }
          );
          pdf.addImage(nextyn_srt_logo, pdfWidth - 20, pdfHeight - 18, 10, 10);
        }
      });

      // Remove the temporary container after each profile's canvas is created
      document.body.removeChild(cont);
    }

    // Remove the PDF generating class from the root element
    root.classList.remove("generatePDF");

    // Save the PDF file
    if (preview) {
      // Remove the PDF generating class from the root element
      root.classList.remove("generatePDF");

      // Convert the PDF to Blob and show preview
      const pdfBlob = pdf.output("blob"); // Convert the PDF to a Blob object

      // Show the PDF preview in a modal or iframe
      const pdfUrl = URL.createObjectURL(pdfBlob);
      return pdfUrl;
    } else {
      pdf.save(
        `Qualified Expert Profiles ${data?.[0]?.projects?.[0]?.project_title
          ? `- ${data?.[0]?.projects?.[0]?.project_title}`
          : ""
        }.pdf`
      );

      return true;
    }
  } catch (error) {
    console.error("error: ", error);
    return false;
  }
};

const pdfContent = (expertProfiles) => {
  return expertProfiles
    ?.map((data) => {
      let currentDesignationHTML =
        data?.expert_experience?.filter((dat) => dat?.current_designation)
          ?.length > 0
          ? data?.expert_experience
            ?.filter((dat) => dat?.current_designation)
            ?.map((exp) => {
              if (exp?.show_check === "yes") {
                return `<tr><td style="font-weight: 600; width:580px;border-bottom:1px solid #c6c6c6;border-right:1px solid #c6c6c6;padding:10px;">${exp?.current_designation
                  }, ${exp?.current_compny
                  }</td><td style="font-weight: 600; width:35%;border-bottom:1px solid #c6c6c6;padding:10px;">${exp?.current_designation_date?.split("-")?.[0]?.trim()
                    ? moment(
                      exp?.current_designation_date
                        ?.split("-")?.[0]
                        ?.trim(),
                      ["MM, YYYY"]
                    )?.format("MMM YYYY")
                    : "NA"
                  } - ${exp?.till_present === "yes"
                    ? "Present"
                    : exp?.current_designation_date?.split("-")?.[1]?.trim()
                      ? moment(
                        exp?.current_designation_date
                          ?.split("-")?.[1]
                          ?.trim(),
                        ["MM, YYYY"]
                      )?.format("MMM YYYY")
                      : "NA"
                  }</td></tr>`;
              } else {
                return `<tr><td style="width:580px;border-bottom:1px solid #c6c6c6;border-right:1px solid #c6c6c6;padding:10px;">${exp?.current_designation
                  }, ${exp?.current_compny
                  }</td><td style="width:35%;border-bottom:1px solid #c6c6c6;padding:10px;">${exp?.current_designation_date?.split("-")?.[0]?.trim()
                    ? moment(
                      exp?.current_designation_date
                        ?.split("-")?.[0]
                        ?.trim(),
                      ["MM, YYYY"]
                    )?.format("MMM YYYY")
                    : "NA"
                  } - ${exp?.till_present === "yes"
                    ? "Present"
                    : exp?.current_designation_date?.split("-")?.[1]?.trim()
                      ? moment(
                        exp?.current_designation_date
                          ?.split("-")?.[1]
                          ?.trim(),
                        ["MM, YYYY"]
                      )?.format("MMM YYYY")
                      : "NA"
                  }</td></tr>`;
              }
            })
            .join("")
          : "";
      // : `<tr style="width:100%;"><td> - </td></tr>`;

      let previousDesignationHTML =
        data?.expert_experience?.filter((dat) => dat?.previous_designation)
          ?.length > 0
          ? data?.expert_experience
            ?.filter((dat) => dat?.previous_designation)
            ?.map((data) => {
              if (data?.show_check === "yes") {
                return `<tr><td style="font-weight: 600; width:580px;border-bottom:1px solid #c6c6c6;border-right:1px solid #c6c6c6;padding:10px;">${data?.previous_designation
                  }, ${data?.previous_compny
                  }</td><td style="font-weight: 600; width:35%;border-bottom:1px solid #c6c6c6;padding:10px;">${data?.previous_designation_date?.split("-")?.[0]?.trim()
                    ? moment(
                      data?.previous_designation_date
                        ?.split("-")?.[0]
                        ?.trim(),
                      ["MM, YYYY"]
                    )?.format("MMM YYYY")
                    : "NA"
                  } - ${data?.previous_designation_date?.split("-")?.[1]?.trim()
                    ? moment(
                      data?.previous_designation_date
                        ?.split("-")?.[1]
                        ?.trim(),
                      ["MM, YYYY"]
                    )?.format("MMM YYYY")
                    : "NA"
                  }</td></tr>`;
              } else {
                return `<tr><td style="width:580px;border-bottom:1px solid #c6c6c6;border-right:1px solid #c6c6c6;padding:10px;">${data?.previous_designation
                  }, ${data?.previous_compny
                  }</td><td style="width:35%;border-bottom:1px solid #c6c6c6;padding:10px;">${data?.previous_designation_date?.split("-")?.[0]?.trim()
                    ? moment(
                      data?.previous_designation_date
                        ?.split("-")?.[0]
                        ?.trim(),
                      ["MM, YYYY"]
                    )?.format("MMM YYYY")
                    : "NA"
                  } - ${data?.previous_designation_date?.split("-")?.[1]?.trim()
                    ? moment(
                      data?.previous_designation_date
                        ?.split("-")?.[1]
                        ?.trim(),
                      ["MM, YYYY"]
                    )?.format("MMM YYYY")
                    : "NA"
                  }</td></tr>`;
              }
            })
            .join("")
          : "";
      // : `<tr style="width:100%;"><td > - </td></tr>`;

      return `<div class="ex_sm_wrppr_pa">
 <div id="ex_sm_wrppr">
  <div class="ex_sm_wrppr">
   <div>
    <div style="text-align:center">
     <img style="max-width: 300px;width: 100%;" src="${nextyn_logo}" alt="Nextyn Logo" />
    </div>
    <div style="text-align:center">
     <h3>QUALIFIED EXPERT PROFILE</h3>
    </div>
   </div>
   <div class="tp_brdr_wrpr">
    <div class="tp_brdr" style=${!data?.profile_number ? "display:none" : ""}>
     <h5>EXPERT PROFILE NO. ${data?.profile_number || ""}</h5>
    </div>
    <div>
     <table style="border: 1px solid #c6c6c6;">
      <tr style=${!data?.industries_expertise ? "display:none" : ""}>
       <td style="color:#C41C36;font-weight:600;background-color:#f2f2f2;width:300px;border-bottom:1px solid #c6c6c6;border-right:1px solid #c6c6c6;padding:10px">
        <span>Industry/Area of Expertise</span>
       </td>
       <td style="border-bottom:1px solid #c6c6c6;padding:10px;">
        <strong>${data?.industries_expertise}</strong>
       </td>
      </tr>
      <tr style=${!data?.current_location ? "display:none" : ""}>
       <td style="color:#C41C36;font-weight:600;background-color:#f2f2f2;width:300px;border-bottom:1px solid #c6c6c6;border-right:1px solid #c6c6c6;padding:10px"> Current Location </td>
       <td style="border-bottom: 1px solid #c6c6c6;padding:10px;">${data?.current_location
        }</td>
      </tr>
      <tr class="tbl"  style=${!data?.years_of_experience ? "display:none" : ""
        }>
       <td style="color:#C41C36;font-weight:600;background-color:#f2f2f2;width:300px;border-bottom:1px solid #c6c6c6;border-right:1px solid #c6c6c6;padding:10px"> Years of Industry Experience </td>
       <td style="border-bottom: 1px solid #c6c6c6;padding:10px;">${data?.years_of_experience
        }+ Years </Col>
      </tr>
      ${currentDesignationHTML !== "" ? `<tr class="tbl">
       <td style="color:#C41C36;font-weight:600;background-color:#f2f2f2;width:300px;border-bottom:1px solid #c6c6c6;border-right:1px solid #c6c6c6;padding:10px">
        <span> Current Designation </span>
       </td>
       <td>
        <table>
         <tbody> ${currentDesignationHTML} </tbody>
        </table>
       </td>
      </tr>`: ""}
     ${previousDesignationHTML !== "" ? ` <tr>
       <td style="color:#C41C36;font-weight:600;background-color:#f2f2f2;width:300px;border-bottom:1px solid #c6c6c6;border-right:1px solid #c6c6c6;padding:10px">
        <span> Previous Designation </span>
       </td>
       <td>
        <table> ${previousDesignationHTML} </table>
       </td>
      </tr>`: ""}
      <tr class="tbl"  style=${!data?.overview ? "display:none" : ""}>
       <td style="color:#C41C36;font-weight:600;background-color:#f2f2f2;width:300px;border-bottom:1px solid #c6c6c6;border-right:1px solid #c6c6c6;padding:10px">
        <span class="h-100 d-flex align-items-center"> Overview </span>
       </td>
       <td style="border-bottom:1px solid #c6c6c6;padding:10px;width:70%; word-wrap: break-word; word-break: break-word; overflow-wrap: break-word;"> ${data?.overview
        } </td>
      </tr>
      <tr class="tbl" style=${!data?.hourly_consulting_rate ? "display:none" : ""
        }>
       <td style="color: #C41C36;font-weight: 600;background-color: #f2f2f2;width: 300px;border-right:1px solid #c6c6c6;padding:10px"> Hourly Consulting Rate </td>
       <td style="padding:10px;">
        <strong>${data?.expert_client_Currency || "USD"} ${data?.hourly_consulting_rate
        }</strong>
       </td>
      </tr>
     </table>
    </div>
   </div>
  </div>
 </div>
</div>
    `;
    })
    .join("");

  // return `<div class="ex_sm_wrppr_pa">
  //               <div id="ex_sm_wrppr">
  //                 <div class="ex_sm_wrppr">
  //                 <div>
  //                     <div style="text-align:center">
  //                       <img
  //                         style="max-width: 300px;width: 100%;"
  //                         src="${nextyn_logo}"
  //                         alt="Nextyn Logo"
  //                       />
  //                     </div>
  //                     <div style="text-align:center">
  //                       <h3>QUALIFIED EXPERT PROFILE</h3>
  //                     </div>
  //                   </div>
  //                   <div class="tp_brdr_wrpr">
  //                   ${multiProfile}
  //                   </div>
  //                   </div></div></div>`;
};

// export default ExpertSubmittedProfile;
