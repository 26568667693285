import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import API_PATH from "../../Constants/api-path";

import { Card, Select, Tooltip } from "antd";
import moment from "moment";
import { FaFilter } from "react-icons/fa";
import { LuFilter } from "react-icons/lu";
import { formatNumber, getFinancialYear } from "../../Utils/util";
const { Option } = Select;

function ProjectStats() {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [managerFilterList, setManagerFilterList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [teamMemberList, setTeamMemberList] = useState([]);
  const [statisticData, setStatisticData] = useState({
    currentMonth: [],
    financialYearData: [],
    previousMonthData: [],
  });
  const [show, setShow] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    project_name: "",
    client_name: "",
    added_by: "",
    manager_id: "",
    date: "",
    project_status: "",
  });
  const getProjectStats = React.useCallback(
    async (options) => {
      let object = {};
      if (options?.project_name) {
        object.project_name = options?.project_name;
      }
      if (options?.client_name) {
        object.company_id = options?.client_name;
      }
      if (options?.added_by) {
        object.added_by = options?.added_by;
      }
      if (options?.manager_id) {
        object.manager_id = options?.manager_id;
      }
      if (options?.project_status) {
        object.project_status = options?.project_status;
      }
      if (options?.date && !["", undefined, "undefined"]?.options?.date) {
        object.sdate = options?.date;
      }

      try {
        const queryParams = new URLSearchParams(object);
        const response = await axios.get(
          API_PATH.PROJECT_STATS +
          `${userData?.data?.email}?${queryParams?.toString()}`
        );
        setManagerFilterList(response?.data?.allManagers);
        setProjectList(response?.data?.projectTitles);
        setClientList(response?.data?.allClients);
        setTeamMemberList(response?.data?.allTeams);
        setStatisticData({
          currentMonth: response?.data?.currentMonthData,
          financialYearData: response?.data?.financialYearData,
          previousMonthData: response?.data?.previousMonthData,
        });
      } catch (error) {
        console.error("error: ", error);
      }
    },
    [userData?.data?.email]
  );

  const onChange = React.useCallback(
    (value, key) => {
      const updatedValue = value === undefined ? "" : value;
      let options = { ...selectedOptions, [key]: updatedValue };
      setSelectedOptions(options);
      getProjectStats(options);
    },
    [getProjectStats, selectedOptions]
  );
  const callOnce = useRef(false);

  useEffect(() => {
    if (!callOnce.current) {
      callOnce.current = true;
      getProjectStats(selectedOptions);
    }
  }, [getProjectStats, selectedOptions]);

  return (
    <div>
      <div className="dasb-div bk-div float-start w-100">
        <div className="body-expart-div">
          <span className="d-flex align-items-center mb-3">
            <p className="fs-5">
              <strong style={{ color: "#5746ec" }}>Project Statistics </strong>
            </p>
            <Tooltip
              title="filter"
              overlayInnerStyle={{
                background: "#5746EC",
              }}
            >
              <span
                onClick={() => {
                  setShow(!show);
                }}
                className="ps-3"
                style={{ cursor: "pointer" }}
              >
                {show ? (
                  <LuFilter color="#5746EC" />
                ) : (
                  <FaFilter color="#5746EC" />
                )}
              </span>
            </Tooltip>
          </span>
          {show && (
            <Row className="mb-2 g-2">
              <Col>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Client Name"
                  className="input-size"
                  size="large"
                  showSearch
                  value={selectedOptions?.client_name || null}
                  onChange={(value) => {
                    onChange(value, "client_name");
                  }}
                  filterOption={(input, option) =>
                    option?.props?.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) !== -1
                  }
                  allowClear
                >
                  {clientList &&
                    clientList?.map((option, index) => (
                      <Option value={option?._id} key={index}>
                        {option?.clients?.[0]}
                      </Option>
                    ))}
                </Select>
              </Col>
              <Col>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Project Name"
                  className="input-size"
                  size="large"
                  showSearch
                  value={selectedOptions?.project_name || null}
                  onChange={(value) => onChange(value, "project_name")}
                  filterOption={(input, option) =>
                    option?.props?.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) !== -1
                  }
                  allowClear
                >
                  {projectList &&
                    projectList?.map((option, index) => (
                      <Option value={option?.project_title} key={index}>
                        {option?.project_title}
                      </Option>
                    ))}
                </Select>
              </Col>

              {(userData?.data?.roleData?.[0]?.name === "Super Admin" ||
                userData?.data?.roleData?.[0]?.name === "Manager") && (
                  <Col>
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Team Member"
                      className="input-size"
                      size="large"
                      showSearch
                      value={selectedOptions?.added_by || null}
                      onChange={(value) => onChange(value, "added_by")}
                      filterOption={(input, option) =>
                        option?.props?.children
                          ?.toLowerCase()
                          .indexOf(input?.toLowerCase()) !== -1
                      }
                      allowClear
                    >
                      {teamMemberList &&
                        teamMemberList?.map((option, index) => (
                          <Option value={option?._id} key={index}>
                            {option?.name}
                          </Option>
                        ))}
                    </Select>
                  </Col>
                )}
              {userData?.data?.roleData?.[0]?.name === "Super Admin" && (
                <Col>
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Manager"
                    className="input-size"
                    size="large"
                    showSearch
                    value={selectedOptions?.manager_id || null}
                    onChange={(value) => onChange(value, "manager_id")}
                    filterOption={(input, option) =>
                      option?.props?.children
                        ?.toLowerCase()
                        .indexOf(input?.toLowerCase()) !== -1
                    }
                    allowClear
                  >
                    {managerFilterList &&
                      managerFilterList?.map((option, index) => (
                        <Option value={option?._id} key={index}>
                          {option?.manager}
                        </Option>
                      ))}
                  </Select>
                </Col>
              )}
              <Col>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Project Status"
                  className="input-size"
                  size="large"
                  showSearch
                  value={selectedOptions?.project_status || null}
                  onChange={(value) => onChange(value, "project_status")}
                  filterOption={(input, option) =>
                    option?.props?.children
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) !== -1
                  }
                  allowClear
                >
                  <Option value={"active"} key={1}>
                    {"Active"}
                  </Option>
                  <Option value={"on-hold"} key={2}>
                    {"On Hold"}
                  </Option>
                  <Option value={"close"} key={3}>
                    {"Closed"}
                  </Option>
                </Select>
              </Col>
              {/* <Col>
                        <DatePicker
                            size="large"
                            style={{ width: "100%", height: "40px" }}
                            format="YYYY-MM-DD"
                            className="input-size"
                            value={
                                selectedOptions?.date
                                    ? dayjs(selectedOptions?.date, "YYYY-MM-DD")
                                    : ""
                                // selectedOptions?.date?.[0] && selectedOptions?.date?.[1]
                                //     ? [
                                //         dayjs(selectedOptions?.date?.[0], "YYYY-MM-DD"),
                                //         dayjs(selectedOptions?.date?.[1], "YYYY-MM-DD"),
                                //     ]
                                //     : []
                            }
                            onChange={(date, dateString) => onChange(dateString, "date")}
                            allowClear
                        />
                    </Col> */}
            </Row>
          )}
          <Row className="g-2 mb-3">
            <Col>
              <Card
                title={
                  <span className="d-flex justify-content-between">
                    <span>Performance Statistics (Numbers)</span>
                    <span>1 Apr {getFinancialYear()} - Present</span>
                  </span>
                }
                bordered={false}
              >
                <div className="row">
                  <div className="col-md-4 col-4">
                    <span>
                      Profiles Sent
                      <span>
                        <h6 className="col-indigo fw-bold">
                          {statisticData?.financialYearData?.[0]
                            ?.no_of_profiles_sent || 0}
                        </h6>
                      </span>
                    </span>
                  </div>
                  <div className="col-md-4 col-4">
                    <span>
                      Expected Calls
                      <span>
                        <h6 className="col-indigo fw-bold">
                          {statisticData?.financialYearData?.[0]
                            ?.no_of_calls_expected || 0}
                        </h6>
                      </span>
                    </span>
                  </div>
                  <div className="col-md-4 col-4">
                    <span>
                      Completed Calls
                      <span>
                        <h6 className="col-indigo fw-bold">
                          {statisticData?.financialYearData?.[0]
                            ?.no_of_calls_completed || 0}
                        </h6>
                      </span>
                    </span>
                  </div>
                </div>
              </Card>
            </Col>
            <Col>
              <Card
                title={
                  <span className="d-flex justify-content-between">
                    <span>Performance Statistics (Percentage)</span>
                    <span>1 Apr {getFinancialYear()} - Present</span>
                  </span>
                }
                bordered={false}
              >
                <div className="row">
                  <div className="col-md-6 col-6">
                    <span style={{ whiteSpace: "nowrap" }}>Fill Rate</span>
                    <h6 className="col-indigo fw-bold">
                      {formatNumber(
                        (statisticData?.financialYearData?.[0]
                          ?.no_of_profiles_sent /
                          (2 *
                            statisticData?.financialYearData?.[0]
                              ?.no_of_calls_expected)) *
                        100 || 0
                      )}
                      %
                    </h6>
                  </div>
                  <div className="col-md-6 col-6">
                    <span style={{ whiteSpace: "nowrap" }}>Call Rate</span>
                    <h6 className="col-indigo fw-bold">
                      {formatNumber(
                        (statisticData?.financialYearData?.[0]
                          ?.no_of_calls_completed /
                          statisticData?.financialYearData?.[0]
                            ?.no_of_calls_expected) *
                        100 || 0
                      )}
                      %
                    </h6>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="g-2 mb-3">
            <Col>
              <Card
                title={
                  <span className="d-flex justify-content-between">
                    <span>Performance Statistics (Numbers)</span>
                    <span>{moment().format("MMMM YYYY")}</span>
                  </span>
                }
                bordered={false}
              >
                <div className="row">
                  <div className="col-md-4 col-4">
                    <span>
                      Profiles Sent
                      <span>
                        <h6 className="col-indigo fw-bold">
                          {statisticData?.currentMonth?.[0]
                            ?.no_of_profiles_sent || 0}
                        </h6>
                      </span>
                    </span>
                  </div>
                  <div className="col-md-4 col-4">
                    <span>
                      Expected Calls
                      <span>
                        <h6 className="col-indigo fw-bold">
                          {statisticData?.currentMonth?.[0]
                            ?.no_of_calls_expected || 0}
                        </h6>
                      </span>
                    </span>
                  </div>
                  <div className="col-md-4 col-4">
                    <span>
                      Completed Calls
                      <span>
                        <h6 className="col-indigo fw-bold">
                          {statisticData?.currentMonth?.[0]
                            ?.no_of_calls_completed || 0}
                        </h6>
                      </span>
                    </span>
                  </div>
                </div>
              </Card>
            </Col>
            <Col>
              <Card
                title={
                  <span className="d-flex justify-content-between">
                    <span>Performance Statistics (Percentage)</span>
                    <span>{moment().format("MMMM YYYY")}</span>
                  </span>
                }
                bordered={false}
              >
                <div className="row">
                  <div className="col-md-6 col-6">
                    <span style={{ whiteSpace: "nowrap" }}>Fill Rate</span>
                    <h6 className="col-indigo fw-bold">
                      {formatNumber(
                        (statisticData?.currentMonth?.[0]?.no_of_profiles_sent /
                          (2 *
                            statisticData?.currentMonth?.[0]
                              ?.no_of_calls_expected)) *
                        100 || 0
                      )}
                      %
                    </h6>
                  </div>
                  <div className="col-md-6 col-6">
                    <span style={{ whiteSpace: "nowrap" }}>Call Rate</span>
                    <h6 className="col-indigo fw-bold">
                      {formatNumber(
                        (statisticData?.currentMonth?.[0]
                          ?.no_of_calls_completed /
                          statisticData?.currentMonth?.[0]
                            ?.no_of_calls_expected) *
                        100 || 0
                      )}
                      %
                    </h6>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          <Row className="g-2">
            <Col>
              <Card
                title={
                  <span className="d-flex justify-content-between">
                    <span>Performance Statistics (Numbers)</span>
                    <span>
                      {moment().subtract(1, "months").format("MMMM YYYY")}
                    </span>
                  </span>
                }
                bordered={false}
              >
                <div className="row">
                  <div className="col-md-4 col-4">
                    <span>
                      Profiles Sent
                      <span>
                        <h6 className="col-indigo fw-bold">
                          {statisticData?.previousMonthData?.[0]
                            ?.no_of_profiles_sent || 0}
                        </h6>
                      </span>
                    </span>
                  </div>
                  <div className="col-md-4 col-4">
                    <span>
                      Expected Calls
                      <span>
                        <h6 className="col-indigo fw-bold">
                          {statisticData?.previousMonthData?.[0]
                            ?.no_of_calls_expected || 0}
                        </h6>
                      </span>
                    </span>
                  </div>
                  <div className="col-md-4 col-4">
                    <span>
                      Completed Calls
                      <span>
                        <h6 className="col-indigo fw-bold">
                          {statisticData?.previousMonthData?.[0]
                            ?.no_of_calls_completed || 0}
                        </h6>
                      </span>
                    </span>
                  </div>
                </div>
              </Card>
            </Col>
            <Col>
              <Card
                title={
                  <span className="d-flex justify-content-between">
                    <span>Performance Statistics (Percentage)</span>
                    <span>
                      {moment().subtract(1, "months").format("MMMM YYYY")}
                    </span>
                  </span>
                }
                bordered={false}
              >
                <div className="row">
                  <div className="col-md-6 col-6">
                    <span style={{ whiteSpace: "nowrap" }}>Fill Rate</span>
                    <h6 className="col-indigo fw-bold">
                      {formatNumber(
                        (statisticData?.previousMonthData?.[0]
                          ?.no_of_profiles_sent /
                          (2 *
                            statisticData?.previousMonthData?.[0]
                              ?.no_of_calls_expected)) *
                        100 || 0
                      )}
                      %
                    </h6>
                  </div>
                  <div className="col-md-6 col-6">
                    <span style={{ whiteSpace: "nowrap" }}>Call Rate</span>
                    <h6 className="col-indigo fw-bold">
                      {formatNumber(
                        (statisticData?.previousMonthData?.[0]
                          ?.no_of_calls_completed /
                          statisticData?.previousMonthData?.[0]
                            ?.no_of_calls_expected) *
                        100 || 0
                      )}
                      %
                    </h6>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default ProjectStats;
